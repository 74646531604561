import { AxiosResponse } from 'axios';
import { RestServiceConnection } from '../restServiceConnection';

const RECIPE_ENDPOINT = '/recipes';

export class RecipeService extends RestServiceConnection {

    getRecipes = async (filters?: any) => {
        this.response = await this.makeRequest({
            method: 'POST',
            url: RECIPE_ENDPOINT + '/list',
            data: {
                ...filters
            }
        }, true) as AxiosResponse;
        return this;
    }

    getRecipeById = async (id: string, company: any) => {
        this.response = await this.makeRequest({
            method: 'POST',
            url: RECIPE_ENDPOINT + '/get',
            data: {
                recipe: id,
                company: company
            }
        }, true);
        return this;
    }

    getIngredientsByCompany = async (filters?: any, company?: string) => {
        this.response = await this.makeRequest({
            method: 'POST',
            url: '/foods/list-all',
            data: {
                ...filters,
                company: company
            }
        }, true);
        return this;
    }

    createRecipe = async (recipe: any) => {

        this.response = await this.makeRequest({
            method: 'POST',
            url: RECIPE_ENDPOINT + '/create',
            data: recipe,
        }, true);
        return this;
    }

    editRecipe = async (recipe: any) => {
        this.response = await this.makeRequest({
            method: 'POST',
            url: RECIPE_ENDPOINT + '/edit',
            data: recipe,
        }, true);
        return this;
    }

    deleteRecipe = async (id: string) => {
        this.response = await this.makeRequest({
            method: 'POST',
            url: RECIPE_ENDPOINT + '/delete',
            data: {
                recipe: id
            }
        }, true);
        return this;
    }

    cloneRecipe = async (id: string) => {
        this.response = await this.makeRequest({
            method: 'POST',
            url: RECIPE_ENDPOINT + '/clone',
            data: {
                recipe: id
            }
        }, true);
        return this;
    }

    toggleRecipeStatus = async (id: string) => {
        this.response = await this.makeRequest({
            method: 'POST',
            url: RECIPE_ENDPOINT + '/toggle',
            data: {
                recipe: id
            }
        }, true);
        return this;
    }

    editRecipeImg = async (id: string, img: File) => {
        const formData = new FormData();
        formData.append('recipe', id);
        formData.append('recipe_img', img);

        this.response = await this.makeRequest({
            method: 'POST',
            url: RECIPE_ENDPOINT + '/add-image',
            data: formData,
            headers: {
                "Content-Type": "multipart/form-data"
            }
        }, true);
        return this;
    }

    deleteRecipeImg = async (id: string) => {
        this.response = await this.makeRequest({
            method: 'POST',
            url: RECIPE_ENDPOINT + '/delete-image',
            data: {
                recipe: id
            }
        }, true);
        return this;
    }

    editGroups = async (id: string, groups: any) => {
        this.response = await this.makeRequest({
            method: 'POST',
            url: RECIPE_ENDPOINT + '/edit-groups',
            data: {
                recipe: id,
                groups: groups
            }
        }, true);
        return this;
    }

    addIngredient = async (ingredient: string, recipe: string, quantity: number, company?: string) => {
        this.response = await this.makeRequest({
            method: 'POST',
            url: '/ingredients/create',
            data: {
                food: ingredient,
                recipe: recipe,
                quantity: quantity,
                order_number: 1,
                company: company
            }
        }, true);
        return this;
    }

    getIngredientsByRecipe = async (recipe: string, company: string) => {
        this.response = await this.makeRequest({
            method: 'POST',
            url: '/ingredients/list',
            data: {
                recipe: recipe,
                company: company
            }
        }, true);
        return this;
    }

    deleteIngredient = async (id: string) => {
        this.response = await this.makeRequest({
            method: 'POST',
            url: '/ingredients/delete',
            data: {
                ingredient: id
            }
        }, true);
        return this;
    }

    downloadDocument = async (id: string) => {
        this.response = await this.makeRequest({
            method: 'POST',
            url: RECIPE_ENDPOINT + '/technical-sheet',
            responseType: 'arraybuffer', // importante para que no se convierta a string
            data: {
                recipe: id
            }
        }, true);
        return this;
    }

}