import { FC, Fragment, useCallback } from "react";
import { useNavigate, useParams } from "react-router-dom";
import Button from "../../../components/bootstrap/Button";
import Spinner from "../../../components/bootstrap/Spinner";
import ErrorMessage from "../../../components/ErrorMessage";
import useFetch from "../../../hooks/useFetch";
import Page from "../../../layout/Page/Page";
import SubHeader, { SubHeaderLeft, SubHeaderRight } from "../../../layout/SubHeader/SubHeader";
import { AlimentService } from "../../../services/aliments/alimentService";
import { Company } from "../../../type/company-type";
import FoodInfoCard from "./FoodInfoCard";
import FoodRecipes from "./FoodRecipes";

const AlimentView: FC = () => {

    const { id = "" } = useParams<{ id: string }>();

    const fetchAliment = useCallback(async () => {
        const alimentService = new AlimentService();
        const response = await alimentService.getAlimentById(id as string);
        return response.getResponseData() as Company;
    }, []);

    const [data, loading, error] = useFetch(fetchAliment);

    const navigate = useNavigate();

    const getContent = () => {
        if (loading) return <Spinner />;
        if (error) return <ErrorMessage error={error} />;

        if (data) {
            return (
                <Fragment>
                    <div className="pt-3 pb-5 d-flex align-items-center">
                        <span className="display-6 fw-bold me-3">{data.name}</span>
                    </div>
                    <div className="row">
                        <div className="col-lg-4">
                            <FoodInfoCard
                                name_en={data.nameEn}
                                food_img={data.foodImage?.id}
                                foodData={data}
                            />
                        </div>
                        <div className="col-lg-8">
                            <FoodRecipes />
                        </div>
                    </div>
                </Fragment>
            )
        }
    };

    return (
        <Fragment>
            <SubHeader>
                <SubHeaderLeft>
                    <Button color='secondary' isLink icon='ArrowBack' onClick={() => navigate(-1)}>
                    </Button>
                </SubHeaderLeft>
                <SubHeaderRight>
                    <Button
                        color="brand"
                        icon="Edit"
                        onClick={() => navigate(`/foods/${id}/edit`, { replace: true })}
                    >
                        Editar
                    </Button>
                </SubHeaderRight>
            </SubHeader>
            <Page container='xxl'>
                <Fragment>
                    {getContent()}
                </Fragment>
            </Page>
        </Fragment>
    )
}

export default AlimentView;