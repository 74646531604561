import Swal from "sweetalert2";
import withReactContent from "sweetalert2-react-content";

interface IConfirmationAlert {
  title: string;
  text: string;
  icon: 'warning' | 'success' | 'error' | 'info' | 'question';
  onConfirm: () => void;
}

const MySwal = withReactContent(Swal);

export const handleConfirmationAlert = async (props: IConfirmationAlert) => {
  return MySwal.fire({
    title: props.title,
    text: props.text,
    icon: props.icon,
    showCancelButton: true,
    confirmButtonColor: '#30b7af',
    cancelButtonColor: '#e5133d',
    confirmButtonText: 'Confirmar',
    cancelButtonText: 'Cancelar',
    heightAuto: false,
  }).then((result) => {
    if (result.isConfirmed) {
      props.onConfirm();
    }
  });
};