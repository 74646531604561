import { FC } from "react";
import Modal, { ModalBody, ModalHeader, ModalTitle } from "../../../components/bootstrap/Modal";
import EditFillValues from "../edit-fill-values/EditFillValues";

export interface IFieldsFilters {
    form?: string;
}

interface CreateTemplateModalProps {
    isOpen: boolean;
    setIsOpen(...args: unknown[]): unknown;
    formFillId: string;
    refetch(): void;
}

const AssignMenuToPatient: FC<CreateTemplateModalProps> = ({ isOpen, setIsOpen, formFillId, refetch }) => {

    return (
        <Modal isOpen={isOpen} setIsOpen={setIsOpen} size='md' titleId='Datos del paciente' isStaticBackdrop>
            <ModalHeader setIsOpen={setIsOpen} className='p-4'>
                <ModalTitle id='patient-data'>Asignar formulario</ModalTitle>
            </ModalHeader>
            <ModalBody className='px-4'>
                {
                    formFillId !== '' && <EditFillValues formFillId={formFillId} setIsOpen={setIsOpen} refetch={refetch} />
                }
            </ModalBody>
        </Modal>
    )
}

export default AssignMenuToPatient;