import { FC, useCallback } from "react";
import { useNavigate, useParams } from "react-router-dom";
import Spinner from "../../../components/bootstrap/Spinner";
import useFetch from "../../../hooks/useFetch";
import { CompanyService } from "../../../services/companies/organizationService";
import { Company } from "../../../type/company-type";
import CompanyForm from "../CompanyForm";
import { toast } from "react-toastify";
import { administrationMenu } from "../../../menu";
import ErrorMessage from "../../../components/ErrorMessage";

const CompanyEdit: FC = () => {

  const navigate = useNavigate();
  const { id } = useParams<{ id: string }>();

  const [data, loading, error] = useFetch(useCallback(async () => {
    const companyService = new CompanyService();
    const response = await companyService.getCompanyById(id as string);
    return response.getResponseData() as Company;
  }, [id]));

  const handleUpdate = async (values: any) => {
    const formData = new FormData();

    for (let value in values) {
      if (values[value]) {
        formData.append(value, values[value]);
      }
    }

    formData.append("company", id as string)

    let hasImg = formData.get("profile_img") ? true : false;

    try {
      const response = await (await (new CompanyService()).editCompany(formData, hasImg)).getResponseData();
      if (response.success) {
        navigate(administrationMenu.companies.path, { replace: true });
        setTimeout(() => {
          toast.success('Organización editada correctamente');
        }, 100)
      } else {
        toast.error(response.message || "Error al editar la organización");
      }
    } catch (error: any) {
      toast.error(error.message || "Error al editar la organización");
    }
  };

  const getContent = () => {
    if (loading) return <Spinner />;

    if (error) return <ErrorMessage message={error} />;

    if (data !== null) {
      const companyData = {
        cif: data.cif !== null ? data.cif : "",
        name: data.name !== null ? data.name : "",
        address: data.address !== null ? data.address : "",
      };

      return <CompanyForm isLoading={false} submit={handleUpdate} companyData={companyData} logo={data.logo} />;
    }
  };

  return (
    <>{getContent()}</>
  );
};

export default CompanyEdit;