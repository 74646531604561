import { useState } from "react";
import { useNavigate } from "react-router-dom";
import { ActivitiesService } from "../../../services/activities/activitiesService";
import { toast } from "react-toastify";
import ActivityForm from "../ActivityForm";
import { menusMenu } from "../../../menu";

const ActivityCreate: React.FC = () => {
    const navigate = useNavigate();

    const [loading, setLoading] = useState<boolean>(false);

    const handleCreateActivity = async (values: any) => {
        setLoading(true);

        try {
            const response = await (await (new ActivitiesService()).createActivity(values)).getResponseData();

            if (response.success) {
                navigate(menusMenu.menus.subMenu.activities.path, { replace: true });
                setTimeout(() => {
                    toast.success('Actividad física creada correctamente');
                }, 100);
            } else {
                toast.error(response.message || 'Error al crear la actividad física');
            }
        } catch (error: any) {
            toast.error(error.message || 'Error al crear la actividad física');
        } finally {
            setLoading(false);
        }
    }

    return <ActivityForm submit={handleCreateActivity} isLoading={loading} />;
}

export default ActivityCreate;