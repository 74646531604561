import { FC, Fragment } from "react";
import Card, { CardBody } from "../../../components/bootstrap/Card";
import Icon from "../../../components/icon/Icon";
import AsyncImg from "../../../components/AsyncImg";

interface OrganizationInfoCard {
    cif: string;
    address: string | null;
    logo: string | null;
    timeSlot: number;
}

const OrganizationInfoCard: FC<OrganizationInfoCard> = ({ cif, address, logo, timeSlot }) => {

    return (
        <Fragment>
            <Card className='shadow-3d-primary'>
                <CardBody>
                    <div className='row g-5 py-3'>
                        <div className='col-12 d-flex justify-content-center'>
                            <AsyncImg isBackground height="120px" width="120px" styles="rounded-circle avatar-contain" id={logo} />
                        </div>
                        <div className='col-12'>
                            <div className='row g-3'>
                                <div className='col-12'>
                                    <div className='d-flex align-items-center'>
                                        <div className='flex-shrink-0'>
                                            <Icon icon='Grid3x3' size='2x' color='primary' />
                                        </div>
                                        <div className='flex-grow-1 ms-3'>
                                            <div className='fw-bold fs-5 mb-0'>
                                                {cif}
                                            </div>
                                            <div className='text-muted'>
                                                CIF
                                            </div>
                                        </div>
                                    </div>
                                </div>
                                <div className='col-12'>
                                    <div className='d-flex align-items-center'>
                                        <div className='flex-shrink-0'>
                                            <Icon icon='Home' size='2x' color='primary' />
                                        </div>
                                        <div className='flex-grow-1 fs-5 ms-3'>
                                            <div className='fw-bold mb-0'>
                                                {address ? address : 'No proporcionada'}
                                            </div>
                                            <div className='text-muted'>
                                                Dirección
                                            </div>
                                        </div>
                                    </div>
                                </div>
                                <div className='col-12'>
                                    <div className='d-flex align-items-center'>
                                        <div className='flex-shrink-0'>
                                            <Icon icon='AccessTime' size='2x' color='primary' />
                                        </div>
                                        <div className='flex-grow-1 fs-5 ms-3'>
                                            <div className='fw-bold mb-0'>
                                                {timeSlot + ' minutos'}
                                            </div>
                                            <div className='text-muted'>
                                                Franja del calendario
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </CardBody>
            </Card>
        </Fragment>
    )
}

export default OrganizationInfoCard;