import { FC, useState } from "react";
import { useNavigate } from "react-router-dom";
import { toast } from 'react-toastify';
import AlimentForm from "../AlimentForm";
import { AlimentService } from "../../../services/aliments/alimentService";

const CreateAliment: FC = () => {
	const navigate = useNavigate();

	const [loading, setLoading] = useState<boolean>(false);

	const handleCreation = async (values: any) => {
		setLoading(true);

		// Capitalize first letter of name
		values.name = values.name.charAt(0).toUpperCase() + values.name.slice(1);
		values.name_en = values.name_en.charAt(0).toUpperCase() + values.name_en.slice(1);

		try {
			const response = await (await (new AlimentService()).createAliment(values)).getResponseData();

			if (response.success) {
				navigate(-1)
				setTimeout(() => {
					toast.success('Alimento creado correctamente');
				}, 100);
			} else {
				toast.error(response.data.errors[0].message || 'Error al crear el alimento');
			}
		} catch (error: any) {
			toast.error('Error al crear el alimento');
		} finally {
			setLoading(false);
		}
	};

	return <AlimentForm submit={handleCreation} isLoading={loading} />;
};

export default CreateAliment;