export const formOptions = [
    { value: 'number', label: 'Número' },
    { value: 'text', label: 'Texto' },
    { value: 'date', label: 'Fecha' },
    { value: 'select', label: 'Selector' },
];

export const formOptions2 = [
    { value: 'number', label: 'Número' },
    { value: 'text', label: 'Texto' },
    { value: 'date', label: 'Fecha' },
];