import { useFormik } from "formik";
import { ChangeEvent, FC, Fragment, useCallback, useState } from "react";
import Button from "../../components/bootstrap/Button";
import Card, { CardBody, CardHeader, CardLabel, CardTitle } from "../../components/bootstrap/Card";
import FormGroup from "../../components/bootstrap/forms/FormGroup";
import Input from "../../components/bootstrap/forms/Input";
import Select from "../../components/bootstrap/forms/Select";
import Spinner from "../../components/bootstrap/Spinner";
import PlaceholderImage from "../../components/extras/PlaceholderImage";
import * as yup from "yup";
import { Companies, CompaniesApiResponse } from "../../type/company-type";
import { CompanyService } from "../../services/companies/organizationService";
import useFetch from "../../hooks/useFetch";
import { RoleService } from "../../services/auth/roleService";
import { Roles, RolesApiResponse } from "../../type/role-type";
import { UserService } from "../../services/users/userService";
import { toast } from "react-toastify";
import { useNavigate, useParams } from "react-router-dom";
import AsyncImg from "../../components/AsyncImg";
import { handleConfirmationAlert } from "../../components/ConfirmationAlert";
import SubHeader, { SubHeaderLeft, SubHeaderRight, SubheaderSeparator } from "../../layout/SubHeader/SubHeader";
import Page from "../../layout/Page/Page";
import { usePrivilege } from "../../components/priviledge/PriviledgeProvider";
import { ROLES } from "../../utils/RoleMapping";
import { colors } from "../../utils/LabelsUtils";
import InputPhone from "react-phone-number-input";
import { CirclePicker, ColorResult } from 'react-color';
import CustomPasswordInput from "../../components/bootstrap/forms/CustomPasswordInput";
import es from "react-phone-number-input/locale/es.json";
import 'react-phone-number-input/style.css';

interface CreateFormProps {
    isLoading: boolean;
    submit: Function;
    userData?: UserForm;
    profileImg?: any;
}

interface UserForm {
    name: string;
    last_name: string;
    email: string;
    dni: string;
    bankAccount: string;
    color: string;
    comments: string;
    address: string;
    telephone: string;
    password: string;
    passwordConfirm: string;
    company: string;
    companyName: string;
    role: string;
    roleName: string;
    birthday_date: string;
}

const userInitialValues: UserForm = {
    name: '',
    last_name: '',
    email: '',
    dni: '',
    bankAccount: '',
    color: '',
    comments: '',
    address: '',
    telephone: '',
    password: '',
    passwordConfirm: '',
    company: '',
    companyName: '',
    role: '',
    roleName: '',
    birthday_date: '',
}

const UserEditSchema = yup.object({
    name: yup.string().min(1, 'Demasiado Corto').max(20, 'Demasiado Largo').matches(/^[a-zA-ZáéíóúüÁÉÍÓÚÜñÑ ]+$/u, 'Sólo se permiten caracteres alfabéticos').required('El nombre es obligatorio'),
    last_name: yup.string().min(1, 'Demasiado Corto').max(50, 'Demasiado Largo').matches(/^[a-zA-ZáéíóúüÁÉÍÓÚÜñÑ ]+$/u, 'Sólo se permiten caracteres alfabéticos').required('Los apellidos son obligatorios'),
    email: yup.string().email('Correo Inválido').required('El email es obligatorio'),
    dni: yup.string().matches(/^[0-9]{8}[TRWAGMYFPDXBNJZSQVHLCKE]$/i, 'Formato de DNI no válido').required('El DNI es obligatorio'),
    //bankAccount: yup.string().test('bankAccount', 'Formato de IBAN no válido', (value: any) => IBAN.isValid(value)),
    telephone: yup.string().matches(/^\+34\d{9}$/, 'Formato necesario +34XXXXXXXXX').required('El teléfono es obligatorio'),
    password: yup.string().matches(/^(?=.*[a-z])(?=.*[A-Z])(?=.*\d)[a-zA-Z\d\S]{8,30}$/, 'La contraseña debe tener al menos 8 caracteres, una mayúscula, una minúscula y un número'),
    passwordConfirm: yup.string().oneOf([yup.ref('password'), ''], 'Las contraseñas no coinciden'),
    birthday_date: yup.date().max(new Date(), 'Fecha no válida'),
    role: yup.string().required('Campo Obligatorio'),
});

const UserCreateSchema = UserEditSchema.concat(
    yup.object({
        password: yup.string().required('La contraseña es obligatoria').matches(/^(?=.*[a-z])(?=.*[A-Z])(?=.*\d)[a-zA-Z\d\S]{8,30}$/, 'La contraseña debe tener al menos 8 caracteres, una mayúscula, una minúscula y un número'),
        passwordConfirm: yup.string().required('Confirmación de contraseña obligatoria').oneOf([yup.ref('password'), ''], 'Las contraseñas no coinciden'),
    })
);

const UserForm: FC<CreateFormProps> = ({ isLoading, submit, userData, profileImg }) => {
    const navigate = useNavigate();
    const priviledge = usePrivilege();
    const { id = '' } = useParams<{ id: string }>();
    const mode = userData ? 'Editar' : 'Crear';
    const userService = new UserService();

    const [selectedImage, setSelectedImage] = useState<any>(null);
    const [organizationSelected, setOrganizationSelected] = useState<string | undefined>(undefined);

    const [organizations, fetchingOrganizations] = useFetch(useCallback(async () => {
        if (!priviledge.userHasRole(ROLES.SUPER_ADMIN)) return [];

        setOrganizationSelected(userData?.company);
        const companyService = new CompanyService();
        const response = await companyService.getOrganizations();
        return response.getResponseData() as CompaniesApiResponse;
    }, []));

    const [roleList] = useFetch(useCallback(async () => {
        if (!organizationSelected) return;

        const roleService = new RoleService();
        const response = await roleService.getRolesByOrganization(organizationSelected);
        return response.getResponseData() as RolesApiResponse;
    }, [organizationSelected]));

    const [adminInfo] = useFetch(useCallback(async () => {
        const response = await userService.getAdminInfo();
        return response.getResponseData() as any;
    }, [id]));

    const handleImageUpload = async (event: React.ChangeEvent<any>) => {
        const file = event.target.files[0];
        const reader = new FileReader();
        reader.onload = () => {
            setSelectedImage(reader.result);
        };
        reader.readAsDataURL(file);

        try {
            const response = await userService.editUserImg(id, file);

            const responseData = response.getResponseData();

            if (responseData.success) {
                toast.success('Imagen actualizada');
            } else {
                toast.error(responseData.message || 'Error al actualizar la imagen');
            }
        } catch (error: any) {
            toast.error("Formato de imagen incorrecto");
        }
    };

    const deleteImage = async () => {
        handleConfirmationAlert({
            title: '¿Estás seguro?',
            text: 'Se eliminará la imagen de perfil',
            icon: 'warning',
            onConfirm: async () => {
                try {
                    const response = await userService.deleteUserImg(id);

                    const responseData = response.getResponseData();

                    if (responseData.success) {
                        setSelectedImage(null);
                        window.location.reload();
                    } else {
                        toast.error(responseData.message || 'Error al eliminar la imagen');
                    }
                } catch (error: any) {
                    toast.error("Error al eliminar la imagen");
                }
            }
        })
    }

    const handleRoleChange = (selectedOption: any) => {
        formik.setFieldValue("role", selectedOption.target.value);
    };

    const getOrganizationsList = () => {
        if (organizations as Companies) {
            return organizations.companies.map((option: { id: string; name: string; }) => {
                return {
                    value: option.id,
                    label: option.name
                }
            })
        }
        return [];
    }

    const getRolesList = () => {
        if (roleList as Roles) {
            return roleList.map((option: { id: string; name: string; }) => {
                return {
                    value: option.id,
                    label: option.name
                }
            })
        }
        return [];
    }

    const getAdminRoles = () => {
        if (adminInfo as Roles) {
            return adminInfo.map((option: { id: string; name: string; }) => {
                return {
                    value: option.id,
                    label: option.name
                }
            })
        }
        return [];
    }

    const formik = useFormik({
        initialValues: userData ? userData : userInitialValues,
        validationSchema: (mode === 'Editar') ? UserEditSchema : UserCreateSchema,
        onSubmit: values => submit(values)
    });

    const verifyClass = (inputFieldID: keyof UserForm) => { return (formik.touched[inputFieldID] && formik.errors[inputFieldID]) ? 'is-invalid' : '' };

    const showErrors = (inputFieldID: keyof UserForm) => {
        return (formik.touched[inputFieldID] && formik.errors[inputFieldID]) ? <div className="invalid-feedback">{formik.errors[inputFieldID]}</div> : <></>;
    }

    const getContent = () => {
        return (
            <form onSubmit={formik.handleSubmit} autoComplete="off">
                <div className="row">
                    <div className='col-md-2'>
                        {mode === 'Editar' && (
                            <Card stretch={false}>
                                <CardBody className="text-center">
                                    <div className='text-center pb-4'>
                                        {selectedImage ?
                                            <img width={200} height={200} src={selectedImage} alt="selected" className='mx-auto d-block img-fluid mb-3 rounded' /> :

                                            profileImg
                                                ? <AsyncImg isBackground height="200px" width="200px" styles="avatar-content rounded" id={profileImg.id} />
                                                : <PlaceholderImage width={200} height={200} className='mx-auto d-block img-fluid mb-3 rounded' />
                                        }
                                    </div>

                                    <Input
                                        type='file'
                                        onChange={(e: React.ChangeEvent<any>) => handleImageUpload(e)}
                                        placeholder={"Cambiar imagen"}
                                        className="mb-3"
                                        autoComplete='photo'
                                    />

                                    <Button color='dark' isLight icon='Delete' onClick={deleteImage}>Eliminar</Button>
                                </CardBody>
                            </Card>
                        )}
                    </div>

                    <div className="col-md-8">
                        <Card stretch={false}>
                            <CardBody>
                                <div className="row">
                                    <CardHeader className='rounded-1 mb-0'>
                                        <CardLabel icon='AccountCircle' iconColor='secondary'>
                                            <CardTitle>Información personal</CardTitle>
                                        </CardLabel>
                                    </CardHeader>

                                    <CardBody>
                                        <div className="row">
                                            <div className="col-md-8">
                                                <div>
                                                    <div className="row">
                                                        <FormGroup requiredInputLabel label='Nombre' className='col-md-3'>
                                                            <Input id='name' onChange={formik.handleChange} value={formik.values.name} onBlur={formik.handleBlur} className={verifyClass('name')} />
                                                            {showErrors('name')}
                                                        </FormGroup>
                                                        <FormGroup requiredInputLabel label='Apellidos' className='col-md-4'>
                                                            <Input id='last_name' onChange={formik.handleChange} value={formik.values.last_name} onBlur={formik.handleBlur} className={verifyClass('last_name')} />
                                                            {showErrors('last_name')}
                                                        </FormGroup>
                                                        <FormGroup requiredInputLabel label='Email' className='col-md-5'>
                                                            <Input id='email' type='email' onChange={formik.handleChange} value={formik.values.email} onBlur={formik.handleBlur} className={verifyClass('email')} />
                                                            {showErrors('email')}
                                                        </FormGroup>
                                                    </div>
                                                    <div className="row mt-4">
                                                        <FormGroup requiredInputLabel label='DNI' className='col-md-3'>
                                                            <Input id='dni' onChange={formik.handleChange} value={formik.values.dni} onBlur={formik.handleBlur} className={verifyClass('dni')} />
                                                            {showErrors('dni')}
                                                        </FormGroup>
                                                        <FormGroup label='Cuenta bancaria' className='col-md-5'>
                                                            <Input id='bankAccount' onChange={formik.handleChange} value={formik.values.bankAccount} className={verifyClass('bankAccount')} />
                                                            {showErrors('bankAccount')}
                                                        </FormGroup>
                                                        <FormGroup requiredInputLabel label='Teléfono' className='col-md-4'>
                                                            <InputPhone
                                                                id='telephone'
                                                                onBlur={formik.handleBlur}
                                                                labels={es}
                                                                international
                                                                defaultCountry="ES"
                                                                onChange={e => { formik.setFieldValue('telephone', e) }}
                                                                value={formik.values.telephone || ''}
                                                                className={verifyClass('telephone')}
                                                            />
                                                            {showErrors('telephone')}
                                                        </FormGroup>
                                                    </div>
                                                    <div className="row mt-2">
                                                        <FormGroup label='Fecha de nacimiento' className='col-md-3'>
                                                            <Input id='birthday_date' type='date' onChange={formik.handleChange} value={formik.values.birthday_date} className={verifyClass('birthday_date')} />
                                                            {showErrors('birthday_date')}
                                                        </FormGroup>
                                                        <FormGroup label='Dirección' className="col-md-9">
                                                            <Input id='address' onChange={formik.handleChange} value={formik.values.address} className={verifyClass('address')} />
                                                            {showErrors('address')}
                                                        </FormGroup>
                                                    </div>
                                                </div>
                                            </div>

                                            <div className="col-md-4">
                                                <FormGroup label='Observaciones'>
                                                    <textarea
                                                        id="comments"
                                                        onChange={(e: ChangeEvent<HTMLTextAreaElement>) => {
                                                            formik.handleChange(e);
                                                        }}
                                                        rows={1}
                                                        value={formik.values.comments}
                                                        style={{
                                                            borderRadius: '10px',
                                                            boxShadow: '0 0 0 3px rgb(245, 156, 57, 0.3)',
                                                            backgroundColor: '#f8f9fa',
                                                            width: '100%',
                                                            minHeight: '180px',
                                                            padding: '15px',
                                                            border: '0'
                                                        }}
                                                    />
                                                    {showErrors('comments')}
                                                </FormGroup>
                                            </div>
                                        </div>
                                    </CardBody>
                                </div>

                                <div className="row">
                                    <FormGroup label='Color (citas y notas)' className='col-md-4'>
                                        <CirclePicker
                                            color={formik.values.color ? formik.values.color : ''}
                                            onChangeComplete={(color: ColorResult) => {
                                                formik.setFieldValue('color', color ? color.hex : null)
                                            }}
                                            colors={colors}
                                            width='50%'
                                        />
                                        {showErrors('color')}
                                    </FormGroup>

                                    <CardBody className='col-md-8'>
                                        <div className="row">
                                            {mode === 'Editar'
                                                ? <CardTitle>Modificar contraseña actual</CardTitle>
                                                : <CardTitle>Seguridad</CardTitle>
                                            }
                                            <CustomPasswordInput
                                                formik={formik}
                                                id='password'
                                                value={formik.values.password}
                                                label="Contraseña"
                                                cols={6}
                                            />

                                            <CustomPasswordInput
                                                formik={formik}
                                                id='passwordConfirm'
                                                value={formik.values.passwordConfirm}
                                                label="Confirmar Contraseña"
                                                cols={6}
                                            />
                                        </div>
                                    </CardBody>
                                </div>
                            </CardBody>
                        </Card>
                    </div>

                    <div className='col-md-2'>
                        <Card stretch={false}>
                            <CardHeader className='rounded-1 mb-0'>
                                <CardLabel icon='Security' iconColor='secondary'>
                                    <CardTitle>Organización</CardTitle>
                                </CardLabel>
                            </CardHeader>
                            <CardBody>
                                {!fetchingOrganizations && organizations && !userData ? (
                                    <Fragment>
                                        <FormGroup requiredInputLabel label='Organización'>
                                            <Select
                                                id='company'
                                                list={getOrganizationsList()}
                                                onChange={(e: ChangeEvent<HTMLInputElement>) => {
                                                    formik.handleChange(e)
                                                    formik.setFieldValue('company', e.target.value)
                                                    setOrganizationSelected(e.target.value)
                                                    formik.setFieldValue('role', '')
                                                }}
                                                value={formik.values.company}
                                                onBlur={formik.handleBlur}
                                                className={verifyClass('company')}
                                                ariaLabel='Listado de organizaciones'
                                                placeholder={formik.values.company ? formik.values.company : 'Elegir...'}
                                            />
                                            {showErrors('company')}
                                        </FormGroup>

                                        <FormGroup requiredInputLabel id='role' label='Rol' className='mt-2'>
                                            <Select
                                                name="role"
                                                id='roles-select'
                                                list={getRolesList() ? getRolesList() : [{ value: '', label: 'No hay roles disponibles' }]}
                                                ariaLabel='Select de roles'
                                                placeholder={'Elegir rol'}
                                                onChange={handleRoleChange}
                                                value={formik.values.role.toString()}
                                                onBlur={formik.handleBlur}
                                                className={verifyClass('role')}
                                            />

                                            {showErrors('role')}
                                        </FormGroup>
                                    </Fragment>
                                ) : (
                                    <FormGroup requiredInputLabel id='role' label='Rol'>
                                        <Select
                                            name="role"
                                            id='roles-select-admin'
                                            ariaLabel='Select de roles'
                                            placeholder={'Listado de roles del admin'}
                                            list={getAdminRoles()}
                                            onChange={handleRoleChange}
                                            value={formik.values.role.toString()}
                                            onBlur={formik.handleBlur}
                                            className={verifyClass('role')}
                                        />

                                        {showErrors('role')}
                                    </FormGroup>
                                )
                                }
                            </CardBody>
                        </Card>
                    </div>
                </div>
            </form >
        )
    }

    return (
        <>
            <SubHeader>
                <SubHeaderLeft>
                    <Button color='secondary' isLink icon='ArrowBack' onClick={() => navigate(-1)}>
                    </Button>
                    <SubheaderSeparator />
                    <CardTitle>{mode} Usuario</CardTitle>
                </SubHeaderLeft>
                <SubHeaderRight>
                    <Button type="submit" color='primary' onClick={formik.handleSubmit}>
                        {isLoading ? <Spinner /> : `${mode} Usuario`}
                    </Button>
                </SubHeaderRight>
            </SubHeader>

            <Page container='fluid'>
                {getContent()}
            </Page>
        </>
    )
}

export default UserForm;