import { FC, useState } from "react";
import { useNavigate } from "react-router-dom";
import { toast } from "react-toastify";
import { CompanyService } from "../../../services/companies/organizationService";
import CompanyForm from "../CompanyForm";
import { administrationMenu } from "../../../menu";

const CreateCompany: FC = () => {

	const navigate = useNavigate();

	const [loading, setLoading] = useState<boolean>(false);

	const handleCreation = async (values: any) => {
		setLoading(true);

		try {
			const response = await (await (new CompanyService()).createCompany(values)).getResponseData();
			if (response.success) {
				setLoading(false);
				navigate(administrationMenu.companies.path, { replace: true });
				setTimeout(() => {
					toast.success('Organización creada');
				}, 100)
			} else {
				toast.error(response.message);
			}
		} catch (error: any) {
			toast.error(error.message);
		} finally {
			setLoading(false);
		}
	}

	return (
		<CompanyForm submit={handleCreation} isLoading={loading} />
	)
}

export default CreateCompany;