import { useFormik } from "formik";
import SubHeader, { SubHeaderLeft, SubHeaderRight, SubheaderSeparator } from "../../layout/SubHeader/SubHeader";
import Button from "../../components/bootstrap/Button";
import Card, { CardBody, CardTitle } from "../../components/bootstrap/Card";
import Spinner from "../../components/bootstrap/Spinner";
import Page from "../../layout/Page/Page";
import { useNavigate } from "react-router-dom";
import FormGroup from "../../components/bootstrap/forms/FormGroup";
import Input from "../../components/bootstrap/forms/Input";
import * as Yup from 'yup';

interface ActivityFormProps {
    submit: (values: any) => void;
    isLoading: boolean;
    activityData?: any;
}

interface ActivityForm {
    name: string;
    description: string;
    time: number;
    calories: number;
}

const activityInitialValues: ActivityForm = {
    name: '',
    description: '',
    time: 0,
    calories: 0,
}

const ActivitySchema = Yup.object().shape({
    name: Yup.string().required('El nombre es obligatorio'),
    description: Yup.string(),
    time: Yup.number().required('El tiempo es obligatorio'),
    calories: Yup.number().required('Las calorías son obligatorias'),
});

const ActivityForm: React.FC<ActivityFormProps> = ({ submit, isLoading, activityData }) => {
    const navigate = useNavigate();
    const mode = activityData ? 'Editar' : 'Crear';

    const formik = useFormik<ActivityForm>({
        initialValues: activityData || activityInitialValues,
        validationSchema: ActivitySchema,
        onSubmit: values => submit(values)
    });

    const verifyClass = (inputFieldID: keyof ActivityForm) => {
        return (formik.touched[inputFieldID] && formik.errors[inputFieldID]) ? 'is-invalid' : '';
    }

    const showErrors = (inputFieldID: keyof ActivityForm) => {
        return (formik.touched[inputFieldID] && formik.errors[inputFieldID]) ? <div className="invalid-feedback">{formik.errors[inputFieldID]}</div> : <></>;
    }

    const getContent = () => {
        return (
            <form onSubmit={formik.handleSubmit} autoComplete="off">
                <Card>
                    <CardBody>
                        <CardTitle>Datos de la actividad física</CardTitle>

                        <div className="row mt-4 d-flex justify-content-between">
                            <div className="col-md-5">
                                <FormGroup label="Nombre" requiredInputLabel>
                                    <Input
                                        type="text"
                                        className={verifyClass('name')}
                                        id="name"
                                        name="name"
                                        onChange={formik.handleChange}
                                        value={formik.values.name}
                                    />
                                    {showErrors('name')}
                                </FormGroup>
                            </div>

                            <div className="col-md-3">
                                <FormGroup label="Duración en minutos">
                                    <Input
                                        type="number"
                                        className={verifyClass('time') + ' w-50'}
                                        id="time"
                                        name="time"
                                        onChange={formik.handleChange}
                                        value={formik.values.time}
                                    />
                                    {showErrors('time')}
                                </FormGroup>
                            </div>

                            <div className="col-md-3">
                                <FormGroup label="Calorías quemadas">
                                    <Input
                                        type="number"
                                        className={verifyClass('calories') + ' w-50'}
                                        id="calories"
                                        name="calories"
                                        onChange={formik.handleChange}
                                        value={formik.values.calories}
                                    />
                                    {showErrors('calories')}
                                </FormGroup>
                            </div>
                        </div>

                        <div className="row d-flex justify-content-between mt-3">
                            <div className="col-md-12">
                                <FormGroup label="Descripción">
                                    <textarea
                                        className={`form-control ${verifyClass('description')}`}
                                        id="description"
                                        name="description"
                                        onChange={formik.handleChange}
                                        value={formik.values.description}
                                    />
                                    {showErrors('description')}
                                </FormGroup>
                            </div>
                        </div>
                    </CardBody>
                </Card>
            </form>
        )
    }

    return (
        <>
            <SubHeader>
                <SubHeaderLeft>
                    <Button color='secondary' isLink icon='ArrowBack' onClick={() => navigate(-1)} />
                    <SubheaderSeparator />
                    <CardTitle>{mode} Actividad Física</CardTitle>
                </SubHeaderLeft>
                <SubHeaderRight>
                    <Button type="submit" color='primary' onClick={formik.handleSubmit}>
                        {isLoading ? <Spinner /> : `${mode} Actividad`}
                    </Button>
                </SubHeaderRight>
            </SubHeader>
            <Page container='sm' className="w-50">
                {(mode === "Editar" && !activityData) && <Spinner />}
                {(mode === "Editar" && activityData) && getContent()}
                {mode === "Crear" && getContent()}
            </Page>
        </>
    )
}

export default ActivityForm;