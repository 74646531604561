import { AxiosResponse } from 'axios';
import { RestServiceConnection } from '../restServiceConnection';

const FOOD_ENDPOINT = '/foods';

export class AlimentService extends RestServiceConnection {

    getAliments = async (filters?: any) => {
        this.response = await this.makeRequest({
            method: 'POST',
            url: FOOD_ENDPOINT + '/list',
            data: {
                ...filters
            }
        }, true) as AxiosResponse;
        return this;
    }

    toggleAlimentStatus = async (id: string, status: boolean) => {
        this.response = await this.makeRequest({
            method: 'POST',
            url: FOOD_ENDPOINT + '/toggle',
            data: {
                aliment: id,
                active: status
            }
        }, true);
        return this;
    }

    getAlimentById = async (id: string) => {
        this.response = await this.makeRequest({
            method: 'POST',
            url: FOOD_ENDPOINT + '/get',
            data: {
                food: id
            }
        }, true);
        return this;
    }

    createAliment = async (aliment: FormData) => {

        this.response = await this.makeRequest({
            method: 'POST',
            url: FOOD_ENDPOINT + '/create',
            data: aliment
        }, true);
        return this;
    }

    editAliment = async (aliment: FormData) => {
        this.response = await this.makeRequest({
            method: 'POST',
            url: FOOD_ENDPOINT + '/edit',
            data: aliment
        }, true);
        return this;
    }

    editAlimentImg = async (id: string, img: File) => {
        const formData = new FormData();
        formData.append('food', id);
        formData.append('food_img', img);

        this.response = await this.makeRequest({
            method: 'POST',
            url: FOOD_ENDPOINT + '/add-image',
            data: formData,
            headers: {
                "Content-Type": "multipart/form-data"
            }
        }, true);
        return this;
    }

    deleteAlimentImg = async (id: string) => {
        this.response = await this.makeRequest({
            method: 'POST',
            url: FOOD_ENDPOINT + '/delete-image',
            data: {
                food: id
            }
        }, true);
        return this;
    }

    deleteAliment = async (id: string) => {
        this.response = await this.makeRequest({
            method: 'POST',
            url: FOOD_ENDPOINT + '/delete',
            data: {
                food: id
            }
        }, true);
        return this;
    }

    getNutrients = async (id: string) => {
        this.response = await this.makeRequest({
            method: 'POST',
            url: FOOD_ENDPOINT + '/nutrients',
            data: {
                food: id
            }
        }, true);
        return this;
    }

    getAlimentGroups = async () => {
        this.response = await this.makeRequest({
            method: 'GET',
            url: '/food_groups/list',
        }, true);
        return this;
    }

    editNutrients = async (food_id: string, nutrient: any, nutrientValue: any) => {
        this.response = await this.makeRequest({
            method: 'POST',
            url: FOOD_ENDPOINT + '/edit_nutrients',
            data: {
                food: food_id,
                nutrient_name: nutrient,
                nutrient_value: nutrientValue
            }
        }, true);
        return this;
    }

    getNutrientsList = async () => {
        this.response = await this.makeRequest({
            method: 'GET',
            url: FOOD_ENDPOINT + '/get_nutrients',
        }, true);
        return this;
    }

    editAllergens = async (food_id: string, allergens: any) => {
        this.response = await this.makeRequest({
            method: 'POST',
            url: FOOD_ENDPOINT + '/edit_allergens',
            data: {
                food: food_id,
                allergens: allergens,
            }
        }, true);
        return this;
    }

    getAllergensList = async () => {
        this.response = await this.makeRequest({
            method: 'GET',
            url: FOOD_ENDPOINT + '/get_allergens',
        }, true);
        return this;
    }

    getAllergens = async (id: string) => {
        this.response = await this.makeRequest({
            method: 'POST',
            url: FOOD_ENDPOINT + '/allergens',
            data: {
                food: id
            }
        }, true);
        return this;
    }

    exportFoods = async (filters?: any) => {
        this.response = await this.makeRequest({
            method: 'POST',
            url: FOOD_ENDPOINT + '/export',
            responseType: 'arraybuffer', // define el tipo de respuesta que se espera
            data: {
                filter_filters: {
                    name: filters.searchText,
                }
            }
        }, true);
        return this;
    }

    importFoods = async (file: File) => {
        this.response = await this.makeRequest({
            method: 'POST',
            url: FOOD_ENDPOINT + '/import',
            data: {
                file: file
            },
            headers: {
                "Content-Type": "multipart/form-data"
            }
        }, true);
        return this;
    }
}