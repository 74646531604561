import { useEffect, useState } from "react"
import SearchableSelect from "./SearchableSelect"
import { showErrors } from "../../utils/Forms/FormikVerifyClass"
import { PAYMENT_METHODS_MAP } from "../../type/appointment-type"

type AppointmentPaymentMethodSelectorProps = {
    name: string,
    onChange: (serviceType: any) => void,
    formik: any,
    selectedId: string | undefined
}

export const AppointmentPaymentMethodSelector: React.FC<AppointmentPaymentMethodSelectorProps> = ({ name, onChange, selectedId, formik }) => {

    const defaultValue = { value: 'Tarjeta', label: 'Tarjeta' };

    const [selected, setSelected] = useState<any | null>(null);

    const serviceOptions = Object.values(PAYMENT_METHODS_MAP).map((service: any) => ({
        value: service.value,
        label: service.label,
    }));

    useEffect(() => {
        if (serviceOptions === undefined) return;
        if (selectedId === undefined) return;
        if (selectedId === null) return;
        const selectedItem = serviceOptions.find((c: any) => c.name === selectedId);
        if (selectedItem === undefined) return;
        setSelected({
            value: selectedItem.value,
            label: `${selectedItem.label}`,
        })
    }, [selectedId]);

    useEffect(() => {
        if (selected === undefined) return;
        if (selected === null) return;
        onChange(selected.value);
    }, [selected]);

    return (
        <>
            <SearchableSelect
                name={name}
                isSearchable
                isClearable
                onBlur={formik.handleBlur}
                placeholder='método de pago'
                value={selected}
                defaultValue={defaultValue}
                onChange={(newValue: any) => {
                    if (newValue === null) {
                        setSelected(defaultValue);
                        formik.setFieldValue(name, null);
                        return;
                    }
                    setSelected(newValue)
                    formik.setFieldValue('paymentMethod', newValue.value);
                }}
                options={serviceOptions}
            />
            {showErrors(formik, 'paymentMethod')}
        </>
    )
}