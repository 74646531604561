import { useState } from "react";
import { useNavigate } from "react-router-dom";
import { TipsGroupsService } from "../../../services/tips/groupsService";
import { toast } from "react-toastify";
import GroupForm from "../GroupForm";

const CreateGroup = () => {
    const navigate = useNavigate();

    const [loading, setLoading] = useState<boolean>(false);

    const handleCreateTip = async (values: any) => {
        setLoading(true);

        try {
            const response = await (await (new TipsGroupsService()).createGroup(values)).getResponseData();

            if (response.success) {
                navigate(-1);
                setTimeout(() => {
                    toast.success('Grupo creado correctamente');
                }, 100);
            } else {
                toast.error(response.message || 'Error al crear el grupo');
            }
        } catch (error: any) {
            toast.error(error.message || 'Error al crear el grupo');
        } finally {
            setLoading(false);
        }
    }

    return <GroupForm submit={handleCreateTip} isLoading={loading} />;
}

export default CreateGroup;