import React from 'react'
import InlineSVG from 'react-inlinesvg';
import { toAbsoluteUrl } from './extras/AssetHelpers'

type Props = {
    className?: string
    path: string
    svgClassName?: string
}

const KTSVG: React.FC<Props> = ({ className = '', path, svgClassName = 'mh-50px' }) => {
    return (
        <span className={`svg-icon ${className}`}>
            <InlineSVG src={toAbsoluteUrl(path)} className={svgClassName} />
        </span>
    )
}

export { KTSVG }