import { useFormik } from "formik";
import Button from "../../components/bootstrap/Button";
import { LoginContainer } from "./LoginContainer";
import { LoginService } from "../../services/auth/loginService";
import { toast } from "react-toastify";
import { useNavigate } from "react-router-dom";
import * as Yup from 'yup';
import CustomPasswordInput from "../../components/bootstrap/forms/CustomPasswordInput";

const resetPasswordSchema = Yup.object().shape({
    password: Yup.string().required('La contraseña es obligatoria').matches(/^(?=.*[a-z])(?=.*[A-Z])(?=.*\d)[a-zA-Z\d\S]{8,30}$/, 'La contraseña debe tener al menos 8 caracteres, una mayúscula, una minúscula y un número'),
    repeatPassword: Yup.string().required('Repita la contraseña').oneOf([Yup.ref('password'), ''], 'Las contraseñas no coinciden'),
});

const ResetPassword = () => {
    const navigate = useNavigate();

    // Controlar el envío del formulario
    const handleSubmit = async (password: string, repeatPassword: string) => {
        if (password === repeatPassword) {
            const urlString = window.location.href.split('/').pop() || '';
            const token = urlString.substring(urlString.indexOf("token=") + 6);

            try {
                const response = await (new LoginService()).resetPassword(password, repeatPassword, token);

                if (response.data.success) {
                    navigate('/login');
                    setTimeout(() => {
                        toast.success(response.data.message);
                    }, 100);
                } else {
                    toast.error(response.data.message);
                }
            } catch (error) {
                toast.error('Error al cambiar la contraseña: ' + error);
            }
        } else {
            toast.error('Las contraseñas no coinciden');
        }
    }

    const formik = useFormik({
        enableReinitialize: true,
        initialValues: {
            password: '',
            repeatPassword: '',
        },
        validationSchema: resetPasswordSchema,
        onSubmit: (values) => { handleSubmit(values.password, values.repeatPassword) },
    });

    return (
        <LoginContainer>
            <form onSubmit={formik.handleSubmit} className='row g-4' autoComplete="off">
                <CustomPasswordInput
                    formik={formik}
                    id='password'
                    value={formik.values.password}
                    invalidFeedback={formik.errors.password}
                    cols={12}
                    label="Nueva Contraseña"
                />
                <CustomPasswordInput
                    formik={formik}
                    id='repeatPassword'
                    value={formik.values.repeatPassword}
                    invalidFeedback={formik.errors.repeatPassword}
                    cols={12}
                    label="Repetir Contraseña"
                />
                <div className='col-12 mt-5'>
                    <Button color='dark' className='w-100 py-3' type='submit'>
                        CAMBIAR CONTRASEÑA
                    </Button>
                </div>
            </form>
        </LoginContainer>
    );
}

export default ResetPassword;