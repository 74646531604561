import { FC } from 'react';
import Icon from '../icon/Icon';
import Tooltips from './Tooltips';

interface IconProps {
    label: string;
    icon?: string;
}

const IconWithTooltip: FC<IconProps> = ({ icon, label }) => {
    return (
        <Tooltips placement="top" title={label}>
            <Icon icon={icon || 'Info'} className="ms-2" />
        </Tooltips>
    );
};

export default IconWithTooltip;