import PageWrapper from '../../layout/PageWrapper/PageWrapper';
import Page from '../../layout/Page/Page';
import Cone from '../../assets/img/cone.png';
import Button from '../../components/bootstrap/Button';
import { authMenu, calendarMenu } from '../../menu';

const Page404 = () => {
	return (
		<PageWrapper className='bg-light'>
			<Page>
				<div className='row d-flex align-items-center h-100 errorBackground'>
					<div className='col-12 d-flex flex-column justify-content-center align-items-center'>
						<div
							className='text-secondary fw-bold'
							style={{ fontSize: 'calc(1rem + 1.5vw)' }}>
							Ups! Algo va mal
						</div>
					</div>
					<div className='col-12 d-flex align-items-baseline justify-content-center'>
						<img
							src={Cone}
							alt='Error404'
							style={{ height: '25vh' }}
						/>
					</div>
					<div className='col-12 d-flex flex-column justify-content-center align-items-center'>
						<Button
							className=''
							color='brand'
							icon='HolidayVillage'
							tag='a'
							href={calendarMenu.calendar.path}>
							Página de inicio
						</Button>
					</div>
				</div>
			</Page>
		</PageWrapper>
	);
};

export default Page404;