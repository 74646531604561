import { Fragment } from "react";
import { AppointmentCalendarProvider } from "../providers/AppointmentCalendarProvider";
import { AppointmentCalendar } from "./AppointmentCalendar";
import { ToastContainer } from "react-toastify";

type AppointmentCalendarWrapperProps = {}

export const AppointmentCalendarWrapper: React.FC<AppointmentCalendarWrapperProps> = () => {
    return (
        <Fragment>
            <ToastContainer />
            <AppointmentCalendarProvider>
                <AppointmentCalendar />
            </AppointmentCalendarProvider>
        </Fragment>
    );
}