/*  eslint-disable jsx-a11y/anchor-is-valid */
import React, { useEffect, useState } from 'react'
import ReactPaginate from 'react-paginate'
import ReactSelect from 'react-select'
import './Pagination.css';
import { KTSVG } from '../KTSVG';
import { toast } from 'react-toastify';

type Props = {
    pageCount: number,
    currentPage: number,
    rangeDisplayed?: number,
    perPage?: number,
    handlePagination: Function,
    handlePerPage: Function
}

type Option = {
    value?: number;
    label?: string;
};

const Pagination: React.FC<Props> = ({ pageCount, currentPage, rangeDisplayed, perPage, handlePagination, handlePerPage }) => {

    const pageSizeOptions = [
        { value: 10, label: '10' },
        { value: 20, label: '20' },
        { value: 50, label: '50' },
        { value: 100, label: '100' },
    ]

    const [selectedOption, setSelectedOption] = useState<Option | null>({
        value: perPage,
        label: perPage?.toString(),
    });

    const handleSelectChange = (option: Option | null) => {
        setSelectedOption(option);
        handlePerPage(option?.value);
    };

    return (
        <div className='d-flex flex-stack flex-wrap justify-content-end'>
            <div className={'d-flex align-self-center'}>
                <ReactPaginate
                    pageCount={pageCount}
                    pageRangeDisplayed={rangeDisplayed}
                    initialPage={currentPage - 1}
                    onPageChange={(page: any) => handlePagination(page)}
                    activeLinkClassName={'active'}
                    nextClassName={'page-item'}
                    previousClassName={'page-item'}
                    className={'pagination me-4'}
                    pageClassName={'page-item'}
                    activeClassName={'active'}
                    nextLabel={<KTSVG path={'/duotune/arrows/arr023.svg'} className={""} />}
                    previousLabel={<KTSVG path={'/duotune/arrows/arr022.svg'} className={""} />}
                    nextLinkClassName={'page-link'}
                    previousLinkClassName={'page-link'}
                    pageLinkClassName={'page-link'}
                    breakLinkClassName={'page-link'}
                    breakClassName={'page-item'}
                />

                <ReactSelect
                    menuPlacement='top'
                    className="pagination-select"
                    onChange={handleSelectChange}
                    options={pageSizeOptions}
                    placeholder='Paginación'
                    value={selectedOption} // Establece el valor seleccionado
                    styles={{
                        control: (base, state) => ({
                            ...base,
                            borderRadius: '10px',
                            boxShadow: state.isFocused ? '0 0 0 3px rgba(93, 236, 227, 0.3)' : '0 -1.5px 0 1px rgba(0,0,0, 0.07) !important',
                            border: '0px !important',
                            backgroundColor: '#f8f9fa',
                        }),
                        option: (provided, state) => ({
                            ...provided,
                            backgroundColor: state.isFocused ? '#5dece3' : 'white',
                            color: state.isFocused ? 'white' : 'black',
                            '&:hover': {
                                backgroundColor: '#5dece3',
                                color: 'white',
                                borderColor: '#5dece3 !important'
                            }
                        })
                    }}
                />
            </div>
        </div>
    )
}

Pagination.defaultProps = {
    rangeDisplayed: 2
}

export { Pagination } 
