import { FC, useCallback, useState } from "react";
import { useNavigate } from "react-router-dom";
import { MenuService } from "../../services/menus/menuService";
import useFetch from "../../hooks/useFetch";
import { MenusApiResponse } from "../../type/clientMenus-type";
import ErrorMessage from "../ErrorMessage";
import { toast } from "react-toastify";
import { CustomTable } from "../table/CustomTable";
import moment from "moment";
import StatusDropdown from "../StatusDropdown";
import { handleConfirmationAlert } from "../../components/ConfirmationAlert";
import { authMenu, menusMenu } from "../../menu";

interface MenusTabProps {
    filters: any;
    updateFilters: any;
    updateFilterOrder: any;
    updatePage: any;
    updatePageSize: any;
}

const MenusTab: FC<MenusTabProps> = ({ filters, updateFilters, updateFilterOrder, updatePage, updatePageSize }) => {

    const navigate = useNavigate();

    const [changingStatus, setChangingStatus] = useState<string[]>([]);

    // Obtener los menús del usuario o del paciente según el data proporcionado
    const [menusData, fetchingMenus, menusError, refetchMenus] = useFetch(useCallback(async () => {
        const menuService = new MenuService();
        const response = await menuService.listMenusFilters(filters);
        return response.getResponseData() as MenusApiResponse;
    }, [filters]));

    // Actualizar el estado de un menú
    const toggleMenuStatus = async (id: string, status: boolean, toggleStatus: Function) => {
        try {
            setChangingStatus([...changingStatus, id]);
            const response = await (
                await new MenuService().toggleMenuStatus(id)
            ).getResponseData();
            if (response.success) {
                setChangingStatus(changingStatus.filter((item) => item !== id));
                toggleStatus(status);
                toast.success(status ? 'Se ha activado el menú' : 'Se ha desactivado el menú');
            } else {
                setChangingStatus(changingStatus.filter((item) => item !== id));
            }
        } catch (error: any) {
            setChangingStatus(changingStatus.filter((item) => item !== id));
        }
    };

    // Realizar una copia exacta de un menú
    const _handleCloneMenu = async (menuId: string, patientId: string) => {
        const menu = {
            menu: menuId,
            client: patientId !== '' ? patientId : '',
            company: '',
        }

        try {
            const response = await (await (new MenuService()).cloneMenu(menu)).getResponseData();
            if (response.success) {
                refetchMenus();
                updateFilters({ ...filters });

                toast.success('Menú clonado correctamente');
            } else {
                toast.error(response.message || 'Error al clonar el menú');

            }
        } catch (error: any) {
            toast.error(error.message || 'Error al clonar el menú');
        }
    }

    // Eliminar un menú
    const handleDelete = async (id: string) => {
        try {
            const response = await (await (new MenuService()).deleteMenu(id)).getResponseData();
            if (response.success) {
                refetchMenus();
                updateFilters({ ...filters });

                setTimeout(() => {
                    toast.success('Menú eliminado correctamente');
                }, 100);
            } else {
                setTimeout(() => {
                    toast.error(response.message || 'Error al eliminar el menú');
                }, 100);
            }
        } catch (error: any) {
            toast.error(error.message || 'Error al eliminar el menú');
        }
    }

    const getContent = () => {
        if (menusError) return <ErrorMessage message={menusError} />;

        if (menusData && menusData.menus.length > 0) {
            let apiData = menusData as MenusApiResponse;

            return (
                <CustomTable
                    title="Menús nutricionales"
                    loading={fetchingMenus}
                    data={apiData.menus ? apiData.menus : null}
                    pagination={true}
                    paginationData={{
                        pageSize: filters.limit,
                        currentPage: filters.page,
                        pageCount: apiData as MenusApiResponse ? apiData.lastPage : 1,
                        handlePagination: (page: any) => {
                            updatePage({ selected: page.selected + 1 });
                        },
                        handlePerPage: updatePageSize,
                    }}
                    className={"table-striped table-hover"}
                    columns={[
                        {
                            name: "Nombre",
                            keyValue: "name",
                            sortable: true,
                            sortColumn: updateFilterOrder,
                            render: (element: any) => {
                                return (
                                    <div
                                        className="cursor-pointer text-secondary"
                                        onClick={() => { navigate(menusMenu.menus.path + `/${element.id}/view`) }}
                                    >
                                        {element.name}
                                    </div>
                                )
                            },
                        },
                        {
                            name: "Tipo",
                            keyValue: "type",
                            render: (element: any) => {
                                return (
                                    <>
                                        {element.isTemplate ? 'Plantilla' : 'Asignado'}
                                    </>
                                )
                            }
                        },
                        {
                            name: "Paciente",
                            keyValue: "patient",
                            render: (element: any) => {
                                return (
                                    <>
                                        {
                                            <div
                                                className="cursor-pointer text-secondary"
                                                onClick={() => { navigate(authMenu.patients.path + `/${element.client.id}/view`) }}
                                            >
                                                {
                                                    element.client?.lastName
                                                        ? element.client?.name + ' ' + element.client?.lastName
                                                        : element.client?.name
                                                }
                                            </div>
                                        }
                                    </>
                                )
                            },
                        },
                        {
                            name: "Fecha de creación",
                            keyValue: "createdAt",
                            sortable: true,
                            sortColumn: updateFilterOrder,
                            render: (element: any) => {
                                return (
                                    <>
                                        {moment(element.createdAt.date).format('DD/MM/YYYY')}
                                    </>
                                )
                            },
                        },
                        {
                            name: "Estado",
                            keyValue: "active",
                            className: "text-center",
                            render: (element: any) => {
                                return (
                                    <div className={'text-center'}>
                                        <StatusDropdown
                                            disabled={changingStatus.includes(element.id)}
                                            itemId={element.id}
                                            status={element.active}
                                            change={toggleMenuStatus}
                                        />
                                    </div>
                                );
                            },
                        },
                        { name: "Acciones", className: "min-w-100px text-end", isActionCell: true }
                    ]}
                    actions={[
                        {
                            title: "Clonar",
                            icon: "ContentCopy",
                            buttonType: 'icon',
                            additionalClasses: 'text-primary',
                            description: "Clonar menú",
                            click: (item: any) => {
                                _handleCloneMenu(item.id, item.client?.id);
                            }
                        },
                        {
                            title: "Editar",
                            icon: "Edit",
                            buttonType: 'icon',
                            additionalClasses: 'text-primary',
                            description: "Editar menú",
                            click: (item: any) => {
                                navigate(menusMenu.menus.path + `/${item.id}/view`);
                            },
                        },
                        {
                            title: "Eliminar",
                            icon: "Delete",
                            buttonType: 'icon',
                            additionalClasses: 'text-danger',
                            description: "Eliminar menú",
                            click: (item: any) => {
                                handleConfirmationAlert({
                                    title: "Eliminar menú",
                                    text: "¿Está seguro que desea eliminar el menú?",
                                    icon: "warning",
                                    onConfirm: () => {
                                        handleDelete(item.id);
                                    }
                                })
                            },
                        },
                    ]}
                />
            );
        }
    };

    return (
        <>{getContent()}</>
    );
};

export default MenusTab;