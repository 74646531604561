import React from 'react';

type RecipeProps = {
    allergens?: string[];
    recipe?: Record<string, boolean>;
    filteredAllergens?: [string, unknown][];
    message?: string;
    size?: number;
};

const Allergens: React.FC<RecipeProps> = ({ recipe, message, allergens, size, filteredAllergens }) => {
    const images: JSX.Element[] = [];

    // si no hay tamaño, lo establece a 35
    if (!size) {
        size = 35;
    }


    // Si recibe los alérgenos filtrados por paciente y receta/alimento
    if (filteredAllergens) {
        return (
            <>
                {filteredAllergens.map((allergen) => (
                    <img
                        key={allergen[0]}
                        src={`/allergens/${allergen[0]}.png`}
                        alt={allergen[0]}
                        width={size}
                        data-bs-toggle="tooltip"
                        title={allergen[0]}
                    />
                ))}
            </>
        );
    }

    // Si recibe la receta
    if (recipe) {
        recipe.gluten && images.push(<img key="gluten" src={`/allergens/gluten.png`} alt="gluten" width={size} data-bs-toggle="tooltip" title='Gluten' />);
        recipe.crustaceans && images.push(<img key="crustaceans" src={`/allergens/crustaceans.png`} alt="crustaceans" width={size} data-bs-toggle="tooltip" title='Crustáceos' />);
        recipe.eggs && images.push(<img key="eggs" src={`/allergens/eggs.png`} alt="eggs" width={size} data-bs-toggle="tooltip" title='Huevos' />);
        recipe.fish && images.push(<img key="fish" src={`/allergens/fish.png`} alt="fish" width={size} data-bs-toggle="tooltip" title='Pescado' />);
        recipe.peanuts && images.push(<img key="peanuts" src={`/allergens/peanuts.png`} alt="peanuts" width={size} data-bs-toggle="tooltip" title='Cacahuetes' />);
        recipe.soy && images.push(<img key="soy" src={`/allergens/soy.png`} alt="soy" width={size} data-bs-toggle="tooltip" title='Soja' />);
        recipe.milk && images.push(<img key="milk" src={`/allergens/milk.png`} alt="milk" width={size} data-bs-toggle="tooltip" title='Leche' />);
        recipe.nuts && images.push(<img key="nuts" src={`/allergens/nuts.png`} alt="nuts" width={size} data-bs-toggle="tooltip" title='Nueces' />);
        recipe.celery && images.push(<img key="celery" src={`/allergens/celery.png`} alt="celery" width={size} data-bs-toggle="tooltip" title='Apio' />);
        recipe.mustard && images.push(<img key="mustard" src={`/allergens/mustard.png`} alt="mustard" width={size} data-bs-toggle="tooltip" title='Mostaza' />);
        recipe.sesameseeds && images.push(<img key="sesameseeds" src={`/allergens/sesameseeds.png`} alt="sesameseeds" width={size} data-bs-toggle="tooltip" title='Sésamo' />);
        recipe.sulfurdioxide && images.push(<img key="sulfurdioxide" src={`/allergens/sulfurdioxide.png`} alt="sulfurdioxide" width={size} data-bs-toggle="tooltip" title='Dióxido de azufre' />);
        recipe.lupin && images.push(<img key="lupin" src={`/allergens/lupin.png`} alt="lupin" width={size} data-bs-toggle="tooltip" title='Altramuces' />);
        recipe.molluscs && images.push(<img key="molluscs" src={`/allergens/molluscs.png`} alt="molluscs" width={size} data-bs-toggle="tooltip" title='Moluscos' />);
    }

    // Si recibe los alérgenos
    if (allergens) {
        return (
            <>
                {allergens.map((allergen) => (
                    <img
                        key={allergen}
                        src={`/allergens/${allergen}.png`}
                        alt={allergen}
                        width={size}
                        data-bs-toggle="tooltip"
                        title={allergen}
                    />
                ))}
            </>
        );
    }

    // si no hay alérgenos muestra un mensaje
    if (images.length === 0) {
        return message ? <>{message}</> : <>No contiene</>;
    }

    // devuelve el array de alérgenos
    return <>{images}</>;
};

export default Allergens;
