import Allergens from "../Allergens";
import Button from "../bootstrap/Button";
import { DragSourceMonitor, useDrag } from "react-dnd";
import { DragItem } from "./DraggableItem";
import { useState } from "react";

const DraggableMenuItem = ({ item, foodAversions, foodGroupAversions, selectedFood, selectedRecipe, selectedActivity, setSelectedFood, setSelectedRecipe, setSelectedActivity, deleteMenuItem, getFoodAllergens }: any) => {

    const hasAllergens = getFoodAllergens(item.food ? item.food : (item.recipe ? item.recipe : {})).length > 0;
    const hasAversions = item.food && foodAversions?.some((aversion: any) => aversion.id === item.food.id);
    const isSupplement = item.food && item.food.supplement;
    const hasGroupAversions = foodGroupAversions?.some((aversion: any) => {
        if (item.food) {
            return aversion.id === item.food.foodGroup?.id;
        } else if (item.recipe) {
            return aversion.id === item.recipe.foodGroup?.id;
        }
        return false;
    });
    const itemBgColor = hasAllergens ? "#FFAF9E" : (isSupplement ? '#A6AEFD' : (hasAversions || hasGroupAversions ? '#FFF09E' : '#CBFFCD'));

    const [isHovered, setHovered] = useState(false);

    // Drag
    const [{ isDragging }, dragRef] = useDrag<DragItem, void, { isDragging: boolean }>({
        type: 'menuItem',
        item: {
            type: 'menuItem',
            id: (selectedFood ? selectedFood?.id : (selectedRecipe ? selectedRecipe?.id : (selectedActivity ? selectedActivity?.id : null))),
            name: selectedFood ? item.food?.name : (selectedRecipe ? item.recipe?.name : (selectedActivity ? item.physicalActivity?.name : null)),
            dayId: item.days?.id,
            intakeId: item.intakes?.id,
            isFood: (selectedFood ? true : false),
            isRecipe: (selectedRecipe ? true : false),
            isActivity: (selectedActivity ? true : false),
            quantity: item.quantity || null,
        },
        collect: (monitor: DragSourceMonitor) => ({
            isDragging: monitor.isDragging(),
        }),
    });

    return (
        <div
            ref={dragRef}
            onDragStart={() => {
                item.food && setSelectedFood(item.food);
                item.recipe && setSelectedRecipe(item.recipe);
                item.physicalActivity && setSelectedActivity(item.physicalActivity);
            }}
            key={item.id}
            className='menu-item'
            style={{ backgroundColor: item.physicalActivity ? '#B3DADE' : itemBgColor, cursor: 'pointer' }}
            data-bs-toggle="tooltip"
            data-bs-placement="top"
            title={hasAllergens ? "Contiene alérgenos" : (hasAversions || hasGroupAversions ? 'Contiene aversiones' : '')}
        >
            <div onMouseEnter={() => { setHovered(true) }} onMouseLeave={() => { setHovered(false) }}>
                <span className="menu-item-name">
                    {item.food && item.food.name}
                    {item.recipe && item.recipe.name}
                    {item.physicalActivity && item.physicalActivity.name}
                </span>
                {item.food && <div><Allergens filteredAllergens={getFoodAllergens(item.food)} size={25} /></div>}
                {isHovered && (
                    <div className="d-flex justify-content-center">
                        <Button
                            onClick={() => deleteMenuItem(item.id)}
                            title='Eliminar item'
                            size='sm'
                            icon="Close"
                        />
                    </div>
                )}
            </div>
        </div>
    );
}

export default DraggableMenuItem;