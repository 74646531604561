import classNames from "classnames";
import useDarkMode from "../../hooks/useDarkMode";
import { IEvent } from "../../type/appointment-type";
import Avatar, { AvatarGroup } from "../Avatar";
import Icon from "../icon/Icon";
import moment from "moment";
import Tooltips from "../bootstrap/Tooltips";
import { DocumentService } from "../../services/documents/documentService";
import Popovers from "../bootstrap/Popovers";
import { CalendarEventWindow } from "./CalendarEventWindow";
import { useAppointmentCalendar } from "../../pages/appointments/providers/AppointmentCalendarProvider";
import { useState } from "react";
import './CalendarEvents.css'

const MyEvent = (data: { event: IEvent }) => {
    const { darkModeStatus } = useDarkMode();
    const { event } = data;
    const [windowOpened, setWindowOpened] = useState<boolean>(false);
    const { deleteAppointment, refetchAppointments } = useAppointmentCalendar()

    const documentService = new DocumentService();

    return (
        <Popovers
            opened={windowOpened}
            onOpen={() => setWindowOpened(true)}
            className="window-event-body"
            desc={(
                <CalendarEventWindow
                    event={event}
                    onClose={() => setWindowOpened(false)}
                    onDelete={async (id?: string) => {
                        if (id === undefined) return;
                        await deleteAppointment(id);
                        setWindowOpened(false);
                        refetchAppointments();
                    }}
                />
            )}
        >
            <div className='row g-2 flex-nowrap'>
                {
                    event?.client && (
                        <Tooltips title={`Paciente: ${event.client.name} ${event.client.lastName}`}>
                            <div className='col-auto'>
                                <div className='row g-1 align-items-baseline'>
                                    <div className='col-auto'>
                                        <Avatar src={documentService.renderDocumentURL(event?.client?.profileImg?.id)} srcSet={documentService.renderDocumentURL(event?.client?.profileImg?.id)} size={18} />
                                    </div>
                                </div>
                            </div>
                        </Tooltips>
                    )
                }
                <div className='col-auto flex-grow-1' >
                    <small
                        style={{ maxWidth: '150px', display: 'block', overflow: 'hidden', textOverflow: 'ellipsis', marginLeft: '5px' }}
                        className={classNames('text-truncate', {
                            'text-dark': !darkModeStatus,
                            'text-white': darkModeStatus,
                        })}
                    >
                        {event.client ? (`${event.client.name} ${event.client.lastName || ''}`) : event?.name}
                    </small>
                </div>
                {
                    event?.user?.src && (
                        <Tooltips title={`Profesional: ${event.user.name} ${event.user.lastName}`} className="col-auto">
                            <div className='col-auto m-0'>
                                <div className='row g-1 align-items-baseline'>
                                    <div className='col-auto m-0 pt-2'>
                                        <Avatar src={event?.user?.src} srcSet={event?.user?.srcSet} size={18} />
                                    </div>
                                </div>
                            </div>
                        </Tooltips>
                    )
                }
                {
                    event?.users && (
                        <div className='col-auto'>
                            <AvatarGroup size={18}>
                                {event.users.map((user) => (
                                    <Avatar key={user?.id} src={user?.src ? user.src : ""} />
                                ))}
                            </AvatarGroup>
                        </div>
                    )
                }
            </div>
        </Popovers>
    );
};

const MyEventDay = (data: { event: IEvent }) => {
    const { event } = data;
    return (
        <Tooltips title={`${event?.name} / ${moment(event.start).format('LT')} - ${moment(event.end).format('LT',)}`}>
            <div className='row g-2'>
                {event?.user?.src && (
                    <div className='col-auto'>
                        <Avatar src={event?.user?.src} srcSet={event?.user?.srcSet} size={16} />
                    </div>
                )}
                {event?.users && (
                    <div className='col'>
                        <AvatarGroup size={16}>
                            {event.users.map((user) => (
                                // eslint-disable-next-line react/jsx-props-no-spreading
                                <Avatar key={user?.id} src={user?.src ? user.src : ""} />
                            ))}
                        </AvatarGroup>
                    </div>
                )}
                <small className='col text-truncate'>
                    {event?.icon && <Icon icon={event?.icon} size='lg' className='me-2' />}
                    {event?.name}
                </small>
            </div>
        </Tooltips>
    );
};

const MyWeekEvent = (data: { event: IEvent }) => {
    const { darkModeStatus } = useDarkMode();
    const { event } = data;
    const [windowOpened, setWindowOpened] = useState<boolean>(false);
    const { deleteAppointment, refetchAppointments } = useAppointmentCalendar()

    const documentService = new DocumentService();

    return (
        <Popovers
            opened={windowOpened}
            onOpen={() => setWindowOpened(true)}
            className="window-event-body"
            desc={(
                <CalendarEventWindow
                    event={event}
                    onClose={() => setWindowOpened(false)}
                    onDelete={async (id?: string) => {
                        if (id === undefined) return;
                        await deleteAppointment(id)
                        setWindowOpened(false)
                        refetchAppointments()
                    }} />
            )}
        >
            <div>
                <div className='row g-2 flex-nowrap'>
                    {event?.client && (
                        <Tooltips title={`Paciente: ${event.client.name} ${event.client.lastName}`}>
                            <div className='col-auto'>
                                <div className='row g-1 align-items-baseline'>
                                    <div className='col-auto'>
                                        <Avatar src={documentService.renderDocumentURL(event?.client?.profileImg?.id)} srcSet={documentService.renderDocumentURL(event?.client?.profileImg?.id)} size={18} />
                                    </div>
                                </div>
                            </div>
                        </Tooltips>
                    )}
                    <div className='col-auto flex-grow-1 ' >
                        <small
                            style={{ maxWidth: '150px', display: 'block', overflow: 'hidden', textOverflow: 'ellipsis' }}
                            className={classNames('text-truncate', {
                                'text-dark': !darkModeStatus,
                                'text-white': darkModeStatus,
                            })}
                        >
                            {event?.name}
                        </small>
                    </div>
                    {event?.user?.src && (
                        <Tooltips title={`Profesional: ${event.user.name} ${event.user.lastName}`}>
                            <div className='col-auto'>
                                <div className='row g-1 align-items-baseline'>
                                    <div className='col-auto'>
                                        <Avatar src={event?.user?.src} srcSet={event?.user?.srcSet} size={18} />
                                    </div>
                                </div>
                            </div>
                        </Tooltips>

                    )}
                    {event?.users && (
                        <div className='col-auto'>
                            <AvatarGroup size={18}>
                                {event.users.map((user) => (
                                    <Avatar key={user?.id} src={user?.src ? user.src : ""} />
                                ))}
                            </AvatarGroup>
                        </div>
                    )}
                </div>
                <div className='row g-2 flex-nowrap'>
                    <div className='col-auto pt-2'>
                        <p className="text-muted small">{event.comments}</p>
                    </div>
                </div>
            </div>
        </Popovers>
    );
};

// eslint-disable-next-line @typescript-eslint/no-unused-vars
const eventStyleGetter = (
    event: any,
    start: any,
    end: any,
    isSelected: boolean,
) => {
    const now = new Date();
    const isActiveEvent = start <= now && end >= now;
    const isPastEvent = end < now;

    const color = event.colorHex ? event.colorHex : 'primary';

    return {
        className: classNames({
            'border border-secondary': isActiveEvent,
            'opacity-50': isPastEvent,
        }),
        style: {
            backgroundColor: color
        }
    };
};

export { MyEvent, MyEventDay, MyWeekEvent, eventStyleGetter };