import { AxiosResponse } from 'axios';
import { RestServiceConnection } from '../restServiceConnection';
import { MenuItem } from '../../type/menu-type';

const MENU_ENDPOINT = '/menus';

export class MenuService extends RestServiceConnection {
    private async makeMenuRequest(method: string, url: string, data: any) {
        this.response = await this.makeRequest({
            method,
            url: MENU_ENDPOINT + url,
            data,
        }, true) as AxiosResponse;
        return this;
    }

    listMenusFilters = async (filters: any) => {
        return this.makeMenuRequest('POST', '/list-menus', {
            ...filters,
        });
    }

    listMenus = async (data: any) => {
        this.response = await this.makeRequest({
            method: 'POST',
            url: MENU_ENDPOINT + '/list-menus',
            data: {
                ...data.filters,
                user: data.user,
                client: data.client,
            }
        }, true) as AxiosResponse;
        return this;
    }

    getMenus = async (filters: any) => {
        return this.makeMenuRequest('POST', '/list-company-menus', filters);
    }

    getMenuById = async (id: string, company: any) => {
        return this.makeMenuRequest('POST', '/get-menu', {
            menu: id,
            company: company,
        });
    }

    createMenu = async (menu: any) => {
        return this.makeMenuRequest('POST', '/create-menu', menu);
    }

    editMenu = async (menu: any) => {
        return this.makeMenuRequest('POST', '/edit-menu', menu);
    }

    deleteMenu = async (id: string) => {
        return this.makeMenuRequest('POST', '/delete-menu', {
            menu: id,
        });
    }

    toggleMenuStatus = async (id: string) => {
        return this.makeMenuRequest('POST', '/toggle-menu-status', {
            menu: id,
        });
    }

    getPatients = async (id?: string) => {
        this.response = await this.makeRequest({
            method: 'POST',
            url: '/clients/list',
            data: { company: id }
        }, true) as AxiosResponse;
        return this;
    }

    getMenuConfigs = async (id?: string) => {
        return this.makeMenuRequest('POST', '/get-menu-config', {
            company: id,
        });
    }

    listMenuConfigs = async (filters: any) => {
        return this.makeMenuRequest('POST', '/config', filters);
    }

    createMenuConfig = async (config: FormData) => {
        return this.makeMenuRequest('POST', '/menu-config', config);
    }

    deleteConfig = async (id: string) => {
        return this.makeMenuRequest('POST', '/delete-menu-config', {
            menuConfig: id,
        });
    }

    addMenuItem = async (item: MenuItem) => {
        return this.makeMenuRequest('POST', '/add-menu-item', item);
    }

    deleteMenuItem = async (item: string) => {
        return this.makeMenuRequest('POST', '/delete-menu-item', {
            menu_item: item,
        });
    }

    addRowCol = async (menu: string, day?: boolean, intake?: boolean) => {
        const url = '/add-menu-' + (day ? 'day' : intake ? 'intake' : '');
        return this.makeMenuRequest('POST', url, {
            menu,
        });
    }

    deleteDay = async (id: string) => {
        return this.makeMenuRequest('POST', '/delete-menu-day', {
            menu_day: id,
        });
    }

    deleteIntake = async (id: string) => {
        return this.makeMenuRequest('POST', '/delete-menu-intake', {
            menu_intake: id,
        });
    }

    editDay = async (id: string, name: string) => {
        return this.makeMenuRequest('POST', '/edit-menu-day', {
            menu_day: id,
            name,
        });
    }

    editIntake = async (id: string, name: string) => {
        return this.makeMenuRequest('POST', '/edit-menu-intake', {
            menu_intake: id,
            name,
        });
    }

    getNutritionalValues = async (obj: {}) => {
        return this.makeMenuRequest('POST', '/get-nutritional-values', obj);
    }

    getIntakesValuesPerDay = async (obj: {}) => {
        return this.makeMenuRequest('POST', '/get-intake-values', obj);
    }

    cloneMenu = async (menu: any) => {
        return this.makeMenuRequest('POST', '/clone-menu', menu);
    }

    createTemplate = async (template: any) => {
        return this.makeMenuRequest('POST', '/create-template', template);
    }

    assignTemplate = async (template: any) => {
        return this.makeMenuRequest('POST', '/assign-template', template);
    }

    editMultiplier = async (multiplier: any) => {
        return this.makeMenuRequest('POST', '/edit-multiplier', multiplier);
    }

    changeDayOrder = async (menu_day: string, order: string) => {
        return this.makeMenuRequest('POST', '/change-day-order', {
            menu_day,
            order,
        });
    }

    changeIntakeOrder = async (menu_intake: string, order: string) => {
        return this.makeMenuRequest('POST', '/change-intake-order', {
            menu_intake,
            order,
        });
    }

    sendMenuByEmail = async (menu: string, email: string) => {
        return this.makeMenuRequest('POST', '/send-menu-by-email', {
            menu,
            email,
        });
    }

    downloadMenu = async (menu: string) => {
        this.response = await this.makeRequest({
            method: 'POST',
            url: MENU_ENDPOINT + '/download-menu',
            responseType: 'arraybuffer', // define el tipo de respuesta que se espera
            data: {
                menu: menu,
            }
        }, true);
        return this;
    }

}