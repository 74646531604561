import { useCallback, useRef, useState } from "react";
import useFetch from "../../../hooks/useFetch";
import { CustomTable } from "../../../components/table/CustomTable";
import { useNavigate } from "react-router-dom";
import { toast } from "react-toastify";
import Card, { CardBody, CardTitle } from "../../../components/bootstrap/Card";
import moment from "moment";
import useFilters from "../../../hooks/useFilters";
import SubHeader, { SubHeaderLeft, SubHeaderRight, SubheaderSeparator } from "../../../layout/SubHeader/SubHeader";
import Button from "../../../components/bootstrap/Button";
import Page from "../../../layout/Page/Page";
import { tipsMenu } from "../../../menu";
import { TipsService } from "../../../services/tips/tipsService";
import ErrorMessage from "../../../components/ErrorMessage";
import { handleConfirmationAlert } from "../../../components/ConfirmationAlert";
import Icon from "../../../components/icon/Icon";
import CustomSearchInput from "../../../components/bootstrap/forms/CustomSearchInput";
import { TipsFilters } from "./tips-options/TipsFilters";

interface ITipsFilters {
    client?: any;
}

const tipsFilters: ITipsFilters = {
    client: null,
};

const TipsList = () => {
    const navigate = useNavigate();
    const tipsService = new TipsService();
    const fileInputRef = useRef<HTMLInputElement>(null);

    const [tipId, setTipId] = useState<string>('');

    const { filters, updateFilters, resetFilters, updateFilterOrder, updatePage, updatePageSize } = useFilters(tipsFilters);

    const [tips, loadingTips, tipsError, refetchTips] = useFetch(useCallback(async () => {
        const response = (await tipsService.listTips(filters));
        return response.getResponseData();
    }, [filters]));

    const _handleTextChange = (search: string) => {
        updateFilters({ search_array: search });
    };

    const _handleImport = async (e: React.ChangeEvent<any>) => {
        try {
            const selectedFile = e.target.files && e.target.files[0];

            const response = (await tipsService.addDocument(selectedFile, tipId)).getResponseData();
            if (response.success) {
                toast.success('Documento añadido');
                refetchTips();
            } else {
                toast.error('Error al añadir el documento');
            }
        } catch (error: any) {
            toast.error(error.message);
        }
    };

    const _handleExport = async (documentId: string, documentName: string) => {
        try {
            const response = (await tipsService.downloadDocument(documentId));
            if (response) {
                const fileData = response.getResponseData();
                const blob = new Blob([fileData]);
                const url = window.URL.createObjectURL(blob);

                const link = document.createElement('a');
                link.href = url;
                link.setAttribute('download', documentName);
                document.body.appendChild(link);
                link.click();
                document.body.removeChild(link);
                window.URL.revokeObjectURL(url);
            } else {
                toast.error('Error al exportar el documento');
            }
        } catch (error: any) {
            toast.error(error.message);
        }
    };

    const handleDelete = async (id: string) => {
        try {
            const response = (await tipsService.deleteTip(id)).getResponseData();
            if (response.success) {
                refetchTips();

                toast.success('Consejo eliminado correctamente');
            } else {
                setTimeout(() => {
                    toast.error(response.message || 'Error al eliminar el consejo');
                }, 100);
            }
        } catch (error: any) {
            toast.error(error.message || 'Error al eliminar el consejo');
        }
    };

    const getContent = () => {
        if (tipsError) return <div className="text-center"><ErrorMessage message={tipsError} /></div>;

        if (tips && tips.tips.length > 0) {
            return (
                <CustomTable
                    title="Consejos"
                    loading={loadingTips}
                    data={tips ? tips?.tips : null}
                    pagination
                    paginationData={{
                        pageSize: filters.limit,
                        currentPage: filters.page,
                        pageCount: tips ? tips.lastPage : 1,
                        handlePagination: (page: any) => {
                            updatePage({ selected: page.selected + 1 });
                        },
                        handlePerPage: updatePageSize,
                    }}
                    className={"table-striped table-hover"}

                    columns={[
                        {
                            name: "Nombre",
                            keyValue: "name",
                            sortable: true,
                            sortColumn: updateFilterOrder,
                            render: (element: any) => {
                                return (
                                    <div className="cursor-pointer text-secondary" onClick={() => { navigate(`${tipsMenu.tips.subMenu.tipsList.path}/${element.id}/edit`) }}>
                                        {element.name}
                                    </div>
                                )
                            },
                        },
                        {
                            name: "Descripción",
                            keyValue: "description",
                            sortable: true,
                            sortColumn: updateFilterOrder,
                            render: (element: any) => {
                                return (
                                    <>
                                        {element.description || "-"}
                                    </>
                                )
                            }
                        },
                        {
                            name: "Grupo",
                            keyValue: "group",
                            render: (element: any) => {
                                return (
                                    <>
                                        <div className="cursor-pointer text-secondary" onClick={() => { navigate(`${tipsMenu.tips.subMenu.tipsGroups.path}/${element.tipGroup.id}/edit`) }}>
                                            {element.tipGroup.name}
                                        </div>
                                    </>
                                )
                            }
                        },
                        {
                            name: "Fecha de creación",
                            keyValue: "createdAt",
                            sortable: true,
                            sortColumn: updateFilterOrder,
                            render: (element: any) => {
                                return (
                                    <>
                                        {moment(element.createdAt?.date).format('DD/MM/YYYY') || "-"}
                                    </>
                                )
                            }
                        },
                        {
                            name: "Documento",
                            keyValue: "document",
                            sortable: true,
                            sortColumn: updateFilterOrder,
                            render: (element: any) => {
                                return (
                                    <div
                                        className={element.tipDocuments[0]?.document.id ? "cursor-pointer text-primary" : "text-muted"}
                                        onClick={() => {
                                            if (element.tipDocuments[0]?.document.id) {
                                                _handleExport(element.tipDocuments[0]?.document.id, element.tipDocuments[0]?.document.originalName);
                                            }
                                        }}
                                    >
                                        {element.tipDocuments[0]?.document.originalName || "No tiene documento"}
                                    </div>
                                )
                            }
                        },
                        { name: "Acciones", className: "min-w-100px text-end", isActionCell: true }
                    ]}
                    actions={[
                        {
                            title: "Subir documento",
                            icon: "Upload",
                            buttonType: 'icon',
                            additionalClasses: 'text-primary',
                            description: "Subir documento",
                            click: (item: any) => {
                                if (fileInputRef.current) {
                                    setTipId(item.id);
                                    fileInputRef.current.click();
                                }
                            },
                        },
                        {
                            title: "Descargar documento",
                            icon: "Article",
                            buttonType: 'icon',
                            additionalClasses: 'text-primary',
                            description: "Descargar documento",
                            click: (item: any) => {
                                if (item.tipDocuments.length > 0) {
                                    _handleExport(item.tipDocuments[0].document.id, item.tipDocuments[0].document.originalName);
                                } else {
                                    toast.info('No hay documento para descargar');
                                }
                            },
                        },
                        {
                            title: "Editar",
                            icon: "Edit",
                            buttonType: 'icon',
                            additionalClasses: 'text-primary',
                            description: "Editar consejo",
                            click: (item: any) => {
                                navigate(`${tipsMenu.tips.subMenu.tipsList.path}/${item.id}/edit`);
                            }
                        },
                        {
                            title: "Eliminar",
                            icon: "Delete",
                            buttonType: 'icon',
                            additionalClasses: 'text-danger',
                            description: "Eliminar consejo",
                            click: (item: any) => {
                                handleConfirmationAlert({
                                    title: "Eliminar consejo",
                                    text: "¿Está seguro que desea eliminar el consejo?",
                                    icon: "warning",
                                    onConfirm: () => {
                                        handleDelete(item.id);
                                    }
                                })
                            },
                        },
                    ]}
                />
            )
        }
    };

    return (
        <>
            <SubHeader>
                <SubHeaderLeft>
                    <CardTitle>Listado de Consejos</CardTitle>
                    <SubheaderSeparator />
                    <Button
                        title="Crear Consejo"
                        color="storybook"
                        icon="Add"
                        isLight
                        onClick={() => { navigate(tipsMenu.tips.subMenu.tipsList.path + '/create') }}
                    />
                </SubHeaderLeft>
                <SubHeaderRight>
                    <label className='border-0 bg-transparent cursor-pointer' htmlFor='searchInput'>
                        <Icon icon='Search' size='2x' color='secondary' />
                    </label>

                    <CustomSearchInput onSearch={_handleTextChange} placeholder='Buscar...' />

                    <TipsFilters updateFilters={updateFilters} resetFilters={resetFilters} filters={filters} />
                </SubHeaderRight>
            </SubHeader>
            <Page container="fluid">
                <Card stretch={true}>
                    <CardBody className="table-responsive" isScrollable={true}>
                        {getContent()}

                        <input
                            type="file"
                            ref={fileInputRef}
                            style={{ display: 'none' }}
                            onChange={_handleImport}
                        />
                    </CardBody>
                </Card>
            </Page>
        </>
    );
}

export default TipsList;