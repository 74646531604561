import { FC, Fragment, useCallback, useState } from "react";
import { useNavigate, useParams } from "react-router-dom";
import Button from "../../../components/bootstrap/Button";
import Card, { CardBody, CardFooter, CardFooterRight, CardHeader, CardLabel, CardTabItem, CardTitle } from "../../../components/bootstrap/Card";
import Spinner from "../../../components/bootstrap/Spinner";
import useFetch from "../../../hooks/useFetch";
import Page from "../../../layout/Page/Page";
import SubHeader, { SubHeaderLeft, SubHeaderRight } from "../../../layout/SubHeader/SubHeader";
import { CompanyService } from "../../../services/companies/organizationService";
import { Company } from "../../../type/company-type";
import OrganizationInfoCard from "./OrganizationInfoCard";
import { RoleService } from "../../../services/auth/roleService";
import { Role, Roles, RolesApiResponse } from "../../../type/role-type";
import FormGroup from "../../../components/bootstrap/forms/FormGroup";
import Input from "../../../components/bootstrap/forms/Input";
import * as yup from "yup";
import { useFormik } from "formik";
import { PatientService } from "../../../services/patients/patientService";
import { toast } from "react-toastify";
import StatusDropdown from "../../../components/StatusDropdown";
import { handleConfirmationAlert } from "../../../components/ConfirmationAlert";
import { CustomTable } from "../../../components/table/CustomTable";
import useFilters from "../../../hooks/useFilters";
import { TagsApiResponse } from "../../../type/tags-type";
import Select from "../../../components/bootstrap/forms/Select";
import { colorList } from "../../../utils/LabelsUtils";
import ErrorMessage from "../../../components/ErrorMessage";
import OrganizationUsers from "./OrganizationUsers";
import { administrationMenu } from "../../../menu";
import '../../../styles/styles.scss';

interface TagForm {
    name: string;
    nameEn: string;
    description: string;
    color: string;
}

interface ITagFilters {
    tag?: string;
}

const tagFilters: ITagFilters = {
    tag: '',
};

const tagInitialValues: TagForm = {
    name: '',
    nameEn: '',
    description: '',
    color: '',
}

const CreateTagSchema = yup.object({
    name: yup.string().min(1, 'Es demasiado corto').max(30, 'Es demasiado largo').required('Campo obligatorio'),
});

const OrganizationProfile: FC = () => {
    const navigate = useNavigate();
    const { id = "" } = useParams<{ id: string }>();
    const patientService = new PatientService();

    const [changingStatus, setChangingStatus] = useState<string[]>([]);

    const { filters, updateFilters, resetFilters, updateFilterOrder, updatePage, updatePageSize } = useFilters(tagFilters);

    const [data, loading, error] = useFetch(useCallback(async () => {
        const organizationService = new CompanyService();
        const response = await organizationService.getCompanyById(id as string);
        return response.getResponseData() as Company;
    }, []));

    const [roleList] = useFetch(useCallback(async () => {
        const roleService = new RoleService();
        if (!id) return;
        const response = await roleService.getRolesByOrganization(id);
        return response.getResponseData() as RolesApiResponse;
    }, [id]));

    const [tags, fetchingTags, tagsError, refetchTags] = useFetch(useCallback(async () => {
        const patientNoteService = new PatientService();
        const response = await patientNoteService.getAllPatientTags(id, filters);
        return response.getResponseData();
    }, [filters]));

    const getRolesList = () => {
        if (roleList as Roles) {
            return roleList.map((role: Role) => {
                return (
                    <Button
                        key={role.id}
                        id={role.id + ""}
                        className="btn btn-dark w-auto"
                        onClick={() => navigate(`${administrationMenu.roles.path}/${role.id}/edit`)}
                    >
                        {role.name}
                    </Button>
                );
            });
        }
    }

    const handleCreateTag = async (values: any) => {
        values.company = id;

        try {
            const response = (await patientService.createPatientTags(values)).getResponseData();

            if (response.success) {
                refetchTags();
                navigate(`${administrationMenu.companies.path}/${id}/profile`);
                formik.resetForm();
                setTimeout(() => {
                    toast.success('Etiqueta creada correctamente');
                }, 100);
            } else {
                toast.error(response.message || 'Error al crear la etiqueta');
            }
        } catch (error: any) {
            toast.error(error.message || 'Error al crear la etiqueta');
        }
    }

    const handleDeleteTag = async (id: string) => {
        try {
            const response = (await patientService.dropOrganizationLabel(id)).getResponseData();

            if (response.success) {
                refetchTags();
                toast.success('Etiqueta eliminada');
            } else {
                toast.error(response.message || 'Error al eliminar la etiqueta');
            }
        } catch (error: any) {
            toast.error(error.message || 'Error al eliminar la etiqueta');
        }
    }

    const formik = useFormik({
        initialValues: tagInitialValues,
        validationSchema: CreateTagSchema,
        onSubmit: values => {
            handleCreateTag(values)
        }
    });

    const verifyClass = (inputFieldID: keyof TagForm) => {
        return (formik.touched[inputFieldID] && formik.errors[inputFieldID]) ? 'is-invalid' : '';
    }

    const showErrors = (inputFieldID: keyof TagForm) => {
        return (formik.touched[inputFieldID] && formik.errors[inputFieldID]) ? <div className="invalid-feedback">{formik.errors[inputFieldID]}</div> : <></>;
    }

    const getTagsList = () => {
        const toggleUserStatus = async (id: string, status: boolean, toggleStatus: Function) => {
            try {
                setChangingStatus([...changingStatus, id]);
                const response = (await patientService.toggleTagStatus(id)).getResponseData();
                if (response.success) {
                    setChangingStatus(changingStatus.filter((item) => item !== id));
                    toggleStatus(status);
                } else {
                    setChangingStatus(changingStatus.filter((item) => item !== id));
                }
            } catch (error: any) {
                setChangingStatus(changingStatus.filter((item) => item !== id));
            }
        };

        if (tags) {
            return (
                <CustomTable
                    data={tags.labels}
                    pagination={true}
                    paginationData={{
                        pageSize: filters.limit,
                        currentPage: filters.page,
                        pageCount: tags as TagsApiResponse ? tags.lastPage : 1,
                        handlePagination: (page: any) => {
                            updatePage({ selected: page.selected + 1 });
                        },
                        handlePerPage: updatePageSize,
                    }}
                    className={"table-striped table-hover"}
                    columns={[
                        {
                            name: "Nombre",
                            keyValue: "name",
                        },
                        {
                            name: "Nombre en inglés",
                            keyValue: "nameEn",
                            render: (element: any) => {
                                return (
                                    <span>{element.nameEn || '-'}</span>
                                );
                            }
                        },
                        {
                            name: "Descripción",
                            keyValue: "description",
                            render: (element: any) => {
                                return (
                                    <span>{element.description || '-'}</span>
                                );
                            }
                        },
                        {
                            name: "Estado",
                            keyValue: "active",
                            render: (element: any) => {
                                return (
                                    <StatusDropdown
                                        disabled={changingStatus.includes(element.id)}
                                        itemId={element.id}
                                        status={element.active}
                                        change={toggleUserStatus}
                                    />
                                );
                            },
                        },
                        { name: "Acciones", className: "min-w-100px text-end", isActionCell: true }
                    ]}
                    actions={[
                        {
                            title: 'Eliminar',
                            icon: 'Delete',
                            click: (item: any) => {
                                handleConfirmationAlert({ title: 'Eliminar etiqueta', text: 'Se eliminará permanentemente de la organización', onConfirm: () => handleDeleteTag(item.id), icon: 'warning' })
                            }
                        }
                    ]}
                />
            );
        }
    }

    const tagForm = () => {
        return (
            <form onSubmit={formik.handleSubmit} autoComplete="off">
                <CardBody isScrollable={false}>
                    <div className="row d-flex justify-content-between">
                        <div className='col-12'>
                            <div className="row">
                                <FormGroup requiredInputLabel label='Nombre' className='col-md-6'>
                                    <Input id='name' onChange={formik.handleChange} value={formik.values.name} onBlur={formik.handleBlur} className={verifyClass('name')} />
                                    {showErrors('name')}
                                </FormGroup>
                                <FormGroup label='Nombre en inglés' className='col-md-6'>
                                    <Input id='nameEn' onChange={formik.handleChange} value={formik.values.nameEn} />
                                </FormGroup>
                            </div>
                            <div className="row">
                                <FormGroup label='Descripción' className='col-md-10'>
                                    <Input id='description' onChange={formik.handleChange} value={formik.values.description} />
                                </FormGroup>
                                <FormGroup label='Color' className='col-md-2'>
                                    <Select
                                        name="color"
                                        id='color'
                                        ariaLabel='Selector de color'
                                        placeholder='Seleccione un color...'
                                        list={colorList}
                                        onChange={formik.handleChange}
                                        value={formik.values.color}
                                    />
                                </FormGroup>
                            </div>
                        </div>
                    </div>
                </CardBody>
                <CardFooter>
                    <CardFooterRight>
                        <Button size='sm' color='primary' icon='Save' onClick={() => handleCreateTag(formik.values)}>
                            Crear
                        </Button>
                    </CardFooterRight>
                </CardFooter>
            </form>
        );
    }

    const getContent = () => {
        if (loading) return <Spinner />;

        if (error) return <ErrorMessage message={error} />;

        if (data) {
            return (
                <Fragment>
                    <div className='row'>
                        <div className="pt-3 pb-5 d-flex align-items-center">
                            <span className="display-6 fw-bold me-3">{data.name}</span>
                        </div>

                        <div className="col-3">
                            <OrganizationInfoCard
                                logo={data.logo?.id}
                                cif={data.cif}
                                address={data.address}
                                timeSlot={data.timeSlot}
                            />
                        </div>

                        <div className='col-9'>
                            <Card hasTab>
                                <CardTabItem id='users' title='Usuarios' icon='Person'>
                                    <OrganizationUsers companyId={id} users={data?.users} />
                                </CardTabItem>

                                <CardTabItem id='roles' title='Roles' icon='Security'>
                                    <CardHeader>
                                        <CardTitle>Listado de roles</CardTitle>
                                    </CardHeader>
                                    <CardBody>
                                        <div className='row g-4 d-flex justify-content-around'>
                                            {getRolesList()}
                                        </div>
                                    </CardBody>
                                </CardTabItem>

                                <CardTabItem id='tags' title='Etiquetas' icon='Tag'>
                                    <div className="cardTabForms">
                                        <Card hasTab className="cardLabels">
                                            <CardTabItem id='list-tags' title='Lista' icon='List'>
                                                <CardBody className='row g-4'>
                                                    <CardTitle className="mt-5">Listado de etiquetas</CardTitle>
                                                    {getTagsList()}
                                                </CardBody>
                                            </CardTabItem>

                                            <CardTabItem id='create-tag' title='Crear' icon='Add'>
                                                <CardBody className='row g-4 d-flex justify-content-around'>
                                                    <CardTitle className="mt-5">Crear etiqueta</CardTitle>
                                                    {tagForm()}
                                                </CardBody>
                                            </CardTabItem>
                                        </Card>
                                    </div>
                                </CardTabItem>
                            </Card>
                        </div>
                    </div>
                </Fragment >
            );
        }
    };

    return (
        <Fragment>
            <SubHeader>
                <SubHeaderLeft>
                    <Button color='secondary' isLink icon='ArrowBack' onClick={() => navigate(-1)}>
                    </Button>
                </SubHeaderLeft>
                <SubHeaderRight>
                    <Button
                        color="brand"
                        icon="Edit"
                        onClick={() => navigate(`${administrationMenu.companies.path}/${id}/edit`, { replace: true })}
                    >
                        Editar
                    </Button>
                </SubHeaderRight>
            </SubHeader>
            <Page container='xxl'>
                {getContent()}
            </Page>
        </Fragment>
    )
}

export default OrganizationProfile;