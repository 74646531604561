import React, { useEffect, useState } from "react";
import OffCanvas, { OffCanvasBody, OffCanvasHeader, OffCanvasTitle } from "../../../components/bootstrap/OffCanvas";
import { AppointmentCalendarFilters } from "./AppointmentCalendarFilters";
import './AppointmentCanvas.css';

interface AppointmentFiltersCanvasProps {
    setOpen: (status: boolean) => void;
    isOpen?: boolean;

}

export const AppointmentFiltersCanvas: React.FC<AppointmentFiltersCanvasProps> = ({ setOpen, isOpen }) => {

    const [isOpened, setIsOpened] = useState<boolean>(isOpen ? isOpen : false);

    useEffect(() => {
        if (isOpen === undefined) return;
        setIsOpened(isOpen);
    }, [isOpen]);

    return (
        <>
            <OffCanvas
                id="appointment-filters-canvas"
                setOpen={setOpen}
                isOpen={isOpened}
                titleId='appointment-filters-canvas-title'>
                <OffCanvasHeader
                    setOpen={setOpen}
                    className='p-4'>
                    <OffCanvasTitle id='appointment-filters-canvas-title'>
                        Filtros
                    </OffCanvasTitle>
                </OffCanvasHeader>
                <OffCanvasBody className='p-4'>
                    <AppointmentCalendarFilters />
                </OffCanvasBody>
            </OffCanvas>
        </>
    )
}