import { FC, Fragment, useCallback, useState } from "react";
import Select from "../../../components/bootstrap/forms/Select";
import { toast } from "react-toastify";
import Accordion, { AccordionItem } from "../../../components/bootstrap/Accordion";
import Button from "../../../components/bootstrap/Button";
import FormGroup from "../../../components/bootstrap/forms/FormGroup";
import Input from "../../../components/bootstrap/forms/Input";
import Modal, { ModalBody, ModalFooter, ModalHeader, ModalTitle } from "../../../components/bootstrap/Modal";
import Spinner from "../../../components/bootstrap/Spinner";
import useFetch from "../../../hooks/useFetch";
import { PermissionService } from "../../../services/auth/permissionService";
import { RoleService } from "../../../services/auth/roleService";
import { CompanyService } from "../../../services/companies/organizationService";
import { Companies, CompaniesApiResponse } from "../../../type/company-type";
import { NewRole, Permission, PermissionGroup, PermissionsApiResponse } from "../../../type/role-type";
import { ErrorMessage, useFormik } from "formik";
import Checks from "../../../components/bootstrap/forms/Checks";
import IconWithTooltip from "../../../components/bootstrap/IconWithTooltip";

interface RoleCreateModalProps {
	isOpen: boolean;
	setIsOpen(...args: unknown[]): unknown;
	refetch: () => void;
}

const RoleCreateModal: FC<RoleCreateModalProps> = ({ isOpen, setIsOpen, refetch }) => {

	const [selectedPermissions, setSelectedPermissions] = useState<number[]>([]);
	const [selectAll, setSelectAll] = useState<number[]>([]);

	const [permissions, fetchingPermissions, permissionError] = useFetch(useCallback(async () => {
		const permissionService = new PermissionService();
		const response = await permissionService.getPermissions();
		return response.getResponseData() as PermissionsApiResponse;
	}, []));

	const [companies, fetchingCompanies, companyError] = useFetch(useCallback(async () => {
		const companyService = new CompanyService();
		const response = await companyService.getOrganizations();
		return response.getResponseData() as CompaniesApiResponse;
	}, []));

	const handleCreateRole = async (values: any) => {
		values.permissions = selectedPermissions;

		try {
			const response = await (await new RoleService().createRole(values)).getResponseData();

			if (response.success) {
				setIsOpen(false);
				setTimeout(() => {
					toast.success('Rol creado correctamente');
				}, 100);

				refetch();
			} else {
				throw new Error(response.message);
			}
		} catch (e: any) {
			toast.error(e.message || 'Error al crear el rol');
			companyError(e.message);
			setTimeout(() => {
				companyError('');
			}, 3000);
		}
	}

	const getPermissionsLabel = (label: string, description: string) => {
		return (
			<>
				{label}
				<IconWithTooltip icon="Info" label={description} />
			</>
		)
	}

	const getCompanyList = () => {
		if (companies as Companies) {
			return companies.companies.map((productType: { id: string; name: string }) => {
				return {
					value: productType.id,
					label: productType.name,
				};
			});
		}
		return [];
	};

	const getPermissions = () => {
		if (fetchingPermissions || fetchingCompanies) return <div className="text-center"><Spinner /></div>;

		if (permissionError || companyError) return <ErrorMessage name="Error al obtener los permisos" />;

		return (
			<Fragment>
				<div className="row g-4">
					<FormGroup requiredInputLabel id="name" label="Nombre" className="col-md-3" >
						<Input required value={formik.values.name} onChange={formik.handleChange} />
					</FormGroup>
					<FormGroup
						id="description" label="Breve descripción" className="col-md-6">
						<Input id="description" value={formik.values.description} onChange={formik.handleChange} />
					</FormGroup>
					<FormGroup requiredInputLabel id="company" label="Organización" className="col-md-3"          >
						<Select
							required id="company"
							onChange={formik.handleChange}
							value={formik.values.company}
							ariaLabel="Default select example"
							placeholder="Elegir organización..."
							list={getCompanyList()}
						/>
					</FormGroup>
				</div>

				<div className="row mt-5">
					{permissions?.map((group: PermissionGroup, index: number) => {
						return (
							<div className="col-lg-3 col-md-6 col-sm-6 mb-5" key={index}>
								<Accordion id={group.name} isFlush activeItemId={group.id}>
									<AccordionItem id={group.id} title={group.label}>
										<Checks
											label="Seleccionar todos"
											value="all"
											checked={selectAll.includes(group.id)}
											onChange={() => {
												const list = group.permissions.map((item: Permission) => item.id);
												if (selectAll.includes(group.id)) {
													setSelectAll(selectAll.filter((id: number) => id !== group.id));
													setSelectedPermissions(selectedPermissions.filter(item => !list.includes(item)));
												} else {
													setSelectAll([...selectAll, group.id]);
													setSelectedPermissions([...selectedPermissions.concat(list)]);
												}
											}}
										/>

										{
											group.permissions.map((permission: Permission, index: number) => {
												return (
													<div key={index}>
														<Checks
															label={getPermissionsLabel(permission.label, permission.description)}
															value={permission.id}
															checked={selectedPermissions.includes(permission.id)}
															onChange={() => {
																selectedPermissions.includes(permission.id)
																	? setSelectedPermissions(
																		selectedPermissions.filter(
																			(id: number) => id !== permission.id
																		))
																	: setSelectedPermissions([...selectedPermissions, permission.id]);
															}}
														/>
													</div>
												);
											})
										}
									</AccordionItem>
								</Accordion>
							</div>
						);
					})}
				</div>
			</Fragment>
		);
	};

	const formik = useFormik({
		initialValues: {
			name: "",
			description: "",
			company: "",
			permissions: [],
		},
		onSubmit: (values: NewRole) => { handleCreateRole(values); },
	});

	return (
		<Modal isOpen={isOpen} setIsOpen={setIsOpen} size="xl" titleId="Nuevo Rol">
			<ModalHeader setIsOpen={setIsOpen} className="p-4">
				<ModalTitle id="new_role">Nuevo Rol</ModalTitle>
			</ModalHeader>
			<form onSubmit={formik.handleSubmit} autoComplete="off">
				<ModalBody className="px-4">{getPermissions()}</ModalBody>
				<ModalFooter className="px-4 pb-4">
					<Button icon="Save" color="primary" type="submit">
						Guardar Rol
					</Button>
				</ModalFooter>
			</form>
		</Modal>
	)
}

export default RoleCreateModal;