import { useCallback } from "react";
import { useNavigate, useParams } from "react-router-dom";
import { Activity } from "../../../type/activities-type";
import useFetch from "../../../hooks/useFetch";
import { tipsMenu } from "../../../menu";
import { toast } from "react-toastify";
import Spinner from "../../../components/bootstrap/Spinner";
import ErrorMessage from "../../../components/ErrorMessage";
import GroupForm from "../GroupForm";
import { TipsGroupsService } from "../../../services/tips/groupsService";

const EditGroup: React.FC = () => {

    const navigate = useNavigate();
    const { id = '' } = useParams<{ id: string }>();
    const tipsService = new TipsGroupsService();

    const fetchTip = useCallback(async () => {
        const response = (await tipsService.getGroup(id as string));
        return response.getResponseData() as Activity;
    }, [id]);

    const [data, loading, error, refetch] = useFetch(fetchTip);

    const _handleUpdate = async (values: any) => {
        values.tipGroup = id;

        try {
            const response = await tipsService.editGroup(values);
            const responseData = response.getResponseData();

            if (responseData.success) {
                navigate(tipsMenu.tips.subMenu.tipsGroups.path, { replace: true });
                setTimeout(() => {
                    toast.success('Consejo editado correctamente');
                }, 100);
            } else {
                toast.error(responseData.message || "Error al editar el consejo");
            }
        } catch (error: any) {
            toast.error(error.message);
        }
    };

    const getContent = () => {
        if (loading) return <div className="text-center"><Spinner /></div>;

        if (error) return <ErrorMessage message={error.message} />;

        if (data !== null) {
            const tipData = {
                name: data.name !== null ? data.name : "",
                description: data.description !== null ? data.description : "",
            };

            return (
                <GroupForm isLoading={false} submit={_handleUpdate} tipData={tipData} />
            );
        }
    };

    return (
        <>
            {getContent()}
        </>
    )
}

export default EditGroup;