import { useNavigate } from "react-router-dom";
import { RootState } from "../../../redux/store";
import { useSelector } from "react-redux";
import useFilters from "../../../hooks/useFilters";
import Button from "../../../components/bootstrap/Button";
import Card, { CardBody, CardTitle, } from "../../../components/bootstrap/Card";
import Page from "../../../layout/Page/Page";
import SubHeader, { SubHeaderLeft, SubHeaderRight, SubheaderSeparator, } from "../../../layout/SubHeader/SubHeader";
import MenuFilters from "./menus-options/MenuFilters";
import MenusTab from "../../../components/menu/MenusTab";

export interface IMenuFilters {
    active?: number;
    is_template?: number;
    client?: any;
    user?: string;
}

const MenusList = () => {
    const navigate = useNavigate();
    const token = useSelector((state: RootState) => state.auth);

    const menuFilters: IMenuFilters = {
        active: 3,
        is_template: 3,
        client: null,
        user: token.user?.id,
    };

    const { filters, updateFilters, resetFilters, updateFilterOrder, updatePage, updatePageSize } = useFilters(menuFilters);

    return (
        <>
            <SubHeader>
                <SubHeaderLeft>
                    <CardTitle>Listado de Menús Nutricionales</CardTitle>
                    <SubheaderSeparator />
                    <Button
                        color="storybook"
                        icon="Add"
                        isLight
                        onClick={() => {
                            navigate("create");
                        }}
                    >
                    </Button>
                </SubHeaderLeft>
                <SubHeaderRight>
                    <MenuFilters updateFilters={updateFilters} filters={filters} resetFilters={resetFilters} />
                </SubHeaderRight>
            </SubHeader>

            <Page container="fluid">
                <Card stretch={true}>
                    <CardBody className="table-responsive" isScrollable={true}>
                        <MenusTab
                            filters={filters}
                            updateFilters={updateFilters}
                            updateFilterOrder={updateFilterOrder}
                            updatePage={updatePage}
                            updatePageSize={updatePageSize}
                        />
                    </CardBody>
                </Card>
            </Page>
        </>
    );
};

export default MenusList;