import { useCallback, useEffect, useState } from "react";
import { MenuService } from "../../services/menus/menuService";
import { Menu } from "../../type/clientMenus-type";
import useFetch from "../../hooks/useFetch";
import Accordion, { AccordionItem } from "../bootstrap/Accordion";
import Spinner from "../bootstrap/Spinner";

export interface NutritionalValuesProps {
    nutValues?: any;
}

const NutritionalMenuValues: React.FC<NutritionalValuesProps> = ({ nutValues }) => {
    const menuService = new MenuService();

    const [nutrientsGroups, setNutrientsGroups] = useState<any>([]);
    const [renderNutrients, setRenderNutrients] = useState(false);

    // Obtiene los valores nutricionales del menú (completo o por dia/ingesta)
    const fetchValues = useCallback(async () => {
            const response = (await menuService.getNutritionalValues(nutValues));
            return response.getResponseData() as Menu;
    }, []);

    const [NutritionalValues, loadingValues, valuesError] = useFetch(fetchValues);

    // Agrupa los nutrientes
    const groupNutrients = (nutrients: []) => {
        // Clasifica los nutrientes por grupo
        const groups = nutrients.reduce((acc: any, nutrient: any) => {
            const nutrientGroupExists = acc.find((group: any) => group.group === nutrient.group);

            if (!nutrientGroupExists) {
                acc.push({
                    group: nutrient.group,
                    group_name: nutrient.grupo,
                    nutrients: []
                })
            }

            return acc;
        }, []);

        // Añade los nutrientes a su respectivo grupo
        nutrients.forEach((nutrient: any) => {
            const groupIndex = groups.findIndex((group: any) => group.group === nutrient.group);

            groups[groupIndex].nutrients.push(nutrient);
        });

        setNutrientsGroups(groups);
    }

    // Obtiene la lista de nutrientes separados por grupos
    const _renderNutrients = useCallback(() => {
        if (!renderNutrients || nutrientsGroups.length === 0) return (<></>);

        if (loadingValues) return (
            <div className="row d-flex justify-content-center align-content-center" style={{ height: '250px' }}>
                <Spinner className='h-max' />
            </div>
        );

        if (valuesError) return (<div className="row d-flex justify-content-center"><p>Ha ocurrido un error al obtener los valores nutricionales</p></div>);

        return (
            <Accordion id={"nutrients-group"} className="row mx-2" shadow={'none'}>
                {
                    nutrientsGroups.map((nutrientGroup: any) => (
                        <AccordionItem
                            key={`group-${nutrientGroup.group}`}
                            id={nutrientGroup.group}
                            title={nutrientGroup.group_name}
                            className={"col-md-12 mb-4"}
                        >
                            {
                                nutrientGroup.nutrients.map((nutrient: any) => (
                                    <div key={`nutrient-${nutrient.name}`} className="row d-flex justify-content-center" style={{ borderBottom: '1px solid #f5d0a9' }}>
                                        <div className="col-md-9">{nutrient.nombre}</div>
                                        <div className="col-md-3">{Math.round(nutrient.value)}</div>
                                        {/* <div className="col-md-3">{Number.isInteger(nutrient.value) ? nutrient.value : nutrient.value.toFixed(2)}</div> */}
                                    </div>
                                ))
                            }
                        </AccordionItem>
                    ))
                }
            </Accordion>
        )
    }, [renderNutrients, nutrientsGroups]);

    // Cuando se obtienen los valores nutricionales, se clasifican por grupos
    useEffect(() => {
        if (NutritionalValues !== null) {
            groupNutrients(NutritionalValues);

            setRenderNutrients(true);
        }
    }, [NutritionalValues]);

    return (
        <>
            {
                renderNutrients && _renderNutrients()
            }
        </>
    )
}

export default NutritionalMenuValues;