// axiosConfig.js
import axios from 'axios';
import { removeUserFromLocalStorage } from "../utils/jwt";
import store from './../redux/store';
import { logout } from "../redux/authSlice";

const instance = axios.create();

instance.interceptors.response.use(
  response => response,
  error => {
    // if the error is 401, it means the token has expired
    if (error.response && error.response.status === 401) {
      removeUserFromLocalStorage(); // remove the user from local storage
      store.dispatch(logout()); // logout the user from the store (redux)
      window.location.replace('/login'); // redirect to the login page
    }
    return Promise.reject(error);
  }
);

export default instance;