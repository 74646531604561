import { FC, useCallback, useEffect, useState } from "react";
import { TipsGroupsService } from "../../../services/tips/groupsService";
import Card, { CardTabItem } from "../../../components/bootstrap/Card";
import Spinner from "../../../components/bootstrap/Spinner";
import useFetch from "../../../hooks/useFetch";
import { useParams } from "react-router-dom";
import useFilters from "../../../hooks/useFilters";
import Button from "../../../components/bootstrap/Button";
import Icon from "../../../components/icon/Icon";
import ErrorMessage from "../../../components/ErrorMessage";
import ListPatientTips from "../../tips/patient-tips-list/ListPatientTips";
import SearchableSelect from "../../../components/select/SearchableSelect";
import { TipsService } from "../../../services/tips/tipsService";
import { toast } from "react-toastify";
import { useFormik } from "formik";
import * as Yup from 'yup';
import '../../../styles/styles.scss';

interface IGroupFilters {
    tipGroup?: string;
}

interface TipForm {
    client: string;
    tip: string;
}

const tipInitialValues: TipForm = {
    client: '',
    tip: '',
}

const TipSchema = Yup.object().shape({
    client: Yup.string(),
    tip: Yup.string().required('El consejo es obligatorio'),
});

const TipsTab: FC = () => {
    const { id = '' } = useParams<{ id: string }>();
    const tipsGroupsService = new TipsGroupsService();
    const tipsService = new TipsService();

    const [groupId, setGroupId] = useState<string>('');
    const [displayGroups, setDisplayGroups] = useState<boolean>(false);
    const [displaySelect, setDisplaySelect] = useState<boolean>(false);
    const [displayAssignGroupBtn, setDisplayAssignGroupBtn] = useState<boolean>(false);
    const [displayAssignTipBtn, setDisplayAssignTipBtn] = useState<boolean>(false);

    const [clientTips, loadingClientTips, clientTipsError, refetchClientTips] = useFetch(useCallback(async () => {
        const response = (await tipsGroupsService.getClientTips(id));
        return response.getResponseData();
    }, []));

    const [groupsData] = useFetch(useCallback(async () => {
        const response = (await tipsGroupsService.listGroups());
        return response.getResponseData();
    }, []));

    const GroupFilters: IGroupFilters = {
        tipGroup: clientTips?.clientTipGroup[0]?.tipGroup?.id ? clientTips.clientTipGroup[0].tipGroup.id : groupId, // Filtrar por el primer tipo de grupo
    };

    const { filters, updateFilters, resetFilters, updateFilterOrder, updatePage, updatePageSize } = useFilters(GroupFilters);

    const [tips] = useFetch(useCallback(async () => {
        if (filters.filter_filters?.tipGroup !== '') { // Solo hace el fetch si hay un grupo seleccionado
            const response = (await tipsService.listTips(filters));
            return response.getResponseData();
        } else {
            return null;
        }
    }, [filters]));

    const _handleAssignGroupToPatient = async (values: any) => {
        try {
            const response = await tipsGroupsService.assignGroupToPatient(values);
            const responseData = response.getResponseData();

            if (responseData.success) {
                responseData.message === "El grupo de tips ya está asignado" ? toast.info(responseData.message) : refetchClientTips();
                setDisplayAssignGroupBtn(false);
            } else {
                toast.error(responseData.message || "Error al asignar el grupo de consejos al paciente");
            }
        } catch (error: any) {
            toast.error(error.message);
        }
    }

    const _handleDeleteGroupFromPatient = async (values: any) => {
        try {
            const response = await tipsGroupsService.deleteGroupFromPatient(values);
            const responseData = response.getResponseData();

            if (responseData.success) {
                refetchClientTips();
            } else {
                toast.error(responseData.message || "Error al eliminar el grupo de consejos del paciente");
            }
        } catch (error: any) {
            toast.error(error.message);
        }
    }

    const _handleAssignTipToPatient = async (values: any) => {
        try {
            const response = await tipsService.assignTipToPatient(values);
            const responseData = response.getResponseData();

            if (responseData.success) {
                refetchClientTips();
                setDisplayAssignTipBtn(false);
            } else {
                toast.error(responseData.message || "Error al asignar el consejo al paciente");
            }
        } catch (error: any) {
            toast.error(error.message);
        }
    }

    const formikGroup = useFormik<any>({
        initialValues: {
            client: '',
            tipGroup: '',
        },
        validationSchema: Yup.object().shape({
            client: Yup.string(),
            tipGroup: Yup.string().required('El grupo de consejos es obligatorio'),
        }),
        onSubmit: values => {
            values.client = id;
            _handleAssignGroupToPatient(values);
        }
    });

    const formik = useFormik<TipForm>({
        initialValues: tipInitialValues,
        validationSchema: TipSchema,
        onSubmit: values => {
            values.client = id;
            _handleAssignTipToPatient(values);
        }
    });

    // Actualizar el id del tipo de formulario seleccionado (por defecto, el primero) cuando se obtienen los datos
    useEffect(() => {
        if (clientTips !== null && clientTips.clientTipGroup.length > 0) {
            setGroupId(clientTips.clientTipGroup[0]?.tipGroup.id);
        }
    }, [clientTips]);

    // Actualiza los filtros si cambia de group (por tanto, vuelve a hacer el fetch de los forms)
    useEffect(() => {
        if (groupId !== null) {
            updateFilters({ tipGroup: groupId });
        }
    }, [groupId]);

    // Oculta el boton de asignar grupo a paciente cuando se oculta el select
    useEffect(() => {
        if (displayGroups) {
            setDisplayAssignGroupBtn(false);
        }
    }, [displayGroups]);

    // Oculta el boton de asignar tip a paciente cuando se oculta el select
    useEffect(() => {
        if (displaySelect) {
            setDisplayAssignTipBtn(false);
        }
    }, [displaySelect]);

    const getContent = () => {
        if (loadingClientTips) return <div className="text-center"><Spinner /></div>;

        if (clientTipsError) return <ErrorMessage message={clientTipsError} />;

        return (
            clientTips?.clientTipGroup && (
                <div className="d-flex justify-content-between">
                    <div className="w-100 cardTabForms">
                        {
                            displayGroups && (
                                <form onSubmit={formikGroup.handleSubmit} className="row mb-4">
                                    <div className="col-md-3">
                                        <SearchableSelect
                                            name="tipGroup"
                                            isSearchable
                                            options={groupsData.tip_groups?.map((group: any) => {
                                                return {
                                                    value: group.id,
                                                    label: group.name
                                                }
                                            })}
                                            onChange={(e: any) => { formikGroup.setFieldValue('tipGroup', e.value); setDisplayAssignGroupBtn(true) }}
                                            placeholder='grupo de consejos'
                                        />
                                    </div>
                                    {
                                        displayAssignGroupBtn && (
                                            <div className="col-md-1">
                                                <Button
                                                    title="Asignar grupo"
                                                    className="btnAddForms"
                                                    onClick={formikGroup.handleSubmit}
                                                >
                                                    <Icon icon='Add' />
                                                </Button>
                                            </div>
                                        )
                                    }
                                </form>
                            )
                        }
                        {
                            clientTips.clientTipGroup.length > 0 ? (
                                <Card hasTab>
                                    {
                                        clientTips.clientTipGroup.map((group: any) => (
                                            <CardTabItem
                                                id={group.tipGroup.id}
                                                title={group.tipGroup.name}
                                                key={group.tipGroup.id}
                                                onSelected={(id: string) => { setGroupId(id) }}
                                                hasCloseBtn
                                                closeBtnAction={() => { _handleDeleteGroupFromPatient({ client: id, tipGroup: group.tipGroup.id }) }}
                                            >
                                                {
                                                    displaySelect && (
                                                        <form onSubmit={formik.handleSubmit} className="row mb-4">
                                                            <div className="col-md-3">
                                                                <SearchableSelect
                                                                    name="tip"
                                                                    isSearchable
                                                                    options={tips?.tips.map((tip: any) => {
                                                                        return {
                                                                            value: tip.id,
                                                                            label: tip.name
                                                                        }
                                                                    })}
                                                                    onChange={(e: any) => { formik.setFieldValue('tip', e.value); setDisplayAssignTipBtn(true) }}
                                                                    placeholder='consejo para asignar'
                                                                />
                                                            </div>
                                                            {
                                                                displayAssignTipBtn && (
                                                                    <div className="col-md-1">
                                                                        <Button
                                                                            title="Asignar consejo"
                                                                            className="btnAddForms"
                                                                            onClick={formik.handleSubmit}
                                                                        >
                                                                            <Icon icon='Add' />
                                                                        </Button>
                                                                    </div>
                                                                )
                                                            }
                                                        </form>
                                                    )
                                                }

                                                {<ListPatientTips groupId={group.tipGroup.id} />}
                                            </CardTabItem>
                                        ))
                                    }
                                </Card>
                            ) : (
                                <div className="d-flex justify-content-center mt-3">
                                    <h5 className="text-muted">No hay grupos de consejos</h5>
                                </div>
                            )
                        }
                    </div>

                    <div style={{ height: 'fit-content', marginLeft: '10px' }}>
                        <label className="labelStyle">Asignar:</label>
                        {
                            groupsData?.tip_groups.length > 0 && (
                                <Button
                                    color='primary'
                                    onClick={() => setDisplayGroups(!displayGroups)}
                                    className="btnAddForms2 d-flex align-items-center mb-2"
                                >
                                    Grupo {displayGroups ? <Icon icon="KeyboardArrowUp" color='dark' size={'lg'} /> : <Icon icon="KeyboardArrowDown" color='dark' size={'lg'} />}
                                </Button>
                            )
                        }

                        {
                            clientTips.clientTipGroup.length > 0 && (
                                <Button
                                    color='primary'
                                    onClick={() => { displaySelect ? setDisplaySelect(false) : setDisplaySelect(true) }}
                                    className="btnAddForms2 d-flex align-items-center"
                                >
                                    Consejo {displaySelect ? <Icon icon="KeyboardArrowUp" color='dark' size={'lg'} /> : <Icon icon="KeyboardArrowDown" color='dark' size={'lg'} />}
                                </Button>
                            )
                        }
                    </div>
                </div>
            )
        )
    }

    return (
        <div className="formsPage">
            {getContent()}
        </div>
    )
}

export default TipsTab;