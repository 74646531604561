import { AxiosResponse } from "axios";
import { Role } from "../../type/apiResponse-type";
import { RestServiceConnection } from "../restServiceConnection";

const ROLES_ENDPOINT = "/roles";

export class RoleService extends RestServiceConnection {

  listRoles = async (filters: any) => {
    this.response = await this.makeRequest({
      method: 'POST',
      url: ROLES_ENDPOINT + '/list',
      data: {
        ...filters
      }
    }, true) as AxiosResponse;
    return this;
  }

  getRoles = async () => {
    this.response = await this.makeRequest({
      method: "GET",
      url: ROLES_ENDPOINT + "/get-all",
    }, true);
    return this;
  };

  getRoleById = async (id: string) => {
    this.response = await this.makeRequest({
      method: 'POST',
      url: ROLES_ENDPOINT + '/get',
      data: {
        role: id
      }
    }, true);
    return this;
  }

  getRolesByOrganization = async (organizationId: string) => {
    this.response = await this.makeRequest({
      method: 'POST',
      url: ROLES_ENDPOINT + '/company',
      data: {
        company: organizationId
      }
    }, true);
    return this;
  }

  createRole = async (role: Role) => {
    this.response = await this.makeRequest({
      method: 'POST',
      url: ROLES_ENDPOINT + '/create',
      data: role,
    }, true);
    return this;
  }

  editRole = async (roleData: any) => {
    this.response = await this.makeRequest({
      method: 'POST',
      url: ROLES_ENDPOINT + '/edit',
      data: roleData,
    }, true);
    return this;
  }

  deleteRole = async (id: string) => {
    this.response = await this.makeRequest({
      method: 'POST',
      url: ROLES_ENDPOINT + '/delete',
      data: {
        role: id
      }
    }, true);
    return this;
  }
}
