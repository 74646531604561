import { Middleware, configureStore } from '@reduxjs/toolkit';
import authReducer from './authSlice';
import calendarReducer from './calendarSlice';
import clientReducer from './clientSlice';
import authenticationReducer from './authenticationSlice';
import { loadState } from './browser-storage';
import clientSlice from "./clientSlice";



const store = configureStore({
  reducer: {
    calendar: calendarReducer,
    auth: authReducer,
    client_profile: clientReducer,
  },
  preloadedState: loadState(),
});

export type RootState = ReturnType<typeof store.getState>;
export type AppDispatch = typeof store.dispatch;

export default store;
