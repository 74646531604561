import { FC, useEffect } from "react";
import Button from "../../../components/bootstrap/Button";

interface IAddStepToProcessProps {
    formik: any;
    processData: string[];
    setProcessData: any;
}

export const AddStepToProcess: FC<IAddStepToProcessProps> = ({ formik, processData, setProcessData }) => {

    // Agrega el paso al array de pasos del proceso
    const _handleAddStep = () => {
        const newProcessData = [...processData, formik.values.process];
        setProcessData(newProcessData);
    }

    // Si se presiona enter, se agrega el paso
    const handleTextareaKeyPress = (e: any) => {
        if (e.keyCode === 13 && !e.shiftKey) {
            e.preventDefault();

            _handleAddStep();
        }
    }

    // Si se agrega un paso, se limpia el textarea
    useEffect(() => {
        if (processData.length > 0) {
            formik.setFieldValue('process', '');
        }
    }, [processData]);

    return (
        <div className="row">
            <div className="col-md-12">
                <textarea
                    id="process"
                    onChange={formik.handleChange}
                    onKeyDown={handleTextareaKeyPress}
                    rows={1}
                    value={formik.values.process}
                    style={{
                        borderRadius: '10px',
                        boxShadow: '0 0 0 3px rgb(245, 156, 57, 0.3)',
                        backgroundColor: '#f8f9fa',
                        width: '100%',
                        minHeight: '90px',
                        padding: '15px',
                        border: '0'
                    }}
                    className="mt-4"
                />
            </div>

            <div className="col-md-12 d-flex justify-content-center">
                <Button
                    color="primary"
                    isLight
                    className=" mt-4"
                    onClick={() => { _handleAddStep() }}
                >
                    Agregar paso
                </Button>
            </div>
        </div>
    );
}