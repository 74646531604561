export const colorList = [
    { value: '#ffb7f0', label: 'Rosa' },
    { value: '#b81e53', label: 'Burdeos' },
    { value: '#e5133d', label: 'Rojo' },
    { value: '#f59c39', label: 'Naranja' },
    { value: '#fffeb7', label: 'Amarillo' },
    { value: '#beffb7', label: 'Verde' },
    { value: '#30b7af', label: 'Verde agua' },
    { value: '#2199ff', label: 'Azul' },
    { value: '#b7feff', label: 'Azul celeste' },
    { value: '#3c3c3b', label: 'Negro' },
];

export const colors = [
    '#F7F7F7',
    '#ffb7f0',
    '#b81e53',
    '#e5133d',
    '#f59c39',
    '#fffeb7',
    '#beffb7',
    '#30b7af',
    '#2199ff',
    '#b7feff',
    '#9F63BF',
    '#3c3c3b',
]