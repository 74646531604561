import { FC } from "react";
import { CardBody, CardFooter } from "../../../components/bootstrap/Card";
import Modal, { ModalBody, ModalHeader, ModalTitle } from "../../../components/bootstrap/Modal";
import FormGroup from "../../../components/bootstrap/forms/FormGroup";
import Input from "../../../components/bootstrap/forms/Input";
import Button from "../../../components/bootstrap/Button";
import * as yup from 'yup';
import { FormService } from "../../../services/forms/formService";
import { useFormik } from "formik";
import { toast } from "react-toastify";
import { FormField } from "../create-form/CreateForm";
import Select from "../../../components/bootstrap/forms/Select";
import Check from "../../../components/bootstrap/forms/Checks";
import { formOptions2 } from "../../../utils/FormsUtils";
import 'react-toastify/dist/ReactToastify.css';

interface AddFieldModalProps {
    isOpen: boolean;
    setIsOpen(...args: unknown[]): unknown;
    refetch(): void;
    formId: string;
}

const formTypeInitialValues: FormField = {
    form: '',
    name: '',
    description: '',
    type: 'number',
    required: false,
}

const formTypeSchema = yup.object({
    name: yup.string().required('El nombre es obligatorio'),
    description: yup.string(),
    type: yup.string().required('El tipo es obligatorio'),
    required: yup.boolean().required('El campo requerido es obligatorio'),
});

const AddFieldModal: FC<AddFieldModalProps> = ({ isOpen, setIsOpen, refetch, formId }) => {

    const _handleAddField = async (values: any) => {
        try {
            const response = await (await (new FormService()).createFormField(values)).getResponseData();

            if (response.success) {
                refetch();
            } else {
                toast.error(response.message || 'Error al crear el agregar el campo');
            }
        } catch (error: any) {
            toast.error(error.message || 'Error al agregar el campo');
        }
    }

    const formikType = useFormik<FormField>({
        initialValues: formTypeInitialValues,
        validationSchema: formTypeSchema,
        onSubmit: values => {
            values.form = formId;
            _handleAddField(values);
        }
    });

    const verifyClass = (inputFieldID: keyof FormField) => {
        return (formikType.touched[inputFieldID] && formikType.errors[inputFieldID]) ? 'is-invalid' : '';
    }

    const showErrors = (inputFieldID: keyof FormField) => {
        return (formikType.touched[inputFieldID] && formikType.errors[inputFieldID]) ? <div className="invalid-feedback">{formikType.errors[inputFieldID]}</div> : <></>;
    }

    const getContent = () => {
        return (
            <form onSubmit={formikType.handleSubmit} autoComplete="off">
                <CardBody>
                    <div className="row">
                        <FormGroup requiredInputLabel label='Nombre'>
                            <Input id='name' onChange={formikType.handleChange} value={formikType.values.name} onBlur={formikType.handleBlur} className={verifyClass('name')} />
                            {showErrors('name')}
                        </FormGroup>
                    </div>

                    <div className="row mt-4">
                        <FormGroup label='Descripción'>
                            <Input id='description' onChange={formikType.handleChange} value={formikType.values.description} onBlur={formikType.handleBlur} className={verifyClass('description')} />
                            {showErrors('description')}
                        </FormGroup>
                    </div>

                    <div className="row mt-4">
                        <div className="col-6">
                            <FormGroup requiredInputLabel label='Tipo'>
                                <Select
                                    id='type'
                                    onChange={formikType.handleChange}
                                    list={formOptions2}
                                    value={formikType.values.type}
                                    onBlur={formikType.handleBlur}
                                    className={verifyClass('type')}
                                    ariaLabel={'Tipo de campo'}
                                />
                                {showErrors('type')}
                            </FormGroup>
                        </div>
                        <div className="col-6 d-flex justify-content-center">
                            <FormGroup label='Requerido'>
                                <Check
                                    id='required'
                                    type='switch'
                                    onChange={formikType.handleChange}
                                    checked={formikType.values.required}
                                    onBlur={formikType.handleBlur}
                                    className={verifyClass('required')}
                                    ariaLabel={'Campo requerido'}
                                />
                                {showErrors('required')}
                            </FormGroup>
                            dS</div>
                    </div>
                </CardBody>
                <CardFooter className="d-flex justify-content-end">
                    <Button type="submit" color='primary' onClick={formikType.handleSubmit}>
                        Agregar
                    </Button>
                </CardFooter>
            </form>
        )
    }

    return (
        <Modal isOpen={isOpen} setIsOpen={setIsOpen} size='md' titleId='create_type'>
            <ModalHeader setIsOpen={setIsOpen} className='p-4'>
                <ModalTitle id="add-field">Añadir campo al formulario</ModalTitle>
            </ModalHeader>
            <ModalBody className='px-4'>
                <div className="row">
                    {getContent()}
                </div>
            </ModalBody>
        </Modal>
    )
}

export default AddFieldModal;