import { CSSProperties, FC } from "react";

interface FormLabelProps {
    label: string;
    cols?: number;
    className?: string;
    style?: CSSProperties;
    htmlFor?: string;
    required?: boolean;
}

const FormLabel: FC<FormLabelProps> = ({ label, cols, className, required, style, htmlFor }) => {
    return (
        <label className={`labelStyle col-md-${cols || 12} ${className} pe-0`} style={style} htmlFor={htmlFor}>
            {label}
            {required && <span className="labelRequired"> *</span>}
        </label>
    )
}

export default FormLabel;