import { DragSourceMonitor, useDrag } from 'react-dnd';
import Allergens from '../Allergens';

interface DraggableItemProps {
  label: string;
  data: any;
  food?: boolean;
  recipe?: boolean;
  activity?: boolean;
  quantity?: number | null;
  fromSelectors?: boolean;
}
export interface DragItem {
  type: string;
  id: string;
  dayId: string;
  intakeId: string;
  name: string;
  isFood?: boolean;
  isRecipe?: boolean;
  isActivity?: boolean;
  quantity?: number | null;
  fromSelectors?: boolean;
}

// Componente que dibuja cards de alimentos y las hace draggables para poder añadirlos al menú
const DraggableItem: React.FC<DraggableItemProps> = ({ label, data, food, recipe, activity, quantity, fromSelectors }) => {
  const [{ isDragging }, drag] = useDrag<DragItem, void, { isDragging: boolean }>({
    type: 'menuItem', // Define el tipo de elemento que se está arrastrando
    item: { // Establece los datos que se transmitirán al soltar el elemento
      type: 'menuItem',
      id: data?.id,
      name: data?.name,
      dayId: data?.days?.id,
      intakeId: data?.intakes?.id,
      isFood: food,
      isRecipe: recipe,
      isActivity: activity,
      quantity: quantity,
      fromSelectors: fromSelectors,
    },
    collect: (monitor: DragSourceMonitor) => ({
      isDragging: monitor.isDragging(),
    }),
  });

  return (
    <div
      key={data.id}
      ref={drag} draggable
      className={"col-md-3 " + (activity ? 'physical-activity' : 'items-card')}
      style={{ opacity: isDragging ? 0.5 : 1, cursor: 'grab' }}
    >
      <h5 className='text-center'>{label}</h5>

      {food && (
        <>
          <div className='row mb-1'>
            <div className='col-md-6'>
              <p>Kcal: {Math.round(data.energy)}</p>
              <p>HC: {Math.round(data.carbohydrates)}</p>
            </div>
            <div className='col-md-6'>
              <p>Proteínas: {Math.round(data.proteins)}</p>
              <p>Grasas: {Math.round(data.fat)}</p>
            </div>
          </div>
          <Allergens recipe={data} message='-' size={25} />
        </>
      )}

      {activity && (
        <div className='row'>
          <div className='col-md-6'>
            <p className='mb-0'>Duración: {data.time} min</p>
          </div>
          <div className='col-md-6'>
            <p className='mb-0'>Calorías: {Math.round(data.calories)} kcal</p>
          </div>
        </div>
      )}
    </div>
  );
};

export default DraggableItem;