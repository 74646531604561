import { FC, Fragment, useCallback, useState } from "react";
import { CardBody, CardTitle } from "../../../components/bootstrap/Card";
import Progress from "../../../components/bootstrap/Progress";
import { UserService } from "../../../services/users/userService";
import useFetch from "../../../hooks/useFetch";
import { Users, UsersApiResponse } from "../../../type/user-type";
import Spinner from "../../../components/bootstrap/Spinner";
import ErrorMessage from "../../../components/ErrorMessage";
import { CustomTable } from "../../../components/table/CustomTable";
import AsyncImg from "../../../components/AsyncImg";
import { useNavigate } from "react-router-dom";
import { administrationMenu } from "../../../menu";
import moment from "moment";
import Icon from "../../../components/icon/Icon";
import StatusDropdown from "../../../components/StatusDropdown";
import useFilters from "../../../hooks/useFilters";
import { IUserFilters } from "../../users/users-list/UsersList";
import { handleConfirmationAlert } from "../../../components/ConfirmationAlert";
import { toast } from "react-toastify";
import { useSelector } from "react-redux";
import { RootState } from "../../../redux/store";

interface OrganizationUsersProps {
    companyId: string;
    users: [];
}

const OrganizationUsers: FC<OrganizationUsersProps> = ({ companyId, users }) => {

    const navigate = useNavigate();
    const token = useSelector((state: RootState) => state.auth);
    const usersService = new UserService();

    const userFilters: IUserFilters = {
        active: 3,
        company: companyId,
    };

    const { filters, updateFilters, resetFilters, updateFilterOrder, updatePage, updatePageSize } = useFilters(userFilters);

    const [changingStatus, setChangingStatus] = useState<string[]>([]);

    const [data, loading, error, refetch] = useFetch(useCallback(async () => {
        const response = (await usersService.getUsers(filters));
        return response.getResponseData() as Users;
    }, [filters]));

    const toggleUserStatus = async (id: string, status: boolean, toggleStatus: Function) => {
        try {
            setChangingStatus([...changingStatus, id]);
            const response = await (await new UserService().toggleUserStatus(id, status)).getResponseData();

            if (response.success) {
                setChangingStatus(changingStatus.filter((item) => item !== id));
                toggleStatus(status);
                refetch();
                toast.success(status ? 'Se ha activado el usuario' : 'Se ha desactivado el usuario');
            } else {
                setChangingStatus(changingStatus.filter((item) => item !== id));
                toast.error(response.message);
            }
        } catch (error: any) {
            setChangingStatus(changingStatus.filter((item) => item !== id));
            toast.error(error.message);
        }
    };

    const _handleDelete = async (id: string) => {
        id === token.user?.id ? (
            toast.error('No puedes eliminar tu propio usuario')

        ) : (
            handleConfirmationAlert({
                title: "Eliminar usuario",
                text: "¿Está seguro que desea eliminar el usuario?",
                icon: "warning",
                onConfirm: async () => {
                    try {
                        const response = await (await (new UserService()).deleteUser(id)).getResponseData();
                        if (response.success) {
                            refetch();
                            toast.success('Usuario eliminado correctamente');
                        } else {
                            toast.error(response.message || "Error al eliminar el usuario");
                        }
                    } catch (error: any) {
                        toast.error(error.message);
                    }
                }
            })
        )
    };

    const getContent = () => {
        if (loading) return <div className="text-center"><Spinner /></div>

        if (error) return <ErrorMessage error={error} />

        if (data && data.users.length > 0) {
            let apiData = data as UsersApiResponse;

            return (
                <CustomTable
                    data={apiData.users ? apiData.users : null}
                    pagination={true}
                    paginationData={{
                        pageSize: filters.limit,
                        currentPage: filters.page,
                        pageCount: apiData as UsersApiResponse ? apiData.lastPage : 1,
                        handlePagination: (page: any) => {
                            updatePage({ selected: page.selected + 1 });
                        },
                        handlePerPage: updatePageSize,
                    }}
                    className={"table-striped table-hover"}
                    columns={[
                        {
                            name: "",
                            keyValue: "profileImg",
                            render: (element: any) => {
                                return (
                                    <div className="d-flex justify-content-center">
                                        <div className='user-avatar'>
                                            <AsyncImg id={element.profileImg ? element.profileImg.id : null} />
                                        </div>
                                    </div>
                                );
                            },
                        },
                        {
                            name: "Nombre",
                            keyValue: "name",
                            sortable: true,
                            sortColumn: updateFilterOrder,
                            render: (element: any) => {
                                return (
                                    <div className="cursor-pointer text-secondary" onClick={() => { navigate(`${administrationMenu.users.path}/${element.id}/profile`) }}>
                                        {element.name + (element.lastName ? " " + element.lastName : "")}
                                    </div>
                                )
                            },

                        },
                        {
                            name: "Rol",
                            keyValue: "role",
                            render: (element: any) => {
                                return (
                                    <>
                                        {element.userRoles[0]?.role?.name || "-"}
                                    </>
                                );
                            },
                        },
                        {
                            name: "Correo electrónico",
                            keyValue: "email",
                        },
                        {
                            name: "Teléfono",
                            keyValue: "telephone",
                            render: (element: any) => {
                                return (
                                    <>
                                        {element.telephone || "-"}
                                    </>
                                );
                            }
                        },
                        {
                            name: "Último acceso",
                            keyValue: "last_login",
                            render: (element: any) => {
                                return (
                                    <>
                                        {moment(element.lastLogin.date).format('hh:mm - DD/MM/YY') || "-"}
                                    </>
                                );
                            },
                        },
                        {
                            name: "Estado",
                            keyValue: "active",
                            render: (element: any) => {
                                if (element.id === token.user?.id) {
                                    return (
                                        <Icon icon='Block' className='icon-large' />
                                    );
                                } else {
                                    return (
                                        <StatusDropdown
                                            disabled={changingStatus.includes(element.id)}
                                            itemId={element.id}
                                            status={element.active}
                                            change={toggleUserStatus}
                                        />
                                    );
                                }
                            },
                        },
                        { name: "Acciones", className: "min-w-100px text-end", isActionCell: true }
                    ]}
                    actions={
                        [
                            {
                                title: "Editar",
                                icon: "Edit",
                                buttonType: 'icon',
                                additionalClasses: 'text-primary',
                                description: "Editar perfil de usuario",
                                click: (item: any) => {
                                    navigate(`${administrationMenu.users.path}/${item.id}/edit`);
                                },
                            },
                            {
                                title: "Eliminar",
                                icon: "Delete",
                                buttonType: 'icon',
                                additionalClasses: 'text-danger',
                                description: "Eliminar usuario",
                                click: (item: any) => { _handleDelete(item.id); },
                            },
                        ]}
                />
            );
        } else {
            return (
                <div className="text-center">
                    <h5>No se ha encontrado ningún usuario</h5>
                </div>
            );
        }
    }

    return (
        <Fragment>
            <CardTitle>Usuarios activos</CardTitle>
            <CardBody>
                <div className='d-flex justify-content-between'>
                    <p className='fw-bold'>{users?.length || 0} usuarios</p>
                </div>
                <Progress value={users?.length || 0} max={10} isAutoColor className="mb-5" />
                {getContent()}
            </CardBody>
        </Fragment>
    )
}

export default OrganizationUsers;