import { useFormik } from "formik";
import { FC, Fragment, useCallback, useState } from "react";
import { useNavigate, useParams } from "react-router-dom";
import { toast } from "react-toastify";
import Accordion, { AccordionItem } from "../../../components/bootstrap/Accordion";
import Button from "../../../components/bootstrap/Button";
import Card, { CardBody, CardTitle } from "../../../components/bootstrap/Card";
import Checks from "../../../components/bootstrap/forms/Checks";
import FormGroup from "../../../components/bootstrap/forms/FormGroup";
import Input from "../../../components/bootstrap/forms/Input";
import Spinner from "../../../components/bootstrap/Spinner";
import ErrorMessage from "../../../components/ErrorMessage";
import useFetch from "../../../hooks/useFetch";
import { PermissionService } from "../../../services/auth/permissionService";
import { RoleService } from "../../../services/auth/roleService";
import { Permission, PermissionGroup, RolesApiResponse } from "../../../type/role-type";
import { CompanyService } from "../../../services/companies/organizationService";
import { Companies, CompaniesApiResponse } from "../../../type/company-type";
import Select from "../../../components/bootstrap/forms/Select";
import SubHeader, { SubHeaderLeft, SubHeaderRight, SubheaderSeparator } from "../../../layout/SubHeader/SubHeader";
import Page from "../../../layout/Page/Page";
import { administrationMenu } from "../../../menu";
import IconWithTooltip from "../../../components/bootstrap/IconWithTooltip";

interface CreateFormProps {
    isLoading: boolean;
    submit: (values: RoleForm) => void;
    roleData?: any;
}

interface RoleForm {
    name: string;
    description: string;
    permissions: RolePermissions;
    company: RoleCompany;
}

interface RoleCompany {
    id: string;
    cif: string;
    name: string;
    address: string;
    active: boolean;
}

interface RolePermissions {
    users: string[];
    companies: string[];
    roles: string[];
}

const roleInitialValues: RoleForm = {
    name: '',
    description: '',
    company: {
        id: '',
        cif: '',
        name: '',
        address: '',
        active: false,
    },
    permissions: {
        users: [],
        companies: [],
        roles: [],
    },
}

const RoleForm: FC<CreateFormProps> = ({ isLoading, submit, roleData }) => {
    const navigate = useNavigate();
    const { id } = useParams<{ id: string }>();

    const [selectedPermissions, setSelectedPermissions] = useState<number[]>([]);
    const [selectAll, setSelectAll] = useState<number[]>([]);

    const fetchPermissions = useCallback(async () => {
        const permissionService = new PermissionService();
        const response = await permissionService.getPermissions();

        const allPermissionsData = response.getResponseData().data;

        // find permissions selected in roleData
        const defaultSelectedPermissions = allPermissionsData.reduce((acc: number[], group: PermissionGroup) => {
            let permissionIdsOfGroup: number[] = [];
            group.permissions.forEach((permission: Permission) => {
                if (roleData?.permissions[group.name]?.includes(permission.action)) {
                    permissionIdsOfGroup.push(permission.id);
                }
            });
            return [...acc, ...permissionIdsOfGroup];
        }, []);

        setSelectedPermissions(defaultSelectedPermissions);

        return response.getResponseData() as RolesApiResponse;
    }, []);

    const fetchCompanies = useCallback(async () => {
        const companyService = new CompanyService();
        const response = await companyService.getOrganizations();
        return response.getResponseData() as CompaniesApiResponse;
    }, []);

    const [permissionsData, loadingPermissions, errorPermissions, refetchPermissions] = useFetch(fetchPermissions);
    const [companies] = useFetch(fetchCompanies);

    // Controlar el envío del formulario
    const handleEditRole = async (values: any) => {
        values.role = id;
        values.permissions = selectedPermissions;

        if (values && id) {
            try {
                const response = await (await new RoleService().editRole(values)).getResponseData();

                if (response.success) {
                    navigate(administrationMenu.roles.path, { replace: true });
                    toast.success('Rol editado correctamente');
                } else {
                    toast.error(response.message || 'Error al editar el rol');
                }
            } catch (e: any) {
                toast.error(e.message || 'Error al editar el rol');
            }
        }
    }

    // Obtener el label de los permisos
    const getPermissionsLabel = (label: string, description: string) => {
        return (
            <>
                {label}
                <IconWithTooltip icon="Info" label={description} />
            </>
        )
    }

    // Obtener la lista de organizaciones para mostrar en el select
    const getCompanyList = () => {
        if (companies as Companies) {
            return companies.companies.map((productType: { id: any; name: any }) => {
                return {
                    value: productType.id,
                    label: productType.name,
                };
            });
        }
        return [];
    };

    // Mostrar los permisos en forma de acordeón
    const showPermissions = () => {
        if (loadingPermissions) return <div className="text-center"><Spinner /></div>;

        if (errorPermissions) return <ErrorMessage error={errorPermissions} />;

        if (permissionsData) {
            return (
                <>
                    {
                        permissionsData?.map((group: PermissionGroup, index: number) => {
                            return (
                                <div className="col-lg-3 col-md-6 col-sm-6 mt-5" key={index}>
                                    <Accordion id={group.name} isFlush activeItemId={group.id}>
                                        <AccordionItem id={group.id} title={group.label}>
                                            <Checks
                                                label="Seleccionar todos"
                                                value="all"
                                                checked={selectAll.includes(group.id)}
                                                onChange={() => {
                                                    const list = group.permissions.map((item: Permission) => item.id);
                                                    if (selectAll.includes(group.id)) {
                                                        setSelectAll(selectAll.filter((id: number) => id !== group.id));
                                                        setSelectedPermissions(selectedPermissions.filter(item => !list.includes(item)));
                                                    } else {
                                                        setSelectAll([...selectAll, group.id]);
                                                        setSelectedPermissions([...selectedPermissions.concat(list)]);
                                                    }
                                                }}
                                            />

                                            {
                                                group.permissions.map((permission: Permission, index: number) => {
                                                    return (
                                                        <div key={index}>
                                                            <Checks
                                                                label={getPermissionsLabel(permission.label, permission.description)}
                                                                value={permission.id}
                                                                name={`permissions[]`}
                                                                checked={selectedPermissions.includes(permission.id)}
                                                                onChange={() => {
                                                                    selectedPermissions.includes(permission.id)
                                                                        ? setSelectedPermissions(
                                                                            selectedPermissions.filter(
                                                                                (id: number) => id !== permission.id
                                                                            )
                                                                        ) : setSelectedPermissions([...selectedPermissions, permission.id])
                                                                }}
                                                            />
                                                        </div>
                                                    )
                                                })
                                            }
                                        </AccordionItem>
                                    </Accordion>
                                </div>
                            )
                        })
                    }
                </>
            )
        }
    }

    const formik = useFormik({
        initialValues: roleData ? roleData : roleInitialValues,
        onSubmit: handleEditRole,
    });

    const getContent = () => {
        return (
            <form onSubmit={formik.handleSubmit} autoComplete="off">
                <Card>
                    <CardBody isScrollable={false} className="d-flex flex-column">
                        <div className="row d-flex justify-content-around">
                            <FormGroup label='Nombre' className='col-md-2' requiredInputLabel>
                                <Input id='name' onChange={formik.handleChange} value={formik.values.name} />
                            </FormGroup>

                            <FormGroup label='Descripción' className='col-md-6'>
                                <Input id='description' onChange={formik.handleChange} value={formik.values.description} />
                            </FormGroup>

                            <FormGroup id="company" label="Organización" className="col-md-2" requiredInputLabel>
                                <Select
                                    id="company"
                                    onChange={formik.handleChange}
                                    value={formik.values.company}
                                    ariaLabel="Default select example"
                                    placeholder={formik.values.company}
                                    list={getCompanyList()}
                                />
                            </FormGroup>
                        </div>

                        <div className="row mt-5 d-flex justify-content-center">
                            {showPermissions()}
                        </div>
                    </CardBody>
                </Card>
            </form>
        )
    }

    return (
        <Fragment>
            <SubHeader>
                <SubHeaderLeft>
                    <Button
                        color="secondary"
                        isLink
                        icon="ArrowBack"
                        onClick={() => navigate(-1)}
                    ></Button>
                    <SubheaderSeparator />
                    <CardTitle>Editar rol</CardTitle>
                </SubHeaderLeft>
                <SubHeaderRight>
                    <Button type="submit" color='primary' onClick={formik.handleSubmit}>
                        {isLoading ? <Spinner /> : 'Editar Rol'}
                    </Button>
                </SubHeaderRight>
            </SubHeader>
            <Page container="xxl">
                {roleData ? getContent() : <Spinner />}
            </Page>
        </Fragment>
    )
}

export default RoleForm;