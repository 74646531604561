import React from 'react';
import './CircleColor.css';


type Props = {
    size: number,
    color: string
}

export const CircleColor: React.FC<Props> = ({color, size}: Props) => {
    return (
        <div className="circle-color" style={{backgroundColor: color, width: `${size}px`, height: `${size}px`}}></div>
    )
}