import { FC, Fragment, useState } from "react";
import SubHeader, { SubHeaderLeft, SubHeaderRight, SubheaderSeparator } from "../../../layout/SubHeader/SubHeader";
import Button from "../../../components/bootstrap/Button";
import Card, { CardBody, CardTitle } from "../../../components/bootstrap/Card";
import Spinner from "../../../components/bootstrap/Spinner";
import Page from "../../../layout/Page/Page";
import { useNavigate, useParams } from "react-router-dom";
import Input from "../../../components/bootstrap/forms/Input";
import { useFormik } from "formik";
import Checks from "../../../components/bootstrap/forms/Checks";
import Select from "../../../components/bootstrap/forms/Select";
import AddFieldModal from "./AddFieldModal";
import Icon from "../../../components/icon/Icon";
import { handleConfirmationAlert } from "../../../components/ConfirmationAlert";
import { formOptions2 } from "../../../utils/FormsUtils";
import * as yup from 'yup';
import '../../../styles/styles.scss'

interface FormFillProps {
    isLoading: boolean;
    submitFields: (values: any) => void;
    submitName: (values: any) => void;
    deleteField: (values: string) => void;
    formData: any;
    refetch: () => void;
}

interface IForm {
    form: string;
    name: string;
    fields: any[];
}

const formInitialValues: IForm = {
    form: '',
    name: '',
    fields: [],
}

const formSchema = yup.object({
    form: yup.string(),
    name: yup.string(),
});


const FormFill: FC<FormFillProps> = ({ isLoading, submitFields, submitName, deleteField, formData, refetch }) => {
    const navigate = useNavigate();
    const { id = '' } = useParams<{ id: string }>();

    const [addField, setAddField] = useState<boolean>(false);

    const formik = useFormik<IForm>({
        initialValues: formData || formInitialValues,
        validationSchema: formSchema,
        onSubmit: values => {
            const fields = {
                fields: values.fields,
                form: id
            }

            const name = {
                name: values.name,
                form: id
            }

            submitName(name);
            submitFields(fields);
        }
    });

    const verifyClass = (inputFieldID: keyof IForm) => {
        return (formik.touched[inputFieldID] && formik.errors[inputFieldID]) ? 'is-invalid' : '';
    }

    const showErrors = (inputFieldID: keyof IForm) => {
        //@ts-ignore
        return (formik.touched[inputFieldID] && formik.errors[inputFieldID]) ? <div className="invalid-feedback">{formik.errors[inputFieldID]}</div> : <></>;
    }

    const getContent = () => {
        return (
            <Card stretch={false}>
                <form onSubmit={formik.handleSubmit} autoComplete="off">
                    <CardBody>
                        <CardTitle className="mb-4">Formulario</CardTitle>

                        <div className="row">
                            <div className="col-md-3">
                                <Input id='name'
                                    onChange={formik.handleChange}
                                    value={formik.values.name}
                                    onBlur={formik.handleBlur}
                                    className={verifyClass('name')}
                                />
                                {showErrors('name')}
                            </div>
                        </div>

                        <div className="row mt-5">
                            {
                                formData && formData.fields.length > 0 ? (
                                    <>
                                        <h5 className="mt-2 mb-4">Campos del formulario</h5>
                                        <div className="col-md-3">
                                            <label className="form-label m-0">Nombre</label>
                                        </div>
                                        <div className="col-md-3">
                                            <label className="form-label">Descripción</label>
                                        </div>
                                        <div className="col-md-3">
                                            <label className="form-label">Tipo</label>
                                        </div>
                                        <div className="col-md-1">
                                            <label className="form-label">Requerido</label>
                                        </div>
                                        <div className="col-md-2 d-flex justify-content-end">
                                            <label className="form-label">Acciones</label>
                                        </div>
                                    </>
                                ) : (
                                    <div className="col-md-12">
                                        <h5 className="text-center">No tiene campos asociados</h5>
                                    </div>
                                )
                            }
                        </div>

                        <div className="row">
                            {
                                formData && formData.fields.map((field: any, index: number) => {
                                    return (
                                        <div className="row mt-4" key={index}>
                                            <div className="col-md-3">
                                                <Input
                                                    id={field.name + index}
                                                    onChange={(e: any) => { formik.setFieldValue(`fields[${index}].name`, e.target.value) }}
                                                    value={formik.values.fields[index].name}
                                                    onBlur={formik.handleBlur}
                                                    className={verifyClass(field.name)}
                                                />
                                                {showErrors(field.name)}
                                            </div>

                                            <div className="col-md-3">
                                                <Input
                                                    id={field.description + index}
                                                    onChange={(e: any) => { formik.setFieldValue(`fields[${index}].description`, e.target.value) }}
                                                    value={formik.values.fields[index].description}
                                                    onBlur={formik.handleBlur}
                                                    className={verifyClass(field.description)}
                                                />
                                                {showErrors(field.description)}
                                            </div>

                                            <div className="col-md-3">
                                                <Select
                                                    id={field.type + index}
                                                    onChange={(e: any) => { formik.setFieldValue(`fields[${index}].type`, e.target.value) }}
                                                    list={formOptions2}
                                                    value={formik.values.fields[index].type}
                                                    onBlur={formik.handleBlur}
                                                    className={verifyClass(field.type)}
                                                    ariaLabel="Selector de tipo del campo">
                                                </Select>
                                                {showErrors(field.type)}
                                            </div>

                                            <div className="col-md-1 d-flex justify-content-center required-check">
                                                <Checks
                                                    id={`${field.required}-${index}`}
                                                    type='switch'
                                                    onChange={(e: any) => { formik.setFieldValue(`fields[${index}].required`, e.target.checked) }}
                                                    checked={formik.values.fields[index].required}
                                                    onBlur={formik.handleBlur}
                                                    className={verifyClass(field.required)}
                                                />
                                                {showErrors(field.required)}
                                            </div>

                                            <div className="col-md-2 d-flex justify-content-end">
                                                <Button
                                                    color='dark'
                                                    isLight
                                                    icon='Delete'
                                                    title="Eliminar campo"
                                                    onClick={() => {
                                                        handleConfirmationAlert({
                                                            title: "Eliminar campo",
                                                            text: "¿Está seguro que desea eliminar el campo?",
                                                            icon: "warning",
                                                            onConfirm: () => {
                                                                deleteField(field.id);
                                                            }
                                                        })
                                                    }}
                                                />
                                            </div>
                                        </div>
                                    )
                                }
                                )
                            }
                        </div>

                        <div className="row mt-5 d-flex justify-content-center">
                            <Button
                                color='secondary'
                                isLight
                                className="w-auto"
                                onClick={() => setAddField(true)}
                            >
                                Agregar campo <Icon icon="ArrowUpward" />
                            </Button>
                        </div>
                    </CardBody>
                </form>
            </Card>
        )
    }

    return (
        <Fragment>
            <SubHeader>
                <SubHeaderLeft>
                    <Button color='secondary' isLink icon='ArrowBack' onClick={() => navigate(-1)} />
                    <SubheaderSeparator />
                    <CardTitle>Editar Formulario</CardTitle>
                </SubHeaderLeft>
                <SubHeaderRight>
                    <Button type="submit" color='primary' onClick={formik.handleSubmit}>
                        {isLoading ? <Spinner /> : 'Editar'}
                    </Button>
                </SubHeaderRight>
            </SubHeader>
            <Page container='xxl'>
                {
                    addField && (
                        <AddFieldModal isOpen={addField} setIsOpen={setAddField} refetch={refetch} formId={id} />
                    )
                }

                {getContent()}
            </Page>
        </Fragment>
    )
}

export default FormFill;