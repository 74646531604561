import { FC, Fragment, useCallback, useState } from "react";
import SubHeader, { SubHeaderLeft, SubHeaderRight, SubheaderSeparator } from "../../../layout/SubHeader/SubHeader";
import Button from "../../../components/bootstrap/Button";
import Card, { CardBody, CardFooter, CardTitle } from "../../../components/bootstrap/Card";
import Page from "../../../layout/Page/Page";
import { useNavigate } from "react-router-dom";
import FormGroup from "../../../components/bootstrap/forms/FormGroup";
import Input from "../../../components/bootstrap/forms/Input";
import { useFormik } from "formik";
import { FormService } from "../../../services/forms/formService";
import useFetch from "../../../hooks/useFetch";
import SearchableSelect from "../../../components/select/SearchableSelect";
import { toast } from "react-toastify";
import Checks from "../../../components/bootstrap/forms/Checks";
import Select from "../../../components/bootstrap/forms/Select";
import FormField from "./FormField";
import Icon from "../../../components/icon/Icon";
import * as yup from 'yup';
import { formOptions2 } from "../../../utils/FormsUtils";

interface Form {
    name: string;
    formType: string;
    fields: FormField[];
}

export interface FormField {
    form?: string;
    name: string;
    description: string;
    required: boolean;
    type: string;
}

const formInitialValues: Form = {
    name: '',
    formType: '',
    fields: [],
}

const formFieldInitialValues: FormField = {
    name: '',
    description: '',
    required: false,
    type: 'number',
}

const formSchema = yup.object({
    name: yup.string().required('El nombre es obligatorio'),
    formType: yup.string().required('El tipo de formulario es obligatorio'),
});

const fieldsSchema = yup.object({
    name: yup.string().required('El nombre es obligatorio'),
    description: yup.string(),
    required: yup.boolean().required('El campo requerido es obligatorio'),
    type: yup.string().required('El tipo de campo es obligatorio'),
});

const CreateForm: FC = () => {

    const navigate = useNavigate();
    const formService = new FormService();

    const [displayAddField, setDisplayAddField] = useState<boolean>(false);
    const [displayFormFields, setDisplayFormFields] = useState<boolean>(false);
    const [displaySaveButton, setDisplaySaveButton] = useState<boolean>(true);
    const [formId, setFormId] = useState<string>('');

    const [formTypeData] = useFetch(useCallback(async () => {
        const response = (await formService.getFormTypes());
        return response.getResponseData();
    }, []));

    const [formData, loadingForm, formError, refetchForm] = useFetch(useCallback(async () => {
        const response = (await formService.getFormById(formId));
        return response.getResponseData();
    }, [formId]));

    // Crear un nuevo formulario
    const handleCreateForm = async (values: any) => {
        try {
            const response = await (await (new FormService()).createForm(values)).getResponseData();

            if (response.success) {
                setDisplaySaveButton(false);
                setDisplayAddField(true);
                setFormId(response.data);
            } else {
                toast.error(response.message || 'Error al crear el formulario');
            }
        } catch (error: any) {
            toast.error(error.message || 'Error al crear el formulario');
        }
    }

    // Crear un campo para el formulario
    const handleCreateField = async (values: any) => {
        values.form = formId;

        try {
            const response = await (await (new FormService()).createFormField(values)).getResponseData();

            if (response.success) {
                formikFields.resetForm();
                refetchForm();
                setDisplayFormFields(true);
                toast.success('Nuevo campo añadido al formulario');
            } else {
                toast.error(response.message || 'Error al añadir el campo');
            }
        } catch (error: any) {
            toast.error(error.message || 'Error al añadir al campo');
        }
    }

    // Obtener el listado de tipos de formulario
    const getFormTypesList = () => {
        if (formTypeData) {
            return formTypeData.formTypes?.map((formType: any) => {
                return {
                    value: formType.id,
                    label: formType.name
                }
            })
        }
        return [];
    }

    const formikForm = useFormik<Form>({
        initialValues: formInitialValues,
        validationSchema: formSchema,
        onSubmit: values => handleCreateForm(values)
    });

    const formikFields = useFormik<FormField>({
        initialValues: formFieldInitialValues,
        validationSchema: fieldsSchema,
        onSubmit: values => handleCreateField(values)
    });

    const verifyClassForm = (inputFieldID: keyof Form) => {
        return (formikForm.touched[inputFieldID] && formikForm.errors[inputFieldID]) ? 'is-invalid' : '';
    }

    const verifyClassFields = (inputFieldID: keyof FormField) => {
        return (formikFields.touched[inputFieldID] && formikFields.errors[inputFieldID]) ? 'is-invalid' : '';
    }

    const showErrorsForm = (inputFieldID: keyof Form) => {
        // @ts-ignore
        return (formikForm.touched[inputFieldID] && formikForm.errors[inputFieldID]) ? <div className="invalid-feedback">{formikForm.errors[inputFieldID]}</div> : <></>;
    }

    const showErrorsFields = (inputFieldID: keyof FormField) => {
        return (formikFields.touched[inputFieldID] && formikFields.errors[inputFieldID]) ? <div className="invalid-feedback">{formikFields.errors[inputFieldID]}</div> : <></>;
    }

    const getContent = () => {
        return (
            <>
                <div className="row d-flex justify-content-center">
                    <div className="col-md-6">
                        <Card stretch={false}>
                            <form onSubmit={formikForm.handleSubmit} autoComplete="off">
                                <CardBody>
                                    <CardTitle className="mb-4">Formulario</CardTitle>
                                    <div className="row">
                                        <div className="col-md-7">
                                            <FormGroup requiredInputLabel label='Nombre'>
                                                <Input
                                                    id='name'
                                                    onChange={formikForm.handleChange}
                                                    value={formikForm.values.name}
                                                    onBlur={formikForm.handleBlur}
                                                    className={verifyClassForm('name')}
                                                    disabled={!displaySaveButton}
                                                />
                                                {showErrorsForm('name')}
                                            </FormGroup>
                                        </div>
                                        <div className="col-md-5">
                                            <FormGroup requiredInputLabel label='Tipo'>
                                                <SearchableSelect
                                                    name="formType"
                                                    isSearchable
                                                    options={getFormTypesList()}
                                                    onChange={(e: any) => formikForm.setFieldValue('formType', e.value)}
                                                    placeholder='tipo'
                                                    classname={verifyClassForm('formType')}
                                                    isDisabled={!displaySaveButton}
                                                />
                                                {showErrorsForm('formType')}
                                            </FormGroup>
                                        </div>
                                    </div>
                                </CardBody>

                                {!displayAddField && (
                                    <CardFooter className="d-flex justify-content-center">
                                        <Button color='secondary' title='Añadir campo' onClick={formikForm.handleSubmit}><Icon icon='Add' /></Button>
                                    </CardFooter>
                                )}
                            </form>
                        </Card>
                    </div>
                </div>

                {displayAddField && (
                    <div className="row">
                        <div className="col-md-12">
                            <Card stretch={false}>
                                <form onSubmit={formikFields.handleSubmit} autoComplete="off">
                                    <CardBody>
                                        <CardTitle className="mb-4">Campos del formulario</CardTitle>
                                        {displayFormFields && (
                                            <div className="mt-4">
                                                {formData?.formFields?.map((field: any) => {
                                                    return (
                                                        <div className="row mt-3">
                                                            <div className="col-md-3">
                                                                <Input id={field.name} value={field.name} disabled />
                                                            </div>
                                                            <div className="col-md-6">
                                                                <Input id={field.description} value={field.description} disabled />
                                                            </div>
                                                            <div className="col-md-2">
                                                                <Input id={field.type} value={(field.type === 'number') ? 'Número' : 'Texto'} disabled />
                                                            </div>
                                                            <div className="col-md-1">
                                                                <Checks
                                                                    id={field.required}
                                                                    type={'switch'}
                                                                    checked={field.required}
                                                                    disabled
                                                                />
                                                            </div>
                                                        </div>
                                                    )
                                                })}
                                            </div>
                                        )}

                                        <div className="mt-5">
                                            <div className="row">
                                                <div className="col-md-3">
                                                    <FormGroup requiredInputLabel label='Nombre'>
                                                        <Input id='name' onChange={formikFields.handleChange} value={formikFields.values.name} onBlur={formikFields.handleBlur} className={verifyClassFields('name')} />
                                                        {showErrorsFields('name')}
                                                    </FormGroup>
                                                </div>
                                                <div className="col-md-6">
                                                    <FormGroup label='Descripción'>
                                                        <Input id='description' onChange={formikFields.handleChange} value={formikFields.values.description} onBlur={formikFields.handleBlur} className={verifyClassFields('description')} />
                                                        {showErrorsFields('description')}
                                                    </FormGroup>
                                                </div>
                                                <div className="col-md-2">
                                                    <FormGroup label='Tipo'>
                                                        <Select
                                                            name="type"
                                                            list={formOptions2}
                                                            value={formikFields.values.type}
                                                            onChange={(e) => formikFields.handleChange(e)}
                                                            className={verifyClassFields('type')}
                                                            ariaLabel="Tipo de campo"
                                                        />
                                                        {showErrorsFields('type')}
                                                    </FormGroup>
                                                </div>
                                                <div className="col-md-1">
                                                    <FormGroup label='Requerido'>
                                                        <Checks
                                                            id="required"
                                                            type={'switch'}
                                                            checked={formikFields.values.required}
                                                            onChange={(e) => formikFields.handleChange(e)}
                                                            className={verifyClassFields('required') + "mt-3 col-6"}
                                                        />
                                                        {showErrorsFields('required')}
                                                    </FormGroup>
                                                </div>
                                            </div>
                                        </div>
                                    </CardBody>
                                    <CardFooter className="d-flex justify-content-center">
                                        <Button type="submit" color='secondary' onClick={formikFields.handleSubmit}><Icon icon='Add' /></Button>
                                    </CardFooter>
                                </form>
                            </Card>
                        </div>
                    </div>
                )}
            </>
        )
    }

    return (
        <Fragment>
            <SubHeader>
                <SubHeaderLeft>
                    <Button color='secondary' isLink icon='ArrowBack' onClick={() => navigate(-1)} />
                    <SubheaderSeparator />
                    <CardTitle>Crear Formularios</CardTitle>
                </SubHeaderLeft>
                {displaySaveButton && (
                    <SubHeaderRight>
                        <Button color='primary' title='Guardar' onClick={formikForm.handleSubmit}>Guardar</Button>
                    </SubHeaderRight>
                )}
            </SubHeader>
            <Page container='xxl'>
                <>{getContent()}</>
            </Page>
        </Fragment>
    )
}

export default CreateForm;