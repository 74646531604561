import { useState } from 'react';
import { useNavigate } from 'react-router-dom';
import classNames from 'classnames';
import useDarkMode from '../../hooks/useDarkMode';
import Collapse from '../../components/bootstrap/Collapse';
import { NavigationLine } from '../Navigation/Navigation';
import Icon from '../../components/icon/Icon';
import useNavigationItemHandle from '../../hooks/useNavigationItemHandle';
import AsyncImg from '../../components/AsyncImg';
import { useDispatch, useSelector } from 'react-redux';
import { AppDispatch, RootState } from '../../redux/store';
import { logout } from '../../redux/authSlice';
import Navigation from '../../layout/Navigation/Navigation';
import PushNotificationManager from '../../components/notifications/PushNotificationManager';
import { administrationMenu, generalConfigMenu } from '../../menu';

const User = () => {

	const user = useSelector((state: RootState) => state.auth.user);
	const navigate = useNavigate();
	const dispatch = useDispatch<AppDispatch>();
	const handleItem = useNavigationItemHandle();
	const { darkModeStatus, setDarkModeStatus } = useDarkMode();

	const [collapseStatus, setCollapseStatus] = useState<boolean>(false);

	return (
		<>
			<div
				className={classNames('user', { open: collapseStatus })}
				role='presentation'
				onClick={() => setCollapseStatus(!collapseStatus)}
			>
				<div className='user-avatar'>
					<AsyncImg id={user?.profilePictureId || null} width='128' height='128' />
				</div>
				<div className='user-info' style={{ width: '130px' }}>
					<div className='user-name' style={{ overflow: 'hidden', textOverflow: 'ellipsis' }}>
						{user?.name ? user.name : 'Usuario'}
						{user?.lastName ? ' ' + user.lastName : ''}
					</div>
					{user?.companyName &&
						<div className='user-role pe-1 text-light' style={{ overflow: 'hidden', textOverflow: 'ellipsis' }}>
							{user.companyName}
						</div>
					}
					{user?.email &&
						<div className='user-role pe-1 text-light' style={{ overflow: 'hidden', textOverflow: 'ellipsis' }}>
							{user.email}
						</div>
					}
				</div>
			</div>

			<Collapse isOpen={collapseStatus} className='user-menu'>
				<nav aria-label='aside-bottom-user-menu'>
					<div className='navigation'>
						<div
							role='presentation'
							className='navigation-item cursor-pointer'
							onClick={() => { navigate(`${administrationMenu.users.path}/${user?.id}/profile`) }}
						>
							<span className='navigation-link navigation-link-pill'>
								<span className='navigation-link-info'>
									<Icon icon='AccountBox' className='navigation-icon' />
									<span className='navigation-text'>Perfil</span>
								</span>
							</span>
						</div>

						<Navigation menu={generalConfigMenu} id='aside-generalConfig' />
					</div>
				</nav>

				<nav aria-label='aside-bottom-user-menu-2'>
					<div className='navigation'>
						<PushNotificationManager />
					</div>
				</nav>

				<NavigationLine />

				<nav aria-label='aside-bottom-user-menu-2'>
					<div className='navigation'>
						<div
							role='presentation'
							className='navigation-item cursor-pointer'
							onClick={() => { dispatch(logout()) }}
						>
							<span className='navigation-link navigation-link-pill'>
								<span className='navigation-link-info'>
									<Icon icon='Logout' className='navigation-icon' />
									<span className='navigation-text'>Cerrar sesión</span>
								</span>
							</span>
						</div>
					</div>
				</nav>
			</Collapse>
		</>
	);
};

export default User;