import { FC } from 'react';
import { useNavigate } from 'react-router-dom';
import Alert from '../../components/bootstrap/Alert';
import { LoginContainer } from './LoginContainer';
import { LoginForm } from './LoginForm';
import { useDispatch, useSelector } from 'react-redux';
import { AppDispatch, RootState } from '../../redux/store';
import { login } from '../../redux/authSlice';
import { toast } from 'react-toastify';
import { LoginService } from '../../services/auth/loginService';
import { saveUserToLocalStorage } from '../../utils/jwt';
import { DB_VERSION, saveUser } from '../../utils/DBUtils';

const Login: FC = () => {
	const navigate = useNavigate();

	const dispatch = useDispatch<AppDispatch>();
	const { isAuthenticated, loading, error } = useSelector((state: RootState) => state.auth);

	const loginService = new LoginService();

	const handleLogin = async (username: string, password: string) => {
		const response = await loginService.authUserCredentials(username, password);
		//console.log(response);
		const responseData = response.getResponseData();

		if (!responseData.success) {
			toast.error(responseData.message);
			return;
		}

		// store data in indexDb for service worker.
		try {
			saveUser(responseData.data);
		} catch (error) {
			toast.error('Error saving user to indexDb');
			return;
		}

		dispatch(login(responseData.data));
		navigate('/appointments/calendar');
	};

	return (

		<LoginContainer>

			{/* Alert for login errors taking api msg */}
			{/* {
				error && <Alert color='primary' isLight icon='Warning' className='d-flex justify-content-center'> {error} </Alert>
			} */}

			{/* Form section to wrapp all formik logic */}
			<LoginForm isLoading={loading} submit={handleLogin} />

		</LoginContainer>
	);
};

export default Login;
