import { useState } from "react";
import { useNavigate } from "react-router-dom";
import { toast } from "react-toastify";
import { TipsService } from "../../../services/tips/tipsService";
import TipForm from "../TipForm";

const CreateTip = () => {
    const navigate = useNavigate();

    const [loading, setLoading] = useState<boolean>(false);

    const handleCreateTip = async (values: any) => {
        setLoading(true);

        try {
            const response = await (await (new TipsService()).createTip(values)).getResponseData();

            if (response.success) {
                navigate(-1);
                setTimeout(() => {
                    toast.success('Consejo creado correctamente');
                }, 100);
            } else {
                toast.error(response.message || 'Error al crear el consejo');
            }
        } catch (error: any) {
            toast.error(error.message || 'Error al crear el consejo');
        } finally {
            setLoading(false);
        }
    }

    return <TipForm submit={handleCreateTip} isLoading={loading} />;
}

export default CreateTip;