import { useCallback, useState } from "react";
import useFetch from "../../../hooks/useFetch";
import { FormService } from "../../../services/forms/formService";
import useFilters from "../../../hooks/useFilters";
import SubHeader, { SubHeaderLeft, SubHeaderRight, SubheaderSeparator } from "../../../layout/SubHeader/SubHeader";
import Card, { CardBody, CardTitle } from "../../../components/bootstrap/Card";
import Button from "../../../components/bootstrap/Button";
import Page from "../../../layout/Page/Page";
import { CustomTable } from "../../../components/table/CustomTable";
import moment from "moment";
import { handleConfirmationAlert } from "../../../components/ConfirmationAlert";
import { toast } from "react-toastify";
import ErrorMessage from "../../../components/ErrorMessage";
import CreateTypeModal from "./CreateTypeModal";
import Icon from "../../../components/icon/Icon";
import CustomSearchInput from "../../../components/bootstrap/forms/CustomSearchInput";

interface IFormTypeFilters {
    client?: string;
}

const formTypesFilters: IFormTypeFilters = {
    client: ''
};

const ListFormTypes = () => {
    const formService = new FormService();

    const [displayCreate, setDisplayCreate] = useState<boolean>(false);

    const { filters, updateFilters, resetFilters, updateFilterOrder, updatePage, updatePageSize } = useFilters(formTypesFilters);

    const [formTypes, loadingFormTypes, formTypesError, refetchFormTypes] = useFetch(useCallback(async () => {
        const response = (await formService.getFormTypes(filters));
        return response.getResponseData();
    }, [filters]));

    // Eliminar el tipo de formulario
    const handleDelete = async (id: string) => {
        try {
            const response = await (await (new FormService()).deleteFormType(id)).getResponseData();
            if (response.success) {
                refetchFormTypes();

                toast.success('Formulario eliminado correctamente');
            } else {
                toast.error(response.message || 'Error al eliminar el formulario');
            }
        } catch (error: any) {
            toast.error(error.message || 'Error al eliminar el formulario');
        }
    }

    const _handleTextChange = (search: string) => {
        updateFilters({ search_array: search });
    };

    const getContent = () => {
        if (formTypesError) return <ErrorMessage message={formTypesError} />;

        if (formTypes && formTypes.formTypes.length > 0) {
            return (
                <CustomTable
                    title="Tipos de formulario"
                    loading={loadingFormTypes}
                    data={formTypes ? formTypes.formTypes : null}
                    pagination
                    paginationData={{
                        pageSize: filters.limit,
                        currentPage: filters.page,
                        pageCount: formTypes ? formTypes.lastPage : 1,
                        handlePagination: (page: any) => {
                            updatePage({ selected: page.selected + 1 });
                        },
                        handlePerPage: updatePageSize,
                    }}
                    className={"table-striped table-hover"}
                    columns={[
                        {
                            name: "Nombre",
                            keyValue: "name",
                            sortable: true,
                            sortColumn: updateFilterOrder,
                        },
                        {
                            name: "Formularios asociados",
                            keyValue: "forms",
                            sortable: true,
                            sortColumn: updateFilterOrder,
                            render: (element: any) => {
                                return (
                                    <>
                                        {element.forms.length || 0}
                                    </>
                                )
                            }
                        },
                        {
                            name: "Fecha de creación",
                            keyValue: "createdAt",
                            sortable: true,
                            sortColumn: updateFilterOrder,
                            render: (element: any) => {
                                return (
                                    <>
                                        {moment(element.createdAt?.date).format('DD/MM/YYYY')}
                                    </>
                                )
                            }
                        },
                        { name: "Acciones", className: "min-w-100px text-end", isActionCell: true }
                    ]}
                    actions={[
                        {
                            title: "Eliminar",
                            icon: "Delete",
                            buttonType: 'icon',
                            additionalClasses: 'text-danger',
                            description: "Eliminar tipo de formulario",
                            click: (item: any) => {
                                handleConfirmationAlert({
                                    title: "Eliminar tipo de formulario",
                                    text: "¿Está seguro que desea eliminar el tipo de formulario?",
                                    icon: "warning",
                                    onConfirm: () => {
                                        handleDelete(item.id);
                                    }
                                })
                            },
                        },
                    ]}
                />
            )
        }
    }

    return (
        <>
            <SubHeader>
                <SubHeaderLeft>
                    <CardTitle>Listado de Tipos de Formulario</CardTitle>
                    <SubheaderSeparator />
                    <Button
                        title="Crear Tipo"
                        color="storybook"
                        icon="Add"
                        isLight
                        onClick={() => setDisplayCreate(true)}
                    />
                </SubHeaderLeft>
                <SubHeaderRight>
                    <label className='border-0 bg-transparent cursor-pointer' htmlFor='searchInput'>
                        <Icon icon='Search' size='2x' color='secondary' />
                    </label>

                    <CustomSearchInput onSearch={_handleTextChange} placeholder='Buscar...' />
                </SubHeaderRight>
            </SubHeader>
            <Page container="fluid">
                <Card stretch={true}>
                    {displayCreate && <CreateTypeModal isOpen={displayCreate} setIsOpen={setDisplayCreate} label="tipo" refetch={refetchFormTypes} />}

                    <CardBody className="table-responsive" isScrollable={true}>
                        {getContent()}
                    </CardBody>
                </Card>
            </Page>
        </>
    )
}

export default ListFormTypes;