import { FC, useCallback, useState } from "react";
import Button from "../../components/bootstrap/Button";
import Card, { CardBody, CardFooter, CardTitle } from "../../components/bootstrap/Card";
import FormGroup from "../../components/bootstrap/forms/FormGroup";
import Input from "../../components/bootstrap/forms/Input";
import * as yup from "yup";
import useFetch from "../../hooks/useFetch";
import { useNavigate, useParams } from "react-router-dom";
import useFilters from "../../hooks/useFilters";
import { useFormik } from "formik";
import { MenuService } from "../../services/menus/menuService";
import Page from "../../layout/Page/Page";
import SubHeader, { SubHeaderLeft, SubheaderSeparator } from "../../layout/SubHeader/SubHeader";
import SearchableSelect from "../../components/select/SearchableSelect";
import { MenusApiResponse } from "../../type/clientMenus-type";
import { useSelector } from "react-redux";
import { RootState } from "../../redux/store";
import { toast } from "react-toastify";
import Spinner from "../bootstrap/Spinner";

interface TemplateForm {
    name: string;
    client: string;
    menu: string;
}

const templateInitialValues: TemplateForm = {
    name: '',
    client: '',
    menu: '',
}

const TemplateSchema = yup.object({
    name: yup.string().min(1, 'Demasiado corto').max(30, 'Demasiado largo').required('Campo Obligatorio'),
    menu: yup.string().required('Campo Obligatorio'),
});

const AssignTemplate: FC = () => {
    const navigate = useNavigate();
    const { id = '' } = useParams<{ id: string }>();
    const token = useSelector((state: RootState) => state.auth);
    const [loading, setLoading] = useState<boolean>(false);

    const templateFilters = {
        user: token.user?.id,
        is_template: 1,
    }

    const { filters } = useFilters(templateFilters);

    const [templates] = useFetch(useCallback(async () => {
        const menuService = new MenuService();
        const response = await menuService.listMenusFilters(filters);
        return response.getResponseData() as MenusApiResponse;
    }, [filters]));

    // Lista de plantillas
    const getTemplateList = () => {
        if (templates) {
            return templates.menus.map((template: any) => {
                return {
                    value: template.id,
                    label: template.name,
                }
            })
        }
        return [];
    }

    // Controlar el cambio de plantilla
    const _handleSelectTemplateChange = (selectedOption: any) => {
        formikTemplate.handleChange(selectedOption.value)
        formikTemplate.setFieldValue('menu', selectedOption.value)
    };

    // Asignar plantilla al paciente
    const _handleAssignTemplate = async (values: any) => {
        setLoading(true);

        values.client = id;

        try {
            const response = await (await (new MenuService()).assignTemplate(values)).getResponseData();

            if (response.success) {
                navigate(-1);
                setTimeout(() => {
                    toast.success('Plantilla asociada al paciente');
                }, 100);
            } else {
                toast.error(response.message || 'Error al asociar la plantilla');
            }
        } catch (error: any) {
            toast.error(error.message || 'Error al asociar la plantilla');
        } finally {
            setLoading(false);
        }
    }

    const formikTemplate = useFormik({
        initialValues: templateInitialValues,
        validationSchema: TemplateSchema,
        onSubmit: values => _handleAssignTemplate(values)
    });

    const verifyClass = (inputFieldID: keyof TemplateForm) => {
        return (formikTemplate.touched[inputFieldID] && formikTemplate.errors[inputFieldID]) ? 'is-invalid' : '';
    }

    const showErrors = (inputFieldID: keyof TemplateForm) => {
        return (formikTemplate.touched[inputFieldID] && formikTemplate.errors[inputFieldID]) ? <div className="invalid-feedback">{formikTemplate.errors[inputFieldID]}</div> : <></>;
    }

    return (
        <>
            <SubHeader>
                <SubHeaderLeft>
                    <Button
                        color="secondary"
                        isLink
                        icon="ArrowBack"
                        onClick={() => navigate(-1)}
                    ></Button>
                    <SubheaderSeparator />
                    <CardTitle>Asignar plantilla</CardTitle>
                </SubHeaderLeft>
            </SubHeader>
            <Page container="xxl" className="w-50">
                <Card className='rounded-2'>
                    <CardBody>
                        <form onSubmit={formikTemplate.handleSubmit} autoComplete="off">
                            <CardTitle>Asignar plantilla</CardTitle>
                            <div className="row mt-5 mx-2">
                                <div className='col-md-5'>
                                    <FormGroup requiredInputLabel label='Nombre del menú'>
                                        <Input
                                            id='name'
                                            onChange={formikTemplate.handleChange}
                                            value={formikTemplate.values.name}
                                            onBlur={formikTemplate.handleBlur}
                                            className={verifyClass('name')}
                                        />
                                        {showErrors('name')}
                                    </FormGroup>
                                </div>

                                <div className='col-md-7'>
                                    <FormGroup requiredInputLabel label='Selecciona la plantilla'>
                                        <SearchableSelect
                                            isSearchable
                                            onChange={_handleSelectTemplateChange}
                                            name='template-select'
                                            placeholder='plantilla'
                                            options={getTemplateList()}
                                            classname={verifyClass('menu')}
                                        />
                                        {showErrors('menu')}
                                    </FormGroup>
                                </div>
                            </div>
                        </form>
                    </CardBody>
                    <CardFooter className="d-flex justify-content-end">
                        <Button color='primary' onClick={formikTemplate.handleSubmit}>
                            {loading ? <Spinner /> : 'Asignar'}
                        </Button>
                    </CardFooter>
                </Card>
            </Page>
        </>
    )
}

export default AssignTemplate;