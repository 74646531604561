import { FC, Fragment, useState } from "react";
import Button from "./bootstrap/Button"
import Dropdown, { DropdownToggle, DropdownMenu, DropdownItem } from "./bootstrap/Dropdown"
import Spinner from "./bootstrap/Spinner";

interface StatusDropdownProps {
    itemId: string;
    status: boolean;
    change: (id: string, status: boolean, toggleStatus: Function) => void;
    disabled?: boolean;
    btnClass?: string;
    labelActive?: string;
    labelInactive?: string;
}

const StatusDropdown: FC<StatusDropdownProps> = ({ itemId, status, change, disabled = false, btnClass, labelActive, labelInactive }) => {

    const [isActive, setIsActive] = useState<boolean>(status);

    labelActive = labelActive || 'Activado';
    labelInactive = labelInactive || 'Desactivado';

    const toggleState = (newStatus: boolean) => {
        change(itemId, newStatus, setIsActive);
    }

    return (
        <Dropdown>
            <DropdownToggle hasIcon={false}>
                <Button isLink isDisable={disabled} color={isActive ? 'success' : 'danger'} icon='Circle' className={'text-nowrap ' + btnClass}>
                    {disabled ? <Spinner isSmall /> : <Fragment> {isActive ? labelActive : labelInactive}  </Fragment>}
                </Button>
            </DropdownToggle>
            <DropdownMenu>
                <DropdownItem>
                    <Button isLink onClick={() => toggleState(!isActive)} color={isActive ? 'danger' : 'success'} icon='Circle' className={'text-nowrap ' + btnClass}> {isActive ? labelInactive : labelActive} </Button>
                </DropdownItem>
            </DropdownMenu>
        </Dropdown>
    )
}

export default StatusDropdown;