import { useFormik } from "formik";
import FormGroup from "../../components/bootstrap/forms/FormGroup";
import Input from "../../components/bootstrap/forms/Input";
import Button from "../../components/bootstrap/Button";
import { LoginContainer } from "./LoginContainer";
import { ReCAPTCHA } from "react-google-recaptcha";
import axios from 'axios';
import { LoginService } from "../../services/auth/loginService";
import { toast } from "react-toastify";

const RecoverPassword = () => {

    // Controlar el envío del formulario
    const handleSubmit = async (email: string, captcha: string) => {
        /*TODO: quitar la siguiente línea cuando el captcha esté funcionando, para realizar la llamada sólo cuando se valide el captcha */
        sendEmail(email);

        if (captcha) {
            try {
                const response = await axios.post('verify-recaptcha', { recaptcha_response: captcha });

                if (response.data.success) {
                    sendEmail(email);
                } else {
                    toast.error('Captcha inválido. No se puede enviar el formulario.');
                }
            } catch (error: any) {
                toast.error('Error al verificar el captcha:', error);
            }
        }
    };

    // Enviar el correo electrónico al servidor
    const sendEmail = async (email: string) => {
        try {
            await (new LoginService()).sendEmailToResetPassword(email);
            toast.success('Correo electrónico enviado, por favor, revisa tu bandeja de entrada');
        } catch (error: any) {
            toast.error('Error al enviar el correo electrónico');
        }
    };

    const formik = useFormik({
        enableReinitialize: true,
        initialValues: {
            email: '',
            recaptcha_response: '',
        },
        validate: (values) => {
            const errors: { email?: string/* , recaptcha_response?: string */ } = {};
            if (!values.email) {
                errors.email = 'Debes introducir el correo electrónico asociado a tu cuenta';
            }
            if (values.email && !/^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,}$/i.test(values.email)) {
                errors.email = 'Correo electrónico inválido';
            }
            /* if (!values.recaptcha_response) {
                errors.recaptcha_response = 'Por favor, completa el captcha';
            } */
            return errors;
        },
        validateOnChange: false,
        onSubmit: (values) => {
            handleSubmit(values.email, values.recaptcha_response);
        },
    });

    return (
        <LoginContainer>
            <form onSubmit={formik.handleSubmit} className='row g-4' autoComplete="off">
                <div className="col-12">
                    <p className="fs-4">Ayuda de contraseña</p>
                    <p>Introduce la dirección de correo electrónico asociada a la cuenta y te enviaremos un enlace para ayudarte a restablecer tu contraseña.</p>

                </div>
                <div className='col-12'>
                    <FormGroup id='email' isFloating label='Correo electrónico'>
                        <Input
                            value={formik.values.email}
                            type="email"
                            onChange={formik.handleChange}
                            onFocus={formik.setErrors}
                            onBlur={formik.handleBlur}
                            isTouched={formik.touched.email}
                            invalidFeedback={formik.errors.email}
                            isValid={formik.isValid}
                        />
                    </FormGroup>
                </div>
                {/* <div className='col-12'>
                    <p>Por seguridad, necesitamos saber que no eres un robot</p>
                    <ReCAPTCHA sitekey="6Lcbu_cmAAAAAAlgdnzAJU5nPeQK70SzqZmJ8f6J" onChange={(value) => formik.setFieldValue("recaptcha_response", value)} />
                </div> */}
                <div className='col-12 mt-5'>
                    <Button color='dark' className='w-100 py-3' type='submit'>
                        SOLICITAR NUEVA CONTRASEÑA
                    </Button>
                </div>
            </form>
        </LoginContainer>
    );
}

export default RecoverPassword;