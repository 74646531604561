import { User } from "../redux/authSlice";
import { saveUser } from "./DBUtils";

export const USER_KEY = "nutric_user";


export const saveUserToLocalStorage = (user: User) => {
  try {
    const serializedUser = JSON.stringify(user);
    localStorage.setItem(USER_KEY, serializedUser);
    saveUser(user);
  } catch (error) {
    console.error(
      "Error al guardar el usuario en el almacenamiento local:",
      error
    );
  }
};

export const removeUserFromLocalStorage = () => {
  try {
    localStorage.removeItem(USER_KEY);
  } catch (error) {
    console.error(
      "Error al eliminar el usuario del almacenamiento local:",
      error
    );
  }
};

export const loadUserFromLocalStorage = (): User | null => {
  try {
    const serializedUser = localStorage.getItem(USER_KEY);
    if (serializedUser === null) {
      return null;
    }
    return JSON.parse(serializedUser);
  } catch (error) {
    console.error(
      "Error al cargar el usuario desde el almacenamiento local:",
      error
    );
    return null;
  }
};
