import moment, { Moment } from "moment";
import { TColor } from "./color-type";
import { Company } from "./company-type";
import { User } from "./user-type";
import { CreatedAt } from "./apiResponse-type";
import { AppointmentClient } from "./client-type";

export interface IEvent {
    start?: Date;
    end?: Date;
    name?: string;
    id?: string;
    status?: number;
    serviceType?: string;
    user?: IEventUser;
    color?: TColor;
    colorHex?: string;
    icon?: string;
    users?: IEventUser[];
    client?: AppointmentClient | null,
    comments?: string | null;
    notified?: boolean;
    type?: string | null;
    appointment_color?: string | null;
    paymentMethod?: string | null;
    price?: number | null;
}

export interface IEventUser {
    id?: string;
    name?: string;
    lastName?: string;
    src?: string;
    srcSet?: string;
    color?: string | null;
}

export interface AppointmentFormData {
    start?: Moment;
    end?: Moment;
    name?: string;
    id?: string;
    client?: string;
    user?: string;
    color?: string;
    createdAt?: string;
    updatedAt?: string;
    comments?: string;
    status?: number;
    serviceType?: string;
    type?: string;
    appointment_color?: string;
    paymentMethod?: string;
    price?: number;
}

export type TAppointmentTypes = {
    [key: string]: any
}

export const APPOINTMENT_TYPES_MAP: TAppointmentTypes = {
    OTHERS: {
        name: 'Others',
        label: 'Otros'
    },
    INSITE: {
        name: 'Insite',
        label: 'Presencial'
    },
    ONLINE: {
        name: 'Online',
        label: 'Online'
    },
}

export type TAppointmentServicesTypes = {
    [key: string]: any
}

export const APPOINTMENT_SERVICES_MAP: TAppointmentServicesTypes = {
    DEFAULT: {
        name: '',
        label: 'Servicio por defecto',
    },
    NEW: {
        name: 'First Date',
        label: 'Primera consulta',
    },
    REVIEW: {
        name: 'Review',
        label: 'Revisión',
    },
}

type TAppointmentStatuses = {
    [key: string]: any
}

export const APPOINTMENT_STATUSES_MAP: TAppointmentStatuses = {
    PENDING: {
        id: 1,
        name: 'Pendiente',
        color: '#FD8D14'
    },
    CONFIRMED: {
        id: 2,
        name: 'Confirmada',
        color: '#91C8E4'

    },
    PAID: {
        id: 3,
        name: 'Pagada',
        color: '#CECE5A'
    },
    CANCELLED: {
        id: 4,
        name: 'Cancelada',
        color: '#C51605'
    },
}

export const PAYMENT_METHODS_MAP: any = [
    { value: 'Efectivo', label: 'Efectivo' },
    { value: 'Tarjeta', label: 'Tarjeta' },
    { value: 'Bizum', label: 'Bizum' },
    { value: 'Transferencia', label: 'Transferencia' },
];


export type Appointment = {
    id?: string;
    name?: string;
    type?: string;
    appointmentColor?: null;
    startDate?: CreatedAt;
    endDate?: CreatedAt;
    createdAt?: CreatedAt;
    updateAt?: null;
    comments?: null;
    status?: number;
    serviceType?: string;
    paymentMethod?: string;
    price?: number;
    notified?: boolean;
    client?: AppointmentClient | null;
    company?: Company;
    user?: User;
}

export const APPOINTMENT_STATUSES: any[] = Object.values(APPOINTMENT_STATUSES_MAP);

export const getAppointmentStatus = (id: number) => {
    for (const status of Object.values(APPOINTMENT_STATUSES_MAP)) {
        if (status.id === id) {
            return status;
        }
    }
}

export const getAppointmentType = (name: string) => {
    for (const type of Object.values(APPOINTMENT_TYPES_MAP)) {
        if (type.name === name) {
            return type;
        }
    }
}

export const getAppointmentServiceType = (name: string) => {
    for (const type of Object.values(APPOINTMENT_SERVICES_MAP)) {
        if (type.name === name) {
            return type;
        }
    }
}

export const getAppointmentFormFromEvent = (event: IEvent): AppointmentFormData => {
    return {
        id: event.id,
        name: event.name,
        start: moment(event.start),
        end: moment(event.end),
        color: event.color,
        comments: event.comments ? event.comments : undefined,
        status: event.status,
        serviceType: event.serviceType ? event.serviceType : undefined,
        type: event.type ? event.type : undefined,
        appointment_color: event.appointment_color ? event.appointment_color : undefined,
        client: event.client?.id,
        user: event.user?.id,
    }
}