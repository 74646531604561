import React, { useEffect } from 'react'
import Select, { ActionMeta, GroupBase, MultiValue, OptionsOrGroups, SingleValue } from 'react-select';

type Props = {
    id: string,
    className?: string,
    isClearable?: boolean,
    options?: OptionsOrGroups<unknown, GroupBase<unknown>>
    isMulti?: boolean,
    onChange?: (selected: MultiValue<unknown>) => void,
    onChangeSingle?: (selected: SingleValue<unknown>) => void,
    onInputChange?: (inputValue: string) => void;
    defaultValue?: OptionsOrGroups<any, any> | null | unknown
    isDisabled?: boolean
}

const CustomSearchSelect: React.FC<Props> = ({ options, onChange, isMulti, onChangeSingle, defaultValue, onInputChange, isDisabled, id, className, isClearable }) => {

    const customStyles = {
        control: (base: any, state: any) => ({
            ...base,
            borderRadius: '10px',
            boxShadow: state.isFocused ? '0 0 0 3px rgba(208, 70, 54, 0.3)' : '0 -1.5px 0 1px rgba(0,0,0, 0.07) !important',
            border: '0px !important',
            backgroundColor: isDisabled ? '#e9ecef' : '#f8f9fa',
        }),
        option: (provided: any, state: any) => ({
            ...provided,
            backgroundColor: state.isFocused ? '#d04636' : 'white',
            color: state.isFocused ? 'white' : 'black',
            '&:hover': {
                backgroundColor: '#d04636',
                color: 'white',
                borderColor: '#30b7af !important'
            }
        })
    };

    const onSelectChange = (newValue: MultiValue<unknown>, actionMeta: ActionMeta<unknown>) => {
        if (onChange !== undefined) {
            onChange(newValue);
        }
    };

    const onSelectChangeSingle = (newValue: SingleValue<unknown>, actionMeta: ActionMeta<unknown>) => {
        if (onChangeSingle !== undefined) {
            onChangeSingle(newValue);
        }
    };

    const onInputValueChange = (inputValue: string) => {
        if (onInputChange !== undefined) {
            onInputChange(inputValue);
        }
    };

    return (
        <>
            {
                isMulti === true
                    ? (
                        <Select
                            id={id}
                            isSearchable={true}
                            filterOption={(option: any, inputValue: any) => { return true }}
                            isMulti={isMulti}
                            isClearable={isClearable ? isClearable : false}
                            isDisabled={isDisabled}
                            options={options}
                            defaultValue={defaultValue}
                            onChange={onSelectChange}
                            onInputChange={onInputValueChange}
                            placeholder='Empieza a escribir para mostrar opciones ...'
                            noOptionsMessage={() => 'Sin resultados'}
                            className={className}
                            styles={customStyles}
                        />
                    ) : (
                        <Select
                            id={id}
                            isSearchable={true}
                            filterOption={(option: any, inputValue: any) => { return true }}
                            isMulti={isMulti}
                            isClearable={isClearable ? isClearable : false}
                            isDisabled={isDisabled}
                            options={options}
                            defaultValue={defaultValue}
                            onChange={onSelectChangeSingle}
                            onInputChange={onInputValueChange}
                            placeholder='Empieza a escribir para mostrar opciones ...'
                            noOptionsMessage={() => 'Sin resultados'}
                            className={className}
                            styles={customStyles}
                        />
                    )
            }
        </>
    )
};

/* CustomSearchSelect.defaultProps = {
    placeholder: 'Elegir ...',
}; */

export default CustomSearchSelect;