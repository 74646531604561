import { FC, useCallback, useEffect } from "react";
import useFetch from "../../../hooks/useFetch";
import { FormService } from "../../../services/forms/formService";
import { CustomTable } from "../../../components/table/CustomTable";
import { useNavigate, useParams } from "react-router-dom";
import { handleConfirmationAlert } from "../../../components/ConfirmationAlert";
import { toast } from "react-toastify";
import { CardBody } from "../../../components/bootstrap/Card";
import useFilters from "../../../hooks/useFilters";
import { formsMenu } from "../../../menu";

interface FormModalProps {
    formId: string;
}

export interface IFormsFilters {
    form?: string;
    client?: string;
}

const ListPatientForms: FC<FormModalProps> = ({ formId }) => {
    const navigate = useNavigate();
    const { id = '' } = useParams<{ id: string }>();
    const formService = new FormService();

    const formsFilters: IFormsFilters = {
        form: formId,
        client: id
    };

    const { filters, updateFilters, resetFilters, updateFilterOrder, updatePage, updatePageSize } = useFilters(formsFilters);

    // Obtener los formularios del tipo seleccionado
    const [fills, loadingFills, fillError, refetchFills] = useFetch(useCallback(async () => {
        const response = (await formService.listFormFills(filters));
        return response.getResponseData();
    }, [filters]));

    // Eliminar un formulario
    const handleDelete = async (id: string) => {
        try {
            const response = await (await (new FormService()).deleteFill(id)).getResponseData();
            if (response.success) {
                refetchFills();
                toast.success('Formulario eliminado correctamente');
            } else {
                setTimeout(() => {
                    toast.error(response.message || 'Error al eliminar el formulario');
                }, 100);
            }
        } catch (error: any) {
            toast.error(error.message || 'Error al eliminar el formulario');
        }
    }

    // Actualizar los filtros cuando se cambia el tipo de formulario
    useEffect(() => {
        if (formId !== null) {
            updateFilters({ ...filters, formType: formId, client: id });
        }
    }, [formId]);

    const getFormList = () => {
        if (fills?.formFills?.length > 0) {
            // Obtener los campos del formulario y crear las columnas de la tabla de forma dinámica
            const fieldsColumns = [] as any;

            fills.formFills[0].formFillValues.forEach((field: any, index: number) => {
                if (index < 6) {
                    fieldsColumns.push({
                        name: field.formField.name,
                        keyValue: `field-${index}`,
                        render: (element: any) => {
                            return (
                                <div>{element.formFillValues[index].value}</div>
                            )
                        }
                    })
                }
            });

            return (
                <CustomTable
                    data={fills ? fills.formFills : null}
                    pagination
                    paginationData={{
                        pageSize: filters.limit,
                        currentPage: filters.page,
                        pageCount: fills ? fills.lastPage : 1,
                        handlePagination: (page: any) => {
                            updatePage({ selected: page.selected + 1 });
                        },
                        handlePerPage: updatePageSize,
                    }}
                    className={"table-striped table-hover"}
                    columns={[
                        {
                            name: "Nombre",
                            keyValue: "name",
                            sortable: true,
                            sortColumn: updateFilterOrder,
                            render: (element: any) => {
                                return (
                                    <div className="cursor-pointer text-secondary" onClick={() => { navigate(formsMenu.forms.path + `/${element.id}/edit-fields`) }}>
                                        {element.name}
                                    </div>
                                )
                            },
                        },
                        ...fieldsColumns,
                        {
                            name: "Creación",
                            keyValue: "createdAt",
                            sortable: true,
                            sortColumn: updateFilterOrder,
                            render: (element: any) => {
                                return (
                                    <div>{new Date(element.createdAt.date).toLocaleDateString()}</div>
                                )
                            }
                        },
                        { name: "Acciones", className: "min-w-100px text-end", isActionCell: true }
                    ]}
                    actions={[
                        {
                            title: "Editar",
                            icon: "Edit",
                            buttonType: 'icon',
                            additionalClasses: 'text-primary',
                            description: "Editar formulario",
                            click: (item: any) => {
                                navigate(formsMenu.forms.path + `/${item.id}/edit-fields`);
                            }
                        },
                        {
                            title: "Eliminar",
                            icon: "Delete",
                            buttonType: 'icon',
                            additionalClasses: 'text-danger',
                            description: "Eliminar formulario",
                            click: (item: any) => {
                                handleConfirmationAlert({
                                    title: "Eliminar formulario",
                                    text: "¿Está seguro que desea eliminar el formulario?",
                                    icon: "warning",
                                    onConfirm: () => {
                                        handleDelete(item.id);
                                    }
                                })
                            },
                        },
                    ]}
                />
            )
        } else {
            return (
                <div className="text-center mt-3"><h5 className="text-muted">No hay formularios</h5></div>
            )
        }
    }

    return (
        <>
            <CardBody className="cardBodyForms">
                {formId ? getFormList() : <div className="text-center"><h5 className="text-muted">No hay formularios</h5></div>}
            </CardBody>
        </>
    );
}

export default ListPatientForms;