import { FC, useState } from "react";
import Input from "./Input"
import Button from "../Button";
import InputGroup from "./InputGroup";
import Icon from "../../icon/Icon";
import { PatientForm } from "../../../pages/patients/PatientForm";
import FormGroup from "./FormGroup";
import classNames from "classnames";

interface CustomPasswordInputProps {
    formik: any;
    id: keyof PatientForm;
    value: string;
    invalidFeedback?: string;
    label?: string;
    cols?: number;
    isFloating?: boolean;
}

const CustomPasswordInput: FC<CustomPasswordInputProps> = ({ formik, id, label, value, cols, invalidFeedback, isFloating }) => {

    const [showPass, setShowPass] = useState(false);

    const verifyClass = (inputFieldID: keyof PatientForm) => { return (formik.touched[inputFieldID] && formik.errors[inputFieldID]) ? 'is-invalid' : '' };

    const showErrors = (inputFieldID: keyof PatientForm) => {
        //@ts-ignore
        return (formik.touched[inputFieldID] && formik.errors[inputFieldID]) ? <div className="text-danger" style={{ fontSize: '11.375px' }}>{formik.errors[inputFieldID]}</div> : <></>;
    }

    return (
        <FormGroup requiredInputLabel isFloating={isFloating} label={label} className={classNames('mt-4', { [`col-md-${cols}`]: cols })}>
            <InputGroup>
                <Input
                    type={showPass ? 'text' : 'password'}
                    id={id as string}
                    onChange={formik.handleChange}
                    onBlur={formik.handleBlur}
                    value={value}
                    isValid={formik.isValid}
                    invalidFeedback={invalidFeedback}
                    autoComplete="new-password"
                    className={verifyClass(id)}
                    placeholder={isFloating ? '' : "Contraseña"}
                />
                <Button onClick={() => { setShowPass(!showPass) }} style={{ backgroundColor: '#F8F9FA' }}>
                    <Icon icon={showPass ? 'RemoveRedEye' : 'VisibilityOff'} size={'lg'} />
                </Button>
            </InputGroup>

            {showErrors(id)}
        </FormGroup>
    )
}

export default CustomPasswordInput;