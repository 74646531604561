import { FC, useCallback } from "react";
import { useNavigate, useParams } from "react-router-dom";
import Spinner from "../../../components/bootstrap/Spinner";
import useFetch from "../../../hooks/useFetch";
import { toast } from "react-toastify";
import PatientForm from "../PatientForm";
import { PatientService } from "../../../services/patients/patientService";
import { Patient } from "../../../type/patients-type";
import { ProfileService } from "../../../services/profile/profileService";
import { authMenu } from "../../../menu";
import { allergenList } from "../../../utils/FoodUtils";
import ErrorMessage from "../../../components/ErrorMessage";
import { UserService } from "../../../services/users/userService";

const PatientEdit: FC = () => {
  const navigate = useNavigate();
  const { id = '' } = useParams<{ id: string }>();

  // Servicios
  const patientService = new PatientService();
  const profileService = new ProfileService();
  const userService = new UserService();

  // Obtener el perfil del usuario logueado
  const [profile] = useFetch(useCallback(async () => {
    const response = await profileService.getMyProfile();
    return response.getResponseData();
  }, []));

  // Obtener los datos del paciente por id
  const [data, loading, error] = useFetch(useCallback(async () => {
    const response = (await patientService.getPatientById(id as string));
    return response.getResponseData() as Patient;
  }, [id]));

  // Cambiar contraseña
  const changePassword = async (password: string, confirmPassword: string) => {
    try {
      const response = await userService.changePassword(id, password, confirmPassword);
      const responseData = response.getResponseData();

      if (!responseData.success) {
        toast.error(responseData.message || "Error al cambiar la contraseña");
      }
    }
    catch (error: any) {
      toast.error(error.message);
    }
  }

  const handleUpdate = async (values: any) => {
    values.client = id; // asignar el id del paciente al objeto values
    !values.user && (values.user = profile?.id); // asignar el id del usuario al objeto values

    if (values.password !== "" && values.passwordConfirm !== "" && values.password === values.passwordConfirm) {
      await changePassword(values.password, values.passwordConfirm);
    }

    try {
      const response = await patientService.editPatient(values);
      const responseData = response.getResponseData();

      if (responseData.success) {
        navigate(authMenu.patients.path, { replace: true });
        setTimeout(() => {
          toast.success('Paciente editado correctamente');
        }, 100);
      } else {
        toast.error(responseData.message || "Error al editar el paciente");
      }
    } catch (error: any) {
      toast.error(error.message);
    }
  };

  const getContent = () => {
    if (loading) return <Spinner />;

    if (error) return <ErrorMessage message={error.message} />;

    if (data !== null) {
      const patientData = {
        user: data.user !== null ? data.user?.id : "",
        name: data.name !== null ? data.name : "",
        last_name: data.lastName !== null ? data.lastName : "",
        email: data.email !== null ? data.email : "",
        dni: data.dni !== null ? data.dni : "",
        sex: data.sex !== null ? data.sex : "",
        province: data.province !== null ? data.province : "",
        city: data.city !== null ? data.city : "",
        pc: data.pc !== null ? data.pc : "",
        address: data.address !== null ? data.address : "",
        telephone: data.telephone !== null ? data.telephone : "",
        company: data.clientRoles[0].company !== null ? data.clientRoles[0].company.id : "",
        password: "",
        passwordConfirm: "",
        birthday_date: data.birthdayDate !== null ? data.birthdayDate.date.slice(0, 10) : "",
        clientAversions: data.clientAversions !== null ? data.clientAversions : [],
        allergens: [] as string[],
      };

      // Obtener la lista de alérgenos y asignar al paciente los que sean true
      for (const allergenKey in data) {
        if (data[allergenKey] === true) {
          const allergen = allergenList.find(item => item.name === allergenKey);
          if (allergen) {
            patientData.allergens.push(allergen.name);
          }
        }
      }

      return (
        <PatientForm isLoading={false} submit={handleUpdate} patientData={patientData} profileImg={data.profileImg} />
      );
    }
  };

  return (
    <>{getContent()}</>
  );
};

export default PatientEdit;