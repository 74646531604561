import { FC, Fragment, useCallback } from "react";
import { CardBody } from "../../../components/bootstrap/Card";
import Progress from "../../../components/bootstrap/Progress";
import { Patients, PatientsApiResponse } from "../../../type/patients-type";
import { CustomTable } from "../../../components/table/CustomTable";
import AsyncImg from "../../../components/AsyncImg";
import { useNavigate } from "react-router-dom";
import useFetch from "../../../hooks/useFetch";
import Spinner from "../../../components/bootstrap/Spinner";
import { PatientService } from "../../../services/patients/patientService";
import { handleConfirmationAlert } from "../../../components/ConfirmationAlert";
import { toast } from "react-toastify";
import ErrorMessage from "../../../components/ErrorMessage";

interface UserHasPatientsProps {
    id: string;
}

const UserHasPatients: FC<UserHasPatientsProps> = (id) => {
    const navigate = useNavigate();

    const [patients, loadingPatients, errorPatients, refetchPatients] = useFetch(useCallback(async () => {
        const patientService = new PatientService();
        const response = await patientService.getUserPatients(id.id);
        return response.getResponseData() as Patients;
    }, []));

    const _handleDelete = async (id: string) => {
        try {
            const response = await (await (new PatientService()).deletePatient(id)).getResponseData();
            if (response.success) {
                refetchPatients();
                toast.success('Paciente eliminado correctamente');
            } else {
                toast.error(response.message || 'Error al eliminar el paciente');
            }
        } catch (error: any) {
            toast.error(error.message || 'Error al eliminar el paciente');
        }
    }

    const getPatientsList = () => {
        if (loadingPatients) return <Spinner />;

        if (errorPatients) return <ErrorMessage message={errorPatients} />;

        if (patients && patients.patients.length > 0) {
            let apiData = patients as PatientsApiResponse;

            return (
                <CustomTable
                    data={apiData.patients ? apiData.patients : null}
                    className={"table-striped table-hover"}

                    columns={[
                        {
                            name: "",
                            keyValue: "PatientImage",
                            render: (element: any) => {
                                return (
                                    <div className="d-flex justify-content-center">
                                        <div className='user-avatar'>
                                            <AsyncImg id={element.profileImg ? element.profileImg.id : null} />
                                        </div>
                                    </div>
                                );
                            },
                        },
                        {
                            name: "Nombre",
                            keyValue: "name",
                            sortable: true,
                            //sortColumn: updateFilterOrder,
                            render: (element: any) => {
                                return (
                                    <div className="cursor-pointer text-secondary" onClick={() => { navigate(`/clients/${element.id}/view`) }}>
                                        {element.name + (element.lastName ? " " + element.lastName : "")}
                                    </div>
                                )
                            },
                        },
                        {
                            name: "Correo electrónico",
                            keyValue: "email",
                            render: (element: any) => {
                                return (
                                    <>
                                        {element.email || "-"}
                                    </>
                                )
                            }
                        },
                        {
                            name: "Teléfono",
                            keyValue: "telephone",
                            render: (element: any) => {
                                return (
                                    <>
                                        {element.telephone || "-"}
                                    </>
                                )
                            }
                        },
                        {
                            name: "Etiquetas",
                            keyValue: "clientLabels",
                            render: (element: any) => {
                                return (
                                    <>
                                        {
                                            element.clientLabels ? (
                                                element.clientLabels?.map((tag: any) => {
                                                    return (
                                                        <span
                                                            key={tag.clientLabel.name}
                                                            className="badge text-center w-auto me-3 p-2 ps-3 pe-3 position-relative"
                                                            style={
                                                                {
                                                                    backgroundColor: tag.clientLabel.color || '#d7d7d7',
                                                                    color: tag.clientLabel.color == '#3c3c3b' || tag.clientLabel.color == '#b81e53' || tag.clientLabel.color == '#e5133d' ? 'white' : 'black',
                                                                }}
                                                        >
                                                            {tag.clientLabel.name}
                                                        </span>
                                                    );
                                                })
                                            ) : '-'
                                        }
                                    </>
                                )
                            }
                        },
                        { name: "Acciones", className: "min-w-100px text-end", isActionCell: true }
                    ]}
                    actions={[
                        {
                            title: "Editar",
                            icon: "Edit",
                            buttonType: 'icon',
                            additionalClasses: 'text-primary',
                            description: "Editar perfil de paciente",
                            click: (item: any) => {
                                navigate(`/clients/${item.id}/edit`);
                            },
                        },
                        {
                            title: "Eliminar",
                            icon: "Delete",
                            buttonType: 'icon',
                            additionalClasses: 'text-danger',
                            description: "Eliminar paciente",
                            click: (item: any) => {
                                handleConfirmationAlert({
                                    title: "Eliminar paciente",
                                    text: "¿Está seguro que desea eliminar el paciente?",
                                    icon: "warning",
                                    onConfirm: () => {
                                        _handleDelete(item.id);
                                    }
                                })
                            },
                        },
                    ]}
                />
            )
        } else {
            return (
                <div className="text-center">
                    <h5 className="text-muted mt-5">No hay pacientes asociados a este usuario</h5>
                </div>
            )
        }
    }

    return (
        <Fragment>
            <CardBody>
                <div className='d-flex justify-content-between'>
                    <p className='fw-bold'>{patients?.patients.length}</p>
                </div>
                <Progress value={patients?.patients.length} max={10} isAutoColor className="mb-5" />
                {getPatientsList()}
            </CardBody>
        </Fragment>
    )
}

export default UserHasPatients;