import { AxiosResponse } from 'axios';
import { RestServiceConnection } from '../restServiceConnection';

const NOTE_ENDPOINT = '/notes';

export class NotesService extends RestServiceConnection {

    getNote = async (id: string) => {
        this.response = await this.makeRequest({
            method: 'POST',
            url: NOTE_ENDPOINT + '/get',
            data: {
                client: id
            }
        }, true);
        return this;
    }

    listNotes = async (filters?: any) => {
        this.response = await this.makeRequest({
            method: 'POST',
            url: NOTE_ENDPOINT + '/list',
            data: {
                ...filters
            }
        }, true) as AxiosResponse;
        return this;
    }

    createPatientNote = async (note: any) => {
        this.response = await this.makeRequest({
            method: 'POST',
            url: NOTE_ENDPOINT + '/create',
            data: note
        }, true);
        return this;
    }

    editPatientNote = async (note: any) => {
        this.response = await this.makeRequest({
            method: 'POST',
            url: NOTE_ENDPOINT + '/edit',
            data: note
        }, true);
        return this;
    }

    deletePatientNote = async (note: string) => {
        this.response = await this.makeRequest({
            method: 'POST',
            url: NOTE_ENDPOINT + '/delete',
            data: {
                note: note
            }
        }, true);
        return this;
    }

}