import { Fragment, useCallback, useState } from "react";
import Button from "../../../components/bootstrap/Button";
import Card, { CardBody, CardTitle } from "../../../components/bootstrap/Card";
import Icon from "../../../components/icon/Icon";
import Page from "../../../layout/Page/Page";
import SubHeader, { SubHeaderLeft, SubHeaderRight, SubheaderSeparator } from "../../../layout/SubHeader/SubHeader";
import { RoleService } from "../../../services/auth/roleService";
import { RolesApiResponse } from "../../../type/role-type";
import useFetch from "../../../hooks/useFetch";
import { useNavigate } from "react-router-dom";
import { handleConfirmationAlert } from "../../../components/ConfirmationAlert";
import { toast } from "react-toastify";
import RoleCreateModal from "../role-create/RoleCreateModal";
import ErrorMessage from "../../../components/ErrorMessage";
import { CustomTable } from "../../../components/table/CustomTable";
import useFilters from "../../../hooks/useFilters";
import CustomSearchInput from "../../../components/bootstrap/forms/CustomSearchInput";

export interface IRoleFilters {
  name?: string;
}

const roleFilters: IRoleFilters = {
  name: '',
};

const RoleList = () => {

  const navigate = useNavigate();

  const [roleCreationModal, setRoleCreationModal] = useState(false);

  const { filters, updateFilters, resetFilters, updateFilterOrder, updatePage, updatePageSize } = useFilters(roleFilters);

  const [roleData, loadingRole, errorRole, refetch] = useFetch(useCallback(async () => {
    const roleService = new RoleService();
    const response = await roleService.listRoles(filters);
    return response.getResponseData() as RolesApiResponse;
  }, [filters]));

  const handleDelete = async (id: string) => {
    try {
      const response = await (await (new RoleService()).deleteRole(id)).getResponseData();
      if (response.success) {
        refetch();
        setTimeout(() => {
          toast.success("Rol eliminado correctamente");
        }, 100);
      } else {
        toast.error(response.message);
      }
    } catch (error: any) {
      toast.error(error);
    }
  }

  const getContent = () => {
    if (errorRole) return <ErrorMessage error={errorRole} />;

    const renderRoleTable = (props: any) => {
      const { roleData, handleConfirmationAlert, handleDelete, navigate } = props;

      return (
        <CustomTable
          title="Roles"
          loading={loadingRole}
          data={roleData.roles ? roleData.roles : null}
          pagination={true}
          paginationData={{
            pageSize: filters.limit,
            currentPage: filters.page,
            pageCount: roleData as RolesApiResponse ? roleData.lastPage : 1,
            handlePagination: (page: any) => {
              updatePage({ selected: page.selected + 1 });
            },
            handlePerPage: updatePageSize,
          }}
          className={"table-striped table-hover"}
          columns={[
            {
              name: "Nombre del rol",
              keyValue: "name",
              sortable: true,
              sortColumn: updateFilterOrder,
              render: (element: any) => {
                return (
                  <div className="cursor-pointer text-secondary" onClick={() => { navigate(`${element.id}/edit`) }}>
                    {element.name}
                  </div>
                );
              },
            },
            {
              name: "Organización",
              keyValue: 'organization',
              sortable: true,
              sortColumn: updateFilterOrder,
              render: (item: any) => {
                return item.company ? item.company.name : 'Administrador por defecto'
              },
            },
            {
              name: "Descripción",
              keyValue: "description",
              sortable: true,
              sortColumn: updateFilterOrder,
              render: (element: any) => {
                return (
                  <>
                    {element.description || "-"}
                  </>
                );
              },
            },
            {
              name: "Nº Permisos asociados",
              keyValue: "permissions",
              sortable: true,
              sortColumn: updateFilterOrder,
              render: (item: any) => {
                return item.permissions.length;
              },
            },
            { name: "Acciones", className: "min-w-100px text-end", isActionCell: true }
          ]}
          actions={[
            {
              title: "Editar",
              icon: "Edit",
              buttonType: 'icon',
              additionalClasses: 'text-primary',
              description: "Editar rol",
              click: (item: any) => {
                navigate(`${item.id}/edit`);
              },
            },
            {
              title: "Eliminar",
              icon: "Delete",
              buttonType: 'icon',
              additionalClasses: 'text-danger',
              description: "Eliminar rol",
              click: (item: any) => {
                handleConfirmationAlert({
                  title: "Eliminar rol",
                  text: "¿Está seguro que desea eliminar el rol?",
                  icon: "warning",
                  onConfirm: () => {
                    handleDelete(item.id);
                  }
                })
              },
            },
          ]}
        />
      );
    };

    if (roleData) {
      return renderRoleTable({
        roleData,
        handleConfirmationAlert,
        handleDelete,
        navigate,
      });
    };
  }

  const _handleTextChange = (search: string) => {
    updateFilters({ search_array: search });
  };

  return (
    <Fragment>
      <SubHeader>
        <SubHeaderLeft>
          <CardTitle>Listado de Roles</CardTitle>
          <SubheaderSeparator />
          <Button
            color="storybook"
            icon="Add"
            isLight
            onClick={() => setRoleCreationModal(true)}
          >
          </Button>
        </SubHeaderLeft>
        <SubHeaderRight>
          <label className='border-0 bg-transparent cursor-pointer' htmlFor='searchInput'>
            <Icon icon='Search' size='2x' color='secondary' />
          </label>

          <CustomSearchInput onSearch={_handleTextChange} placeholder='Buscar...' />
        </SubHeaderRight>
      </SubHeader>
      <Page container="fluid">
        <Card stretch={true}>
          <CardBody>
            <>{getContent()}</>

            {
              roleCreationModal && (
                <RoleCreateModal
                  isOpen={roleCreationModal}
                  setIsOpen={setRoleCreationModal}
                  refetch={refetch}
                />)
            }
          </CardBody>
        </Card>
      </Page>
    </Fragment>
  );
};

export default RoleList;