import { useEffect, useState } from "react"
import SearchableSelect from "./SearchableSelect"
import { showErrors } from "../../utils/Forms/FormikVerifyClass"
import { APPOINTMENT_STATUSES } from "../../type/appointment-type"

type AppointmentStatusSelectorProps = {
    name: string,
    onChange: (data: any) => void,
    formik: any,
    selectedId: string | undefined
}

export const AppointmentStatusSelector: React.FC<AppointmentStatusSelectorProps> = ({ name, onChange, selectedId, formik }) => {

    const defaultValue = { value: '', label: 'Todos' };
    const [selected, setSelected] = useState<any>(defaultValue);

    const data = APPOINTMENT_STATUSES;

    useEffect(() => {
        if (data === undefined) return;
        if (selectedId === undefined) return;
        const selectedItem = data.find((c: any) => c.id === selectedId);
        if (selectedItem === undefined) return;
        setSelected({
            value: selectedItem.id,
            label: `${selectedItem.name}`,
        })
    }, [selectedId]);

    useEffect(() => {
        if (selected === undefined) return;
        //console.log(selected);
        onChange(selected.value + "");
    }, [selected]);

    return (
        <>
            <SearchableSelect
                name={name}
                isSearchable
                onBlur={formik.handleBlur}
                placeholder='Seleccionar estado...'
                value={selected}
                isClearable
                defaultValue={defaultValue}
                onChange={(newValue: any) => {

                    if (newValue === null) {
                        setSelected(defaultValue);
                        formik.setFieldValue(name, null);
                        return;
                    }
                    setSelected(newValue)
                    formik.setFieldValue(name, newValue?.value ? newValue.value : null)
                }}
                options={data?.map((c: any) => ({
                    value: c.id,
                    label: `${c.name}`,
                }))}
            />
            {showErrors(formik, name)}
        </>
    )
}