import React, { useCallback, useEffect, useState } from 'react';
import { MenuService } from '../../services/menus/menuService';
import Button from '../bootstrap/Button';
import Icon from '../icon/Icon';
import { toast } from 'react-toastify';
import { handleConfirmationAlert } from '../../components/ConfirmationAlert';
import NutritionalMenuSelectors from './NutritionalMenuSelectors';
import { Menu, MenusApiResponse } from '../../type/clientMenus-type';
import DropContainer from './DropContainer';
import NutritionalMenuValues from './NutritionalMenuValues';
import useFetch from '../../hooks/useFetch';
import PatientDataModal from './PatientDataModal';
import EasyEdit from 'react-easy-edit';
import CreateTemplateModal from './CreateTemplateModal';
import { useFormik } from 'formik';
import NutritionalGraph from './NutritionalGraph';
import { NavigationLine } from '../../layout/Navigation/Navigation';
import { Intake, Day } from '../../type/menu-type';
import SearchableSelect from '../select/SearchableSelect';
import { AlimentService } from '../../services/aliments/alimentService';
import './styles/styles.scss';
import Spinner from '../bootstrap/Spinner';

interface MenuNutricionalProps {
    menuId: string;
    allergens: string[];
}

const NutritionalMenu: React.FC<MenuNutricionalProps> = ({ menuId, allergens }) => {

    const menuService = new MenuService();
    const foodService = new AlimentService();

    const [nutValuesFrom, setNutValuesFrom] = useState<any>({ 'menu': menuId });
    const [changedNutValues, setChangedNutValues] = useState<boolean>(false);
    const [displayPatientData, setDisplayPatientData] = useState<boolean>(false);
    const [displayCreateTemplate, setDisplayCreateTemplate] = useState<boolean>(false);
    const [displayAddItem, setDisplayAddItem] = useState(false);
    const [displayNutValues, setDisplayNutValues] = useState(false);
    const [displayColorDay, setDisplayColorDay] = useState<string | null>(null);
    const [multiplierGroup, setMultiplierGroup] = useState<string>('');
    const [isSending, setIsSending] = useState(false);
    const [isDownloading, setIsDownloading] = useState(false);

    const [data, loadingMenu, menuError, refetchMenu] = useFetch(useCallback(async () => {
        const response = (await menuService.getMenuById(menuId as string, null));
        return response.getResponseData() as Menu;
    }, []));

    // Obtiene los valores nutricionales de las ingestas por día
    const [intakesValuesPerDay, loadingIntakesValuesPerDay, intakesValuesPerDayError, refetchIntakesValuesPerDay] = useFetch(useCallback(async () => {
        const response = (await menuService.getIntakesValuesPerDay(nutValuesFrom));
        return response.getResponseData() as Menu;
    }, [nutValuesFrom]));

    // buscar los grupos de alimentos
    const [groups] = useFetch(useCallback(async () => {
        const response = await foodService.getAlimentGroups();
        return response.getResponseData();
    }, []));

    // Filtrar los objetos cuyo "name" sea igual a "energy", "carbohydrates", "proteins" o "fat"
    const filteredData = intakesValuesPerDay?.map((day: any) => {
        const filteredValues = day.values?.filter((item: any) =>
            ['energy', 'carbohydrates', 'proteins', 'fat'].includes(item.name)
        ).map((item: any) => {
            if (item.name === 'energy') {
                return {
                    ...item,
                    nombre: 'Kcal',
                };
            } else if (item.name === 'carbohydrates') {
                return {
                    ...item,
                    nombre: 'HC (g)',
                };
            } else if (item.name === 'proteins') {
                return {
                    ...item,
                    nombre: 'Prot (g)',
                };
            } else if (item.name === 'fat') {
                return {
                    ...item,
                    nombre: 'Lip (g)',
                };
            }
            return item;
        });

        return {
            ...day,
            values: filteredValues,
        };
    });

    // Valor máximo de día e ingesta para ordenar las columnas y filas
    const maxDayValue = data?.days.reduce((prev: number, current: Day) => (prev > current.value) ? prev : current.value, 0);
    const maxIntakeValue = data?.intakes.reduce((prev: number, current: Intake) => (prev > current.value) ? prev : current.value, 0);

    // Editar nombre del día/ingesta
    const _handleUpdateName = async (id: string, name: string, day: boolean) => {
        const serviceFunction = day ? menuService.editDay : menuService.editIntake;
        const errorMessage = day ? "Error al editar el día" : "Error al editar la ingesta";

        try {
            const response = await serviceFunction(id, name);
            const responseData = response.getResponseData() as MenusApiResponse;

            if (responseData.success) {
                refetchMenu();
            } else {
                toast.error(errorMessage);
            }
        } catch (error: any) {
            toast.error(error.message);
        }
    };

    // Descargar menú
    const _downloadMenu = async () => {
        try {
            setIsDownloading(true);
            const response = await menuService.downloadMenu(menuId);
            if (response) {
                const fileData = response.getResponseData();
                const blob = new Blob([fileData], { type: 'application/pdf' });
                const url = window.URL.createObjectURL(blob);
                const link = document.createElement('a');
                link.href = url;
                link.setAttribute('download', data.name.replace(/\s/g, "") + '.pdf');
                document.body.appendChild(link);
                link.click();
                document.body.removeChild(link);
                window.URL.revokeObjectURL(url);
            } else {
                toast.error('Error al exportar los alimentos');
            }
            setIsDownloading(false);
        } catch (error: any) {
            toast.error(error.message);
            setIsDownloading(false);
        }
    };

    // Enviar menú por email
    const _sendMenuByEmail = async () => {
        try {
            setIsSending(true);
            const response = await menuService.sendMenuByEmail(menuId, data.client.email);
            const responseData = response.getResponseData() as MenusApiResponse;

            if (responseData.success) {
                toast.success("Plan enviado al paciente");
            } else {
                toast.error("Error al enviar el plan al paciente");
            }
            setIsSending(false);
        } catch (error: any) {
            toast.error(error.message);
            setIsSending(false);
        }
    };

    // Editar multiplicador
    const _handleUpdateMultiplier = async (values: any) => {
        values.menuId = menuId;

        try {
            const response = await menuService.editMultiplier(values);
            const responseData = response.getResponseData() as MenusApiResponse;

            if (responseData.success) {
                refetchMenu();
                toast.success("Multiplicador editado");
            } else {
                toast.error("Error al editar el multiplicador");
            }
        } catch (error: any) {
            toast.error(error.message);
        }
    };

    // Cambiar multiplicador por grupo
    const _handleGroupMultiplierChange = async (e: any) => {
        setMultiplierGroup(e);
        formikMultiplier.setFieldValue('menuGroupMultiplier', e.value);
    };

    // obtener la lista de grupos de alimentos
    const getGroupsOptions = () => {
        if (groups) {
            return groups.food_groups.map((option: { id: any; name: any; }) => {
                return {
                    value: option.id,
                    label: option.name
                }
            })
        }
        return [];
    }

    // Añadir día/ingesta al menú
    const _addRowCol = async (rowCol: string) => {
        try {
            const response = await (rowCol === "day"
                ? await (menuService).addRowCol(menuId, true, false)
                : await (menuService).addRowCol(menuId, false, true)).getResponseData();
            if (response.success) {
                refetchMenu();
            } else {
                toast.error(response.data.errors[0].message || "Error al añadir " + rowCol === "day" ? "día" : "ingesta");
            }
        } catch (error: any) {
            toast.error(error.message || "Error al añadir " + rowCol === "day" ? "día" : "ingesta");
        }
    };

    // Cambiar orden de día/ingesta
    const _handleChangeOrderItem = async (day: boolean, id: string, order: string) => {
        try {
            const response = await (day
                ? await (menuService).changeDayOrder(id, order)
                : await (menuService).changeIntakeOrder(id, order)).getResponseData();
            if (response.success) {
                refetchMenu();
            } else {
                toast.error(response.data.errors[0].message || "Error al cambiar el orden del día");
            }
        } catch (error: any) {
            toast.error(error.message || "Error al cambiar el orden del día");
        }
    };

    // Eliminar día
    const _deleteDay = async (dayId: string) => {
        try {
            const response = (await (new MenuService()).deleteDay(dayId)).getResponseData();
            if (response.success) {
                refetchMenu();
                setChangedNutValues(true);
            } else {
                toast.error(response.data.errors[0].message || "Error al eliminar el día");
            }
        } catch (error: any) {
            toast.error(error.message || "Error al eliminar el día");
        }
    };

    // Eliminar ingesta
    const _deleteIntake = async (intakeId: string) => {
        try {
            const response = (await (new MenuService()).deleteIntake(intakeId)).getResponseData();
            if (response.success) {
                refetchMenu();
            } else {
                toast.error(response.data.errors[0].message || "Error al eliminar la ingesta");
            }
        } catch (error: any) {
            toast.error(error.message || "Error al eliminar la ingesta");
        }
    };

    // Cambiar el color de fondo del día seleccionado
    const _handleDayClick = (dayId: string) => {
        setDisplayColorDay(dayId);
    };

    // Setea a true el estado de changedNutValues cuando se cambia el valor de nutValues
    useEffect(() => {
        if (nutValuesFrom !== null) {
            setChangedNutValues(true);
            setDisplayNutValues(true);
        }
    }, [nutValuesFrom]);

    const formik = useFormik({
        initialValues: {
            id: '',
            name: '',
            day: true
        },
        onSubmit: async (values) => {
            _handleUpdateName(values.id, values.name, values.day)
        }
    });

    const formikMultiplier = useFormik({
        initialValues: {
            multiplier: 1,
            menuGroupMultiplier: ''
        },
        onSubmit: async (values) => {
            _handleUpdateMultiplier(values)
        }
    });

    const getMenu = () => {
        if (!data) return <></>;

        return (
            <>
                <div className='actions-menu rounded row d-flex justify-content-between'>
                    <div className='col-md-4'>
                        {
                            data.client && (
                                <form onSubmit={formikMultiplier.handleSubmit} autoComplete="off" className='row'>
                                    <div className='col-md-4 d-flex align-items-center'>
                                        <h5 className="w-auto">
                                            <EasyEdit
                                                type="number"
                                                onSave={(e: any) => {
                                                    formikMultiplier.setFieldValue('multiplier', e)
                                                }}
                                                value='Multiplicador'
                                                placeholder='1'
                                                attributes={{ className: 'easy-input' }}
                                                saveButtonLabel={<Icon icon='Check' color='dark' />}
                                                cancelButtonLabel={<Icon icon='Close' color='dark' />}
                                            />
                                        </h5>
                                    </div>

                                    <div className='col-md-6 d-flex align-items-center'>
                                        {
                                            groups && (
                                                <SearchableSelect
                                                    onChange={(e: any) => _handleGroupMultiplierChange(e)}
                                                    value={multiplierGroup}
                                                    options={getGroupsOptions()}
                                                    name='group'
                                                    classname='w-100'
                                                    placeholder='grupo'
                                                />
                                            )
                                        }
                                    </div>

                                    <div className='col-md-2 d-flex align-items-center p-0'>
                                        <Button
                                            className='p-2 m-0'
                                            color='light'
                                            onClick={() => formikMultiplier.handleSubmit()}
                                            title='Guardar'
                                        >
                                            <Icon icon="Save" color='secondary' size={'lg'} />
                                        </Button>
                                    </div>
                                </form>
                            )
                        }
                    </div>

                    <div className='col-md-8 d-flex justify-content-end p-0'>
                        <Button
                            className="p-2"
                            color='primary'
                            isLight
                            onClick={() => { _downloadMenu() }}
                            title='Imprimir'
                        >
                            {isDownloading ? <div className='text-center'><Spinner /></div> : <Icon icon="Print" color='dark' size={'2x'} />}
                        </Button>

                        {
                            data.client && (
                                <>
                                    <Button
                                        className="p-2"
                                        color='primary'
                                        isLight
                                        onClick={() => { _sendMenuByEmail() }}
                                        title='Enviar por email'
                                    >
                                        {isSending ? <div className='text-center'><Spinner /></div> : <Icon icon="Email" color='dark' size={'2x'} />}
                                    </Button>

                                    <Button
                                        className="p-2"
                                        color='primary'
                                        isLight
                                        onClick={() => { setDisplayPatientData(true) }}
                                        title='Datos del paciente'
                                    >
                                        <Icon icon="Portrait" color='dark' size={'2x'} />
                                    </Button>
                                </>
                            )
                        }

                        <Button
                            className='p-2'
                            color='primary'
                            isLight
                            onClick={() => { setDisplayCreateTemplate(true) }}
                            title='Crear plantilla'
                        >
                            <Icon icon="Assignment" color='dark' size={'2x'} />
                        </Button>

                        <Button
                            className='p-2'
                            color='primary'
                            isLight
                            onClick={() => {
                                setChangedNutValues(false);
                                setNutValuesFrom({ menu: menuId });
                                _handleDayClick(menuId);
                            }}
                            title='Ver resumen general'
                        >
                            <Icon icon='QueryStats' color='dark' size={'2x'} />
                        </Button>

                        <Button
                            className='p-2'
                            color='primary'
                            isLight
                            onClick={() => { displayAddItem ? setDisplayAddItem(false) : setDisplayAddItem(true) }}
                            title='Añadir items'
                        >
                            <Icon icon="Add" color='dark' size={'2x'} />
                            {displayAddItem ? <Icon icon="KeyboardArrowUp" color='dark' size={'lg'} /> : <Icon icon="KeyboardArrowDown" color='dark' size={'lg'} />}
                        </Button>
                    </div>
                </div>

                {displayAddItem && <NutritionalMenuSelectors />}

                <div className="table-responsive" id="menuTab">
                    <table className="menu-table table table-striped" style={{ width: '99%' }}>
                        <thead>
                            <tr>
                                <th className='sticky-header'>
                                    <Button
                                        className="btn btn-light"
                                        onClick={() => _addRowCol("day")}
                                        title='Añadir día'
                                        isDisable={data.days.length >= 31}
                                    >
                                        <Icon icon="Add" color='secondary' size={'lg'} />
                                    </Button>
                                </th>
                                {
                                    data.days.map((day: Day) => (
                                        <th key={day.id} className={'sticky-header' + (displayColorDay === day.id ? ' selected-day' : '')}>
                                            <form onSubmit={formik.handleSubmit} autoComplete="off">
                                                <EasyEdit
                                                    type="text"
                                                    onSave={(e: any) => {
                                                        formik.setFieldValue('name', e)
                                                        formik.setFieldValue('id', day.id)
                                                        formik.setFieldValue('day', true)
                                                        formik.handleSubmit()
                                                    }}
                                                    value={day.name}
                                                    attributes={{ className: 'easy-input' }}
                                                    saveButtonLabel={<Icon icon='Check' color='dark' />}
                                                    cancelButtonLabel={<Icon icon='Close' color='dark' title='Cancelar' />}
                                                    inputStyle={{ width: '30px', minWidth: '30px', maxWidth: '40px' }}
                                                />
                                            </form>

                                            {
                                                day.value > 1 && (
                                                    <Button
                                                        className="btn p-0"
                                                        onClick={() => _handleChangeOrderItem(true, day.id, 'down')}
                                                        title='Izquierda'
                                                    >
                                                        <Icon icon="KeyboardArrowLeft" size={'2x'} style={{ color: 'grey' }} />
                                                    </Button>
                                                )
                                            }

                                            <Button
                                                className="btn btn-sm"
                                                onClick={() => {
                                                    setChangedNutValues(false);
                                                    setDisplayNutValues(false);
                                                    setNutValuesFrom({ day: day.id });
                                                    refetchIntakesValuesPerDay();
                                                    _handleDayClick(day.id);
                                                }}
                                                title='Valores nut. del día'
                                            >
                                                <Icon icon="Search" size={'2x'} style={{ color: 'lightgrey' }} />
                                            </Button>

                                            <Button
                                                className="btn btn-sm"
                                                onClick={() => {
                                                    handleConfirmationAlert({
                                                        title: "Eliminar día",
                                                        text: "¿Está seguro que desea eliminar el día completo?",
                                                        icon: "warning",
                                                        onConfirm: () => {
                                                            _deleteDay(day.id);
                                                        }
                                                    })
                                                }}
                                                title='Eliminar día'
                                            >
                                                <Icon icon="HighlightOff" size={'2x'} style={{ color: 'lightgrey' }} />
                                            </Button>

                                            {
                                                day.value < maxDayValue && (
                                                    <Button
                                                        className="btn p-0"
                                                        onClick={() => _handleChangeOrderItem(true, day.id, 'up')}
                                                        title='Derecha'
                                                    >
                                                        <Icon icon="KeyboardArrowRight" size={'2x'} style={{ color: 'grey' }} />
                                                    </Button>
                                                )
                                            }
                                        </th>
                                    ))
                                }
                            </tr>
                        </thead>

                        <tbody>
                            {
                                data.intakes.map((intake: Intake) => (
                                    <tr key={intake.id}>
                                        <td
                                            className={'sticky-column' + (displayColorDay === intake.id ? ' selected-column' : '')}
                                            style={{ minWidth: '100px', width: '110px', minHeight: '150px', padding: '5px' }}
                                        >
                                            <form onSubmit={formik.handleSubmit} autoComplete="off">
                                                <EasyEdit
                                                    type="text"
                                                    onSave={(e: any) => {
                                                        formik.setFieldValue('name', e)
                                                        formik.setFieldValue('id', intake.id)
                                                        formik.setFieldValue('day', false)
                                                        formik.handleSubmit()
                                                    }}
                                                    value={intake.name}
                                                    attributes={{ className: 'easy-input' }}
                                                    saveButtonLabel={<Icon icon='Check' color='dark' />}
                                                    cancelButtonLabel={<Icon icon='Close' color='dark' />}
                                                />
                                            </form>

                                            {
                                                intake.value > 1 && (
                                                    <Button
                                                        className="btn p-0"
                                                        onClick={() => _handleChangeOrderItem(false, intake.id, 'down')}
                                                        title='Arriba'
                                                    >
                                                        <Icon icon="KeyboardArrowUp" size={'2x'} style={{ color: 'grey' }} />
                                                    </Button>
                                                )
                                            }

                                            <Button
                                                className="btn btn-sm"
                                                onClick={() => {
                                                    setChangedNutValues(false);
                                                    setNutValuesFrom({ intake: intake.id });
                                                }}
                                                title='Valores nut. por ingesta'
                                            >
                                                <Icon icon="Search" size={'2x'} style={{ color: 'lightgrey' }} />
                                            </Button>

                                            <Button
                                                className="btn btn-sm"
                                                onClick={() => {
                                                    handleConfirmationAlert({
                                                        title: "Eliminar ingesta",
                                                        text: "¿Está seguro que desea eliminar la ingesta completa?",
                                                        icon: "warning",
                                                        onConfirm: () => {
                                                            _deleteIntake(intake.id);
                                                        }
                                                    })
                                                }}
                                                title='Eliminar ingesta'
                                            >
                                                <Icon icon="HighlightOff" size={'2x'} style={{ color: 'lightgrey' }} />
                                            </Button>

                                            {
                                                intake.value < maxIntakeValue && (
                                                    <Button
                                                        className="btn p-0"
                                                        onClick={() => _handleChangeOrderItem(false, intake.id, 'up')}
                                                        title='Abajo'
                                                    >
                                                        <Icon icon="KeyboardArrowDown" size={'2x'} style={{ color: 'grey' }} />
                                                    </Button>
                                                )
                                            }

                                            <div className='row'>
                                                <div className='col-12'>
                                                    {
                                                        filteredData && displayNutValues && (
                                                            <>
                                                                {
                                                                    filteredData?.map((intakeFiltered: any) => (
                                                                        intakeFiltered.id === intake.id && (
                                                                            <div key={intakeFiltered.id}>
                                                                                {
                                                                                    intakeFiltered.values?.map((item: any) => (
                                                                                        <p key={item.name} className='m-0 text-muted'>
                                                                                            {item.nombre + ' - ' + Math.round(item.value)}
                                                                                        </p>
                                                                                    ))
                                                                                }
                                                                            </div>
                                                                        )
                                                                    ))
                                                                }
                                                            </>
                                                        )
                                                    }
                                                </div>
                                            </div>
                                        </td>

                                        {
                                            data.days.map((day: Day) => (
                                                <td key={`${intake.id}-${day.id}`} className="menu-cell" draggable style={{ width: '50px' }}>
                                                    <DropContainer
                                                        menuId={menuId} day={day} intake={intake}
                                                        menuItems={data.menuItems} allergens={allergens}
                                                        aversions={data.client?.clientAversions}
                                                        refetch={refetchMenu} loading={loadingMenu}
                                                    />
                                                </td>
                                            ))
                                        }
                                    </tr>
                                ))
                            }
                            <tr>
                                <td className='sticky-column'>
                                    <Button
                                        className="btn btn-light sticky-column"
                                        onClick={() => _addRowCol("intake")}
                                        title='Añadir ingesta'
                                        isDisable={data.intakes.length >= 10}
                                    >
                                        <Icon icon="Add" color='secondary' size={'lg'} />
                                    </Button>
                                </td>
                            </tr>
                        </tbody>
                    </table>
                </div>
            </>
        )
    }

    return (
        <div className='nutritional-menu'>
            {displayPatientData && data.client && (
                <PatientDataModal isOpen={displayPatientData} setIsOpen={setDisplayPatientData} patientId={data.client.id} />
            )}

            {displayCreateTemplate && data && (
                <CreateTemplateModal isOpen={displayCreateTemplate} setIsOpen={setDisplayCreateTemplate} menuId={menuId} menuName={data.name} />
            )}

            <div className="row">
                <div className="col-8 p-0">
                    {getMenu()}
                </div>

                {
                    changedNutValues && data?.menuItems?.length > 0 && (
                        <>
                            <div className="col-2 p-0">
                                <NutritionalGraph nutValuesFrom={nutValuesFrom} nutrients={['proteins', 'carbohydrates', 'fat']} />
                                <NavigationLine />
                                <NutritionalGraph nutValuesFrom={nutValuesFrom} nutrients={['oligosaccharides', 'sugars']} group='Carbohidratos' className='mt-3' />
                                <NavigationLine />
                                <NutritionalGraph nutValuesFrom={nutValuesFrom} nutrients={['fatacidssaturated', 'fatacidsmonounsaturated', 'fatacidspolyunsaturated']} className='mt-3' />
                            </div>
                            <div className="col-2 p-0">
                                <NutritionalMenuValues nutValues={nutValuesFrom} />
                            </div>
                        </>
                    )
                }
            </div>
        </div>
    )
};

export default NutritionalMenu;