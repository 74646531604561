import { Fragment, useState } from "react"
import { ToastContainer, toast } from "react-toastify"
import SubHeader, { SubHeaderLeft, SubHeaderRight, SubheaderSeparator } from "../../../layout/SubHeader/SubHeader"
import Card, { CardBody, CardTitle } from "../../../components/bootstrap/Card"
import Button from "../../../components/bootstrap/Button"
import Page from "../../../layout/Page/Page"
import { useAppointmentCalendar } from "../providers/AppointmentCalendarProvider"
import { AppointmentListFilters } from "../components/AppointmentListFilters"
import { AppointmentsTable } from "../components/AppointmentsTable"
import { AppointmentCanvas } from "../components/AppointmentCanvas"
import { Appointment } from "../../../type/appointment-type"
import { AppointmentService } from "../../../services/appointments/appointmentService"
import Dropdown, { DropdownItem, DropdownMenu, DropdownToggle } from "../../../components/bootstrap/Dropdown"

export const AppointmentList: React.FC = () => {

    const appointmentService = new AppointmentService();

    const { appointments, refetchAppointments, filters, updatePage, updatePageSize, updateFilterOrder, selectAppointment, appointmentSelected, lastPage, totalResults, totalAmount } = useAppointmentCalendar();

    const _handleExportPDF = async () => {
        try {
            const response = (await appointmentService.exportPDF(filters));
            if (response) {
                const fileData = response.getResponseData();
                const blob = new Blob([fileData]);
                const url = window.URL.createObjectURL(blob);

                const link = document.createElement('a');
                link.href = url;
                link.setAttribute('download', 'citas.pdf');
                document.body.appendChild(link);
                link.click();
                document.body.removeChild(link);
                window.URL.revokeObjectURL(url);
            } else {
                toast.error('Error al exportar las citas a pdf');
            }
        } catch (error: any) {
            toast.error(error.message);
        }
    }

    const _handleExportExcel = async () => {
        try {
            const response = (await appointmentService.exportExcel(filters));
            if (response) {
                const fileData = response.getResponseData();
                const blob = new Blob([fileData]);
                const url = window.URL.createObjectURL(blob);

                const link = document.createElement('a');
                link.href = url;
                link.setAttribute('download', 'citas.xlsx');
                document.body.appendChild(link);
                link.click();
                document.body.removeChild(link);
                window.URL.revokeObjectURL(url);
            } else {
                toast.error('Error al exportar las citas a excel');
            }
        } catch (error: any) {
            toast.error(error.message);
        }

    }

    return (
        <Fragment>
            <ToastContainer />
            <SubHeader>
                <SubHeaderLeft>
                    <CardTitle>Listado de citas</CardTitle>
                    <SubheaderSeparator />
                    <Button color="storybook" icon="Add" isLight onClick={() => { selectAppointment({}) }} />

                    <Dropdown className="w-auto">
                        <DropdownToggle hasIcon >
                            <Button
                                isLink
                                isLight
                                className="text-nowrap"
                                color="secondary"
                            >
                                Exportar
                            </Button>
                        </DropdownToggle>
                        <DropdownMenu size='sm' isCloseAfterLeave>
                            <DropdownItem key='assign-template'>
                                <Button
                                    isLink
                                    className="text-nowrap"
                                    onClick={() => _handleExportPDF()}
                                >
                                    PDF
                                </Button>
                            </DropdownItem>
                            <DropdownItem key='create-menu'>
                                <Button
                                    isLink
                                    className="text-nowrap"
                                    onClick={() => _handleExportExcel()}
                                >
                                    Excel
                                </Button>
                            </DropdownItem>
                        </DropdownMenu>
                    </Dropdown>
                    <SubheaderSeparator />
                    <CardTitle>Resultados: {totalResults}</CardTitle>
                    <SubheaderSeparator />

                    <CardTitle>Facturación total: {totalAmount} €</CardTitle>
                </SubHeaderLeft>
                <SubHeaderRight>
                    <AppointmentListFilters />
                </SubHeaderRight>
            </SubHeader>

            <Page container="fluid">
                <Card stretch={true}>
                    <CardBody className="table-responsive" isScrollable={true}>
                        {
                            appointments && (
                                <AppointmentsTable
                                    filters={filters}
                                    appointments={appointments ? appointments : []}
                                    lastPage={lastPage}
                                    updatePage={updatePage}
                                    updatePageSize={updatePageSize}
                                    onEdit={(appointment: Appointment) => {
                                        const appointmentService = new AppointmentService();
                                        let data = appointmentService.transformAppointmentToAppointmentFormData(appointment);
                                        selectAppointment(data);
                                    }}
                                    onDelete={(appointment: string) => {
                                        const appointmentService = new AppointmentService();
                                        appointmentService.deleteAppointment(appointment).then(() => {
                                            toast.success('Cita eliminada correctamente');
                                            refetchAppointments();
                                        }).catch((error: any) => {
                                            toast.error(error.message);
                                        });
                                    }}
                                    updateFilterOrder={updateFilterOrder}
                                />
                            )
                        }
                    </CardBody>
                </Card>
            </Page>

            <AppointmentCanvas appointment={appointmentSelected}
                setOpen={(status: boolean) => {
                    selectAppointment(null);
                }}
            />
        </Fragment>
    )
}