import { Fragment, useCallback, useState } from "react";
import SubHeader, { SubHeaderLeft, SubHeaderRight, SubheaderSeparator } from "../../../layout/SubHeader/SubHeader";
import Card, { CardBody, CardTitle } from "../../../components/bootstrap/Card";
import Button from "../../../components/bootstrap/Button";
import Page from "../../../layout/Page/Page";
import { useNavigate } from "react-router-dom";
import useFilters from "../../../hooks/useFilters";
import { ActivitiesService } from "../../../services/activities/activitiesService";
import useFetch from "../../../hooks/useFetch";
import { toast } from "react-toastify";
import ErrorMessage from "../../../components/ErrorMessage";
import { ActivitiesApiResponse } from "../../../type/activities-type";
import { CustomTable } from "../../../components/table/CustomTable";
import StatusDropdown from "../../../components/StatusDropdown";
import ActivitiesFilters from "./activities-options/ActivitiesFilters";
import { administrationMenu } from "../../../menu";
import { handleConfirmationAlert } from "../../../components/ConfirmationAlert";

export interface IActivitiesFilters {
    status: number;
    user: string;

}

const activitiesFilters: IActivitiesFilters = {
    status: 3,
    user: '',
};

const ActivitiesList = () => {
    const navigate = useNavigate();
    const activitiesService = new ActivitiesService();

    const [changingStatus, setChangingStatus] = useState<string[]>([]);

    const { filters, updateFilters, resetFilters, updateFilterOrder, updatePage, updatePageSize } = useFilters(activitiesFilters);

    const [activitiesData, fetchingActivities, activitiesError, refetchActivities] = useFetch(useCallback(async () => {
        const response = await activitiesService.listActivities(filters);
        return response.getResponseData();
    }, [filters]));

    const togglePatientStatus = async (id: string, status: boolean, toggleStatus: Function) => {
        try {
            setChangingStatus([...changingStatus, id]);
            const response = (await activitiesService.toggleActivityStatus(id)).getResponseData();
            if (response.success) {
                setChangingStatus(changingStatus.filter((item) => item !== id));
                toggleStatus(status);
                refetchActivities();
                toast.success(status ? 'Se ha activado la actividad' : 'Se ha desactivado la actividad');
            } else {
                setChangingStatus(changingStatus.filter((item) => item !== id));
                toast.error(response.message || 'Error al cambiar el estado de la actividad');
            }
        } catch (error: any) {
            setChangingStatus(changingStatus.filter((item) => item !== id));
            toast.error(error.message || 'Error al cambiar el estado de la actividad');
        }
    };

    const _handleDelete = async (id: string) => {
        try {
            const response = (await activitiesService.deleteActivity(id)).getResponseData();
            if (response.success) {
                refetchActivities();
                setTimeout(() => {
                    toast.success('Actividad física eliminada correctamente');
                }, 100);
            } else {
                setTimeout(() => {
                    toast.error('Error al eliminar la actividad física');
                }, 100);
            }
        } catch (error: any) {
            toast.error('Error al eliminar la actividad física');
        }
    };

    const getContent = () => {
        if (activitiesError) return <ErrorMessage message={activitiesError.message} />;

        if (activitiesData && activitiesData.physical_activities.length > 0) {
            let apiData = activitiesData as ActivitiesApiResponse;

            return (
                <CustomTable
                    title="Actividades físicas"
                    loading={fetchingActivities}
                    data={apiData.physical_activities || null}
                    pagination={true}
                    paginationData={{
                        pageSize: filters.limit,
                        currentPage: filters.page,
                        pageCount: apiData as ActivitiesApiResponse ? apiData.lastPage : 1,
                        handlePagination: (page: any) => {
                            updatePage({ selected: page.selected + 1 });
                        },
                        handlePerPage: updatePageSize,
                    }}
                    className={"table-striped table-hover"}

                    columns={[
                        {
                            name: "Nombre",
                            keyValue: "name",
                            sortable: true,
                            sortColumn: updateFilterOrder,
                            render: (element: any) => {
                                return (
                                    <div className="cursor-pointer text-secondary" onClick={() => { navigate(`${element.id}/edit`) }}>
                                        {element.name}
                                    </div>
                                )
                            },
                        },
                        {
                            name: "Calorías",
                            keyValue: "calories",
                            sortable: true,
                            sortColumn: updateFilterOrder,
                            render: (element: any) => {
                                return (
                                    <>
                                        {element.calories}
                                    </>
                                )
                            }
                        },
                        {
                            name: "Duración (min)",
                            keyValue: "time",
                            sortable: true,
                            sortColumn: updateFilterOrder,
                            render: (element: any) => {
                                return (
                                    <>
                                        {element.time}
                                    </>
                                )
                            }
                        },
                        {
                            name: "Descripción",
                            keyValue: "description",
                            sortable: true,
                            sortColumn: updateFilterOrder,
                            render: (element: any) => {
                                return (
                                    <>
                                        {element.description || "-"}
                                    </>
                                )
                            }
                        },
                        {
                            name: "Usuario",
                            keyValue: "user",
                            sortable: true,
                            sortColumn: updateFilterOrder,
                            render: (element: any) => {
                                return (
                                    <>
                                        <div className="cursor-pointer text-secondary" onClick={() => { navigate(`${administrationMenu.users.path}/${element.user.id}/profile`) }}>
                                            {element.user.name + (element.user.lastName ? " " + element.user.lastName : "")}
                                        </div>
                                    </>
                                )
                            }
                        },
                        {
                            name: "Estado",
                            keyValue: "active",
                            className: "text-center",
                            render: (element: any) => {
                                return (
                                    <div className={'text-center'}>
                                        <StatusDropdown
                                            disabled={changingStatus.includes(element.id)}
                                            itemId={element.id}
                                            status={element.status}
                                            change={togglePatientStatus}
                                        />
                                    </div>
                                );
                            },
                        },
                        { name: "Acciones", className: "min-w-100px text-end", isActionCell: true }
                    ]}
                    actions={[
                        {
                            title: "Editar",
                            icon: "Edit",
                            buttonType: 'icon',
                            additionalClasses: 'text-primary',
                            description: "Editar actividad física",
                            click: (item: any) => {
                                navigate(`${item.id}/edit`);
                            },
                        },
                        {
                            title: "Eliminar",
                            icon: "Delete",
                            buttonType: 'icon',
                            additionalClasses: 'text-danger',
                            description: "Eliminar actividad física",
                            click: (item: any) => {
                                handleConfirmationAlert({
                                    title: "Eliminar actividad física",
                                    text: "¿Está seguro que desea eliminar la actividad física?",
                                    icon: "warning",
                                    onConfirm: () => _handleDelete(item.id)
                                })
                            },
                        },
                    ]}
                />
            );
        }
    }

    return (
        <Fragment>
            <SubHeader>
                <SubHeaderLeft>
                    <CardTitle>Listado de Actividades Físicas</CardTitle>
                    <SubheaderSeparator />
                    <Button
                        title="Crear Actividad Física"
                        color="storybook"
                        icon="Add"
                        isLight
                        onClick={() => { navigate("create") }}
                    />
                </SubHeaderLeft>
                <SubHeaderRight>
                    <ActivitiesFilters updateFilters={updateFilters} filters={filters} resetFilters={resetFilters} />
                </SubHeaderRight>
            </SubHeader>
            <Page container="fluid">
                <Card stretch={true}>
                    <CardBody className="table-responsive" isScrollable={true}>
                        <>{getContent()}</>
                    </CardBody>
                </Card>
            </Page>
        </Fragment>
    )
}

export default ActivitiesList;