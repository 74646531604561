export const NUTRIENTS = [
    { value: 'proteins', label: 'Proteinas (g)' },
    { value: 'carbohydrates', label: 'Carbohidratos (g)' },
    { value: 'fat', label: 'Grasas (g)' },
    { value: 'alcohol', label: 'Alcohol (g)' },
    { value: 'water', label: 'Agua (g)' },
    { value: 'totalfiber', label: 'Fibra total (g)' },
    { value: 'starch', label: 'Almidón (g)' },
    { value: 'sugars', label: 'Azucares (g)' },
    { value: 'fatacidspolyunsaturated', label: 'Acidos grasos poliinsaturados (g)' },
    { value: 'fatacidssaturated', label: 'Acidos grasos saturados (g)' },
    { value: 'colesterol', label: 'Colesterol (g)' },
    { value: 'fatacidlauric12', label: 'Acido graso laurico 12:0 (g)' },
    { value: 'fatacidmyristic14', label: 'Acido graso miristico 14:0 (g)' },
    { value: 'fatacidpalmitic16', label: 'Acido graso palmítico 16:0 (g)' },
    { value: 'fatacidstearic18', label: 'Acido graso esteárico 18:0 (g)' },
    { value: 'fatacidoleic181', label: 'Acido graso oleico 18:1 n-9 cis (g)' },
    { value: 'fatacid182', label: 'Acido graso 18:2 (g)' },
    { value: 'fatacid183', label: 'Acido graso 18:3 (g)' },
    { value: 'fatacidarachidonic204', label: 'Acido graso araquidonico 20:4 n-6 (g)' },
    { value: 'fatacideicosapentaenoic205', label: 'Acido graso eicosapentaenoico 20:5 n-3 (g)' },
    { value: 'fataciddocosahexaenoic226', label: 'Acido graso docosahexaenoico 22:6 n-3 (g)' },
    { value: 'vitamind', label: 'Vitamina D / calciferol (g)' },
    { value: 'vitamine', label: 'Vitamina E / tocoferol (g)' },
    { value: 'vitaminb7', label: 'Vitamina B7 / biotina (g)' },
    { value: 'vitaminb9', label: 'Vitamina B9 / folato (g)' },
    { value: 'vitaminb3', label: 'Vitamina B3 / niacina (g)' },
    { value: 'vitaminb5', label: 'Vitamina B5 / ácido pantoténico (g)' },
    { value: 'vitaminb2', label: 'Vitamina B2 / riboflavina (g)' },
    { value: 'vitaminb1', label: 'Vitamina B1 / tiamina (g)' },
    { value: 'vitaminb12', label: 'Vitamina B12 / cobalamina (g)' },
    { value: 'vitaminb6', label: 'Vitamina B6 / piridoxina (g)' },
    { value: 'vitaminc', label: 'Vitamina C / ácido ascórbico (g)' },
    { value: 'vitamina', label: 'Vitamina A / retinol (g)' },
    { value: 'calcium', label: 'Calcio (g)' },
    { value: 'iron', label: 'Hierro (g)' },
    { value: 'potassium', label: 'Potasio (g)' },
    { value: 'magnesium', label: 'Magnesio (g)' },
    { value: 'sodium', label: 'Sodio (g)' },
    { value: 'phosphorus', label: 'Fosforo (g)' },
    { value: 'copper', label: 'Cobre (g)' },
    { value: 'iodine', label: 'Iodo (g)' },
    { value: 'selenium', label: 'Selenio (g)' },
    { value: 'zinc', label: 'Zinc (g)' },
    { value: 'energy', label: 'Energía (kcal)' },
    { value: 'energykj', label: 'Energía (kJ)' },
    { value: 'cisteine', label: 'Cisteína (g)' },
    { value: 'histidine', label: 'Histidina (g)' },
    { value: 'isoleucine', label: 'Isoleucina (g)' },
    { value: 'leucine', label: 'Leucina (g)' },
    { value: 'lysine', label: 'Lisina (g)' },
    { value: 'methionine', label: 'Metionina (g)' },
    { value: 'phenylalanine', label: 'Fenilalanina (g)' },
    { value: 'serine', label: 'Serina (g)' },
    { value: 'threonine', label: 'Treonina (g)' },
    { value: 'tryptophan', label: 'Triptófano (g)' },
    { value: 'tyrosine', label: 'Tirosina (g)' },
    { value: 'valine', label: 'Valina (g)' },
    { value: 'arginine', label: 'Arginina (g)' },
    { value: 'glutamine', label: 'Glutamina (g)' },
    { value: 'alanine', label: 'Alanina (g)' },
    { value: 'asparagine', label: 'Asparagina (g)' },
    { value: 'glycine', label: 'Glicina (g)' },
    { value: 'proline', label: 'Prolina (g)' },
    { value: 'glucose', label: 'Glucosa (g)' },
    { value: 'fructose', label: 'Fructosa (g)' },
    { value: 'galactose', label: 'Galactosa (g)' },
    { value: 'sucrose', label: 'Sacarosa (g)' },
    { value: 'maltose', label: 'Maltosa (g)' },
    { value: 'lactose', label: 'Lactosa (g)' },
    { value: 'oligosaccharides', label: 'Oligosacaridos (g)' },
    { value: 'solublefiber', label: 'Fibra soluble (g)' },
    { value: 'insolublefiber', label: 'Fibra insoluble (g)' },
    { value: 'vitamink', label: 'Vitamina K / naftoquinona (g)' },
    { value: 'chlorine', label: 'Cloro (g)' },
    { value: 'fluoride', label: 'Fluor (g)' },
    { value: 'cobalt', label: 'Cobalto (g)' },
    { value: 'nickel', label: 'Niquel (g)' },
    { value: 'manganese', label: 'Manganeso (g)' },
    { value: 'oxalate', label: 'Oxalato (g)' },
    { value: 'purines', label: 'Purinas (g)' },
    { value: 'caffeine', label: 'Cafeína (g)' },
];

export const allergenList = [
    { nombre: 'Gluten', name: 'gluten' },
    { nombre: 'Crustáceos', name: 'crustaceans' },
    { nombre: 'Huevos', name: 'eggs' },
    { nombre: 'Pescado', name: 'fish' },
    { nombre: 'Cacahuetes', name: 'peanuts' },
    { nombre: 'Soja', name: 'soy' },
    { nombre: 'Leche', name: 'milk' },
    { nombre: 'Frutos de cascara', name: 'nuts' },
    { nombre: 'Apio', name: 'celery' },
    { nombre: 'Mostaza', name: 'mustard' },
    { nombre: 'Granos de  sésamo', name: 'sesameseeds' },
    { nombre: 'Dióxido de azufre', name: 'sulfurdioxide' },
    { nombre: 'Altramuces', name: 'lupin' },
    { nombre: 'Moluscos', name: 'molluscs' },
];

export const monthsList = [
    { label: 'Enero', value: 'enero' },
    { label: 'Febrero', value: 'febrero' },
    { label: 'Marzo', value: 'marzo' },
    { label: 'Abril', value: 'abril' },
    { label: 'Mayo', value: 'mayo' },
    { label: 'Junio', value: 'junio' },
    { label: 'Julio', value: 'julio' },
    { label: 'Agosto', value: 'agosto' },
    { label: 'Septiembre', value: 'septiembre' },
    { label: 'Octubre', value: 'octubre' },
    { label: 'Noviembre', value: 'noviembre' },
    { label: 'Diciembre', value: 'diciembre' },
];

export const DIFFICULTY = [
    { value: 'Fácil', label: 'Fácil' },
    { value: 'Media', label: 'Media' },
    { value: 'Difícil', label: 'Difícil' }
];

export const TECHNIQUES = [
    { label: 'Horno', value: 'oven' },
    { label: 'Guisado', value: 'stew' },
    { label: 'Salteado', value: 'sauteed' },
    { label: 'Frito', value: 'fry' },
    { label: 'Cocción', value: 'cooking' },
    { label: 'Crudo', value: 'crude' },
    { label: 'Hervido', value: 'boil' },
    { label: 'Freidora de aire', value: 'airFryer' },
    { label: 'Plancha', value: 'griddle' },
    { label: 'Vapor', value: 'steam' },
    { label: 'Microondas', value: 'microwave' },
];

export const STORAGE_CONDITIONS = [
    { label: 'Se conservará caliente hasta su consumo. Temperatura superior a 65ºC', value: 'hot' },
    { label: 'Se conservará en frío hasta su consumo. Temperatura aproximada 4ºC', value: 'cold' },
];

export const RATION_PRESENTATION = [
    { label: 'Entero/plato completo', value: 'whole' },
    { label: 'Troceado/porciones/unidades', value: 'slicing' },
    { label: 'Crema/puré', value: 'cream' },
];