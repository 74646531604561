import React, { ChangeEvent, FC, Fragment, useState } from 'react'
import Button from '../../../../components/bootstrap/Button'
import FormGroup from '../../../../components/bootstrap/forms/FormGroup'
import Input from '../../../../components/bootstrap/forms/Input'
import OffCanvas, { OffCanvasBody, OffCanvasHeader, OffCanvasTitle } from '../../../../components/bootstrap/OffCanvas'
import Icon from '../../../../components/icon/Icon'
import Select from '../../../../components/bootstrap/forms/Select'
import CustomSearchInput from '../../../../components/bootstrap/forms/CustomSearchInput'
import { UserSelector } from '../../../../components/select/UserSelector'
import { useFormik } from 'formik'

interface IActivitiesFiltersProps {
    updateFilters: (filters: any) => void
    resetFilters: () => void
    filters: any
}

const ActivitiesFilters: FC<IActivitiesFiltersProps> = ({ updateFilters, filters, resetFilters }) => {
    const [filterMenu, setFilterMenu] = useState(false);

    const _handleTextChange = (search: string) => {
        updateFilters({ search_array: search });
    };

    const formik = useFormik({
        initialValues: {
            user: undefined,
            minCal: undefined,
            maxCal: undefined,
        },
        onSubmit: (values) => { updateFilters(values) }
    })

    return (
        <Fragment>
            <label className='border-0 bg-transparent cursor-pointer' htmlFor='searchInput'>
                <Icon icon='Search' size='2x' color='secondary' />
            </label>

            <CustomSearchInput onSearch={_handleTextChange} placeholder='Buscar...' />

            <Button color='secondary' isLight icon='Filter' onClick={() => {
                setFilterMenu(true)
            }}>
                Filtros
            </Button>

            <OffCanvas setOpen={setFilterMenu} isOpen={filterMenu} titleId='activitiesFilters' isBodyScroll placement='end'>
                <OffCanvasHeader setOpen={setFilterMenu}>
                    <OffCanvasTitle id='activitiesFilters'> Filtros de Actividades Físicas </OffCanvasTitle>
                </OffCanvasHeader>
                <OffCanvasBody>
                    <form onSubmit={formik.handleSubmit} className="d-flex flex-column">
                        <div className='flex-grow'>
                            <FormGroup id='filter1' label='Estado'>
                                <Select
                                    id='active-select'
                                    list={[
                                        { value: 3, label: 'Todos', text: 'Todos' },
                                        { value: 1, label: 'Activo', text: 'Activo' },
                                        { value: 0, label: 'Desactivado', text: 'Desactivado' }
                                    ]}
                                    onChange={(e: React.ChangeEvent<HTMLInputElement>) => updateFilters({ status: parseInt(e.target.value) })}
                                    value={filters.filter_filters.status.toString()}
                                    placeholder='Estado...'
                                    ariaLabel='Estado'
                                />
                            </FormGroup>

                            <FormGroup label="Usuario" className="mt-2">
                                <UserSelector name="user" formik={formik} selectedId={formik.values.user} onChange={formik.handleChange} />
                            </FormGroup>

                            <FormGroup label='Calorías (kcal)' className='mt-3 row'>
                                <div className='col-6'>
                                    <div className='row'>
                                        <div className='col-md-4 d-flex flex-column'>
                                            <p className='m-0 d-flex align-items-center' style={{ height: '100%' }}>Mínimo: </p>
                                        </div>
                                        <div className='col-md-8'>
                                            <Input
                                                name='minCal'
                                                type='number'
                                                value={formik.values.minCal}
                                                onChange={formik.handleChange}
                                            />
                                        </div>
                                    </div>
                                </div>
                                <div className='col-6'>
                                    <div className='row d-flex justify-content-end'>
                                        <div className='col-md-4 d-flex flex-column'>
                                            <p className='m-0 d-flex align-items-center' style={{ height: '100%' }}>Máximo: </p>
                                        </div>
                                        <div className='col-md-8'>
                                            <Input
                                                name='maxCal'
                                                type='number'
                                                value={formik.values.maxCal}
                                                onChange={formik.handleChange}
                                            />
                                        </div>
                                    </div>
                                </div>
                            </FormGroup>
                        </div>

                        <div className="row mt-4">
                            <div className="d-flex justify-content-start col">
                                <Button type="button" color="secondary" className="me-4" isLight onClick={() => { formik.resetForm() }}>Limpiar</Button>
                            </div>
                            <div className="d-flex justify-content-end col">
                                <Button type="submit" color="primary">Filtrar</Button>
                            </div>
                        </div>
                    </form>
                </OffCanvasBody>
            </OffCanvas>
        </Fragment>
    )
}

export default ActivitiesFilters;