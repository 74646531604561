// Priviledge provider to handle priviledge checking and state management to view and hide components based on user priviledges

import React, { useContext, useEffect, useState } from "react";
import { useSelector } from "react-redux";
import { RootState } from "../../redux/store";
import { AuthState } from "../../redux/authSlice";
import { removeUserFromLocalStorage } from "../../utils/jwt";
import { useNavigate } from "react-router-dom";
import { JWTDecoded, Permissions } from "../../type/jwt-type";
import { toast } from "react-toastify";
import isActive = toast.isActive;

type PrivilegeContextType = {
  permissions?: Permissions,
  roles?: string[],
  userCan: (action: string, group: string) => boolean,
  userHasRole: (role: string) => boolean,
  decode: (token: string) => any,
}

// Create default context
const PrivilegeContext: React.Context<PrivilegeContextType> = React.createContext<PrivilegeContextType>({
  permissions: {},
  roles: [],
  userCan: (action: string, group: string): boolean => false,
  userHasRole: (role: string): boolean => false,
  decode: (token: string): any => {}
});

type PrivilegeProviderProps = {
  children: React.ReactNode
}

const decode = (token: string) => {
  const base64Url = token.split('.')[1];
  const base64 = base64Url.replace('-', '+').replace('_', '/');
  return JSON.parse(window.atob(base64));
}

// Create provider component
const PrivilegeProvider: React.FC<PrivilegeProviderProps> = ({ children }) => {

  const { isAuthenticated, user }: AuthState = useSelector((state: RootState) => state.auth);
  const navigate = useNavigate();
  const [permissions, setPermissions] = useState<Permissions | undefined>(undefined);
  const [roles, setRoles] = useState<string[] | undefined>([]);

  // JWT token decode and configure state
  useEffect(() => {
    if (!isAuthenticated) {
      setPermissions(undefined);
      setRoles(undefined);
    }
    if (permissions === undefined && user !== null) {
      const decoded: JWTDecoded = decode(user?.token as string);

      setPermissions(decoded.permissions);
      setRoles(decoded.roles)
    }

  }, [isAuthenticated]);


  const userCan = (action: string, group: string) => {
    //console.log(permissions);
    //console.log(action, group)
    if (permissions === undefined) return false;
    return permissions[group] && permissions[group].includes(action);
  }

  const userHasRole = (role: string) => {
    if (!roles) return false;
    return roles.includes(role);
  }

  // Define context and mount
  const value: PrivilegeContextType = {
    permissions: permissions,
    roles: roles,
    userCan,
    userHasRole,
    decode
  }


  return <PrivilegeContext.Provider value={value}>{children}</PrivilegeContext.Provider>
}

export { PrivilegeProvider, PrivilegeContext }

export function usePrivilege() {
  return useContext(PrivilegeContext);
}