import { FC, useState } from "react";
import { useNavigate } from "react-router-dom";
import { toast } from 'react-toastify';
import PatientForm from "../PatientForm";
import { PatientService } from "../../../services/patients/patientService";
import { authMenu } from "../../../menu";

const CreatePatient: FC = () => {
	const navigate = useNavigate();

	const [loading, setLoading] = useState<boolean>(false);

	const handleCreatePatient = async (values: any) => {
		setLoading(true);

		try {
			const response = await (await (new PatientService()).createPatient(values)).getResponseData();

			if (response.success) {
				navigate(authMenu.patients.path, { replace: true });
				setTimeout(() => {
					toast.success('Paciente creado correctamente');
				}, 100);
			} else {
				toast.error(response.message || 'Error al crear el paciente');
			}
		} catch (error: any) {
			toast.error(error.message || 'Error al crear el paciente');
		} finally {
			setLoading(false);
		}
	}

	return (
		<PatientForm submit={handleCreatePatient} isLoading={loading} />
	)
}

export default CreatePatient;