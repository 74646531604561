import { createSlice, createAsyncThunk } from '@reduxjs/toolkit';
import { LoginService } from '../services/auth/loginService';
import { toast } from 'react-toastify';

export type Device = {
  id: string;
  token: string;
  device_type: string;
}

export type Ration = {
  id: string;
  name: string;
  description: string;
  value: number;
}

export interface User {
  token: string;
  id: string;
  name: string;
  lastName?: string | null;
  companyName: string;
  company: string;
  calendarTimeSlot: number;
  email: string;
  roles: string[];
  profilePictureId: string;
  devices: Device[];
  foodRationConfiguration: Ration[];
}

export interface AuthState {
  isAuthenticated: boolean;
  user: User | null;
  loading: boolean;
  error: string | null;
}

const initialState: AuthState = {
  isAuthenticated: false,
  user: null,
  loading: false,
  error: null,
};


const authSlice = createSlice({
  name: 'auth',
  initialState,
  reducers: {
    logout: (state) => {
      state.isAuthenticated = false;
      state.loading = false;
      state.error = null;
      state.user = null;
    },
    login: (state, action) => {
      const { token, user } = action.payload;
      state.isAuthenticated = true;
      state.user = {
        token: token,
        id: user.id,
        name: user.name,
        lastName: user.lastName,
        companyName: user.companyName,
        calendarTimeSlot: user.companyTimeSlot,
        company: user.company,
        email: user.email,
        roles: user.roles,
        profilePictureId: user.imgProfile,
        devices: user.devices,
        foodRationConfiguration: user.foodRationConfiguration,
      };
      state.loading = false;
      state.error = null;
    },
    addDevice: (state, action) => {
      state.user?.devices.push(action.payload);
    }
  },
});

export const { logout, login, addDevice } = authSlice.actions;

export default authSlice.reducer;
