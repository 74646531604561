import { FC, useState } from "react";
import { useNavigate } from "react-router-dom";
import { UserService } from "../../../services/users/userService";
import UserForm from "../UserForm";
import { toast } from 'react-toastify';
import { administrationMenu } from "../../../menu";

const CreateUser: FC = () => {

	const navigate = useNavigate();

	const [loading, setLoading] = useState<boolean>(false);

	const _handleCreate = async (values: any) => {
		setLoading(true);

		try {
			const response = await (await (new UserService()).createUser(values)).getResponseData();

			if (response.success) {
				navigate(administrationMenu.users.path, { replace: true });
				setTimeout(() => {
					toast.success('Usuario creado correctamente');
				}, 100);
			} else {
				toast.error(response.message || 'Error al crear el usuario');
			}
		} catch (error: any) {
			toast.error(error.message || 'Error al crear el usuario');
		} finally {
			setLoading(false);
		}
	}

	return (
		<UserForm submit={_handleCreate} isLoading={loading} />
	)
}

export default CreateUser;