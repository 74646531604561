import { useCallback } from "react";
import { useNavigate, useParams } from "react-router-dom";
import { Activity } from "../../../type/activities-type";
import useFetch from "../../../hooks/useFetch";
import { tipsMenu } from "../../../menu";
import { toast } from "react-toastify";
import Spinner from "../../../components/bootstrap/Spinner";
import ErrorMessage from "../../../components/ErrorMessage";
import { TipsService } from "../../../services/tips/tipsService";
import TipForm from "../TipForm";

const EditTip: React.FC = () => {

    const navigate = useNavigate();
    const { id = '' } = useParams<{ id: string }>();
    const tipsService = new TipsService();

    const fetchTip = useCallback(async () => {
        const response = (await tipsService.getTip(id as string));
        return response.getResponseData() as Activity;
    }, [id]);

    const [data, loading, error] = useFetch(fetchTip);

    const _handleUpdate = async (values: any) => {
        try {
            const response = await tipsService.editTip(values);
            const responseData = response.getResponseData();

            if (responseData.success) {
                navigate(tipsMenu.tips.subMenu.tipsGroups.path, { replace: true });
                setTimeout(() => {
                    toast.success('Consejo editado correctamente');
                }, 100);
            } else {
                toast.error(responseData.message || "Error al editar el consejo");
            }
        } catch (error: any) {
            toast.error(error.message);
        }
    };

    const getContent = () => {
        if (loading) return <div className="text-center"><Spinner /></div>;

        if (error) return <ErrorMessage message={error.message} />;

        if (data !== null) {
            const tipData = {
                tipGroup: data.tipGroup !== null ? data.tipGroup?.id : "",
                name: data.name !== null ? data.name : "",
                description: data.description !== null ? data.description : "",
            };

            return <TipForm isLoading={false} submit={_handleUpdate} tipData={tipData} />;
        }
    };

    return (
        <>{getContent()}</>
    )
}

export default EditTip;