import { AxiosResponse } from 'axios';
import { RestServiceConnection } from '../restServiceConnection';

const ACTIVITIES_ENDPOINT = '/physical_activity';

export class ActivitiesService extends RestServiceConnection {

    listActivities = async (filters?: any) => {
        this.response = await this.makeRequest({
            method: 'POST',
            url: ACTIVITIES_ENDPOINT + '/list',
            data: { ...filters }
        }, true) as AxiosResponse;
        return this;
    }

    getActivity = async (id: string) => {
        this.response = await this.makeRequest({
            method: 'POST',
            url: ACTIVITIES_ENDPOINT + '/get',
            data: {
                physicalActivity: id
            }
        }, true) as AxiosResponse;
        return this;
    }

    createActivity = async (activity: any) => {
        this.response = await this.makeRequest({
            method: 'POST',
            url: ACTIVITIES_ENDPOINT + '/create',
            data: activity
        }, true) as AxiosResponse;
        return this;
    }

    editActivity = async (activity: any) => {
        this.response = await this.makeRequest({
            method: 'POST',
            url: ACTIVITIES_ENDPOINT + '/edit',
            data: activity
        }, true) as AxiosResponse;
        return this;
    }

    deleteActivity = async (id: string) => {
        this.response = await this.makeRequest({
            method: 'POST',
            url: ACTIVITIES_ENDPOINT + '/delete',
            data: {
                physicalActivity: id
            }
        }, true) as AxiosResponse;
        return this;
    }

    toggleActivityStatus = async (id: string) => {
        this.response = await this.makeRequest({
            method: 'POST',
            url: ACTIVITIES_ENDPOINT + '/toggle',
            data: {
                physicalActivity: id
            }
        }, true) as AxiosResponse;
        return this;
    }

}