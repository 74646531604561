import sha256 from 'crypto-js/sha256';

const SW_VERSION = "v3";
const SW_VERSION_KEY = "sw_version";

/**
 * Script for register service worker
 */
export function registerServiceWorker() {
  if ("serviceWorker" in navigator) {
    window.addEventListener("load", async () => {

      navigator.serviceWorker
        .register(`/service-worker.js`, {updateViaCache: 'none'})
        .then((registration) => {
          if (registration.active?.state !== "activated") {
            console.log("Service Worker registrado con éxito:", registration);
            window.location.reload();
          } else {
            // check the hash of the service-worker.js file and if it is different then update service worker and reload the page
            fetch(`/service-worker.js`)
              .then((response) => response.text())
              .then((text) => {
                
                const savedHash  = localStorage.getItem(SW_VERSION_KEY);
                const hash = sha256(text);
                
                // Delete service worker when is different to cached.
                if (hash.toString() !== savedHash) {
                  registration.unregister().then(() => {
                    console.log("Service Worker actualizado con éxito:")
                    localStorage.setItem(SW_VERSION_KEY, hash.toString());
                    window.location.reload();
                  });
                }
              });
          }

        })
        .catch((error) => {
          console.log("Fallo al registrar el Service Worker:", error);
        });
    });
  }
}