import { FC, useState, useRef, useEffect } from 'react';
import { DocumentService } from '../services/documents/documentService';
import Spinner from './bootstrap/Spinner';
import Avatar from './Avatar';
import DefaultUserImg from "..//assets/img/defaults/default-user-image.png";

interface IAsyncImg {
    id: string | null,
    isBackground?: boolean,
    height?: string,
    width?: string,
    styles?: string,
    food?: boolean,
}

const AsyncImg: FC<IAsyncImg> = ({ id, food, styles = '', isBackground = false, ...props }) => {

    const [imgSrc, setImgSrc] = useState<string>('');

    const divRef = useRef<HTMLDivElement | null>(null);

    const [loading, setLoading] = useState<boolean>(false);
    const [error, setError] = useState<boolean>(false);

    useEffect(() => {
        const fetchData = async (docId: string) => {
            try {
                setLoading(true);
                setImgSrc(await (new DocumentService()).renderDocumentURL(docId));
                /*
                //const response = (await (new DocumentService()).renderDocument(docId)).getResponse() as AxiosResponse;
                const response = (await (new DocumentService()).renderDocumentURL(docId));

                //if (response.status === 200 && response.data) {
                if (response) {
                    //let file = new Blob([response.data], { type: 'image/jpeg' });
                    let file = new Blob([response], { type: 'image/jpeg' });
                    let stream = URL.createObjectURL(file);
                    setImgSrc(stream);
                }
                */
            } catch (error) {
                setError(true);
            } finally {
                setLoading(false);
            }
        };
        id ? fetchData(id) : setError(true);
    }, [id]);

    if (loading) return <div className='d-flex justify-content-center'><Spinner isSmall /></div>

    if (error && !food) return <Avatar src={DefaultUserImg} />
    if (error && food) return <Avatar src='/food_placeholder.png' />

    if (isBackground && divRef.current) {
        divRef.current.style.backgroundImage = `url(${imgSrc})`;
        divRef.current.style.backgroundPosition = 'center';
        divRef.current.style.backgroundSize = 'cover';
        return (
            <div ref={divRef} className={styles} style={{ ...props }}></div>
        )
    }

    return <img {...props} className={styles} src={imgSrc} alt='img' />
}

export default AsyncImg;