import { FC, useCallback } from "react";
import { useNavigate, useParams } from "react-router-dom";
import Spinner from "../../../components/bootstrap/Spinner";
import useFetch from "../../../hooks/useFetch";
import { RoleService } from "../../../services/auth/roleService";
import { Role } from "../../../type/role-type";
import ErrorMessage from "../../../components/ErrorMessage";
import { toast } from "react-toastify";
import RoleForm from "./RoleForm";
import { administrationMenu } from "../../../menu";

const RoleEdit: FC = () => {
  const navigate = useNavigate();
  const { id } = useParams<{ id: string }>();
  const roleService = new RoleService();

  const [dataRole, loadingRole, errorRole] = useFetch(useCallback(async () => {
    const response = await roleService.getRoleById(id as string);
    return response.getResponseData() as Role;
  }, [id]));

  const handleUpdate = async (values: any) => {
    try {
      const response = (await roleService.editRole(values)).getResponseData();
      if (response.success) {
        navigate(administrationMenu.roles.path, { replace: true });
        setTimeout(() => {
          toast.success('Rol editado correctamente');
        }, 100);
      } else {
        toast.error(response.message || "Error al editar el rol");
      }
    } catch (error: any) {
      toast.error(error.message || "Error al editar el rol");
    }
  };

  const getContent = () => {
    if (loadingRole) return <Spinner />;

    if (errorRole) return <ErrorMessage error={errorRole} />;

    if (dataRole !== null) {
      const roleData = {
        name: dataRole.name !== null ? dataRole.name : "",
        description: dataRole.description !== null ? dataRole.description : "",
        company: dataRole.company !== null ? dataRole.company.id : "",
        permissions: dataRole.permissions !== null ? dataRole.permissions : [],
      };

      return (
        <RoleForm isLoading={false} submit={handleUpdate} roleData={roleData} />
      );
    }
  };

  return (
    <>{getContent()}</>
  );
};

export default RoleEdit;