import { FC, useCallback } from 'react';
import { VictoryBar, VictoryChart, VictoryAxis, VictoryTheme } from 'victory';
import { MenuService } from '../../services/menus/menuService';
import { Menu } from '../../type/clientMenus-type';
import useFetch from '../../hooks/useFetch';
import Spinner from '../bootstrap/Spinner';

interface GraphProps {
    nutValuesFrom: any;
    nutrients: any;
    group?: string;
    className?: string;
}

const NutritionalGraph: FC<GraphProps> = ({ nutValuesFrom, nutrients, group, className }) => {
    const menuService = new MenuService();

    // Obtiene los valores nutricionales del menú (completo o por dia/ingesta)
    const fetchValues = useCallback(async () => {
        const response = (await menuService.getNutritionalValues(nutValuesFrom));
        return response.getResponseData() as Menu;
    }, []);

    const [NutritionalValues, loadingValues, valuesError] = useFetch(fetchValues);

    if (loadingValues) return (
        <div className="row d-flex justify-content-center align-content-center" style={{ height: '250px' }}>
            <Spinner className='h-max' />
        </div>
    );

    if (valuesError) return (
        <div className="row d-flex justify-content-center align-content-center" style={{ height: '250px' }}>
            <h6 className='text-center mt-5'>No se ha podido cargar la gráfica</h6>
        </div>
    );

    // Filtra los nutrientes que se van a mostrar en la gráfica
    const nutrientList = NutritionalValues.map((nutrient: any) => ({ name: nutrient.name, nombre: nutrient.nombre, grupo: nutrient.grupo, value: Math.round(nutrient.value) })).filter((nutrient: any) => nutrients.includes(nutrient.name));

    // Obtiene todos los valores de los nutrientes
    const allValues = nutrientList.map((nutrient: any) => nutrient.value);

    return (
        <div className={className}>
            <h5 className='text-center'>{group || nutrientList[0].grupo} (%)</h5>

            <VictoryChart
                domainPadding={20}
                theme={VictoryTheme.material}
                height={250}
                title={group || nutrientList[0].grupo}
            >
                <VictoryAxis
                    tickValues={nutrientList.map((x: any, index: any) => index)}
                    tickFormat={nutrientList.map((x: any) => x.nombre)}
                />
                <VictoryAxis
                    dependentAxis
                    tickFormat={allValues}
                />
                <VictoryBar
                    data={nutrientList}
                    x="name"
                    y="value"
                    barWidth={30}
                />
            </VictoryChart>
        </div>
    )
}

export default NutritionalGraph;