import { useCallback, useState, FC, useEffect, Fragment } from "react";
import Accordion, { AccordionItem } from "../../../components/bootstrap/Accordion";
import Button from "../../../components/bootstrap/Button";
import Modal, { ModalBody, ModalFooter, ModalHeader, ModalTitle } from "../../../components/bootstrap/Modal";
import Spinner from "../../../components/bootstrap/Spinner";
import Checks from "../../../components/bootstrap/forms/Checks";
import { Permission, PermissionGroup, PermissionsApiResponse, RolePermission } from "../../../type/role-type";
import useFetch from "../../../hooks/useFetch";
import { PermissionService } from "../../../services/auth/permissionService";
import { UserService } from "../../../services/users/userService";
import { UserApiResponse } from "../../../type/user-type";
import { toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import ErrorMessage from "../../../components/ErrorMessage";
import IconWithTooltip from "../../../components/bootstrap/IconWithTooltip";

interface PermissionsModalProps {
	isOpen: boolean;
	setIsOpen(...args: unknown[]): unknown;
	userPermissions: RolePermission[];
	newPermissions: RolePermission[] | null;
	setNewPermissions: (permissions: RolePermission[]) => void;
	userId: string;
	companyId: string;
}

const PermissionsModal: FC<PermissionsModalProps> = ({ isOpen, setIsOpen, userPermissions, userId, companyId, setNewPermissions, newPermissions }) => {

	const [selectedPermissions, setSelectedPermissions] = useState<number[]>([]);
	const [selectAll, setSelectAll] = useState<number[]>([]);
	const [updating, setUpdating] = useState<boolean>(false);

	const [permissionsData, permissionsLoading, permissionError] = useFetch(useCallback(async () => {
		const permissionService = new PermissionService();
		const response = await permissionService.getPermissions();
		return response.getResponseData() as PermissionsApiResponse;
	}, []));

	const updatePermissions = async () => {
		try {
			setUpdating(true);
			let response = await (await (new UserService()).editUserPermissions(userId, companyId, selectedPermissions)).getResponseData() as UserApiResponse;
			if (response.success && response.data) {
				setNewPermissions(response.data.userPermissions);
				toast.success(response.message);
				setTimeout(() => {
					toast.info('Recuerde desactivar y activar el usuario para aplicar los cambios');
				}, 3000);
				setSelectedPermissions([]);
			} else {
				toast.error(response.message);
			}
		} catch (error: any) {
			setIsOpen(false);
			toast.error('Error al Actualizar Permisos');
		} finally {
			setUpdating(false);
			setIsOpen(false);
		}
	};

	const getPermissionsLabel = (id: string, label: string, description: string) => {
		return (
			<>
				<label htmlFor={id}>{label}</label>
				<IconWithTooltip icon="Info" label={description} />
			</>
		)
	};

	useEffect(() => {
		if (newPermissions) {
			setSelectedPermissions(newPermissions.map((permission: RolePermission) => permission.permission.id));
		} else {
			setSelectedPermissions(userPermissions.map((permission: RolePermission) => permission.permission.id));
		}
	}, [newPermissions, userPermissions]);

	const getContent = () => {
		if (permissionsLoading) return <div className="text-center"><Spinner /></div>;

		if (permissionError) return <ErrorMessage message={permissionError.message} />;

		return permissionsData?.map((group: PermissionGroup, index: number) => {
			return (
				<div className="col-lg-3 col-md-6 col-sm-6 mb-5" key={index}>
					<Accordion id={group.name} isFlush activeItemId={group.id}>
						<AccordionItem id={group.id} title={group.label}>
							<Checks
								label="Seleccionar todos"
								value="true"
								checked={selectAll.includes(group.id)}
								onChange={() => {
									const list = group.permissions.map((item: Permission) => item.id);
									if (selectAll.includes(group.id)) {
										setSelectAll(selectAll.filter((id: number) => id !== group.id));
										setSelectedPermissions(selectedPermissions.filter(item => !list.includes(item)));
									} else {
										setSelectAll([...selectAll, group.id]);
										setSelectedPermissions([...selectedPermissions.concat(list)]);
									}
								}}
							/>

							{group.permissions.map((permission: Permission, index: number) => {
								return (
									<div key={permission.id}>
										<Checks
											value={permission.id.toString()}
											checked={selectedPermissions.includes(permission.id)}
											onChange={() => {
												selectedPermissions.includes(permission.id)
													? setSelectedPermissions(selectedPermissions.filter((id: number) => id !== permission.id))
													: setSelectedPermissions([...selectedPermissions, permission.id])
											}}
											label={getPermissionsLabel(permission.id.toString(), permission.label, permission.description)}
										/>
									</div>
								)
							})}
						</AccordionItem>
					</Accordion>
				</div>
			);
		});
	};

	return (
		<Modal isOpen={isOpen} setIsOpen={setIsOpen} size='xl' titleId='Nuevo Rol'>
			<ModalHeader setIsOpen={setIsOpen} className='p-4'>
				<ModalTitle id='new_role'>Editar permisos</ModalTitle>
			</ModalHeader>
			<ModalBody className='px-4'>
				<div className="row">
					{getContent()}
				</div>
			</ModalBody>
			<ModalFooter className='px-4 pb-4'>
				<Button isDisable={updating} icon={updating ? '' : 'Save'} color='primary' onClick={updatePermissions}>
					{updating ? (<Fragment> <Spinner isSmall /> Actualizando... </Fragment>) : 'Actualizar'}
				</Button>
			</ModalFooter>
		</Modal>
	)
}

export default PermissionsModal;