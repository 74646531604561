import { ChangeEvent, useCallback, useEffect, useState } from "react";
import useFetch from "../../../hooks/useFetch";
import { CustomTable } from "../../../components/table/CustomTable";
import { useNavigate } from "react-router-dom";
import { handleConfirmationAlert } from "../../../components/ConfirmationAlert";
import { toast } from "react-toastify";
import Card, { CardBody, CardTitle } from "../../../components/bootstrap/Card";
import moment from "moment";
import useFilters from "../../../hooks/useFilters";
import SubHeader, { SubHeaderLeft, SubHeaderRight, SubheaderSeparator } from "../../../layout/SubHeader/SubHeader";
import Button from "../../../components/bootstrap/Button";
import Page from "../../../layout/Page/Page";
import { TipsGroupsService } from "../../../services/tips/groupsService";
import { tipsMenu } from "../../../menu";
import Spinner from "../../../components/bootstrap/Spinner";
import ErrorMessage from "../../../components/ErrorMessage";
import Input from "../../../components/bootstrap/forms/Input";
import Icon from "../../../components/icon/Icon";
import CustomSearchInput from "../../../components/bootstrap/forms/CustomSearchInput";

interface ITipsFilters {
    client?: any;
}

const tipsFilters: ITipsFilters = {
    client: null,
};

const GroupsList = () => {
    const navigate = useNavigate();
    const tipsService = new TipsGroupsService();

    const { filters, updateFilters, resetFilters, updateFilterOrder, updatePage, updatePageSize } = useFilters(tipsFilters);

    const [groups, loadingGroups, groupsError, refetchGroups] = useFetch(useCallback(async () => {
        const response = (await tipsService.listGroups(filters));
        return response.getResponseData();
    }, [filters]));

    const handleDelete = async (id: string) => {
        try {
            const response = (await tipsService.deleteGroup(id)).getResponseData();
            if (response.success) {
                refetchGroups();

                toast.success('Grupo eliminado correctamente');
            } else {
                setTimeout(() => {
                    toast.error(response.message || 'Error al eliminar el grupo');
                }, 100);
            }
        } catch (error: any) {
            toast.error(error.message || 'Error al eliminar el grupo');
        }
    };

    const _handleTextChange = (search: string) => {
        updateFilters({ search_array: search });
    };

    const getContent = () => {
        if (groupsError) return <div className="text-center"><ErrorMessage message={groupsError} /></div>;

        if (groups && groups.tip_groups.length > 0) {
            return (
                <CustomTable
                    title="Grupos de consejos"
                    loading={loadingGroups}
                    data={groups ? groups?.tip_groups : null}
                    pagination
                    paginationData={{
                        pageSize: filters.limit,
                        currentPage: filters.page,
                        pageCount: groups ? groups.lastPage : 1,
                        handlePagination: (page: any) => {
                            updatePage({ selected: page.selected + 1 });
                        },
                        handlePerPage: updatePageSize,
                    }}
                    className={"table-striped table-hover"}

                    columns={[
                        {
                            name: "Nombre",
                            keyValue: "name",
                            sortable: true,
                            sortColumn: updateFilterOrder,
                            render: (element: any) => {
                                return (
                                    <div className="cursor-pointer text-secondary" onClick={() => { navigate(`${tipsMenu.tips.subMenu.tipsGroups.path}/${element.id}/edit`) }}>
                                        {element.name}
                                    </div>
                                )
                            },
                        },
                        {
                            name: "Descripción",
                            keyValue: "fields",
                            sortable: true,
                            sortColumn: updateFilterOrder,
                            render: (element: any) => {
                                return (
                                    <>
                                        {element.description || "-"}
                                    </>
                                )
                            }
                        },
                        {
                            name: "Fecha de creación",
                            keyValue: "createdAt",
                            sortable: true,
                            sortColumn: updateFilterOrder,
                            render: (element: any) => {
                                return (
                                    <>
                                        {moment(element.createdAt?.date).format('DD/MM/YYYY') || "-"}
                                    </>
                                )
                            }
                        },
                        {
                            name: "Última actualización",
                            keyValue: "updatedAt",
                            sortable: true,
                            sortColumn: updateFilterOrder,
                            render: (element: any) => {
                                return (
                                    <>
                                        {element.updateAt?.date ? moment(element.updateAt?.date).format('DD/MM/YYYY') : "-"}
                                    </>
                                )
                            }
                        },
                        { name: "Acciones", className: "min-w-100px text-end", isActionCell: true }
                    ]}
                    actions={[
                        {
                            title: "Editar",
                            icon: "Edit",
                            buttonType: 'icon',
                            additionalClasses: 'text-primary',
                            description: "Editar grupo",
                            click: (item: any) => {
                                navigate(`${tipsMenu.tips.subMenu.tipsGroups.path}/${item.id}/edit`);
                            }
                        },
                        {
                            title: "Eliminar",
                            icon: "Delete",
                            buttonType: 'icon',
                            additionalClasses: 'text-danger',
                            description: "Eliminar grupo",
                            click: (item: any) => {
                                handleConfirmationAlert({
                                    title: "Eliminar grupo",
                                    text: "¿Está seguro que desea eliminar el grupo?",
                                    icon: "warning",
                                    onConfirm: () => {
                                        handleDelete(item.id);
                                    }
                                })
                            },
                        },
                    ]}
                />
            )
        }
    };

    return (
        <>
            <SubHeader>
                <SubHeaderLeft>
                    <CardTitle>Listado de Grupos de Consejos</CardTitle>
                    <SubheaderSeparator />
                    <Button
                        title="Crear Grupo"
                        color="storybook"
                        icon="Add"
                        isLight
                        onClick={() => { navigate(tipsMenu.tips.subMenu.tipsGroups.path + '/create') }}
                    />
                </SubHeaderLeft>
                <SubHeaderRight>
                    <label className='border-0 bg-transparent cursor-pointer' htmlFor='searchInput'>
                        <Icon icon='Search' size='2x' color='secondary' />
                    </label>

                    <CustomSearchInput onSearch={_handleTextChange} placeholder='Buscar...' />
                </SubHeaderRight>
            </SubHeader>
            <Page container="fluid">
                <Card stretch={true}>
                    <CardBody className="table-responsive" isScrollable={true}>
                        {getContent()}
                    </CardBody>
                </Card>
            </Page>
        </>
    );
}

export default GroupsList;