import { FC, useCallback, useEffect, useRef, useState } from "react";
import { useParams } from "react-router-dom";
import { TipsService } from "../../../services/tips/tipsService";
import useFilters from "../../../hooks/useFilters";
import useFetch from "../../../hooks/useFetch";
import { CardBody } from "../../../components/bootstrap/Card";
import { CustomTable } from "../../../components/table/CustomTable";
import moment from "moment";
import { handleConfirmationAlert } from "../../../components/ConfirmationAlert";
import { toast } from "react-toastify";
import StatusDropdown from "../../../components/StatusDropdown";
import '../../../styles/styles.scss';

interface PatientTipsProps {
    groupId: string;
}

interface ITipsFilters {
    tipGroup?: string;
    client?: string;
}

const ListPatientTips: FC<PatientTipsProps> = ({ groupId }) => {
    const { id = '' } = useParams<{ id: string }>();

    const tipsService = new TipsService();

    const [changingStatus, setChangingStatus] = useState<string[]>([]);

    const tipsFilters: ITipsFilters = {
        tipGroup: groupId,
        client: id
    };

    const { filters, updateFilters, resetFilters, updateFilterOrder, updatePage, updatePageSize } = useFilters(tipsFilters);

    const [tips, loadingTips, tipsError, refetchTips] = useFetch(useCallback(async () => {
        const response = (await tipsService.listTips(filters));
        return response.getResponseData();
    }, [filters]));

    const toggleTipStatus = async (id: string, status: boolean, toggleStatus: Function) => {
        try {
            setChangingStatus([...changingStatus, id]);
            const response = (await tipsService.toggleTipStatus(id)).getResponseData();
            if (response.success) {
                setChangingStatus(changingStatus.filter((item) => item !== id));
                toggleStatus(status);
                refetchTips();
                toast.info(status ? 'Consejo trabajado' : 'Consejo sin trabajar');
            } else {
                setChangingStatus(changingStatus.filter((item) => item !== id));
                toast.error(response.message || 'Error al cambiar el estado del consejo');
            }
        } catch (error: any) {
            setChangingStatus(changingStatus.filter((item) => item !== id));
            toast.error(error.message || 'Error al cambiar el estado del consejo');
        }
    };

    const _handleExport = async (documentId: string, documentName: string) => {
        try {
            const response = (await tipsService.downloadDocument(documentId));
            if (response) {
                const fileData = response.getResponseData();
                const blob = new Blob([fileData]);
                const url = window.URL.createObjectURL(blob);

                const link = document.createElement('a');
                link.href = url;
                link.setAttribute('download', documentName);
                document.body.appendChild(link);
                link.click();
                document.body.removeChild(link);
                window.URL.revokeObjectURL(url);
            } else {
                toast.error('Error al exportar el documento');
            }
        } catch (error: any) {
            toast.error(error.message);
        }
    };

    const _handleDelete = async (id: string) => {
        try {
            const response = (await tipsService.deleteTip(id)).getResponseData();
            if (response.success) {
                refetchTips();
            } else {
                setTimeout(() => {
                    toast.error(response.message || 'Error al quitar el consejo al paciente');
                }, 100);
            }
        } catch (error: any) {
            toast.error(error.message || 'Error al quitar el consejo al paciente');
        }
    };

    // Actualizar los filtros cuando se cambia el grupo
    useEffect(() => {
        if (groupId !== null) {
            updateFilters({ tipGroup: groupId, client: id });
        }
    }, [groupId]);

    const getContent = () => {
        if (tips?.tips?.length > 0) {
            return (
                <CustomTable
                    data={tips ? tips.tips : null}
                    pagination
                    paginationData={{
                        pageSize: filters.limit,
                        currentPage: filters.page,
                        pageCount: tips ? tips.lastPage : 1,
                        handlePagination: (page: any) => {
                            updatePage({ selected: page.selected + 1 });
                        },
                        handlePerPage: updatePageSize,
                    }}
                    className={"table-striped table-hover"}

                    columns={[
                        {
                            name: "Nombre",
                            keyValue: "name",
                            sortable: true,
                            sortColumn: updateFilterOrder,
                        },
                        {
                            name: "Descripción",
                            keyValue: "description",
                            sortable: true,
                            sortColumn: updateFilterOrder,
                            render: (element: any) => {
                                return (
                                    <>
                                        {element.description || "-"}
                                    </>
                                )
                            }
                        },
                        {
                            name: "Fecha de creación",
                            keyValue: "createdAt",
                            sortable: true,
                            sortColumn: updateFilterOrder,
                            render: (element: any) => {
                                return (
                                    <>
                                        {moment(element.createdAt?.date).format('DD/MM/YYYY') || "-"}
                                    </>
                                )
                            }
                        },
                        {
                            name: "Documento",
                            keyValue: "document",
                            render: (element: any) => {
                                return (
                                    <div
                                        className={element.tipDocuments[0]?.document.id ? "cursor-pointer text-secondary" : "text-muted"}
                                        onClick={() => {
                                            if (element.tipDocuments[0]?.document.id) {
                                                _handleExport(element.tipDocuments[0]?.document.id, element.tipDocuments[0]?.document.originalName);
                                            }
                                        }}
                                    >
                                        {element.tipDocuments[0]?.document.originalName || "No tiene documento"}
                                    </div>
                                )
                            }
                        },
                        {
                            name: "Estado",
                            keyValue: "worked",
                            className: "text-center",
                            render: (element: any) => {
                                return (
                                    <div className={'text-center'}>
                                        <StatusDropdown
                                            disabled={changingStatus.includes(element.id)}
                                            itemId={element.id}
                                            status={element.tipWorkClient[0].worked}
                                            change={toggleTipStatus}
                                            btnClass="btnNoBg"
                                            labelActive="Trabajado"
                                            labelInactive="Sin trabajar"
                                        />
                                    </div>
                                );
                            },
                        },
                        { name: "Acciones", className: "min-w-100px text-end", isActionCell: true }
                    ]}
                    actions={[
                        {
                            title: "Descargar documento",
                            icon: "Article",
                            buttonType: 'icon',
                            additionalClasses: 'text-primary',
                            description: "Descargar documento",
                            click: (item: any) => {
                                if (item.tipDocuments.length > 0) {
                                    _handleExport(item.tipDocuments[0].document.id, item.tipDocuments[0].document.originalName);
                                } else {
                                    toast.info('No hay documento para descargar');
                                }
                            }
                        },
                        {
                            title: "Quitar",
                            icon: "Close",
                            buttonType: 'icon',
                            additionalClasses: 'text-danger',
                            description: "Quitar consejo",
                            click: (item: any) => {
                                handleConfirmationAlert({
                                    title: "Quitar consejo",
                                    text: "¿Está seguro que desea quitar el consejo al paciente?",
                                    icon: "warning",
                                    onConfirm: () => {
                                        _handleDelete(item.id);
                                    }
                                })
                            },
                        },
                    ]}
                />
            )
        } else {
            return (
                <div className="text-center mt-3"><h5 className="text-muted">El paciente no tiene consejos asociados</h5></div>
            )
        }
    }

    return (
        <CardBody className="cardBodyForms">
            {groupId ? getContent() : <div className="text-center"><h5 className="text-muted">El paciente no tiene consejos asociados</h5></div>}
        </CardBody>
    )
}

export default ListPatientTips;