/* eslint-disable jsx-a11y/anchor-is-valid */
import CustomSearchInput from './../bootstrap/forms/CustomSearchInput';
import './CustomTable.css';
import React, { ChangeEvent, Key, useState } from 'react'
import Spinner from './../bootstrap/Spinner';
import { Pagination } from './Pagination';
import clsx from "clsx";
import Icon from '../icon/Icon';
import { isEmptyArray } from 'formik';

interface Column {
    name: string,
    sortable?: boolean,
    keyValue?: string,
    className?: string,
    cellClassName?: string,
    ordered?: "ASC" | "DESC",
    render?(element: any): JSX.Element,
    isActionCell?: boolean,
    isVisible?: boolean,
    sortColumn?(keyValue: string, order: "asc" | "desc"): void,
    sortOrder?: "asc" | "desc",
    style?: React.CSSProperties
}

interface SearchInput {
    placeholder: string,
    onSearch(value: string): void
}

type Columns = Array<Column>;

interface Action {
    title: string,
    icon: string;
    click: (item: any) => void;
    iconPath?: string,
    description?: string,
    buttonType?: "normal" | "icon",
    additionalClasses?: string,
    callback?(element: any): void
}

type Actions = Array<Action>;

interface PaginationProps {
    pageCount: number,
    currentPage: number,
    pageSize: number
    handlePagination: Function
    handlePerPage: Function
}

type Props = {
    className: string,
    tableClassName?: string,
    title?: string,
    subtitle?: string,
    data: Array<any> | null,
    selectableItems?: boolean,
    onSelectAllItems?(checked: boolean): void,
    onChangeCheckedItems?(id: string, event: ChangeEvent<HTMLInputElement>, element: any): void,
    columns: Columns
    toolbar?: boolean,
    actions?: Actions | null,
    startElementToShow?: number,
    pagination?: boolean
    paginationData?: PaginationProps,
    onClickRow?(id: string): void,
    searchInput?: SearchInput,
    loading?: boolean
}


const DEFAULT_COLUMN_WIDTH = "min-w-100px";

const CustomTable: React.FC<Props> = ({ className, title, subtitle, data, selectableItems, onSelectAllItems, onChangeCheckedItems, toolbar, columns, actions, tableClassName, startElementToShow, pagination, paginationData, onClickRow, searchInput, loading }) => {

    const [confColumns, setConfColumns] = useState(columns);
    const [sortedBy, setSortedBy] = useState<Key | null>(null);
    const [selectedAll, setSelectedAll] = useState<boolean>(false);
    const [selectedItems, setSelectedItems] = useState<any[]>([]);

    const tableColumn = (column: Column, index: Key) => {
        let className = column.className ?? DEFAULT_COLUMN_WIDTH;
        let columnName = column.name;
        let sortable = column.sortable ?? false;
        let checkboxColumn = null;

        if (selectableItems === true && index === 0) {
            checkboxColumn = (
                <th key={`table-checkbox-${index}`} className={``}>
                    <input
                        className="form-check-input"
                        type="checkbox"
                        id={`table-checkbox-select-all`}
                        checked={selectedAll}
                        onChange={(event: ChangeEvent<HTMLInputElement>) => {
                            let checked = event.target.checked;
                            setSelectedAll(checked);
                            onSelectAllItems && onSelectAllItems(checked);
                        }}
                    />
                </th>
            );
        }

        return (
            <th key={index} className={className}>
                {columnName}
                {sortable && renderSortArrows(index, column)}
            </th>
        )
    }

    /**
     * Render cell with configured props.
     *
     * @param element
     * @param column
     * @returns
     */
    const renderCell = (element: any, index: Key, column: Column) => {
        if (column.keyValue) {
            if (column.render !== undefined) {
                return (
                    <td key={index} style={column.style}>
                        {column.render(element)}
                    </td>
                )
            }

            return (
                <td key={index} style={column.style}>
                    <div className={column.cellClassName}>
                        {element[column.keyValue]}
                    </div>
                </td>
            )
        }

        if (column.isActionCell === true && actions !== undefined && actions !== null) {
            return renderActions(element, index, actions);
        }

        throw new Error(`Column ${column.name} is not defined correctly.`)
    }

    const callToAction = (action: Action, element: any) => {
        if (action.callback) {
            action.callback(element);
        }
    }

    const renderSortArrows = (index: Key, column: Column) => {
        return (
            <a
                key={index + '-' + column.name}
                onClick={() => {
                    if (column.sortColumn != undefined && column.keyValue != undefined) {
                        if (sortedBy == index) {
                            setSortedBy(null)
                            column.sortColumn(column.keyValue, "desc")
                        } else {
                            setSortedBy(index);
                            column.sortColumn(column.keyValue, "asc")
                        }
                    }
                }}
                data-toogle="tooltip"
            >
                <Icon
                    icon={sortedBy === index ? 'KeyboardArrowUp' : 'KeyboardArrowDown'}
                    className='icon-large'
                />
            </a>
        )
    }

    const renderActions = (element: any, index: Key, actions: Array<Action>): JSX.Element => {
        return (
            <td key={index}>
                <div className='d-flex justify-content-end flex-shrink-0'>
                    {actions.map((action: Action, index: number) => {
                        return actionCell(action, index, element);
                    })}
                </div>
            </td>
        );
    }

    const actionCell = (action: Action, index: Key, element: any) => {
        return (
            <a
                key={index + '-' + element.id}
                onClick={() => {
                    callToAction(action, element);
                }}
                title={action.description}
                data-toogle="tooltip"
                className={
                    clsx({
                        'me-1': action.buttonType !== "normal",
                        'btn btn-sm btn-primary me-2': action.buttonType === "normal"
                    }) + " " + action.additionalClasses
                }
            >
                {
                    <div onClick={() => action.click(element)}>
                        <Icon icon={action.icon} className='icon-large me-2' />
                    </div>
                }
            </a>
        );
    }

    const renderCheckbox = (selectableItems: boolean = false, element: any) => {
        if (selectableItems === true) {
            return (
                <td key={element.id}>
                    <div>
                        <div className="form-check">
                            <input
                                className="form-check-input"
                                type="checkbox"
                                id={`checkbox-${element.id}`}
                                checked={selectedAll || selectedItems.includes(element.id)}
                                onChange={(event: ChangeEvent<HTMLInputElement>) => {
                                    setSelectedItems((prev) => {
                                        if (event.target.checked) {
                                            return [...prev, element.id];
                                        } else {
                                            return prev.filter((item) => item !== element.id);
                                        }
                                    });
                                    onChangeCheckedItems && onChangeCheckedItems(element.id, event, element);
                                }}
                            />
                        </div>
                    </div>
                </td>
            );
        }
    };

    const tableRow = (element: any, index: number, columns: Columns) => {
        if (startElementToShow !== undefined && index >= startElementToShow) {
            return (
                <tr key={index} onClick={() => onClickRow != undefined ? onClickRow(element.id) : null} >
                    {renderCheckbox(selectableItems ?? false, element)}
                    {columns.map((column: Column, index: number) => {
                        if (column.isVisible === undefined || column.isVisible) {
                            return renderCell(element, index, column);
                        }
                        return null;
                    })}
                </tr>
            );
        }
        return null;
    }

    return (
        <div className={`custom-table table-responsive p-2 ${className}`}>
            {
                searchInput !== undefined && (
                    <div className="row me-5 mt-5 d-flex justify-content-end">
                        <div className="col-md-3">
                            <CustomSearchInput placeholder={searchInput.placeholder} onSearch={searchInput.onSearch} />
                        </div>
                    </div>
                )
            }

            {/* <table className={'table table-modern mb-5 ' + tableClassName}>
                <thead>
                    <tr className='fw-bolder text-muted'>
                        {confColumns.map(tableColumn)}
                    </tr>
                </thead>
                <tbody>
                    {data !== null ? data.map((element: any, index: number) => tableRow(element, index, columns)) : <Spinner isGrow />}
                </tbody>
            </table> */}

            <table className={'table table-row-dashed table-row-gray-300 align-middle gs-0 gy-4 ' + tableClassName}>
                <thead>
                    <tr className='fw-bolder text-muted'>
                        {confColumns.map(tableColumn)}
                    </tr>
                </thead>
                <tbody className='w-100'>
                    {loading && (<tr><td colSpan={confColumns.length} className="text-center"><Spinner /></td></tr>)}
                    {data !== null && data.map((element: any, index: number) => tableRow(element, index, columns))}
                    {isEmptyArray(data) && (
                        <tr><td colSpan={confColumns.length} className="text-center">
                            <h5 className="text-muted mt-5">No se han encontrado {title ? title.toLocaleLowerCase() : 'resultados'}</h5>
                        </td></tr>
                    )}
                    {data === null && (<tr><td colSpan={confColumns.length} className='text-center'><Spinner isGrow /></td></tr>)}
                </tbody>
            </table>

            {
                pagination == true && paginationData !== undefined && paginationData !== null && (
                    <Pagination
                        currentPage={paginationData.currentPage}
                        pageCount={paginationData.pageCount}
                        perPage={paginationData?.pageSize}
                        handlePagination={paginationData.handlePagination}
                        handlePerPage={paginationData.handlePerPage}
                    />
                )
            }
        </div>
    )
}

CustomTable.defaultProps = {
    tableClassName: '',
    startElementToShow: 0
}

export { CustomTable }