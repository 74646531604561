import { FC, useCallback, useState } from 'react'
import FormGroup from '../../../components/bootstrap/forms/FormGroup'
import Spinner from '../../../components/bootstrap/Spinner'
import { AlimentService } from '../../../services/aliments/alimentService'
import useFetch from '../../../hooks/useFetch'
import Select from '../../../components/bootstrap/forms/Select'
import SearchableSelect from '../../../components/select/SearchableSelect'
import { monthsList } from '../../../utils/FoodUtils'
import Button from '../../bootstrap/Button'
import Checks from '../../bootstrap/forms/Checks'

interface IFoodFiltersProps {
    updateFilters: (filters: any) => void;
    filters: any;
    resetFilters: () => void;
}

const FoodFilters: FC<IFoodFiltersProps> = ({ filters, updateFilters, resetFilters }) => {

    const [groupsSelected, setGroupsSelected] = useState<any>([]);
    const [highIn, setHighIn] = useState<any[]>([]);
    const foodService = new AlimentService();

    // buscar los grupos de alimentos
    const [groups] = useFetch(useCallback(async () => {
        const response = await foodService.getAlimentGroups();
        return response.getResponseData();
    }, []));

    // buscar los nutrientes
    const [nutrients] = useFetch(useCallback(async () => {
        const response = await foodService.getNutrientsList();
        return response.getResponseData();
    }, []));

    // controlar el cambio en el selector de grupos
    const handleGroupsChange = (e: any) => {
        setGroupsSelected(e);
        updateFilters({ group_food: e.map((group: any) => group.value) });
    }

    // controlar el cambio en el selector de nutrientes
    const handleNutrientsChange = (e: any) => {
        setHighIn(e);
        updateFilters({ high_in: e.value });
    }

    // controlar el cambio en el selector de comienzo de temporada
    const handleSeasonChange = (e: any) => {
        updateFilters({ season: e.target.value });
    }

    // controlar el cambio en el selector de fin de temporada
    const handleEndSeasonChange = (e: any) => {
        updateFilters({ seasonend: e.target.value });
    }

    // obtener la lista de grupos de alimentos
    const getGroupsOptions = () => {
        if (groups) {
            return groups.food_groups.map((option: { id: any; name: any; }) => {
                return {
                    value: option.id,
                    label: option.name
                }
            })
        }
        return [];
    }

    // obtener la lista de nutrientes
    const getNutrientsOptions = () => {
        if (nutrients) {
            return nutrients.map((option: { name: string; nombre: string; }) => {
                return {
                    value: option.name,
                    label: option.nombre
                }
            })
        }
        return [];
    }

    // resetear los filtros
    const _handleResetFilters = () => {
        resetFilters();
        setGroupsSelected([]);
        setHighIn([]);
    }

    return (
        <>
            <div className='row p-0 d-flex justify-content-between'>
                <div className='col-md-2'>
                    <FormGroup id='filter0' label='Suplemento'>
                        <Checks
                            onChange={(e: any) => updateFilters({ supplement: (e.target.checked === true ? 1 : 0) })}
                            checked={filters.filter_filters.supplement}
                            type='switch'
                            name='supplement'
                        />
                    </FormGroup>
                </div>

                <div className='col-md-3'>
                    <FormGroup id='filter1' label='Grupos de alimentos'>
                        {
                            groups ? (
                                <SearchableSelect
                                    isMulti
                                    onChange={(e: any) => handleGroupsChange(e)}
                                    value={groupsSelected}
                                    name='groups_filter'
                                    placeholder='grupos'
                                    options={getGroupsOptions()}
                                />
                            ) : (
                                <Spinner isSmall className='ms-3' />
                            )
                        }
                    </FormGroup>
                </div>

                <div className='col-md-3'>
                    <FormGroup id='filter2' label='Alto contenido'>
                        {nutrients ? (
                            <SearchableSelect
                                onChange={(e: any) => handleNutrientsChange(e)}
                                value={highIn}
                                name='nutrients_filter'
                                placeholder='nutrientes'
                                options={getNutrientsOptions()}
                            />
                        ) : (
                            <Spinner isSmall className='ms-3' />
                        )}
                    </FormGroup>
                </div>

                <div className='col-md-4'>
                    <FormGroup id='filter3' label='Temporalidad'>
                        <div className='row'>
                            <div className='col-6'>
                                <div className='row'>
                                    <p className='m-0 d-flex align-content-center' style={{ height: '100%' }}>Inicio: </p>
                                    <div className='col-md-12'>
                                        <Select
                                            onChange={(e: any) => handleSeasonChange(e)}
                                            value={filters.filter_filters.season}
                                            id='season-select'
                                            list={monthsList} ariaLabel={''}
                                        />
                                    </div>
                                </div>
                            </div>
                            <div className='col-6'>
                                <div className='row d-flex justify-content-end'>
                                    <p className='m-0 d-flex align-content-center' style={{ height: '100%' }}>Fin: </p>
                                    <div className='col-md-12'>
                                        <Select
                                            onChange={(e: any) => handleEndSeasonChange(e)}
                                            value={filters.filter_filters.seasonend}
                                            id='seasonend-select'
                                            list={monthsList} ariaLabel={''}
                                        />
                                    </div>
                                </div>
                            </div>
                        </div>
                    </FormGroup>
                </div>
            </div >

            <div className='row mt-4 p-0 d-flex justify-content-end'>
                <Button
                    className='w-auto'
                    color='light'
                    onClick={() => _handleResetFilters()}
                    style={{ marginRight: '10px' }}
                >
                    Limpiar filtros
                </Button>
            </div>
        </>
    )
}

export default FoodFilters;