import { FC } from "react";
import img from "../assets/error_message.jpg";

interface ErrorMessageProps {
  error?: any;
  message?: string;
}

const ErrorMessage: FC<ErrorMessageProps> = ({ error, message }) => {
  return (
    <div className='row d-flex align-items-center h-100'>
      <div className='col-12 d-flex align-items-baseline justify-content-center'>
        {error && <p>{error.message}</p>}
        {
          <p className="text-brand h4 mt-5 mb-5">{message ? message : 'Ha ocurrido un error al cargar los datos'}</p>
        }
      </div>

      <div className='col-12 d-flex align-items-baseline justify-content-center'>
        <img width='350px' height='275px' src={img} alt="Error image" className="img-responsive" />
      </div>
    </div>
  );
};

export default ErrorMessage;