import React, { useCallback, useEffect, useState } from 'react';
import { AlimentService } from '../../services/aliments/alimentService';
import { RecipeService } from '../../services/recipes/recipeService';
import { AlimentsApiResponse } from '../../type/aliments-type';
import { RecipesApiResponse } from '../../type/recipes-type';
import useFetch from '../../hooks/useFetch';
import Card, { CardTabItem } from '../bootstrap/Card';
import DraggableItem from './DraggableItem';
import { ActivitiesService } from '../../services/activities/activitiesService';
import useFilters from '../../hooks/useFilters';
import FormGroup from '../bootstrap/forms/FormGroup';
import { activityFilters, foodFilters, recipeFilters } from '../../type/menu-type';
import FoodFilters from './Filters/FoodFilters';
import RecipeFilters from './Filters/RecipeFilters';
import { ActivitiesApiResponse } from '../../type/activities-type';
import CustomSearchInput from '../bootstrap/forms/CustomSearchInput';
import Input from '../bootstrap/forms/Input';
import './styles/styles.scss';

interface MenuSelectorsProps {

}

const NutritionalMenuSelector: React.FC<MenuSelectorsProps> = ({ }) => {

  const foodService = new AlimentService();
  const recipeService = new RecipeService();
  const activitiesService = new ActivitiesService();

  const [searchFoodValue, setSearchFoodValue] = useState("");
  const [searchRecipeValue, setSearchRecipeValue] = useState("");
  const [searchRecipeResults, setSearchRecipeResults] = useState<any[]>([]);
  const [searchPhysicalActivityValue, setSearchPhysicalActivityValue] = useState("");
  const [searchPhysicalActivityResults, setSearchPhysicalActivityResults] = useState<any[]>([]);
  const [foodQuantity, setFoodQuantity] = useState<number>(100);

  const { filters, updateFilters, resetFilters, updateFilterOrder, updatePage, updatePageSize } = useFilters(foodFilters);
  const { filters: filtersRecipes, updateFilters: updateFiltersRecipe, resetFilters: resetFiltersRecipe, updateFilterOrder: updateFilterOrderRecipe, updatePage: updatePageRecipe, updatePageSize: updatePageSizeRecipe } = useFilters(recipeFilters);
  const { filters: filtersPhysicalActivities, updateFilters: updateFiltersPhysicalActivities, resetFilters: resetFiltersPhysicalActivities, updateFilterOrder: updateFilterOrderPhysicalActivities, updatePage: updatePagePhysicalActivities, updatePageSize: updatePageSizePhysicalActivities } = useFilters(activityFilters);

  const [foodsData] = useFetch(useCallback(async () => {
    const response = await foodService.getAliments(filters);
    return response.getResponseData() as AlimentsApiResponse;
  }, [filters]));

  const [recipesData] = useFetch(useCallback(async () => {
    const response = await recipeService.getRecipes(filtersRecipes);
    return response.getResponseData() as RecipesApiResponse;
  }, [filtersRecipes]));

  const [physicalActivitiesData] = useFetch(useCallback(async () => {
    const response = await activitiesService.listActivities(filtersPhysicalActivities);
    return response.getResponseData() as ActivitiesApiResponse;
  }, [filtersPhysicalActivities]));

  // Muestra las cards de alimentos
  const showAliments = () => {
    if (searchFoodValue === "") return null;
    return (
      <div className="row itemsCard-container d-flex justify-content-around p-2">
        {foodsData?.data?.map((element: any, index: number) => {
          return <DraggableItem key={index} label={element.name} data={element} food={true} quantity={foodQuantity} fromSelectors={true} />;
        })}
      </div>
    );
  };

  // Muestra las cards de recetas
  const showRecipes = () => {
    if (searchRecipeValue === "") return null;
    return (
      <div className="row itemsCard-container d-flex justify-content-around p-2">
        {searchRecipeResults?.map((element, index) => {
          const { label, data } = element;
          return <DraggableItem key={index} label={label} data={data} recipe={true} />;
        })}
      </div>
    );
  };

  // Muestra las cards de actividades físicas
  const showPhysicalActivities = () => {
    if (searchPhysicalActivityValue === "") return null;
    return (
      <div className="row itemsCard-container d-flex justify-content-around p-2">
        {searchPhysicalActivityResults?.map((element, index) => {
          const { label, data } = element;
          return <DraggableItem key={index} label={label} data={data} activity={true} />;
        })}
      </div>
    );
  };

  // Filtra los resultados de búsqueda del input cada 1s
  useEffect(() => {
    const timer = setTimeout(() => {
      const filteredResults = recipesData?.recipes.filter((recipe: any) =>
        searchRecipeValue
          .toLowerCase()
          .split(' ') // Divide el término de búsqueda en palabras individuales
          .every((searchWord: string) =>
            recipe.name
              .toLowerCase()
              .split(' ') // Divide el nombre de la receta en palabras individuales
              .some((recipeWord: string) =>
                recipeWord.includes(searchWord)
              )
          )
      );
      setSearchRecipeResults(
        filteredResults?.map((result: any) => ({
          label: result.name,
          //value: result.id,
          data: result,
        }))
      );
    }, 1000);
    return () => {
      clearTimeout(timer);
    };
  }, [searchRecipeValue, recipesData]);

  useEffect(() => {
    const timer = setTimeout(() => {
      const filteredResults = physicalActivitiesData?.physical_activities.filter((activity: any) =>
        searchPhysicalActivityValue
          .toLowerCase()
          .split(' ') // Divide el término de búsqueda en palabras individuales
          .every((searchWord: string) =>
            activity.name
              .toLowerCase()
              .split(' ') // Divide el nombre de la receta en palabras individuales
              .some((activityWord: string) =>
                activityWord.includes(searchWord)
              )
          )
      );
      setSearchPhysicalActivityResults(
        filteredResults?.map((result: any) => ({
          label: result.name,
          //value: result.id,
          data: result,
        }))
      );
    }, 1000);
    return () => {
      clearTimeout(timer);
    };
  }, [searchPhysicalActivityValue, physicalActivitiesData]);

  return (
    <div className='row mx-0'>
      <Card stretch={true} hasTab className='p-0' id='select-items'>
        <CardTabItem id='recipes' title='Recetas'>
          <div className='row p-0'>
            <FormGroup label='Nombre' className='col-md-3'>
              <CustomSearchInput
                onSearch={(search: string) => {
                  updateFiltersRecipe({ search_array: search });
                  setSearchRecipeValue(search);
                }}
                placeholder='Buscar receta...'
              />
            </FormGroup>
            <div className='col-md-9'>
              <RecipeFilters updateFilters={updateFiltersRecipe} resetFilters={resetFiltersRecipe} />
            </div>
          </div>
          {showRecipes()}
        </CardTabItem>

        <CardTabItem id='foods' title='Alimentos'>
          <div className='row p-0'>
            <FormGroup label='Nombre' className='col-md-2'>
              <CustomSearchInput
                onSearch={(search: string) => {
                  updateFilters({ search_array: search });
                  setSearchFoodValue(search);
                }}
                placeholder='Buscar alimento...'
              />
            </FormGroup>
            <FormGroup label='Cantidad (g)' className='col-md-2'>
              <Input
                type='number'
                value={foodQuantity}
                onChange={(e: any) => setFoodQuantity(e.target.value)}
                className='w-50'
              />
            </FormGroup>
            <div className='col-md-8' /* style={{ borderLeft: '1px solid lightgray' }} */>
              <FoodFilters updateFilters={updateFilters} filters={filters} resetFilters={resetFilters} />
            </div>
          </div>
          {showAliments()}
        </CardTabItem>

        <CardTabItem id='physicalActivities' title='Ejercicio'>
          <div className='row p-0'>
            <div className='col-md-3'>
              <FormGroup label='Nombre'>
                <CustomSearchInput
                  onSearch={(search: string) => {
                    updateFiltersPhysicalActivities({ search_array: search });
                    setSearchPhysicalActivityValue(search);
                  }}
                  placeholder='Buscar receta...'
                />
              </FormGroup>
            </div>
          </div>
          {showPhysicalActivities()}
        </CardTabItem>
      </Card>
    </div>
  );
};

export default NutritionalMenuSelector;