import { FC, useState } from "react"
import AsyncImg from "./AsyncImg"
import Button from "./bootstrap/Button"
import Card, { CardBody, CardHeader, CardLabel, CardTitle } from "./bootstrap/Card"
import Input from "./bootstrap/forms/Input"
import PlaceholderImage from "./extras/PlaceholderImage"
import { handleConfirmationAlert } from "./ConfirmationAlert"
import { toast } from "react-toastify"

interface ImageFormProps {
    dataId: string;
    dataImage: any;
    name: string;
    service: any;
    setImage: any;
}

export const ImageForm: FC<ImageFormProps> = ({ dataId, dataImage, name, setImage, service }) => {

    const [selectedImage, setSelectedImage] = useState<any>(null);

    const handleImageUpload = async (event: React.ChangeEvent<any>) => {
        const file = event.target.files[0];
        const reader = new FileReader();
        reader.onload = () => {
            setSelectedImage(reader.result);
        };
        reader.readAsDataURL(file);

        setImage(file);
    };

    const deleteImage = async () => {
        handleConfirmationAlert({
            title: '¿Estás seguro?',
            text: 'Se eliminará la foto de la receta',
            icon: 'warning',
            onConfirm: async () => {
                try {
                    const response = await service.deleteRecipeImg(dataId);
                    const responseData = response.getResponseData();

                    if (responseData.success) {
                        setSelectedImage(null);
                        window.location.reload();
                    }
                } catch (error: any) {
                    toast.error("Error al eliminar la foto");
                }
            }
        })
    };

    return (
        <Card stretch={false}>
            <CardHeader className='rounded-1 mb-0'>
                <CardLabel icon='PhotoCamera' iconColor='secondary'>
                    <CardTitle>Foto de {name}</CardTitle>
                </CardLabel>
            </CardHeader>

            <CardBody className="text-center">
                <div className={'text-center pb-4'}>
                    {
                        selectedImage
                            ? <img width={125} height={125} src={selectedImage} alt="selected" className='mx-auto d-block img-fluid mb-3 rounded' />
                            : dataImage
                                ? <AsyncImg isBackground height="125px" width="125px" styles="rounded-circle" id={dataImage.id} />
                                : <PlaceholderImage width={125} height={125} className='mx-auto d-block img-fluid mb-3 rounded' />
                    }
                </div>

                <Input
                    type='file'
                    onChange={(e: React.ChangeEvent<any>) => handleImageUpload(e)}
                    placeholder="Cambiar imagen"
                    autoComplete='photo'
                />

                <Button color='dark' isLight icon='Delete' onClick={deleteImage} title="Eliminar imagen" className="mt-4" />
            </CardBody>
        </Card>
    )
}