import useFilters from "../../../hooks/useFilters";
import { IMenuFilters } from "../../menus/menu-list/menusList";
import MenusTab from "../../../components/menu/MenusTab";
import { FC } from "react";

interface ListPatientMenusProps {
    id: string;
}

const ListPatientMenus: FC<ListPatientMenusProps> = ({ id }) => {
    const menuFilters: IMenuFilters = {
        active: 1,
        client: id,
    };

    const { filters, updateFilters, resetFilters, updateFilterOrder, updatePage, updatePageSize } = useFilters(menuFilters);

    return (
        <MenusTab
            filters={filters}
            updateFilters={updateFilters}
            updateFilterOrder={updateFilterOrder}
            updatePage={updatePage}
            updatePageSize={updatePageSize}
        />
    )
}

export default ListPatientMenus;