import AccessDenied from '../components/AccessDenied';
import { menusMenu, authMenu, appointmentsMenu, formsMenu, tipsMenu, administrationMenu, calendarMenu, generalConfigMenu } from '../menu';
import AlimentsWrapper from '../pages/aliments';
import CreateAliment from '../pages/aliments/aliment-create';
import AlimentEdit from '../pages/aliments/aliment-edit/AlimentEdit';
import AlimentView from '../pages/aliments/aliment-view/alimentView';
import AlimentsList from '../pages/aliments/aliments-list/AlimentsList';
import CompaniesWrapper from '../pages/companies';
import CompaniesList from '../pages/companies/companies-list/CompaniesList';
import CreateCompany from '../pages/companies/company-create';
import CompanyEdit from '../pages/companies/company-edit/CompanyEdit';
import OrganizationProfile from '../pages/companies/company-profile/OrganizationProfile';
import MenusWrapper from '../pages/menus';
import ConfigMenu from '../pages/menus/menu-config/menuConfigCreate';
import ConfigMenuList from '../pages/menus/menu-config/menuConfigList';
import CreateMenu from '../pages/menus/menu-create';
import MenusList from '../pages/menus/menu-list/menusList';
import MenuView from '../pages/menus/menu-view/MenuView';
import PatientsWrapper from '../pages/patients';
import CreatePatient from '../pages/patients/patient-create';
import PatientEdit from '../pages/patients/patient-edit/PatientEdit';
import PatientsList from '../pages/patients/patient-list/PatientsList';
import PatientProfile from '../pages/patients/patient-profile/PatientProfile';
import RecipesWrapper from '../pages/recipes';
import CreateRecipe from '../pages/recipes/recipe-create';
import RecipeEdit from '../pages/recipes/recipe-edit/RecipeEdit';
import RecipesList from '../pages/recipes/recipe-list/RecipesList';
import RecipeView from '../pages/recipes/recipe-view/RecipeView';
import RoleWrapper from '../pages/roles';
import RoleEdit from '../pages/roles/role-edit/RoleEdit';
import RoleList from '../pages/roles/role-list/RoleList';
import UsersWrapper from '../pages/users';
import CreateUser from '../pages/users/user-create';
import UserEdit from '../pages/users/user-edit/UserEdit';
import UserProfile from '../pages/users/user-profile/UserProfile';
import UsersList from '../pages/users/users-list/UsersList';
import FormsWrapper from '../pages/forms';
import ListFormTypes from '../pages/forms/forms-types/ListFormTypes';
import CreateForm from '../pages/forms/create-form/CreateForm';
import { AppointmentCalendarWrapper } from '../pages/appointments/appointment-calendar/AppointmentCalendarWrapper';
import { AppointmentListWrapper } from '../pages/appointments/appointment-list/AppointmentListWrapper';
import AssignTemplate from '../components/menu/AssignTemplate';
import FormsList from '../pages/forms/forms-list/ListForms';
import FormEdit from '../pages/forms/edit-form/FormEdit';
import EditFillValues from '../pages/forms/edit-fill-values/EditFillValues';
import ActivitiesWrapper from '../pages/activities';
import ActivityEdit from '../pages/activities/activity-edit/ActivityEdit';
import ActivityCreate from '../pages/activities/activity-create/ActivityCreate';
import ActivitiesList from '../pages/activities/activities-list/ActivitiesList';
import TipsWrapper from '../pages/tips';
import GroupsList from '../pages/tips/groups-list/ListGroups';
import CreateGroup from '../pages/tips/create-group/CreateGroup';
import EditGroup from '../pages/tips/edit-group/EditGroup';
import TipsList from '../pages/tips/tips-list/ListTips';
import CreateTip from '../pages/tips/create-tip/CreateTip';
import EditTip from '../pages/tips/edit-tip/EditTip';
import GeneralConfigWrapper from '../pages/generalConfig';
import EditConfig from '../pages/generalConfig/config-edit/ConfigEdit';

const protectedRoutes = [

	/* ***************** Organizaciones ***************** */
	{
		path: administrationMenu.companies.path,
		element: <CompaniesWrapper />,
		isProtected: true,
		access: {
			group: 'companies',
			action: 'list'
		},
		sub: [
			{
				element: <CompaniesList />,
				index: true
			},
			{
				path: ':id/profile',
				element: <OrganizationProfile />,
			},
			{
				path: ':id/edit',
				element: <CompanyEdit />,
			},
			{
				path: 'create',
				element: <CreateCompany />,
			}
		]
	},

	/* ******************** Roles ******************** */
	{
		path: administrationMenu.roles.path,
		element: <RoleWrapper />,
		isProtected: true,
		sub: [
			{
				element: <RoleList />,
				index: true
			},
			{
				path: ':id/edit',
				element: <RoleEdit />,
			}
		]
	},

	/* ******************* Usuarios ******************* */
	{
		path: administrationMenu.users.path,
		element: <UsersWrapper />,
		sub: [
			{
				element: <UsersList />,
				index: true,
			},
			{
				path: ':id/profile',
				element: <UserProfile />,
			},
			{
				path: ':id/edit',
				element: <UserEdit />,
			},
			{
				path: 'create',
				element: <CreateUser />,
			}
		]
	},

	/* ************* Configuración General ************* */
	{
		path: generalConfigMenu.generalConfig.path,
		element: <GeneralConfigWrapper />,
		isProtected: true,
		access: {
			group: 'companies',
			action: 'list'
		},
		sub: [
			{
				element: <EditConfig />,
				index: true,
			},
		]
	},

	/* ****************** Pacientes ******************* */
	{
		path: authMenu.patients.path,
		element: <PatientsWrapper />,
		isProtected: true,
		sub: [
			{
				element: <PatientsList />,
				index: true
			},
			{
				path: ':id/edit',
				element: <PatientEdit />,
			},
			{
				path: 'create',
				element: <CreatePatient />,
			},
			{
				path: ':id/view',
				element: <PatientProfile />,
			},
		]
	},

	/* ***************** Calendario ***************** */
	{
		path: calendarMenu.calendar.path,
		element: <AppointmentCalendarWrapper />,
		isProtected: true
	},
	{
		path: appointmentsMenu.appointments_list.path,
		element: <AppointmentListWrapper />,
		isProtected: true
	},

	/* ******************** Menús ******************** */
	{
		path: menusMenu.menus.path,
		element: <MenusWrapper />,
		sub: [
			{
				element: <MenusList />,
				access: {
					group: 'menus',
					action: 'list'
				},
				index: true,
			},
			{
				path: ':id/view',
				access: {
					group: 'menus',
					action: 'list'
				},
				element: <MenuView />,
			},
			{
				path: 'create',
				access: {
					group: 'menus',
					action: 'create'
				},
				element: <CreateMenu />,
			},
			{
				path: ':id/assign-menu',
				access: {
					group: 'menus',
					action: 'create'
				},
				element: <AssignTemplate />,
			},
			{
				path: ':id/create-menu',
				access: {
					group: 'menus',
					action: 'create'
				},
				element: <CreateMenu />,
			},
			{
				path: 'config-list',
				access: {
					group: 'menus',
					action: 'list'
				},
				element: <ConfigMenuList />,
			},
			{
				path: 'config-create',
				access: {
					group: 'menus',
					action: 'create'
				},
				element: <ConfigMenu />,
			},
		]
	},

	/* ***************** Alimentos ***************** */
	{
		path: menusMenu.menus.subMenu.foods.path,
		element: <AlimentsWrapper />,
		isProtected: true,
		sub: [
			{
				element: <AlimentsList />,
				index: true
			},
			{
				path: ':id/edit',
				element: <AlimentEdit />,
			},
			{
				path: 'create',
				element: <CreateAliment />,
			},
			{
				path: ':id/view',
				element: <AlimentView />,
			},
		]
	},

	/* ****************** Recetas ****************** */
	{
		path: menusMenu.menus.subMenu.recipes.path,
		element: <RecipesWrapper />,
		isProtected: true,
		sub: [
			{
				element: <RecipesList />,
				index: true
			},
			{
				path: 'create',
				element: <CreateRecipe />,
			},
			{
				path: ':id/edit',
				element: <RecipeEdit />,
			},
			{
				path: ':id/view',
				element: <RecipeView />,
			},
		]
	},

	/* ***************** A. Físicas ***************** */
	{
		path: menusMenu.menus.subMenu.activities.path,
		element: <ActivitiesWrapper />,
		isProtected: true,
		sub: [
			{

				element: <ActivitiesList />,
				index: true
			},
			{
				path: 'create',
				element: <ActivityCreate />,
			},
			{
				path: ':id/edit',
				element: <ActivityEdit />,
			},
		]
	},

	/* ***************** Formularios ***************** */
	{
		path: formsMenu.forms.path,
		element: <FormsWrapper />,
		isProtected: true,
		sub: [
			{
				path: formsMenu.forms.subMenu.typesList.path,
				element: <ListFormTypes />,
			},
			{
				path: formsMenu.forms.subMenu.formList.path,
				element: <FormsList />,
			},
			{
				path: 'create',
				element: <CreateForm />,
			},
			{
				path: ':id/edit',
				element: <FormEdit />,
			},
			{
				path: ':id/edit-fields',
				element: <EditFillValues routes />,
			}
		]
	},

	/* ****************** Consejos ****************** */
	{
		path: tipsMenu.tips.path,
		element: <TipsWrapper />,
		isProtected: true,
		sub: [
			/* Grupos */
			{
				path: tipsMenu.tips.subMenu.tipsGroups.path,
				element: <GroupsList />,
			},
			{
				path: tipsMenu.tips.subMenu.tipsGroups.path + '/create',
				element: <CreateGroup />,
			},
			{
				path: tipsMenu.tips.subMenu.tipsGroups.path + '/:id/edit',
				element: <EditGroup />,
			},
			/* Consejos */
			{
				path: tipsMenu.tips.subMenu.tipsList.path,
				element: <TipsList />,
			},
			{
				path: tipsMenu.tips.subMenu.tipsList.path + '/create',
				element: <CreateTip />,
			},
			{
				path: tipsMenu.tips.subMenu.tipsList.path + '/:id/edit',
				element: <EditTip />,
			},
		]
	},

];

const contents = [...protectedRoutes];

export default contents;