import { createSlice } from "@reduxjs/toolkit";


export interface ClientState {
    activeTab: string;
}

export const initialState: ClientState = {
    activeTab: "tips",
};

const clientSlice = createSlice({
    name: 'client_profile',
    initialState,
    reducers: {
        changeClientActiveTab: (state, action) => {
            state.activeTab = action.payload;
        },
    },
});

export const { changeClientActiveTab } = clientSlice.actions;

export default clientSlice.reducer;
