import { FC, Fragment, useCallback } from "react";
import { useNavigate, useParams } from "react-router-dom";
import Button from "../../../components/bootstrap/Button";
import Spinner from "../../../components/bootstrap/Spinner";
import Page from "../../../layout/Page/Page";
import SubHeader, { SubHeaderLeft, SubHeaderRight, } from "../../../layout/SubHeader/SubHeader";
import PersonalInfoCard from "./PersonalInfoCard";
import useFetch from "../../../hooks/useFetch";
import { User } from "../../../type/user-type";
import { UserService } from "../../../services/users/userService";
import Card, {  CardTabItem, CardTitle } from "../../../components/bootstrap/Card";
import UserHasPatients from "./UserHasPatients";
import UserHasMenus from "./UserHasMenus";
import moment from "moment";
import Documents from "../../../components/document/Documents";
import { administrationMenu } from "../../../menu";
import ErrorMessage from "../../../components/ErrorMessage";

const UserProfile: FC = () => {
  const { id = "" } = useParams<{ id: string }>();

  const fetchUser = useCallback(async () => {
    const userService = new UserService();
    const response = await userService.getUserById(id as string);
    return response.getResponseData() as User;
  }, []);

  const [data, loading, error] = useFetch(fetchUser);

  const navigate = useNavigate();

  const getContent = () => {
    if (loading) return <div className='text-center'><Spinner /></div>;

    if (error) return <ErrorMessage error={error} />;

    if (data) {
      return (
        <>
          <div className="pt-3 pb-5 d-flex align-items-center">
            <span className="display-6 fw-bold me-3">{data.name + ' ' + (data.lastName ? data.lastName : '')}</span>
            <span className="border border-success border-2 text-success fw-bold px-3 py-2 rounded">
              {data.userRoles[0].role.name || 'Sin rol asignado'}
            </span>
            <span className="text-muted ms-4">Último acceso: {moment(data.lastLogin.date).format('DD/MM/YYYY')}</span>
          </div>
          <div className="row">
            <div className="col-lg-4">
              <PersonalInfoCard
                company={data.userRoles[0].company ? data.userRoles[0].company.name : 'Sin organización asignada'}
                email={data.email}
                dni={data.dni}
                profileImg={data.profileImg?.id}
                phone={data.telephone}
                address={data.address}
              />
            </div>

            <div className="col-lg-8">
              <Card hasTab>
                <CardTabItem id='patients' title='Paciente' icon='Contacts'>
                  <CardTitle>Pacientes asignados</CardTitle>
                  <UserHasPatients id={data.id} />
                </CardTabItem>

                <CardTabItem id='menus' title='Menús nutricionales' icon='Contacts'>
                  <CardTitle className="mb-4">Listado de menús nutricionales</CardTitle>
                  <UserHasMenus id={data.id} />
                </CardTabItem>

                <CardTabItem id='documents' title='Documentos' icon='Article'>
                  <Documents id={id} isPatient={false} />
                </CardTabItem>
              </Card>
            </div>
          </div>
        </>
      );
    }
  };

  return (
    <Fragment>
      <SubHeader>
        <SubHeaderLeft>
          <Button
            color="secondary"
            isLink
            icon="ArrowBack"
            onClick={() => navigate(-1)}
          />
        </SubHeaderLeft>
        <SubHeaderRight>
          <Button
            color="brand"
            icon="Edit"
            onClick={() => navigate(`${administrationMenu.users.path}/${id}/edit`, { replace: true })}
          >
            Editar
          </Button>
        </SubHeaderRight>
      </SubHeader>
      <Page container="xxl">
        {getContent()}
      </Page>
    </Fragment>
  );
};

export default UserProfile;