import { useFormik } from "formik";
import { ChangeEvent, FC, Fragment, useCallback, useState } from "react";
import Button from "../../components/bootstrap/Button";
import Card, { CardBody, CardTitle } from "../../components/bootstrap/Card";
import FormGroup from "../../components/bootstrap/forms/FormGroup";
import Input from "../../components/bootstrap/forms/Input";
import Select from "../../components/bootstrap/forms/Select";
import Spinner from "../../components/bootstrap/Spinner";
import PlaceholderImage from "../../components/extras/PlaceholderImage";
import * as yup from "yup";
import { toast } from "react-toastify";
import { useNavigate, useParams } from "react-router-dom";
import AsyncImg from "../../components/AsyncImg";
import { handleConfirmationAlert } from "../../components/ConfirmationAlert";
import { PatientService } from "../../services/patients/patientService";
import { CompanyService } from "../../services/companies/organizationService";
import { Companies, CompaniesApiResponse } from "../../type/company-type";
import useFetch from "../../hooks/useFetch";
import SubHeader, { SubHeaderLeft, SubHeaderRight, SubheaderSeparator } from "../../layout/SubHeader/SubHeader";
import Page from "../../layout/Page/Page";
import { usePrivilege } from "../../components/priviledge/PriviledgeProvider";
import { ROLES } from "../../utils/RoleMapping";
import { Users, UsersApiResponse } from "../../type/user-type";
import { UserService } from "../../services/users/userService";
import { AlimentsApiResponse, NutrientList } from "../../type/aliments-type";
import { AlimentService } from "../../services/aliments/alimentService";
import Checks from "../../components/bootstrap/forms/Checks";
import SearchableSelect from "../../components/select/SearchableSelect";
import { RecipeService } from "../../services/recipes/recipeService";
import InputPhone, { isValidPhoneNumber } from "react-phone-number-input";
import CustomPasswordInput from "../../components/bootstrap/forms/CustomPasswordInput";
import es from "react-phone-number-input/locale/es.json";
import 'react-phone-number-input/style.css';

export interface CreateFormProps {
    isLoading: boolean;
    submit: Function;
    patientData?: PatientForm;
    profileImg?: any;
}

export interface PatientForm {
    user: string;
    name: string;
    last_name: string;
    email: string;
    dni: string;
    sex: string;
    province: string;
    city: string;
    pc: string;
    address: string;
    telephone: string;
    company: string;
    password: string;
    passwordConfirm: string;
    birthday_date: string;
    clientAversions?: [];
    food_aversions?: string[];
    group_aversions?: string[];
    [key: string]: any;
}

const patientInitialValues: PatientForm = {
    user: '',
    name: '',
    last_name: '',
    email: '',
    dni: '',
    sex: '',
    province: '',
    city: '',
    pc: '',
    address: '',
    telephone: '',
    company: '',
    password: '',
    passwordConfirm: '',
    birthday_date: '',
    clientAversions: [],
    food_aversions: [],
    group_aversions: [],
}

const PatientEditSchema = yup.object({
    user: yup.string(),
    name: yup.string().min(1, 'Demasiado Corto').max(30, 'Demasiado Largo').matches(/^[a-zA-ZáéíóúüÁÉÍÓÚÜñÑ ]+$/u, 'Sólo se permiten caracteres alfabéticos').required('Campo Obligatorio'),
    last_name: yup.string().min(1, 'Demasiado Corto').max(40, 'Demasiado Largo').matches(/^[a-zA-ZáéíóúüÁÉÍÓÚÜñÑ ]+$/u, 'Sólo se permiten caracteres alfabéticos'),
    email: yup.string().email('Correo Inválido').required('Campo Obligatorio'),
    dni: yup.string().matches(/^[0-9]{8}[TRWAGMYFPDXBNJZSQVHLCKE]$/i, 'Formato de DNI incorrecto').required('Campo Obligatorio'),
    sex: yup.string().matches(/^[HM]$/i, 'Formato de sexo incorrecto').required('Campo Obligatorio'),
    province: yup.string().min(1, 'Demasiado Corto').max(30, 'Demasiado Largo').matches(/^[a-zA-ZáéíóúüÁÉÍÓÚÜñÑ ]+$/u, 'Sólo se permiten caracteres alfabéticos'),
    city: yup.string().min(1, 'Demasiado Corto').max(30, 'Demasiado Largo').matches(/^[a-zA-ZáéíóúüÁÉÍÓÚÜñÑ ]+$/u, 'Sólo se permiten caracteres alfabéticos'),
    pc: yup.string().matches(/^\d{5}$/, 'Formato de CP incorrecto'),
    address: yup.string(),
    telephone: yup.string().matches(/^\+34\d{9}$/, 'Debe ser un número de teléfono registrado en España'),
    //company: yup.string().required('Campo Obligatorio'),
    password: yup.string().matches(/^(?=.*[a-z])(?=.*[A-Z])(?=.*\d)[a-zA-Z\d\S]{8,30}$/, 'La contraseña debe tener al menos 8 caracteres, una mayúscula, una minúscula y un número'),
    passwordConfirm: yup.string().oneOf([yup.ref('password'), ''], 'Las contraseñas no coinciden'),
    birthday_date: yup.date().max(new Date(), 'Fecha no válida'),
});

const PatientCreateSchema = PatientEditSchema.concat(
    yup.object({
        password: yup.string().required('La contraseña es obligatoria').matches(/^(?=.*[a-z])(?=.*[A-Z])(?=.*\d)[a-zA-Z\d\S]{8,30}$/, 'La contraseña debe tener al menos 8 caracteres, una mayúscula, una minúscula y un número'),
        passwordConfirm: yup.string().required('Confirmación de contraseña obligatoria').oneOf([yup.ref('password'), ''], 'Las contraseñas no coinciden'),
    })
);

const PatientForm: FC<CreateFormProps> = ({ isLoading, submit, patientData, profileImg }) => {
    const navigate = useNavigate();
    const auth = usePrivilege();
    const { userCan } = auth;
    const { id = '' } = useParams<{ id: string }>();
    const mode = patientData ? 'Editar' : 'Crear';

    // Servicios
    const patientService = new PatientService();
    const companyService = new CompanyService();
    const userService = new UserService();
    const recipeService = new RecipeService();
    const foodService = new AlimentService();

    // Estados
    const [selectedImage, setSelectedImage] = useState<any>(null);
    const [allergensSelected, setAllergensSelected] = useState<string[]>(patientData?.allergens || []);

    // Obtiene las organizaciones
    const [organizations] = useFetch(useCallback(async () => {
        if (!auth.userHasRole(ROLES.SUPER_ADMIN)) return [];
        const response = await companyService.getOrganizations();
        return response.getResponseData() as CompaniesApiResponse;
    }, []));

    // Obtiene los usuarios
    const [users] = useFetch(useCallback(async () => {
        if (!userCan('access_to_all_patients', 'clients')) return;
        const response = await userService.getUsers();
        return response.getResponseData() as UsersApiResponse;
    }, []));

    // Obtiene los alimentos
    const [foodsData] = useFetch(useCallback(async () => {
        const response = await recipeService.getIngredientsByCompany();
        return response.getResponseData() as AlimentsApiResponse;
    }, []));

    // Obtiene los grupos de alimentos
    const [groupsData] = useFetch(useCallback(async () => {
        const response = await foodService.getAlimentGroups();
        return response.getResponseData() as AlimentsApiResponse;
    }, []));

    // Obtiene los alérgenos
    const [allergensList] = useFetch(useCallback(async () => {
        const response = await (await (new AlimentService()).getAllergensList()).getResponseData();
        return response;
    }, []));

    const handleImageUpload = async (event: React.ChangeEvent<any>) => {
        const file = event.target.files[0];
        const reader = new FileReader();
        reader.onload = () => {
            setSelectedImage(reader.result);
        };
        reader.readAsDataURL(file);

        try {
            const response = await patientService.editPatientImg(id, file);
            const responseData = response.getResponseData();

            if (responseData.success) {
                setTimeout(() => {
                    toast.success('Imagen actualizada');
                }, 100);
            }
        } catch (error: any) {
            toast.error("Formato de imagen incorrecto");
        }
    };

    const deleteImage = async () => {
        handleConfirmationAlert({
            title: '¿Estás seguro?',
            text: 'Se eliminará la imagen de perfil',
            icon: 'warning',
            onConfirm: async () => {
                try {
                    const response = await patientService.deletePatientImg(id);
                    const responseData = response.getResponseData();

                    if (responseData.success) {
                        setSelectedImage(null);
                        window.location.reload();
                    }
                } catch (error: any) {
                    toast.error("Error al eliminar la imagen");
                }
            }
        })
    }

    // obtener lista de organizaciones
    const getOrganizationsList = () => {
        if (organizations as Companies) {
            return organizations.companies.map((option: { id: any; name: any; }) => {
                return {
                    value: option.id,
                    label: option.name
                }
            })
        }
        return [];
    }

    // obtener lista de usuarios
    const getUsersList = () => {
        if (users as Users) {
            return users.users.map((option: { id: string; name: string; lastName: string; }) => {
                return {
                    value: option.id,
                    label: option.name + (option.lastName ? (' ' + option.lastName) : '')
                }
            })
        }
        return [];
    }

    // obtener lista de alimentos
    const getFoodsList = () => {
        const aversions = patientData?.clientAversions; // Aversiones del paciente
        const foodAversions = aversions?.filter((aversion: any) => aversion.food !== null); // Aversiones de alimentos
        const aversionsId = foodAversions?.map((aversion: any) => aversion.food!.id); // Ids de los alimentos

        return foodsData?.data?.map((food: any) => {
            const isAversionSelected = aversionsId?.includes(food.id);

            return {
                value: food.id,
                label: food.name,
                isSelected: isAversionSelected
            }
        }) || [];
    }

    // obtener lista de grupos
    const getGroupsList = () => {
        const aversions = patientData?.clientAversions; // Aversiones del paciente
        const groupAversions = aversions?.filter((aversion: any) => aversion.foodGroup !== null); // Aversiones de grupos
        const aversionsId = groupAversions?.map((aversion: any) => aversion.foodGroup!.id); // Ids de los grupos

        return groupsData?.food_groups.map((group: any) => {
            const isAversionSelected = aversionsId?.includes(group.id);

            return {
                value: group.id,
                label: group.name,
                isSelected: isAversionSelected
            }
        }) || [];
    }

    // Actualizar el estado de los alérgenos seleccionados
    const handleAllergensChecks = (name: string) => {
        const isSelected = allergensSelected.includes(name); // Verificar si el checkbox ya está seleccionado
        // Actualizar el estado según el checkbox
        if (isSelected) {
            setAllergensSelected(allergensSelected.filter((allergen) => allergen !== name));
        } else {
            setAllergensSelected([...allergensSelected, name]);
        }

    };

    // Verificar si el alérgeno está seleccionado
    const _alergenChecked = (allergen: any) => {
        return allergensSelected.includes(allergen.name);
    }

    const formik = useFormik({
        initialValues: patientData || patientInitialValues,
        validationSchema: (mode === 'Editar') ? PatientEditSchema : PatientCreateSchema,
        onSubmit: values => {
            values.allergens = allergensSelected; // Asignar los alérgenos seleccionados al objeto

            submit(values);
        }
    });

    const verifyClass = (inputFieldID: keyof PatientForm) => {
        return (formik.touched[inputFieldID] && formik.errors[inputFieldID]) ? 'is-invalid' : '';
    }

    const showErrors = (inputFieldID: keyof PatientForm) => {
        //@ts-ignore
        return (formik.touched[inputFieldID] && formik.errors[inputFieldID]) ? <div className="invalid-feedback">{formik.errors[inputFieldID]}</div> : <></>;
    }

    const getContent = () => {
        return (
            <form onSubmit={formik.handleSubmit} autoComplete="off">
                <div className="row">
                    <div className={"col-md-2"}>
                        {
                            mode === 'Editar' && (
                                <Card stretch={false} className={'mx-2'}>
                                    <CardBody className={'text-center'}>
                                        <div className={'text-center pb-4'}>
                                            {selectedImage
                                                ? <img width={200} height={200} src={selectedImage} alt="selected"
                                                    className='mx-auto d-block img-fluid mb-3 rounded' />
                                                : profileImg
                                                    ? <AsyncImg isBackground height="200px" width="200px" styles="avatar-content rounded" id={profileImg.id} />
                                                    : <PlaceholderImage width={200} height={200} className='mx-auto d-block img-fluid mb-3 rounded' />
                                            }
                                        </div>

                                        <Input
                                            onChange={(e: React.ChangeEvent<any>) => handleImageUpload(e)}
                                            type='file' autoComplete='photo' placeholder={"Cambiar imagen"} className="mb-3"
                                        />

                                        <Button color='dark' isLight icon='Delete' onClick={deleteImage} title="Eliminar">Eliminar</Button>
                                    </CardBody>
                                </Card>
                            )
                        }

                        {
                            (users as Users) && (
                                <Card className="mx-2">
                                    <CardBody>
                                        <CardTitle>Asignar</CardTitle>
                                        {
                                            (auth.userHasRole(ROLES.SUPER_ADMIN)) && (
                                                <FormGroup requiredInputLabel label='Organización'>
                                                    <Select
                                                        id='company'
                                                        onChange={(e: ChangeEvent<HTMLInputElement>) => {
                                                            formik.handleChange(e)
                                                            formik.setFieldValue('company', e.target.value)
                                                        }}
                                                        value={formik.values.company}
                                                        onBlur={formik.handleBlur}
                                                        className={verifyClass('company')}
                                                        ariaLabel='Listado de organizaciones'
                                                        placeholder='Elegir...'
                                                        list={getOrganizationsList()}
                                                    />
                                                    {showErrors('company')}
                                                </FormGroup>
                                            )
                                        }

                                        <FormGroup requiredInputLabel label='Profesional' className='mt-3'>
                                            <Select
                                                id='user'
                                                onChange={formik.handleChange}
                                                value={formik.values.user}
                                                onBlur={formik.handleBlur}
                                                className={verifyClass('user')}
                                                ariaLabel='Listado de profesionales'
                                                placeholder='Elegir...'
                                                list={getUsersList()}
                                            />
                                            {showErrors('user')}
                                        </FormGroup>
                                    </CardBody>
                                </Card>
                            )
                        }
                    </div>


                    <div className="col-md-7">
                        <div>
                            <div className="row mx-1">
                                <Card stretch={false}>
                                    <CardBody>
                                        <CardTitle>Información de la cuenta</CardTitle>

                                        <div className="row">
                                            <div className="col-md-4">
                                                <FormGroup requiredInputLabel label='Nombre'>
                                                    <Input id='name' onChange={formik.handleChange} value={formik.values.name} onBlur={formik.handleBlur} className={verifyClass('name')} />
                                                    {showErrors('name')}
                                                </FormGroup>
                                                <FormGroup label='Apellidos'>
                                                    <Input id='last_name' onChange={formik.handleChange} value={formik.values.last_name} onBlur={formik.handleBlur} className={verifyClass('last_name')} />
                                                    {showErrors('last_name')}
                                                </FormGroup>
                                            </div>

                                            <div className="col-md-5">
                                                <FormGroup requiredInputLabel label='Email' className="col-md-12">
                                                    <Input id='email' type='email' autoComplete="new-email" onChange={formik.handleChange} value={formik.values.email} onBlur={formik.handleBlur} className={verifyClass('email')} />
                                                    {showErrors('email')}
                                                </FormGroup>
                                                <div className="row">
                                                    <FormGroup requiredInputLabel label='DNI' className="col-md-6">
                                                        <Input id='dni' onChange={formik.handleChange} value={formik.values.dni} onBlur={formik.handleBlur} className={verifyClass('dni')} />
                                                        {showErrors('dni')}
                                                    </FormGroup>
                                                    <FormGroup label='Teléfono' className="col-md-6">
                                                        <InputPhone
                                                            id='telephone'
                                                            onBlur={formik.handleBlur}
                                                            labels={es}
                                                            international
                                                            defaultCountry="ES"
                                                            onChange={e => { formik.setFieldValue('telephone', e) }}
                                                            value={formik.values.telephone || ''}
                                                            className={verifyClass('telephone')}
                                                        />
                                                        {showErrors('telephone')}
                                                    </FormGroup>
                                                </div>
                                            </div>

                                            <div className="col-md-3">
                                                <FormGroup requiredInputLabel label='Género'>
                                                    <Select
                                                        name="sex"
                                                        id='sex'
                                                        value={formik.values.sex}
                                                        onChange={formik.handleChange}
                                                        ariaLabel='Selector de género'
                                                        list={[
                                                            { value: 'H', label: 'Hombre', },
                                                            { value: 'M', label: 'Mujer' },
                                                        ]}
                                                        className={verifyClass('sex')}
                                                        placeholder={'H / M'}
                                                    />
                                                    {showErrors('sex')}
                                                </FormGroup>
                                                <FormGroup label='Fecha de nacimiento'>
                                                    <Input id='birthday_date' type='date' onChange={formik.handleChange} value={formik.values.birthday_date} className={verifyClass('birthday_date')} />
                                                    {showErrors('birthday_date')}
                                                </FormGroup>
                                            </div>
                                        </div>

                                        <div className="row mt-5">
                                            <CardTitle>Ubicación</CardTitle>
                                            <FormGroup label='Provincia' className="col-md-3">
                                                <Input id='province' onChange={formik.handleChange} value={formik.values.province} onBlur={formik.handleBlur} className={verifyClass('province')} />
                                                {showErrors('province')}
                                            </FormGroup>
                                            <FormGroup label='Localidad' className="col-md-3">
                                                <Input id='city' onChange={formik.handleChange} value={formik.values.city} onBlur={formik.handleBlur} className={verifyClass('city')} />
                                                {showErrors('city')}
                                            </FormGroup>
                                            <FormGroup label='Dirección' className="col-md-4">
                                                <Input id='address' onChange={formik.handleChange} value={formik.values.address} className={verifyClass('address')} />
                                                {showErrors('address')}
                                            </FormGroup>
                                            <FormGroup label='Código Postal' className="col-md-2">
                                                <Input type='number' id='pc' onChange={formik.handleChange} value={formik.values.pc} onBlur={formik.handleBlur} className={verifyClass('pc')} placeholder="00000" />
                                                {showErrors('pc')}
                                            </FormGroup>
                                        </div>
                                    </CardBody>
                                </Card>
                            </div>

                            <div className="row">
                                <div className='col-md-6'>
                                    <Card stretch={false}>
                                        <CardBody>
                                            <CardTitle>Aversiones e intolerancias</CardTitle>
                                            {foodsData && (
                                                <FormGroup label='Alimentos' className='mt-4'>
                                                    <div className='row g-4'>
                                                        <SearchableSelect
                                                            name="food_aversions"
                                                            isSearchable
                                                            isMulti
                                                            options={getFoodsList()}
                                                            onChange={(e: any) => {
                                                                const foods = e.map((food: any) => food.value);
                                                                formik.setFieldValue('food_aversions', foods);
                                                            }}
                                                            placeholder='alimentos'
                                                            onBlur={formik.handleBlur}
                                                            defaultValue={getFoodsList().filter((option: { isSelected: boolean; }) => option.isSelected)}
                                                        />
                                                    </div>
                                                </FormGroup>
                                            )}

                                            {groupsData && (
                                                <FormGroup label='Grupos' className='mt-3'>
                                                    <div className='row g-4'>
                                                        <SearchableSelect
                                                            name="group_aversions"
                                                            isSearchable
                                                            isMulti
                                                            options={getGroupsList()}
                                                            onChange={(e: any) => {
                                                                const groups = e.map((group: any) => group.value);
                                                                formik.setFieldValue('group_aversions', groups);
                                                            }}
                                                            placeholder='grupos'
                                                            onBlur={formik.handleBlur}
                                                            defaultValue={getGroupsList().filter((option: { isSelected: boolean; }) => option.isSelected)}
                                                        />
                                                    </div>
                                                </FormGroup>
                                            )}
                                        </CardBody>
                                    </Card>
                                </div>

                                <div className='col-md-6'>
                                    <Card stretch={false}>
                                        <CardBody>
                                            {mode === 'Editar'
                                                ? <CardTitle>Modificar contraseña actual</CardTitle>
                                                : <CardTitle>Seguridad</CardTitle>
                                            }

                                            <CustomPasswordInput
                                                formik={formik}
                                                id='password'
                                                value={formik.values.password}
                                                label="Contraseña"
                                            />

                                            <CustomPasswordInput
                                                formik={formik}
                                                id='passwordConfirm'
                                                value={formik.values.passwordConfirm}
                                                label="Confirmar Contraseña"
                                            />
                                        </CardBody>
                                    </Card>
                                </div>
                            </div>
                        </div>
                    </div>

                    <div className="col-md-3">
                        <Card stretch={false} className='mx-2'>
                            <CardBody>
                                <div className="row">
                                    <CardTitle>Alergias</CardTitle>
                                    <span className={'form-label'}>Selecciona las alergias que posea:</span>
                                    {
                                        allergensList?.map((allergen: NutrientList, index: number) => (
                                            <FormGroup key={`allergen-${index}`}>
                                                <Checks
                                                    id={`check-${allergen.name.toLowerCase()}`}
                                                    key={index}
                                                    type={'switch'}
                                                    checked={_alergenChecked(allergen)}
                                                    onChange={(e: any) => {
                                                        handleAllergensChecks(allergen.name)
                                                        //formik.setFieldValue(allergen.name, !allergensSelected.includes(allergen.name))
                                                        //formik.setFieldValue('allergens', allergensSelected)
                                                        formik.handleChange(e)
                                                    }}
                                                    name={allergen.name}
                                                    label={(
                                                        <div className="ps-3">
                                                            <img key={allergen.name} className="d-inline-block" src={`/allergens/${allergen.name}.png`} alt={allergen.name} width={35} data-bs-toggle="tooltip" title={allergen.name} />
                                                            <span className="form-label ps-3 capitalize">{allergen.nombre}</span>
                                                        </div>
                                                    )}
                                                    className="mt-3 col-6"
                                                />
                                            </FormGroup>
                                        ))
                                    }
                                </div>
                            </CardBody>
                        </Card>
                    </div>
                </div>
            </form>
        )
    }

    return (
        <Fragment>
            <SubHeader>
                <SubHeaderLeft>
                    <Button color='secondary' isLink icon='ArrowBack' onClick={() => navigate(-1)} />
                    <SubheaderSeparator />
                    <CardTitle>{mode} Paciente</CardTitle>
                </SubHeaderLeft>
                <SubHeaderRight>
                    <Button type="submit" color='primary' onClick={formik.handleSubmit}>
                        {isLoading ? <Spinner /> : `${mode} Paciente`}
                    </Button>
                </SubHeaderRight>
            </SubHeader>
            <Page container='fluid'>
                {(mode === "Editar" && !patientData) && <Spinner />}
                {(mode === "Editar" && patientData) && getContent()}
                {mode === "Crear" && getContent()}
            </Page>
        </Fragment>
    )
}

export default PatientForm;