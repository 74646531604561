import { FC, Fragment, useCallback } from "react";
import { useNavigate, useParams } from "react-router-dom";
import Button from "../../../components/bootstrap/Button";
import Spinner from "../../../components/bootstrap/Spinner";
import Page from "../../../layout/Page/Page";
import SubHeader, { SubHeaderLeft, SubHeaderRight } from "../../../layout/SubHeader/SubHeader";
import useFetch from "../../../hooks/useFetch";
import { User } from "../../../type/user-type";
import { PatientService } from "../../../services/patients/patientService";
import CardTab from "./CardTab";
import ErrorMessage from "../../../components/ErrorMessage";
import { authMenu } from "../../../menu";

const PatientProfile: FC = () => {
    const navigate = useNavigate();
    const { id = "" } = useParams<{ id: string }>();

    const fetchPatient = useCallback(async () => {
        const patientService = new PatientService();
        const response = await patientService.getPatientById(id as string);
        return response.getResponseData() as User;
    }, []);

    const [patientData, loading, error] = useFetch(fetchPatient);

    const getContent = () => {
        if (loading) return <div className="d-flex justify-content-center"><Spinner /></div>;

        if (error) return <ErrorMessage />;

        if (patientData) {
            return (
                <Fragment>
                    <div className="row">
                        <CardTab
                            name={patientData.name}
                            last_name={patientData.last_name}
                            profileImg={patientData.profileImg?.id}
                            email={patientData.email}
                            phone={patientData.telephone}
                            address={patientData.address}
                            company={patientData.clientRoles[0].company.id}
                            birthday={patientData.birthdayDate ? patientData.birthdayDate.date : ""}
                        />
                    </div>
                </Fragment>
            );
        }
    };

    return (
        <Fragment>
            <SubHeader>
                <SubHeaderLeft>
                    <Button
                        color="secondary"
                        isLink
                        icon="ArrowBack"
                        onClick={() => navigate(-1)}
                    />
                </SubHeaderLeft>
                <SubHeaderRight>
                    <Button
                        color="brand"
                        icon="Edit"
                        onClick={() => navigate(`${authMenu.patients.path}/${id}/edit`, { replace: true })}
                    >
                        Editar
                    </Button>
                </SubHeaderRight>
            </SubHeader>
            <Page container="xxl">
                <Fragment>{getContent()}</Fragment>
            </Page>
        </Fragment>
    );
};

export default PatientProfile;