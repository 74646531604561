import { FC } from "react";
import { useFormik } from "formik";
import * as Yup from 'yup';
import { toast } from "react-toastify";
import { FormService } from "../../../services/forms/formService";
import { MenusApiResponse } from "../../../type/clientMenus-type";
import Modal, { ModalBody, ModalHeader, ModalTitle } from "../../../components/bootstrap/Modal";
import { CardBody, CardFooter } from "../../../components/bootstrap/Card";
import Input from "../../../components/bootstrap/forms/Input";
import FormGroup from "../../../components/bootstrap/forms/FormGroup";
import 'react-toastify/dist/ReactToastify.css';
import '../../../styles/styles.scss';

interface CreateTemplateModalProps {
    isOpen: boolean;
    setIsOpen(...args: unknown[]): unknown;
    parentId?: string;
    label?: string;
    currentValue?: string;
    refetch(): void;
}

const CreateTypeModal: FC<CreateTemplateModalProps> = ({ isOpen, setIsOpen, parentId, label, currentValue, refetch }) => {
    const formService = new FormService();

    const handleCreateTemplate = async (values: any) => {
        try {
            const response = await formService.createFormType(values);
            const responseData = response.getResponseData() as MenusApiResponse;

            if (responseData.success) {
                setIsOpen(false);
                refetch();
                toast.success('Tipo creado correctamente');
            } else {
                toast.error(responseData.message || "Error al crear el tipo");
            }
        } catch (error: any) {
            toast.error(error.message);
        }
    }

    const formik = useFormik({
        initialValues: {
            name: currentValue,
        },
        validationSchema: Yup.object({
            name: Yup.string().required('El nombre es obligatorio')
        }),
        onSubmit: (values) => { handleCreateTemplate(values) }
    });

    const getContent = () => {
        return (
            <form onSubmit={formik.handleSubmit} autoComplete="off">
                <CardBody>
                    <FormGroup label='Introduzca el nombre' requiredInputLabel>
                        <Input
                            id="name"
                            name="name"
                            type="text"
                            className="form-control"
                            onChange={formik.handleChange}
                            onBlur={formik.handleBlur}
                            value={formik.values.name}
                        />
                    </FormGroup>

                    {formik.touched.name && formik.errors.name ? (
                        <div className="text-danger">{formik.errors.name}</div>
                    ) : null}
                </CardBody>
                <CardFooter className="d-flex justify-content-end">
                    <button type="submit" className="btn btn-primary">Crear</button>
                </CardFooter>
            </form>
        );
    }

    return (
        <Modal isOpen={isOpen} setIsOpen={setIsOpen} size='md' titleId='Datos del paciente'>
            <ModalHeader setIsOpen={setIsOpen} className='p-4'>
                <ModalTitle id='patient-data'>Crear {label}</ModalTitle>
            </ModalHeader>
            <ModalBody className='px-4'>
                {getContent()}
            </ModalBody>
        </Modal>
    )
}

export default CreateTypeModal;