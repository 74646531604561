
import { Fragment } from "react";
import { AppointmentCalendarProvider } from "../providers/AppointmentCalendarProvider";
import { ToastContainer } from "react-toastify";
import { AppointmentList } from "./appointment-list";
import { FilterOptions } from "../../../hooks/useFilters";

type AppointmentListWrapperProps = {}

export const AppointmentListWrapper: React.FC<AppointmentListWrapperProps> = () => {
    return (
        <Fragment>
            <ToastContainer />
            <AppointmentCalendarProvider defaultFilters={{} as FilterOptions} defaultOrders={[]} defaultPage={1} defaultPageSize={50}>
                <AppointmentList />
            </AppointmentCalendarProvider>
        </Fragment>
    );
}