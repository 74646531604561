import { createSlice } from "@reduxjs/toolkit";
import moment from "moment";

export interface CalendarState {
    viewMode: "month" | "week" | "work_week" | "day" | "agenda";
    date: string;
}

export const initialState: CalendarState = {
    viewMode: 'month',
    date: moment().format('YYYY-MM-DD'),
};

const calendarSlice = createSlice({
    name: 'calendar',
    initialState,
    reducers: {
        changeStorageViewMode: (state, action) => {
            state.viewMode = action.payload;
        },
        changeStorageDate: (state, action) => {
            state.date = action.payload;
        }
    },
});

export const { changeStorageViewMode, changeStorageDate } = calendarSlice.actions;

export default calendarSlice.reducer;
