import { RestServiceConnection } from "../restServiceConnection";

export class ProfileService extends RestServiceConnection {

    getMyProfile = async () => {
        this.response = await this.makeRequest({
            method: "GET",
            url: "/profile/me",
        }, true);
        return this;
    }
}
