import React, { FC, Fragment, useState } from 'react'
import Button from '../../../../components/bootstrap/Button'
import FormGroup from '../../../../components/bootstrap/forms/FormGroup'
import OffCanvas, { OffCanvasBody, OffCanvasHeader, OffCanvasTitle } from '../../../../components/bootstrap/OffCanvas'
import Icon from '../../../../components/icon/Icon'
import Select from '../../../../components/bootstrap/forms/Select'
import CustomSearchInput from '../../../../components/bootstrap/forms/CustomSearchInput'
interface IUsersFiltersProps {
    updateFilters: (filters: any) => void
    resetFilters: () => void
    filters: any
}

const CompaniesFilters: FC<IUsersFiltersProps> = ({ updateFilters, filters, resetFilters }) => {
    const [filterMenu, setFilterMenu] = useState(false);

    const _handleTextChange = (search: string) => {
        updateFilters({ search_array: search });
    };

    return (
        <Fragment>
            <label className='border-0 bg-transparent cursor-pointer' htmlFor='searchInput'>
                <Icon icon='Search' size='2x' color='secondary' />
            </label>

            <CustomSearchInput onSearch={_handleTextChange} placeholder='Buscar...' />

            <Button color='secondary' isLight icon='Filter' onClick={() => {
                setFilterMenu(true)
            }}>
                Filtros
            </Button>

            <OffCanvas setOpen={setFilterMenu} isOpen={filterMenu} titleId='patientFilters' isBodyScroll placement='end'>
                <OffCanvasHeader setOpen={setFilterMenu}>
                    <OffCanvasTitle id='patientFilters'> Filtros de Organización </OffCanvasTitle>
                    <Button
                        color="storybook"
                        isLight
                        onClick={() => resetFilters()}
                    >
                        Resetear
                    </Button>
                </OffCanvasHeader>
                <OffCanvasBody>
                    <div className='row g-4'>
                        <div className='col-12'>
                            <FormGroup id='filter1' label='Estado'>
                                <Select
                                    id='active-select'
                                    list={[
                                        { value: 3, label: 'Todos', text: 'Todos' },
                                        { value: 1, label: 'Activo', text: 'Activo' },
                                        { value: 0, label: 'Desactivado', text: 'Desactivado' }
                                    ]}
                                    onChange={(e: React.ChangeEvent<HTMLInputElement>) => updateFilters({ active: parseInt(e.target.value) })}
                                    value={filters.filter_filters.active.toString()}
                                    ariaLabel='Selector de estado'
                                    placeholder='Estado...'
                                />
                            </FormGroup>
                        </div>
                    </div>
                </OffCanvasBody>
            </OffCanvas>
        </Fragment>
    )
}

export default CompaniesFilters