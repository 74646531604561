import { useEffect, useState } from "react"
import SearchableSelect from "./SearchableSelect"
import { showErrors } from "../../utils/Forms/FormikVerifyClass"
import { APPOINTMENT_SERVICES_MAP } from "../../type/appointment-type"

type AppointmentServiceSelectorProps = {
    name: string,
    onChange: (serviceType: any) => void,
    formik: any,
    selectedId: string | undefined
}

export const AppointmentServiceSelector: React.FC<AppointmentServiceSelectorProps> = ({ name, onChange, selectedId, formik }) => {

    const defaultValue = { value: '', label: 'Servicio por defecto' };

    const [selected, setSelected] = useState<any | null>(null);

    const serviceOptions = Object.values(APPOINTMENT_SERVICES_MAP).map((service) => ({
        value: service.name,
        label: service.label,
    }));

    useEffect(() => {
        if (serviceOptions === undefined) return;
        if (selectedId === undefined) return;
        if (selectedId === null) return;
        const selectedItem = serviceOptions.find((c: any) => c.name === selectedId);
        if (selectedItem === undefined) return;
        setSelected({
            value: selectedItem.value,
            label: `${selectedItem.label}`,
        })
    }, [selectedId]);

    useEffect(() => {
        if (selected === undefined) return;
        if (selected === null) return;
        onChange(selected.value);
    }, [selected]);

    return (
        <>
            <SearchableSelect
                name={name}
                isSearchable
                isClearable
                onBlur={formik.handleBlur}
                placeholder='servicio'
                value={selected}
                defaultValue={defaultValue}
                onChange={(newValue: any) => {
                    if (newValue === null) {
                        setSelected(defaultValue);
                        formik.setFieldValue(name, null);
                        return;
                    }
                    setSelected(newValue)
                    formik.setFieldValue('serviceType', newValue.value);
                }}
                options={serviceOptions}
            />
            {showErrors(formik, 'serviceType')}
        </>
    )
}