import { FC, useCallback, useState } from "react";
import Spinner from "../../../components/bootstrap/Spinner";
import { useNavigate, useParams } from "react-router-dom";
import { FormService } from "../../../services/forms/formService";
import useFetch from "../../../hooks/useFetch";
import { toast } from "react-toastify";
import ErrorMessage from "../../../components/ErrorMessage";
import FormFill from "./FormFill";

const FormEdit: FC = () => {
    const navigate = useNavigate();
    const { id = '' } = useParams<{ id: string }>();
    const formService = new FormService();

    const [errors, setErrors] = useState<boolean>(false);

    const [data, loading, error, refetchForm] = useFetch(useCallback(async () => {
        const response = (await formService.getFormById(id));
        return response.getResponseData();
    }, [id]));

    const _handleUpdateFields = async (values: any) => {
        try {
            const response = await formService.editFields(values);
            const responseData = response.getResponseData();

            if (responseData.success && !errors) {
                navigate(-1);
                setTimeout(() => {
                    toast.success('Formulario editado correctamente');
                }, 100);
            } else {
                toast.error(responseData.message || "Error al editar el formulario");
            }
        } catch (error: any) {
            toast.error(error.message);
        }
    };

    const _handleUpdateName = async (values: any) => {
        try {
            const response = await formService.editForm(values);
            const responseData = response.getResponseData();

            if (!responseData.success) {
                toast.error(responseData.message || "Error al editar el nombre");
                setErrors(true);
            }
        } catch (error: any) {
            toast.error(error.message);
        }
    };

    const _handleDeleteField = async (values: string) => {
        try {
            const response = await (await (new FormService()).deleteField(values)).getResponseData();
            if (response.success) {
                refetchForm();
            } else {
                toast.error(response.message || 'Error al eliminar el campo');
            }
        } catch (error: any) {
            toast.error(error.message || 'Error al eliminar el campo');
        }
    };

    const getContent = () => {
        if (loading) return <div className="text-center"><Spinner /></div>;

        if (error) return <ErrorMessage message={error.message} />;

        if (data !== null) {
            const formData = {
                name: data.name !== null ? data.name : "",
                fields: data.formFields !== null ? data.formFields : [],
            };

            return (
                <FormFill
                    isLoading={false}
                    submitFields={_handleUpdateFields}
                    submitName={_handleUpdateName}
                    deleteField={_handleDeleteField}
                    formData={formData}
                    refetch={refetchForm}
                />
            );
        }
    };

    return <>{getContent()}</>;
};

export default FormEdit;