import axios, { AxiosRequestConfig, AxiosResponse } from "axios";
import { User, logout } from "../redux/authSlice";
import { removeUserFromLocalStorage } from "../utils/jwt";
import store from './../redux/store';
import { Appointment, AppointmentFormData } from "../type/appointment-type";
import moment from "moment";
import axiosConfig from "./axiosConfig";

export class RestServiceConnection {
    baseUrl: string | undefined;
    response: AxiosResponse<any> | null = null;
    apiPublicUrl: string | undefined;

    constructor() {
        this.baseUrl = process.env.REACT_APP_API_URL;
        this.response = null;
        this.apiPublicUrl = process.env.REACT_APP_API_PUBLIC_URL;
    }

    async makeRequest(config: AxiosRequestConfig, isAuth: boolean = false) {

        if (config.url !== undefined) {
            config.url = this.baseUrl + config.url;
        }

        if (config.headers === undefined) {
            config.headers = {
                'Content-Type': 'application/json',
            }
        }

        if (isAuth) {
            let storageData = store.getState().auth.user;
            if (storageData) {
                let user = storageData as User;
                if (user && user.token)
                    Object.assign(config.headers, {
                        'Authorization': `Bearer ${user.token}`
                    })
            }
        }

        try {
            this.response = await axiosConfig(config);
        } catch (error: any) {
            if (error.response?.status === 401 && (error.response?.data?.message === "Expired JWT Token")) {
                removeUserFromLocalStorage();
                store.dispatch(logout());
                window.location.reload();
            }
        }

        return this.response;
    }

    getResponse() {
        return this.response;
    }

    getResponseData() {
        return this.response?.data;
    }

    getOnlyData() {
        return this.response?.data?.data;
    }

    transformAppointmentToAppointmentFormData(appointment: Appointment): AppointmentFormData {
        return {
            id: appointment.id,
            client: appointment.client?.id,
            user: appointment.user?.id,
            name: appointment.name,
            start: moment(appointment.startDate?.date),
            end: moment(appointment.endDate?.date),
            color: appointment.appointmentColor || undefined,
            comments: appointment.comments ? appointment.comments : undefined,
            status: appointment.status,
            serviceType: appointment.serviceType ? appointment.serviceType : undefined,
            type: appointment.type,
            appointment_color: appointment.appointmentColor ? appointment.appointmentColor : undefined,
            paymentMethod: appointment.paymentMethod ? appointment.paymentMethod : undefined,
            price: appointment.price ? appointment.price : undefined,
        }
    }
}