import { FC, Fragment, useCallback, useState } from 'react'
import Icon from '../../../../components/icon/Icon'
import Button from '../../../../components/bootstrap/Button'
import OffCanvas, { OffCanvasBody, OffCanvasHeader, OffCanvasTitle } from '../../../../components/bootstrap/OffCanvas'
import FormGroup from '../../../../components/bootstrap/forms/FormGroup'
import Spinner from '../../../../components/bootstrap/Spinner'
import { AlimentService } from '../../../../services/aliments/alimentService'
import useFetch from '../../../../hooks/useFetch'
import Select from '../../../../components/bootstrap/forms/Select'
import SearchableSelect from '../../../../components/select/SearchableSelect'
import { monthsList } from '../../../../utils/FoodUtils'
import CustomSearchInput from '../../../../components/bootstrap/forms/CustomSearchInput'
import { useFormik } from 'formik'

interface IFoosFiltersProps {
    updateFilters: (filters: any) => void;
    updatePageSize: (pageSize: number) => void;
    resetFilters: () => void;
    filters: any;
}

const AlimentsFilters: FC<IFoosFiltersProps> = ({ updateFilters, updatePageSize, filters, resetFilters }) => {

    const foodService = new AlimentService();

    const [filterMenu, setFilterMenu] = useState(false);
    const [groupsSelected, setGroupsSelected] = useState<any>([]);
    const [allergensSelected, setAllergensSelected] = useState<any>([]);

    // buscar los grupos de alimentos
    const [groups] = useFetch(useCallback(async () => {
        const response = await foodService.getAlimentGroups();
        return response.getResponseData();
    }, []));

    // buscar los alérgenos
    const [allergens] = useFetch(useCallback(async () => {
        const response = await foodService.getAllergensList();
        return response.getResponseData();
    }, []));

    // controlar el cambio en el selector de grupos
    const handleGroupsChange = (e: any) => {
        setGroupsSelected(e);
        updateFilters({ group_food: e.map((group: any) => group.value) });
    }

    // controlar el cambio en el selector de alérgenos
    const handleAllergensChange = (e: any) => {
        setAllergensSelected(e);
        updateFilters({ allergens: e.map((allergen: any) => allergen.value) });
    }

    // obtener la lista de grupos de alimentos
    const getGroupsOptions = () => {
        if (groups) {
            return groups.food_groups.map((option: { id: any; name: any; }) => {
                return {
                    value: option.id,
                    label: option.name
                }
            })
        }
        return [];
    }

    // obtener la lista de alérgenos
    const getAllergensOptions = () => {
        if (allergens) {
            return allergens.map((option: { id: any; name: any; nombre: string }) => {
                return {
                    value: option.name,
                    label: option.nombre
                }
            })
        }
        return [];
    }

    const initialValues = {
        group_food: undefined,
        allergens: undefined,
        season: undefined,
        seasonend: undefined,
    }

    const formik = useFormik({
        initialValues,
        onSubmit: (values: any) => {
            updateFilters({
                ...values,
                search_array: values.search
            })
        }
    })

    return (
        <Fragment>
            <label className='border-0 bg-transparent cursor-pointer' htmlFor='searchInput'>
                <Icon icon='Search' size='2x' color='secondary' />
            </label>
            <CustomSearchInput
                onSearch={(search: string) => {
                    updateFilters({ search_array: search });
                    formik.setFieldValue('search', search);
                }}
                placeholder='Buscar...'
            />

            <Button color='secondary' isLight icon='Filter' onClick={() => {
                setFilterMenu(true)
            }}>
                Filtros
            </Button>

            <OffCanvas setOpen={setFilterMenu} isOpen={filterMenu} titleId='foodsFilters' isBodyScroll placement='end'>
                <OffCanvasHeader setOpen={setFilterMenu}>
                    <OffCanvasTitle id='foodsFilters'> Filtros de Alimentos </OffCanvasTitle>
                    <Button
                        color="storybook"
                        isLight
                        onClick={() => {
                            resetFilters()
                            setGroupsSelected([])
                            setAllergensSelected([])
                        }}
                    >
                        Resetear
                    </Button>
                </OffCanvasHeader>

                <OffCanvasBody>
                    <div className='row g-4'>
                        <div className='col-12'>
                            <FormGroup id='filter1' label='Grupos de alimentos'>
                                {
                                    groups ? (
                                        <SearchableSelect
                                            isMulti
                                            onChange={(e: any) => handleGroupsChange(e)}
                                            value={groupsSelected}
                                            name='groups_filter'
                                            placeholder='grupos'
                                            options={getGroupsOptions()}
                                        />
                                    ) : (
                                        <Spinner isSmall className='ms-3' />
                                    )
                                }
                            </FormGroup>

                            <FormGroup id='filter2' label='Alérgenos (alimentos que no los contengan)' className='mt-4'>
                                {
                                    allergens ? (
                                        <SearchableSelect
                                            isMulti
                                            onChange={(e: any) => handleAllergensChange(e)}
                                            value={allergensSelected}
                                            name='allergen_filter'
                                            placeholder='alérgenos'
                                            options={getAllergensOptions()}
                                        />
                                    ) : (
                                        <Spinner isSmall className='ms-3' />
                                    )
                                }
                            </FormGroup>

                            <FormGroup id='filter3' label='Temporalidad' className='mt-4'>
                                <div className='row'>
                                    <div className='col-6'>
                                        <div className='row'>
                                            <div className='col-3 d-flex flex-column align-content-center'><p className='m-0 d-flex align-content-center' style={{ height: '100%' }}>Inicio: </p></div>
                                            <div className='col-9'>
                                                <Select
                                                    onChange={(e: React.ChangeEvent<HTMLInputElement>) => updateFilters({ season: e.target.value })}
                                                    value={filters.filter_filters.season}
                                                    id='season-select'
                                                    list={monthsList}
                                                    ariaLabel={'Inicio de temporada'}
                                                />
                                            </div>
                                        </div>
                                    </div>
                                    <div className='col-6'>
                                        <div className='row d-flex justify-content-end'>
                                            <div className='col-2 d-flex flex-column align-content-center'><p className='m-0 d-flex align-content-center' style={{ height: '100%' }}>Fin: </p></div>
                                            <div className='col-9'>
                                                <Select
                                                    onChange={(e: React.ChangeEvent<HTMLInputElement>) => updateFilters({ seasonend: e.target.value })}
                                                    value={filters.filter_filters.seasonend}
                                                    id='seasonend-select'
                                                    list={monthsList}
                                                    ariaLabel={'Fin de temporada'}
                                                />

                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </FormGroup>
                        </div>
                    </div>
                </OffCanvasBody>
            </OffCanvas>
        </Fragment>
    )
}

export default AlimentsFilters;