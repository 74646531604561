import { FC, useState } from "react";
import { useNavigate } from "react-router-dom";
import { toast } from 'react-toastify';
import { RecipeService } from "../../../services/recipes/recipeService";
import RecipeForm from "../RecipeForm";
import 'react-toastify/dist/ReactToastify.css';

const CreateRecipe: FC = () => {
    const navigate = useNavigate();
    const recipeService = new RecipeService();

    const [loading, setLoading] = useState<boolean>(false);

    const handleCreateRecipe = async (values: any) => {
        setLoading(true);

        if (values.foodGroup === '') values.foodGroup = null;

        // Capitalize first letter of name and process
        values.name = values.name.charAt(0).toUpperCase() + values.name.slice(1);
        values.process = values.process.map((process: string) => process.charAt(0).toUpperCase() + process.slice(1));

        try {
            const response = (await recipeService.createRecipe(values)).getResponseData();

            if (response.success) {
                handleSaveImage(response.data, values.image);
                navigate(-1);
                setTimeout(() => {
                    toast.success('Receta creada correctamente');
                }, 100);
            } else {
                response.data.errors.forEach((error: any) => {
                    toast.error(error.message);
                });
            }
        } catch (error: any) {
            toast.error(error.message || 'Error al crear la receta');
        } finally {
            setLoading(false);
        }
    }

    const handleSaveImage = async (recipeId: string, image: any) => {
        try {
            const response = await recipeService.editRecipeImg(recipeId, image);
            /* const responseData = response.getResponseData();

            if (responseData.success) {
                setTimeout(() => {
                    toast.success('Imagen actualizada');
                }, 100);
            } */
        } catch (error: any) {
            toast.error("Formato de imagen incorrecto");
        }
    }

    return <RecipeForm submit={handleCreateRecipe} isLoading={loading} />;
}

export default CreateRecipe;