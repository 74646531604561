import { Fragment } from "react"
import { Outlet } from "react-router-dom"
import { ToastContainer } from "react-toastify"

const PatientsWrapper = () => {
    return (
        <Fragment>
            <ToastContainer />
            <Outlet />
        </Fragment>
    )
}

export default PatientsWrapper;