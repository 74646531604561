import { useFormik } from "formik";
import { FC, Fragment, useCallback } from "react";
import Button from "../../components/bootstrap/Button";
import { CardTitle } from "../../components/bootstrap/Card";
import Input from "../../components/bootstrap/forms/Input";
import { toast } from "react-toastify";
import useFetch from "../../hooks/useFetch";
import { PatientService } from "../../services/patients/patientService";
import Spinner from "../../components/bootstrap/Spinner";
import ErrorMessage from "../../components/ErrorMessage";
import { CustomTable } from "../../components/table/CustomTable";
import useFilters from "../../hooks/useFilters";
import { handleConfirmationAlert } from "../../components/ConfirmationAlert";
import moment from "moment";
import { UserService } from "../../services/users/userService";

interface DocumentsProps {
    id: string;
    isPatient: boolean;
}

interface IPatientFilters {
    client: string;
}

interface IUserFilters {
    user: string;
}

const Documents: FC<DocumentsProps> = ({ id, isPatient }) => {

    const userService = new UserService();
    const patientService = new PatientService();
    const service = isPatient ? patientService : userService;

    moment.locale('es-ES');

    const patientFilters: IPatientFilters = {
        client: id,
    };

    const userFilters: IUserFilters = {
        user: id,
    };

    const documentsFilters = isPatient ? patientFilters : userFilters;

    const { filters, updateFilters, resetFilters, updateFilterOrder, updatePage, updatePageSize } = useFilters(documentsFilters);

    const [documents, loading, error, refetch] = useFetch(useCallback(async () => {
        const response = await service.listDocuments(filters);
        return response.getResponseData();
    }, [filters]));

    const _handleDelete = async (documentId: string) => {
        handleConfirmationAlert({
            title: "Eliminar documento",
            text: "¿Está seguro que desea eliminar el documento?",
            icon: "warning",
            onConfirm: async () => {
                try {
                    const response = (await service.deleteDocument(documentId)).getResponseData();
                    if (response.success) {
                        refetch();
                        toast.success('Documento eliminado correctamente');
                    } else {
                        toast.error(response.message || "Error al eliminar el documento");
                    }
                } catch (error: any) {
                    toast.error(error.message);
                }
            }
        });
    };

    const uploadDocument = async (values: any) => {
        const formData = new FormData();
        formData.append("file", values.document);
        isPatient ? formData.append("client", values.id) : formData.append("user", values.id);

        try {
            const response = (await service.uploadDocument(formData)).getResponseData();

            if (response.success) {
                refetch();
                formik.resetForm();
                toast.success(response.message);
            } else {
                toast.error(response.message);
            }

        } catch (error: any) {
            toast.error(error.message);
        }
    };

    const downloadDocument = async (documentId: string, documentName: string) => {
        try {
            const response = (await service.downloadDocument(documentId));
            if (response) {
                const fileData = response.getResponseData();
                const blob = new Blob([fileData]);
                const url = window.URL.createObjectURL(blob);

                const link = document.createElement('a');
                link.href = url;
                link.setAttribute('download', documentName);
                document.body.appendChild(link);
                link.click();
                document.body.removeChild(link);
                window.URL.revokeObjectURL(url);
            } else {
                toast.error('Error al descargar el documento');
            }
        } catch (error: any) {
            toast.error(error.message);
        }
    };

    const formik = useFormik({
        initialValues: {
            document: "",
            id: id,
        },
        onSubmit: (values) => uploadDocument(values),
    });

    const getContent = () => {
        if (loading) return <div className="text-center"><Spinner /></div>;

        if (error) return <ErrorMessage message={error.message} />;

        if (documents && documents.data.length > 0) {
            let apiData = documents.data;
            return (
                <CustomTable
                    data={apiData ? apiData : null}
                    pagination={true}
                    paginationData={{
                        pageSize: filters.limit,
                        currentPage: filters.page,
                        pageCount: apiData.lastPage ? apiData.lastPage : 1,
                        handlePagination: (page: any) => {
                            updatePage({ selected: page.selected + 1 });
                        },
                        handlePerPage: updatePageSize,
                    }}
                    className="table-striped table-hover mt-5"
                    columns={[
                        {
                            name: "Nombre del documento",
                            keyValue: "name",
                            sortable: true,
                            sortColumn: updateFilterOrder,
                            render: (element: any) => {
                                return (
                                    <div
                                        onClick={() => { downloadDocument(element.document.id, element.document.originalName) }}
                                        className="cursor-pointer text-secondary"
                                    >
                                        {element.document.originalName}
                                    </div>
                                )
                            },
                        },
                        {
                            name: "Fecha de subida",
                            keyValue: "createdAt",
                            sortable: true,
                            sortColumn: updateFilterOrder,
                            render: (element: any) => {
                                return (
                                    <div>
                                        {moment(element.document.createdAt.date).format('DD/MM/YYYY')}
                                    </div>
                                )
                            }
                        },
                        { name: "Acciones", className: "min-w-100px text-end", isActionCell: true }
                    ]}
                    actions={
                        [
                            {
                                title: "Descargar",
                                icon: "Download",
                                buttonType: 'icon',
                                additionalClasses: 'text-primary',
                                description: "Descargar documento",
                                click: (item: any) => { downloadDocument(item.document.id, item.document.originalName) },
                            },
                            {
                                title: "Eliminar",
                                icon: "Delete",
                                buttonType: 'icon',
                                additionalClasses: 'text-danger',
                                description: "Eliminar documento",
                                click: (item: any) => { _handleDelete(item.document.id); },
                            },
                        ]}
                />
            );
        } else {
            return (
                <div className="text-center">
                    <h5 className="text-muted mt-5">No se ha encontrado ningún documento</h5>
                </div>
            );
        }
    }

    return (
        <Fragment>
            <CardTitle className="mb-4">Documentos</CardTitle>
            <form onSubmit={formik.handleSubmit} autoComplete="off">
                <div className="row d-flex justify-content-center">
                    <div className="col-5">
                        <Input
                            name="file"
                            type="file"
                            title="Selecciona un archivo"
                            onChange={(e: React.ChangeEvent<any>) => formik.setFieldValue("document", e.currentTarget.files[0])}
                        />
                    </div>

                    <div className="col-1">
                        <Button
                            type="submit"
                            title="Subir documento"
                            color="secondary"
                            isLight
                            icon="UploadFile"
                        />
                    </div>
                </div>
            </form>

            {getContent()}
        </Fragment>
    );
};

export default Documents;