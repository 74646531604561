import React from "react";
import FormGroup from "../../components/bootstrap/forms/FormGroup";
import Input from "../../components/bootstrap/forms/Input";
import Button from "../../components/bootstrap/Button";
import { useFormik } from 'formik';
import Spinner from "../../components/bootstrap/Spinner";
import CustomPasswordInput from "../../components/bootstrap/forms/CustomPasswordInput";

interface LoginFormProps {
    isLoading: boolean;
    submit: Function;
}

export const LoginForm: React.FC<LoginFormProps> = ({ isLoading = false, submit }) => {

    const formik = useFormik({
        enableReinitialize: true,
        initialValues: {
            loginUsername: '',
            loginPassword: '',
        },
        validate: (values) => {
            const errors: { loginUsername?: string; loginPassword?: string } = {};
            if (!values.loginUsername) {
                errors.loginUsername = 'El correo electrónico es obligatorio';
            }
            if (!values.loginPassword) {
                errors.loginPassword = 'La contraseña es obligatoria';
            }
            return errors;
        },
        validateOnChange: false,
        onSubmit: (values) => { submit(values.loginUsername, values.loginPassword) },
    });


    return (
        <form onSubmit={formik.handleSubmit} className='row g-4' autoComplete="off">
            <FormGroup id='loginUsername' isFloating label='Correo electrónico' className="col-md-12">
                <Input
                    autoComplete='username'
                    value={formik.values.loginUsername}
                    isTouched={formik.touched.loginUsername}
                    invalidFeedback={formik.errors.loginUsername}
                    isValid={formik.isValid}
                    onChange={formik.handleChange}
                    onBlur={formik.handleBlur}
                    onFocus={() => { formik.setErrors({}) }}
                />
            </FormGroup>
            <CustomPasswordInput
                formik={formik}
                id='loginPassword'
                value={formik.values.loginPassword}
                invalidFeedback={formik.errors.loginPassword}
                cols={12}
            />
            <div className='col-12'>
                <Button color='brand' className='w-100 py-3' type='submit' isDisable={isLoading}>
                    {isLoading ? <Spinner /> : 'Iniciar Sesión'}
                </Button>
            </div>
            <div className='col-12 mt-5'>
                <p className='text-center mb-0'>
                    <a href='/recover-password'>¿Has olvidado tu contraseña?</a>
                </p>
            </div>
        </form>
    )
}