import React from "react";
import OffCanvas, { OffCanvasBody, OffCanvasHeader, OffCanvasTitle } from "../../../components/bootstrap/OffCanvas";
import { AppointmentFormData } from "../../../type/appointment-type";
import { AppointmentForm } from "./AppointmentForm";
import { useAppointmentCalendar } from "../providers/AppointmentCalendarProvider";
import './AppointmentCanvas.css';

interface AppointmentCanvasProps {
    appointment: AppointmentFormData | null;
    setOpen: (status: boolean) => void;
}

export const AppointmentCanvas: React.FC<AppointmentCanvasProps> = ({ appointment, setOpen }) => {

    // Get Calendar Provider
    const { refetchAppointments } = useAppointmentCalendar();

    const _handleSubmited = (values: any) => {
        setOpen(false);
        refetchAppointments();
    }

    return (
        <>
            {appointment && (
                <OffCanvas
                    id="appointment-canvas"
                    setOpen={setOpen}
                    isOpen={appointment !== null}
                    titleId='canvas-title'>
                    <OffCanvasHeader
                        setOpen={setOpen}
                        className='p-4'>
                        <OffCanvasTitle id='canvas-title'>
                            {appointment?.id === undefined ? 'Añadir cita' : 'Editar cita'}
                        </OffCanvasTitle>
                    </OffCanvasHeader>
                    <OffCanvasBody className='p-4'>
                        <AppointmentForm appointment={appointment} onSubmitted={_handleSubmited} />
                    </OffCanvasBody>
                </OffCanvas>
            )}
        </>

    )
}