import { useFormik } from "formik";
import { useAppointmentCalendar } from "../providers/AppointmentCalendarProvider";
import FormGroup from "../../../components/bootstrap/forms/FormGroup";
import { Fragment, useState } from "react";
import { UserSelector } from "../../../components/select/UserSelector";
import Button from "../../../components/bootstrap/Button";
import { AppointmentClientSelector } from "../../../components/select/AppointmentClientSelector";
import Icon from "../../../components/icon/Icon";
import CustomSearchInput from "../../../components/bootstrap/forms/CustomSearchInput";
import OffCanvas, { OffCanvasBody, OffCanvasHeader, OffCanvasTitle } from "../../../components/bootstrap/OffCanvas";
import { DateRangePicker, RangeKeyDict } from "react-date-range";
import moment from "moment";
import { CustomDateRangePicker, RangeDate } from "../../../components/bootstrap/forms/CustomDateRangePicker";
import { AppointmentTypeSelector } from "../../../components/select/AppointmentTypeSelector";
import COLORS from "../../../common/data/enumColors";
import { AppointmentStatusSelector } from "../../../components/select/AppointmentStatusSelector";
import { AppointmentServiceSelector } from "../../../components/select/AppointmentServiceSelector";
import { AppointmentPaymentMethodSelector } from "../../../components/select/AppointmentPaymentMethodSelector";
import Input from "../../../components/bootstrap/forms/Input";

type AppointmentListFiltersProps = {

}

export const AppointmentListFilters: React.FC<AppointmentListFiltersProps> = () => {

    const { updateFilters, filters, resetFilters } = useAppointmentCalendar();
    const [filterMenu, setFilterMenu] = useState<boolean>(false);

    // DatePicker configuration
    const dateNow = moment();

    const initialValues = {
        user: undefined,
        client: undefined,
        appointment_status: undefined,
        type: undefined,
        service: undefined,
        paymentMethod: undefined,
        minAmount: undefined,
        maxAmount: undefined,
    }

    const formik = useFormik({
        initialValues,
        onSubmit: (values: any) => {
            //console.log(values);
            updateFilters({
                ...values,
                search_array: values.search
            })
        }
    })

    return (
        <>
            <Fragment>
                {/* Range picker */}
                <label className='border-0 bg-transparent cursor-pointer' htmlFor='searchInput'>
                    <Icon icon='DateRange' size='2x' color='secondary' />
                </label>
                <CustomDateRangePicker customClass="w-50" color={COLORS.PRIMARY.code} onChangeDate={(range: RangeDate) => {
                    updateFilters({
                        between_dates: {
                            start_date: range.startDate,
                            end_date: range.endDate
                        }
                    } as any);
                }} />


                {/* Search input */}
                <label className='border-0 bg-transparent cursor-pointer' htmlFor='searchInput'>
                    <Icon icon='Search' size='2x' color='secondary' />
                </label>
                <CustomSearchInput onSearch={(search: string) => {
                    updateFilters({ search_array: search });
                    formik.setFieldValue('search', search);
                }} placeholder='Buscar...' />


                {/* Filter button */}
                <Button color='secondary' isLight onClick={() => {
                    setFilterMenu(true);
                }}>Filtros</Button>


                <OffCanvas setOpen={setFilterMenu} isOpen={filterMenu} titleId='patientFilters' isBodyScroll placement='end'>
                    <OffCanvasHeader setOpen={setFilterMenu}>
                        <OffCanvasTitle id='patientFilters'> Filtros de Citas </OffCanvasTitle>
                        <Button
                            color="storybook"
                            isLight
                            onClick={() => {
                                window.location.reload();
                            }}
                        >
                            Resetear
                        </Button>
                    </OffCanvasHeader>
                    <OffCanvasBody>
                        <form onSubmit={formik.handleSubmit} className="d-flex flex-column">

                            <div className="flex-grow">

                                <FormGroup label="Usuario" className="mt-2">
                                    <UserSelector name="user" formik={formik} selectedId={formik.values.user} onChange={formik.handleChange} />
                                </FormGroup>

                                <FormGroup label="Paciente" className="mt-4">
                                    <AppointmentClientSelector name="client" formik={formik} selectedId={formik.values.client} onChange={formik.handleChange} />
                                </FormGroup>

                                <FormGroup label="Estado" className="mt-4">
                                    <AppointmentStatusSelector name="appointment_status" formik={formik} selectedId={formik.values.appointment_status} onChange={formik.handleChange} />
                                </FormGroup>

                                <FormGroup label="Tipo de cita" className="mt-4">
                                    <AppointmentTypeSelector name="type" formik={formik} selectedId={formik.values.type} onChange={formik.handleChange} />
                                </FormGroup>

                                <FormGroup label="Tipo de servicio" className="mt-4">
                                    <AppointmentServiceSelector name="serviceType" formik={formik} selectedId={formik.values.service} onChange={formik.handleChange} />
                                </FormGroup>

                                <FormGroup label="Método de pago" className="mt-4">
                                    <AppointmentPaymentMethodSelector name="paymentMethod" formik={formik} selectedId={formik.values.paymentMethod} onChange={formik.handleChange} />
                                </FormGroup>

                                <FormGroup label='Importe' className='mt-4 row'>
                                    <div className='col-6'>
                                        <div className='row'>
                                            <div className='col-md-4 d-flex flex-column'>
                                                <p className='m-0 d-flex align-items-center' style={{ height: '100%' }}>Mínimo: </p>
                                            </div>
                                            <div className='col-md-8'>
                                                <Input
                                                    name='minAmount'
                                                    type='number'
                                                    value={formik.values.minAmount}
                                                    onChange={formik.handleChange}
                                                />
                                            </div>
                                        </div>
                                    </div>
                                    <div className='col-6'>
                                        <div className='row d-flex justify-content-end'>
                                            <div className='col-md-4 d-flex flex-column'>
                                                <p className='m-0 d-flex align-items-center' style={{ height: '100%' }}>Máximo: </p>
                                            </div>
                                            <div className='col-md-8'>
                                                <Input
                                                    name='maxAmount'
                                                    type='number'
                                                    value={formik.values.maxAmount}
                                                    onChange={formik.handleChange}
                                                />
                                            </div>
                                        </div>
                                    </div>
                                </FormGroup>
                            </div>
                            <div className="row mt-4">

                                <div className="d-flex justify-content-end col">

                                    <Button type="submit" color="primary">Filtrar</Button>

                                </div>
                            </div>

                        </form>
                    </OffCanvasBody>
                </OffCanvas>
            </Fragment>
        </>
    );
}