import { FC } from "react";
import Button from "../../../components/bootstrap/Button";
import { handleConfirmationAlert } from "../../../components/ConfirmationAlert";
import { AddNewRation } from "./AddNewRation";
import { CardTitle } from "../../../components/bootstrap/Card";
import Input from "../../../components/bootstrap/forms/Input";
import FormLabel from "../../../components/FormLabel";

interface IRationsProps {
    rationsData: { name: string, description: string, value: number }[];
    setRationsData: any;
    formik: any;
    verifyClass: any;
    showErrors: any;
}

export const Rations: FC<IRationsProps> = ({ rationsData, setRationsData, formik, verifyClass, showErrors }) => {

    const newRationsData = [...rationsData];

    return (
        <div className="mt-5">
            <CardTitle>Raciones de la ficha técnica</CardTitle>

            {rationsData.length > 0 && (
                <div className="mb-3" style={{ borderBottom: '1px solid lightgrey' }}>
                    <FormLabel cols={4} label="Nombre" style={{ padding: '9.75px', marginBottom: '0px' }} />
                    <FormLabel cols={1} label="Mult." style={{ padding: '9.75px', marginBottom: '0px' }} />
                    <FormLabel cols={6} label="Descripción" style={{ padding: '9.75px', marginBottom: '0px' }} />

                    {rationsData.map((ration: { name: string, description: string, value: number }, index: number) => {
                        return (
                            <div className="row mb-4" key={index}>
                                <div className="col-md-4">
                                    <Input
                                        id={ration.name + index}
                                        value={ration.name}
                                        onChange={(e: any) => {
                                            newRationsData[index].name = e.target.value;
                                            setRationsData(newRationsData);
                                        }}
                                        autoComplete="off"
                                    />
                                </div>

                                <div className="col-md-1">
                                    <Input
                                        id={ration.value + 'multiplier'}
                                        type="number"
                                        value={ration.value}
                                        onChange={(e: any) => {
                                            if (e.target.value < 0) return;
                                            newRationsData[index].value = e.target.value;
                                            setRationsData(newRationsData);
                                        }}
                                        autoComplete="off"
                                    />
                                </div>

                                <div className="col-md-6">
                                    <Input
                                        id={ration.description}
                                        value={ration.description}
                                        onChange={(e: any) => {
                                            newRationsData[index].description = e.target.value;
                                            setRationsData(newRationsData);
                                        }}
                                        autoComplete="off"
                                    />
                                </div>

                                <div className="col-md-1 d-flex align-items-center">
                                    <Button
                                        icon="Delete"
                                        title="Eliminar ración"
                                        onClick={() => {
                                            handleConfirmationAlert({
                                                title: "Eliminar ración",
                                                text: "¿Está seguro que desea eliminar la ración?",
                                                icon: "warning",
                                                onConfirm: () => {
                                                    const newRationsData = [...rationsData];
                                                    newRationsData.splice(index, 1);
                                                    setRationsData(newRationsData);
                                                }
                                            })
                                        }}
                                    />
                                </div>
                            </div>
                        )
                    })}
                </div>
            )}

            <AddNewRation formik={formik} verifyClass={verifyClass} showErrors={showErrors} rationsData={rationsData} />
        </div>
    );
};