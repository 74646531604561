export const generalConfigMenu = {
	generalConfig: {
		id: 'general-config',
		text: 'Config. general',
		path: '/general-config',
		icon: 'Settings',
		access: {
			/* group: 'configurations', */
			group: 'companies',
			action: 'list',
		},
		subMenu: null,
	},
};

export const administrationMenu = {
	companies: {
		id: 'companies',
		text: 'Organizaciones',
		path: '/organizations',
		icon: 'Business',
		access: {
			group: 'companies',
			action: 'list',
		},
		subMenu: null,
	},
	roles: {
		id: 'roles',
		text: 'Roles',
		path: '/roles',
		icon: 'AdminPanelSettings',
		access: {
			group: 'roles',
			action: 'list',
		},
		subMenu: null,
	},
	users: {
		id: 'users',
		text: 'Usuarios',
		path: '/users',
		icon: 'Person',
		access: {
			group: 'users',
			action: 'list',
		},
		subMenu: null,
	},
};

export const authMenu = {
	patients: {
		id: 'patients',
		text: 'Pacientes',
		path: '/clients',
		icon: 'Contacts',
		submenu: {
			clients: {
				id: 'patients',
				text: 'Pacientes',
				path: '/clients',
				icon: 'Contacts',
			},
			tags: {
				id: 'tags',
				text: 'Etiquetas',
				path: '/tags',
				icon: 'LocalOffer',
			}
		},
	},
};

export const calendarMenu = {
	calendar: {
		id: 'calendar',
		text: 'Calendario',
		path: '/appointments/calendar',
		icon: 'CalendarToday',
		access: {
			group: 'appointments',
			action: 'list',
		},
		subMenu: null,
	},
};

export const appointmentsMenu = {
	appointments_list: {
		id: 'appointments-list',
		text: 'Contabilidad',
		path: '/appointments/list',
		icon: 'Savings',
		access: {
			group: 'appointments',
			action: 'list',
		},
		subMenu: null,
	},
};

export const menusMenu = {
	menus: {
		id: 'menus',
		text: 'Nutrición',
		path: '/menus',
		icon: 'RestaurantMenu',
		subMenu: {
			menuList: {
				id: 'menu-list',
				text: 'Menús',
				path: '/menus',
				icon: 'List',
				access: {
					group: 'menus',
					action: 'list',
				},
			},
			foods: {
				id: 'foods',
				text: 'Alimentos',
				path: '/foods',
				icon: 'Kitchen',
				access: {
					group: 'foods',
					action: 'list',
				},
			},
			recipes: {
				id: 'recipes',
				text: 'Recetas',
				path: '/recipes',
				icon: 'MenuBook',
				access: {
					group: 'recipe',
					action: 'list',
				},
			},
			activities: {
				id: 'activities',
				text: 'Actividades',
				path: '/physical_activities',
				icon: 'DirectionsRun',
			},
			menuConfigList: {
				id: 'menu-config-list',
				text: 'Configuraciones',
				path: '/menus/config-list',
				icon: 'Settings',
			},
		},
	},
};

export const formsMenu = {
	forms: {
		id: 'forms',
		text: 'Formularios',
		path: '/forms',
		icon: 'DynamicForm',
		subMenu: {
			typesList: {
				id: 'types-list',
				text: 'Tipos',
				path: '/forms/types-list',
				icon: 'ViewStream',
			},
			formList: {
				id: 'form-list',
				text: 'Lista',
				path: '/forms/forms-list',
				icon: 'List',
			},
		}
	},
};

export const tipsMenu = {
	tips: {
		id: 'tips',
		text: 'Consejos',
		path: '/tips',
		icon: 'TipsAndUpdates',
		access: {
			group: 'tips',
			action: 'list',
		},
		subMenu: {
			tipsGroups: {
				id: 'tips-groups',
				text: 'Grupos',
				path: '/tips/groups',
				icon: 'Workspaces',
			},
			tipsList: {
				id: 'tips-list',
				text: 'Lista',
				path: '/tips/tips',
				icon: 'List',
			},
		},
	},
};