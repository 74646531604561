import { useFormik } from "formik";
import { FC, Fragment, useCallback } from "react";
import * as yup from "yup";
import Card, { CardBody, CardTitle } from "../../../components/bootstrap/Card";
import FormGroup from "../../../components/bootstrap/forms/FormGroup";
import Input from "../../../components/bootstrap/forms/Input";
import Button from "../../../components/bootstrap/Button";
import { useNavigate } from "react-router-dom";
import { MenuService } from "../../../services/menus/menuService";
import { toast } from "react-toastify";
import SubHeader, { SubHeaderLeft, SubHeaderRight, SubheaderSeparator } from "../../../layout/SubHeader/SubHeader";
import Page from "../../../layout/Page/Page";
import useFetch from "../../../hooks/useFetch";
import { CompanyService } from "../../../services/companies/organizationService";
import { Companies, CompaniesApiResponse } from "../../../type/company-type";
import { usePrivilege } from "../../../components/priviledge/PriviledgeProvider";
import { ROLES } from "../../../utils/RoleMapping";
import { menusMenu } from "../../../menu";
import SearchableSelect from "../../../components/select/SearchableSelect";
import AccessDenied from "../../../components/AccessDenied";

export interface ConfigForm {
    name: string;
    day_value: number;
    intake_value: number;
    company?: string | null;
}

const configInitialValues: ConfigForm = {
    name: '',
    day_value: 7,
    intake_value: 5,
    company: null,
}

const ConfigMenu: FC = () => {
    const navigate = useNavigate();
    const priviledge = usePrivilege();

    // obtener las organizaciones
    const fetchOrganizations = useCallback(async () => {
        if (!priviledge.userHasRole(ROLES.SUPER_ADMIN)) return []

        const companyService = new CompanyService();
        const response = await companyService.getOrganizations();
        return response.getResponseData() as CompaniesApiResponse;
    }, []);

    const [organizationsData] = useFetch(fetchOrganizations);

    // crear la configuración de menú
    const handleCreateConfig = async (values: any) => {
        //values.allergens = allergensSelected; // añadir los alérgenos seleccionados al objeto

        try {
            const response = await (await (new MenuService()).createMenuConfig(values)).getResponseData();

            if (response.success) {
                navigate(menusMenu.menus.subMenu.menuConfigList.path, { replace: true });
                setTimeout(() => {
                    toast.success('Configuración de menú añadida');
                }, 100);
            } else {
                toast.error(response.message || 'Error al añadir la configuración');
            }
        } catch (error: any) {
            toast.error(error.message || 'Error al añadir la configuración');
        }
    }

    // devuelve un array de objetos con las organizaciones para mostrar en el select
    const getOrganizationsList = () => {
        if (organizationsData as Companies) {
            return organizationsData.companies.map((company: any) => {
                return {
                    value: company.id,
                    label: company.name,
                }
            })
        }
        return [];
    }

    // validación del formulario
    var CreateConfigSchema = yup.object({
        name: yup.string().required('Campo Obligatorio'),
        day_value: yup.number().min(1, "El mínimo permitido es 1 día").max(31, "El máximo permitido es 31 días").required('Campo Obligatorio'),
        intake_value: yup.number().min(1, "El mínimo permitido es una ingesta").max(10, "El máximo permitido es 10 ingestas").required('Campo Obligatorio'),
    })

    priviledge.userHasRole(ROLES.SUPER_ADMIN) && (
        CreateConfigSchema.concat(yup.object({
            company: yup.string().required('Campo Obligatorio'),
        }))
    )

    const verifyClass = (inputFieldID: keyof ConfigForm) => {
        return (formik.touched[inputFieldID] && formik.errors[inputFieldID]) ? 'is-invalid' : '';
    }

    const showErrors = (inputFieldID: keyof ConfigForm) => {
        return (formik.touched[inputFieldID] && formik.errors[inputFieldID]) ? <div className="invalid-feedback">{formik.errors[inputFieldID]}</div> : <></>;
    }

    const formik = useFormik({
        initialValues: configInitialValues,
        validationSchema: CreateConfigSchema,
        onSubmit: values => {
            handleCreateConfig(values)
        }
    });

    const getContent = () => {
        return (
            <div className='row'>
                <div className='col-md-12'>
                    <Card className='rounded-2'>
                        <CardBody>
                            <form onSubmit={formik.handleSubmit} autoComplete="off">
                                <CardBody isScrollable={false}>
                                    <div className="row d-flex justify-content-between">
                                        {
                                            priviledge.userHasRole(ROLES.SUPER_ADMIN) ? (
                                                <div className="row">
                                                    <div className='col-md-6'>
                                                        <FormGroup requiredInputLabel label='Selecciona la organización'>
                                                            <SearchableSelect
                                                                isSearchable
                                                                onChange={formik.handleChange}
                                                                value={formik.values.company}
                                                                name='organization-select'
                                                                placeholder='organización'
                                                                options={getOrganizationsList()}
                                                                classname={verifyClass('company') + "mb-3"}
                                                            />
                                                            {showErrors('company')}
                                                        </FormGroup>
                                                    </div>
                                                </div>
                                            ) : (
                                                <></>
                                            )
                                        }

                                        <div className="row d-flex justify-content-between">
                                            <FormGroup requiredInputLabel label='Nombre de la configuración' className='col-md-5'>
                                                <Input id='name' onChange={formik.handleChange} value={formik.values.name} onBlur={formik.handleBlur} className={verifyClass('name')} placeholder="Configuración 7x5" />
                                                {showErrors('name')}
                                            </FormGroup>

                                            <FormGroup requiredInputLabel label='Días' className='col-md-2'>
                                                <Input type='number' id='day_value' onChange={formik.handleChange} value={formik.values.day_value} onBlur={formik.handleBlur} className={verifyClass('day_value')} />
                                                {showErrors('day_value')}
                                            </FormGroup>

                                            <FormGroup requiredInputLabel label='Ingestas/día' className='col-md-2'>
                                                <Input type='number' id='intake_value' onChange={formik.handleChange} value={formik.values.intake_value} onBlur={formik.handleBlur} className={verifyClass('intake_value')} />
                                                {showErrors('intake_value')}
                                            </FormGroup>
                                        </div>
                                    </div>
                                </CardBody>
                            </form>
                        </CardBody>
                    </Card>
                </div>
            </div>
        )
    }

    return (

        <Fragment>
            <SubHeader>
                <SubHeaderLeft>
                    <Fragment>
                        <Button
                            color="secondary"
                            isLink
                            icon="ArrowBack"
                            onClick={() => navigate(-1)}
                        />
                        <SubheaderSeparator />
                        <CardTitle>Configuración de plan nutricional</CardTitle>
                    </Fragment>
                </SubHeaderLeft>
                <SubHeaderRight>
                    <Button type='submit' color='primary' onClick={formik.handleSubmit} >
                        Añadir Configuración
                    </Button>
                </SubHeaderRight>
            </SubHeader>
            <Page container='xxl'>
                <Fragment>
                    {getContent()}
                </Fragment>
            </Page>
        </Fragment>

    )
}

export default ConfigMenu;