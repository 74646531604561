import Footer from '../../../layout/Footer/Footer';
import Icon from '../../../components/icon/Icon';

const DefaultFooter = () => {
	return (
		<Footer>
			<p className='m-0'>Hand-crafted and made with <Icon icon='Favorite' color='danger' size='lg' /> by Studio128k</p>
			<p className='m-0'>Nutric &copy;</p>
		</Footer>
	);
};

export default DefaultFooter;
