import { FC, Fragment } from "react";
import Card, { CardBody } from "../../../components/bootstrap/Card";
import Icon from "../../../components/icon/Icon";
import AsyncImg from "../../../components/AsyncImg";

interface FoodInfoCard {
    name_en: string | null;
    food_img: string | null;
}

const FoodInfoCard = () => {

    return (
        <Fragment>
            
        </Fragment>
    )
}

export default FoodInfoCard;