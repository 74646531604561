import { ChangeEvent, FC, useState } from "react";
import FormGroup from "../../../components/bootstrap/forms/FormGroup";
import Input from "../../../components/bootstrap/forms/Input";
import Select from "../../../components/bootstrap/forms/Select";
import { DIFFICULTY, RATION_PRESENTATION, STORAGE_CONDITIONS } from "../../../utils/FoodUtils";
import SearchableSelect from "../../../components/select/SearchableSelect";
import Card, { CardBody, CardHeader, CardLabel, CardTitle } from "../../../components/bootstrap/Card";
import IconWithTooltip from "../../../components/bootstrap/IconWithTooltip";
import { RecipeForm } from "../RecipeForm";

interface RecipeGeneralInfoProps {
    formik: any,
    mode: string,
    getFoodsGroupsList: () => any[],
    getAditionalGroupsList: () => any[],
    getTechniquesList: () => any[],
    getGroupNameById: (id: string) => string,
    group: string,
    newGroup: string,
    setNewGroup: any,
    foodsGroupsData: any,
    refetchGroups: () => void,
}

export const RecipeGeneralInfo: FC<RecipeGeneralInfoProps> = ({
    formik,
    mode,
    getTechniquesList, getFoodsGroupsList, getAditionalGroupsList, getGroupNameById,
    group, newGroup, setNewGroup,
    foodsGroupsData, refetchGroups
}) => {

    const [isGroupSelected, setIsGroupSelected] = useState<boolean>(false);

    const verifyClass = (inputFieldID: keyof RecipeForm) => { return (formik.touched[inputFieldID] && formik.errors[inputFieldID]) ? 'is-invalid' : '' };

    const showErrors = (inputFieldID: keyof RecipeForm) => {
        // @ts-ignore
        return (formik.touched[inputFieldID] && formik.errors[inputFieldID]) ? <div className="invalid-feedback">{formik.errors[inputFieldID]} </div> : <></>;
    };

    return (
        <Card stretch={false}>
            <CardHeader className='rounded-1 mb-0'>
                <CardLabel icon='Info' iconColor='secondary'>
                    <CardTitle>Datos de la receta</CardTitle>
                </CardLabel>
            </CardHeader>

            <CardBody className="row">
                <FormGroup requiredInputLabel label='Nombre' className="col-md-7">
                    <Input id='name'
                        onChange={formik.handleChange}
                        value={formik.values.name}
                        onBlur={formik.handleBlur}
                        className={verifyClass('name')}
                    />
                    {showErrors('name')}
                </FormGroup>

                <FormGroup requiredInputLabel label='Duración (minutos)' className="col-md-3">
                    <Input
                        id='duration'
                        type='number'
                        onChange={formik.handleChange}
                        value={formik.values.duration}
                        onBlur={formik.handleBlur}
                        className={`w-50 ${verifyClass('duration')}`}
                    />
                    {showErrors('duration')}
                </FormGroup>

                <FormGroup requiredInputLabel label='Dificultad' className="col-md-2">
                    <Select
                        id="difficulty"
                        onChange={(selectedOption: ChangeEvent<HTMLInputElement>) => {
                            formik.handleChange(selectedOption);
                            formik.setFieldValue('difficulty', selectedOption.target.value);
                        }}
                        value={formik.values.difficulty}
                        placeholder='Elegir dificultad...'
                        ariaLabel="Dificultad"
                        onBlur={formik.handleBlur}
                        list={DIFFICULTY}
                    />
                    {showErrors('difficulty')}
                </FormGroup>

                <FormGroup label='Técnica culinaria' className="col-md-6 mt-3">
                    <SearchableSelect
                        name="techniques"
                        isSearchable
                        isMulti
                        options={getTechniquesList()}
                        defaultValue={getTechniquesList().filter((option: { isSelected: boolean; }) => option.isSelected)}
                        onChange={(e: any) => {
                            const techniques = e.map((technique: any) => technique.value);
                            formik.setFieldValue('techniques', techniques);
                        }}
                        placeholder='técnicas'
                        onBlur={formik.handleBlur}
                    />
                </FormGroup>

                <FormGroup label='Presentación de la ración' className="col-md-6 mt-3">
                    <Select
                        id="presentation"
                        onChange={(selectedOption: ChangeEvent<HTMLInputElement>) => {
                            formik.handleChange(selectedOption);
                            formik.setFieldValue('presentation', selectedOption.target.value);
                        }}
                        value={formik.values.presentation}
                        placeholder='Elegir presentación...'
                        ariaLabel="presentation"
                        onBlur={formik.handleBlur}
                        list={RATION_PRESENTATION}
                    />
                </FormGroup>

                <FormGroup label='Condiciones de almacenamiento' className="col-md-12 mt-3 mb-3">
                    <Select
                        id="storageConditions"
                        onChange={(selectedOption: ChangeEvent<HTMLInputElement>) => {
                            formik.handleChange(selectedOption);
                            formik.setFieldValue('storageConditions', selectedOption.target.value);
                        }}
                        value={formik.values.storageConditions}
                        placeholder='Elegir condiciones de almacenamiento...'
                        ariaLabel="storageConditions"
                        onBlur={formik.handleBlur}
                        list={STORAGE_CONDITIONS}
                    />
                    {showErrors('storageConditions')}
                </FormGroup>

                {mode === 'Editar' && (
                    <FormGroup label={`Grupo de alimentos actual: ${getGroupNameById(group)}`} className="col-md-6">
                        <Select
                            id="foodGroup"
                            onChange={(selectedOption: ChangeEvent<HTMLInputElement>) => {
                                formik.handleChange(selectedOption);
                                formik.setFieldValue('foodGroup', selectedOption.target.value);
                                setNewGroup(selectedOption.target.value);
                                refetchGroups();
                                setIsGroupSelected(true);
                            }}
                            list={getFoodsGroupsList()}
                            value={newGroup}
                            className="mt-2"
                            placeholder='Seleccione otro grupo para cambiarlo...'
                            ariaLabel="Grupo de alimentos"
                            onBlur={formik.handleBlur}
                        />
                        {showErrors('foodGroup')}
                    </FormGroup>
                )}

                {mode === 'Editar' && foodsGroupsData && (
                    <div className="col-md-6">
                        <div className="mb-2">
                            <label className="labelStyle">Grupos adicionales</label>
                            <IconWithTooltip
                                icon="Info"
                                label="Para cambiar los grupos adicionales, primero debe seleccionar un grupo de alimentos diferente al actual."
                            />
                        </div>
                        <SearchableSelect
                            name="secondaryGroups"
                            isSearchable
                            isMulti
                            options={getAditionalGroupsList()}
                            onChange={(e: any) => {
                                const groups = e.map((group: any) => group.value);
                                formik.setFieldValue('secondaryGroups', groups);
                            }}
                            placeholder='grupos'
                            onBlur={formik.handleBlur}
                            defaultValue={getAditionalGroupsList().filter((option: { isSelected: boolean; }) => option.isSelected)}
                            classname="mt-2"
                            isDisabled={!isGroupSelected}
                        />
                    </div>
                )}
            </CardBody>
        </Card>
    )
}