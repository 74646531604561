import { RestServiceConnection } from '../restServiceConnection';

export class ChatService extends RestServiceConnection {

    getMercureToken = async () => {
        this.response = await this.makeRequest({
            method: 'GET',
            url: '/mercure-token',
        }, true);
        return this;
    }

    sendMessage = async (patientId: string, message: string) => {
        this.response = await this.makeRequest({
            method: 'POST',
            url: '/chat',
            data: {
                topic: patientId,
                text: message,
            }
        }, true);
        return this;
    }

    sendDocument = async (patientId: string, document: File) => {
        this.response = await this.makeRequest({
            method: 'POST',
            url: '/chat',
            data: {
                topic: patientId,
                documentInserted: document,
            },
            headers: {
                "Content-Type": "multipart/form-data"
            }
        }, true);
        return this;
    }

    downloadDocument = async (id: string) => {
        this.response = await this.makeRequest({
            method: 'POST',
            url: '/download-document',
            responseType: 'arraybuffer', // importante para que no se convierta a string
            data: {
                document: id
            }
        }, true);
        return this;
    }

    getPatientMessages = async (patientId: string) => {
        this.response = await this.makeRequest({
            method: 'POST',
            url: '/get-all-messages',
            data: {
                topic: patientId
            }
        }, true);
        return this;
    }

}