import React, { FC, Fragment, useCallback, useState } from 'react'
import Button from '../../../../components/bootstrap/Button'
import FormGroup from '../../../../components/bootstrap/forms/FormGroup'
import OffCanvas, { OffCanvasBody, OffCanvasHeader, OffCanvasTitle } from '../../../../components/bootstrap/OffCanvas'
import Icon from '../../../../components/icon/Icon'
import Select from '../../../../components/bootstrap/forms/Select'
import useFetch from '../../../../hooks/useFetch'
import { PatientService } from '../../../../services/patients/patientService'
import { useSelector } from 'react-redux'
import { RootState } from '../../../../redux/store'
import { Patients, PatientsApiResponse } from '../../../../type/patients-type'
import CustomSearchInput from '../../../../components/bootstrap/forms/CustomSearchInput'

interface IMenuFiltersProps {
    updateFilters: (filters: any) => void
    resetFilters: () => void
    filters: any
}

const MenuFilters: FC<IMenuFiltersProps> = ({ updateFilters, filters, resetFilters }) => {

    const token = useSelector((state: RootState) => state.auth);

    const [filterMenu, setFilterMenu] = useState(false);

    const [patients] = useFetch(useCallback(async () => {
        const patientService = new PatientService();
        const response = await patientService.getPatientsByUser(token.user?.id);
        return response.getResponseData() as PatientsApiResponse;
    }, []));

    // obtener la lista de usuarios
    const getPatientsList = () => {
        if (patients as Patients) {
            return patients.patients.map((option: { id: string; name: string; lastName: string }) => {
                return {
                    value: option.id,
                    label: option.name + (option.lastName ? ' ' + option.lastName : '')
                }
            })
        }
        return [];
    };

    const _handleTextChange = (search: string) => {
        updateFilters({ search_array: search });
    };

    return (
        <Fragment>
            <label className='border-0 bg-transparent cursor-pointer' htmlFor='searchInput'>
                <Icon icon='Search' size='2x' color='secondary' />
            </label>

            <CustomSearchInput onSearch={_handleTextChange} placeholder='Buscar...' />

            <Button color='secondary' isLight icon='Filter' onClick={() => {
                setFilterMenu(true)
            }}>
                Filtros
            </Button>

            <OffCanvas setOpen={setFilterMenu} isOpen={filterMenu} titleId='patientFilters' isBodyScroll placement='end'>
                <OffCanvasHeader setOpen={setFilterMenu}>
                    <OffCanvasTitle id='patientFilters'>Filtros de Menús Nutricionales</OffCanvasTitle>
                    <Button
                        color="storybook"
                        isLight
                        onClick={() => resetFilters()}
                    >
                        Resetear
                    </Button>
                </OffCanvasHeader>

                <OffCanvasBody>
                    <div className='row g-4'>
                        <div className='col-12'>
                            <FormGroup id='filter1' label='Estado'>
                                <Select
                                    id='active-select'
                                    onChange={(e: React.ChangeEvent<HTMLInputElement>) => updateFilters({ active: parseInt(e.target.value) })}
                                    value={filters.filter_filters.active.toString()}
                                    list={[{ value: 3, label: 'Todos', text: 'Todos' },
                                    { value: 1, label: 'Activo', text: 'Activo' },
                                    { value: 0, label: 'Desactivado', text: 'Desactivado' }]}
                                    ariaLabel='Select de estado'
                                    placeholder='Estado...'
                                />
                            </FormGroup>

                            <FormGroup id='filter2' label='Tipo de menú' className='mt-3'>
                                <Select
                                    id='menu-select'
                                    onChange={(e: React.ChangeEvent<HTMLInputElement>) => updateFilters({ is_template: parseInt(e.target.value) })}
                                    value={filters.filter_filters.is_template.toString()}
                                    list={
                                        [
                                            { value: 3, label: 'Todos', text: 'Todos' },
                                            { value: 1, label: 'Plantillas', text: 'Plantillas' },
                                            { value: 0, label: 'Asignados', text: 'Asociados' },
                                        ]
                                    }
                                    ariaLabel='Select de menús'
                                    placeholder='Menús...'
                                />
                            </FormGroup>

                            {
                                patients && (
                                    <FormGroup id='filter3' label='Paciente' className='mt-3'>
                                        <Select
                                            id='patient-select'
                                            onChange={(e: React.ChangeEvent<HTMLInputElement>) => updateFilters({ client: e.target.value })}
                                            value={filters.filter_filters.client}
                                            list={getPatientsList()}
                                            ariaLabel='Listado de profesionales'
                                            placeholder='Paciente...'
                                        />
                                    </FormGroup>
                                )
                            }
                        </div>
                    </div>
                </OffCanvasBody>
            </OffCanvas>
        </Fragment>
    )
}

export default MenuFilters;