import { Fragment, useCallback, useState } from "react";
import { useNavigate } from "react-router-dom";
import Button from "../../../components/bootstrap/Button";
import Card, { CardBody, CardTitle, } from "../../../components/bootstrap/Card";
import Spinner from "../../../components/bootstrap/Spinner";
import StatusDropdown from "../../../components/StatusDropdown";
import Page from "../../../layout/Page/Page";
import SubHeader, { SubHeaderLeft, SubHeaderRight, SubheaderSeparator, } from "../../../layout/SubHeader/SubHeader";
import { UserService } from "../../../services/users/userService";
import UsersFilters from "./users-options/UsersFilters";
import useFetch from "../../../hooks/useFetch";
import { UsersApiResponse } from "../../../type/user-type";
import useFilters from "../../../hooks/useFilters";
import { handleConfirmationAlert } from "../../../components/ConfirmationAlert";
import AsyncImg from "../../../components/AsyncImg";
import { toast } from "react-toastify";
import ErrorMessage from "../../../components/ErrorMessage";
import { CustomTable } from "../../../components/table/CustomTable";
import moment from "moment";
import { ProfileService } from "../../../services/profile/profileService";
import Icon from "../../../components/icon/Icon";

export interface IUserFilters {
  active: number;
  company: string;
  roles?: any;
}

const userFilters: IUserFilters = {
  active: 3,
  company: 'All',
  roles: null,
};

const UsersList = () => {
  const navigate = useNavigate();

  const [changingStatus, setChangingStatus] = useState<string[]>([]);

  const { filters, updateFilters, resetFilters, updateFilterOrder, updatePage, updatePageSize } = useFilters(userFilters);

  const [profile] = useFetch(useCallback(async () => {
    const profileService = new ProfileService();
    const response = await profileService.getMyProfile();
    return response.getResponseData();
  }, []));

  const [data, loading, error, refetch] = useFetch(useCallback(async () => {
    const userService = new UserService();
    const response = await userService.getUsers(filters);
    return response.getResponseData() as UsersApiResponse;
  }, [filters]));

  const toggleUserStatus = async (id: string, status: boolean, toggleStatus: Function) => {
    try {
      setChangingStatus([...changingStatus, id]);
      const response = await (await new UserService().toggleUserStatus(id, status)).getResponseData();

      if (response.success) {
        setChangingStatus(changingStatus.filter((item) => item !== id));
        toggleStatus(status);
        refetch();
        toast.success(status ? 'Se ha activado el usuario' : 'Se ha desactivado el usuario');
      } else {
        setChangingStatus(changingStatus.filter((item) => item !== id));
        toast.error(response.message);
      }
    } catch (error: any) {
      setChangingStatus(changingStatus.filter((item) => item !== id));
      toast.error(error.message);
    }
  };

  const handleDelete = async (id: string) => {
    id === profile?.id ? (
      toast.error('No puedes eliminar tu propio usuario')

    ) : (
      handleConfirmationAlert({
        title: "Eliminar usuario",
        text: "¿Está seguro que desea eliminar el usuario?",
        icon: "warning",
        onConfirm: async () => {
          try {
            const response = await (await (new UserService()).deleteUser(id)).getResponseData();
            if (response.success) {
              refetch();
              toast.success('Usuario eliminado correctamente');
            } else {
              toast.error(response.message || "Error al eliminar el usuario");
            }
          } catch (error: any) {
            toast.error(error.message);
          }
        }
      })
    )
  }

  const getContent = () => {
    if (error) return <ErrorMessage error={error} />;

    if (data && data.users.length > 0) {
      let apiData = data as UsersApiResponse;

      return (
        <CustomTable
          title="Usuarios"
          loading={loading}
          data={apiData.users ? apiData.users : null}
          pagination={true}
          paginationData={{
            pageSize: filters.limit,
            currentPage: filters.page,
            pageCount: apiData as UsersApiResponse ? apiData.lastPage : 1,
            handlePagination: (page: any) => {
              updatePage({ selected: page.selected + 1 });
            },
            handlePerPage: updatePageSize,
          }}
          className={"table-striped table-hover"}

          columns={[
            {
              name: "",
              keyValue: "profileImg",
              render: (element: any) => {
                return (
                  <div className="d-flex justify-content-center">
                    <div className='user-avatar'>
                      <AsyncImg id={element.profileImg ? element.profileImg.id : null} />
                    </div>
                  </div>
                );
              },
            },
            {
              name: "Nombre",
              keyValue: "name",
              sortable: true,
              sortColumn: updateFilterOrder,
              render: (element: any) => {
                return (
                  <div className="cursor-pointer text-secondary" onClick={() => { navigate(`${element.id}/profile`) }}>
                    {element.name + (element.lastName ? " " + element.lastName : "")}
                  </div>
                )
              },

            },
            {
              name: "Rol",
              keyValue: "role",
              sortable: true,
              sortColumn: updateFilterOrder,
              render: (element: any) => {
                return (
                  <>
                    {element.userRoles[0]?.role?.name || "-"}
                  </>
                );
              },
            },
            {
              name: "Correo electrónico",
              keyValue: "email",
              sortable: true,
              sortColumn: updateFilterOrder,
            },
            {
              name: "Teléfono",
              keyValue: "telephone",
              sortable: true,
              sortColumn: updateFilterOrder,
              render: (element: any) => {
                return (
                  <>
                    {element.telephone || "-"}
                  </>
                );
              }
            },
            {
              name: "Último acceso",
              keyValue: "last_login",
              sortable: true,
              sortColumn: updateFilterOrder,
              render: (element: any) => {
                return (
                  <>
                    {moment(element.lastLogin.date).format('hh:mm - DD/MM/YY') || "-"}
                  </>
                );
              },
            },
            {
              name: "Estado",
              keyValue: "active",
              render: (element: any) => {
                if (element.id === profile?.id) {
                  return (
                    <Icon icon='Block' className='icon-large' />
                  );
                } else {
                  return (
                    <StatusDropdown
                      disabled={changingStatus.includes(element.id)}
                      itemId={element.id}
                      status={element.active}
                      change={toggleUserStatus}
                    />
                  );
                }
              },
            },
            { name: "Acciones", className: "min-w-100px text-end", isActionCell: true }
          ]}
          actions={
            [
              {
                title: "Editar",
                icon: "Edit",
                buttonType: 'icon',
                additionalClasses: 'text-primary',
                description: "Editar perfil de usuario",
                click: (item: any) => {
                  navigate(`${item.id}/edit`);
                },
              },
              {
                title: "Eliminar",
                icon: "Delete",
                buttonType: 'icon',
                additionalClasses: 'text-danger',
                description: "Eliminar usuario",
                click: (item: any) => { handleDelete(item.id); },
              },
            ]}
        />
      );
    }
  };

  return (
    <Fragment>
      <SubHeader>
        <SubHeaderLeft>
          <CardTitle>Listado de Usuarios</CardTitle>
          <SubheaderSeparator />
          <Button
            color="storybook"
            title="Crear usuario"
            icon="Add"
            isLight
            onClick={() => { navigate("create") }}
          >
          </Button>
        </SubHeaderLeft>
        <SubHeaderRight>
          <UsersFilters updateFilters={updateFilters} filters={filters} resetFilters={resetFilters} />
        </SubHeaderRight>
      </SubHeader>
      <Page container="fluid">
        <Card stretch={true}>
          <CardBody className="table-responsive" isScrollable={true}>
            <>{getContent()}</>
          </CardBody>
        </Card>
      </Page>
    </Fragment>
  );
};

export default UsersList;