import { FC, useEffect, useState } from "react";
import Button from "../../../components/bootstrap/Button";
import Input from "../../../components/bootstrap/forms/Input";
import FormGroup from "../../../components/bootstrap/forms/FormGroup";

interface IAddNewRationProps {
    formik: any;
    verifyClass: any;
    showErrors: any;
    rationsData: { name: string, description: string, value: number }[];
}

export const AddNewRation: FC<IAddNewRationProps> = ({ formik, verifyClass, showErrors, rationsData }) => {

    const [isEmpty, setIsEmpty] = useState<boolean>(false);

    // Si se agrega una ración, se limpia el textarea
    useEffect(() => {
        if (rationsData.length > 0) {
            formik.setFieldValue('name', '');
            formik.setFieldValue('multiplier', 1);
            formik.setFieldValue('description', '');
        }
    }, [rationsData]);

    // Si el formulario está vacío, se setea isEmpty en true para mostrar los errores
    useEffect(() => {
        if (formik.values.name === '' && formik.values.multiplier === 1 && formik.values.description === '') {
            setIsEmpty(true);
        } else {
            setIsEmpty(false);
        }
    }, [formik.values]);

    return (
        <form onSubmit={formik.handleSubmit} autoComplete="off" className="row">
            <FormGroup label='Nombre' className="col-md-4" requiredInputLabel>
                <Input
                    id={'name'}
                    value={formik.values.name}
                    onChange={formik.handleChange}
                    className={verifyClass('name')}
                />
                {!isEmpty && showErrors('name')}
            </FormGroup>

            <FormGroup label='Mult.' className="col-md-1" requiredInputLabel>
                <Input
                    id={'multiplier'}
                    type="number"
                    value={formik.values.multiplier}
                    onChange={formik.handleChange}
                    className={verifyClass('multiplier')}
                />
                {!isEmpty && showErrors('multiplier')}
            </FormGroup>

            <FormGroup label='Descripción' className="col-md-6">
                <Input
                    id={'description'}
                    value={formik.values.description}
                    onChange={formik.handleChange}
                    className={verifyClass('description')}
                />
                {!isEmpty && showErrors('description')}
            </FormGroup>

            <div className="col-md-12 d-flex justify-content-center">
                <Button
                    color="primary"
                    isLight
                    className=" mt-4"
                    onClick={() => { formik.handleSubmit() }}
                >
                    Agregar ración
                </Button>
            </div>
        </form>
    );
}