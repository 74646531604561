import { FC, useCallback, useState } from "react";
import { useNavigate, useParams } from "react-router-dom";
import useFetch from "../../../hooks/useFetch";
import { toast } from "react-toastify";
import { RecipeService } from "../../../services/recipes/recipeService";
import { Recipe } from "../../../type/recipes-type";
import RecipeForm from "../RecipeForm";

const RecipeEdit: FC = () => {

  const { id = '' } = useParams<{ id: string }>();
  const navigate = useNavigate();

  const recipeService = new RecipeService();

  const [loading, setLoading] = useState<boolean>(false);

  const [data, loadingRecipe, recipeError] = useFetch(useCallback(async () => {
    const response = (await recipeService.getRecipeById(id as string, null));
    return response.getResponseData() as Recipe;
  }, [id]));

  const handleUpdate = async (values: any) => {
    setLoading(true);
    values.recipe = id;

    // Capitalize first letter of name and process
    values.name = values.name.charAt(0).toUpperCase() + values.name.slice(1);
    values.process = values.process.map((process: string) => process.charAt(0).toUpperCase() + process.slice(1));

    try {
      const response = await recipeService.editRecipe(values);
      const responseData = response.getResponseData();

      if (responseData.success) {
        values.image.name !== '' && handleSaveImage(values.image);
        navigate(-1);
        setTimeout(() => {
          toast.success('Receta editada correctamente');
        }, 100);
      } else {
        responseData.data.errors.forEach((error: any) => {
          toast.error(error.message);
        });
      }
    } catch (error: any) {
      toast.error(error.message);
    } finally {
      setLoading(false);
    }
  };

  const handleSaveImage = async (image: any) => {
    try {
      const response = await recipeService.editRecipeImg(id, image);
      /*const responseData = response.getResponseData();

       if (responseData.success) {
        setTimeout(() => {
          toast.success('Imagen actualizada');
        }, 100);
      } */
    } catch (error: any) {
      toast.error("Formato de imagen incorrecto");
    }
  };

  const getContent = () => {
    if (data !== null) {
      const recipeData = {
        company: data.company !== null ? data.company.id : "",
        name: data.name !== null ? data.name : "",
        process: data.process !== null ? data.process : [],
        duration: data.duration !== null ? data.duration : 0,
        difficulty: data.difficulty !== null ? data.difficulty : 'Fácil',
        foodGroup: data.foodGroup !== null ? data.foodGroup.id : "",
        secondaryGroups: data.recipeSecondaryGroups !== null ? data.recipeSecondaryGroups : [],
        ingredients: data.ingredients !== null ? data.ingredients : [],
        techniques: data.techniques !== null ? data.techniques : [],
        storageConditions: data.storageConditions !== null ? data.storageConditions : "",
        presentation: data.presentation !== null ? data.presentation : "",
      };

      return (
        <RecipeForm isLoading={loading} submit={handleUpdate} recipeData={recipeData} recipeImage={data.RecipeImage} />
      );
    }
  };

  return <>{getContent()}</>;
};

export default RecipeEdit;