import React, { ChangeEvent, FC, Fragment, useCallback, useState } from 'react'
import Button from '../../../../components/bootstrap/Button'
import FormGroup from '../../../../components/bootstrap/forms/FormGroup'
import OffCanvas, { OffCanvasBody, OffCanvasHeader, OffCanvasTitle } from '../../../../components/bootstrap/OffCanvas'
import Icon from '../../../../components/icon/Icon'
import Select from '../../../../components/bootstrap/forms/Select'
import ReactSelect from 'react-select'
import { PatientService } from '../../../../services/patients/patientService'
import useFetch from '../../../../hooks/useFetch'
import { Label } from '../../../../type/patients-type'
import Spinner from '../../../../components/bootstrap/Spinner'
import { UserService } from '../../../../services/users/userService'
import { Users, UsersApiResponse } from '../../../../type/user-type'
import { usePrivilege } from "../../../../components/priviledge/PriviledgeProvider";
import CustomSearchInput from "../../../../components/bootstrap/forms/CustomSearchInput";
import { AlimentService } from '../../../../services/aliments/alimentService'
import SearchableSelect from '../../../../components/select/SearchableSelect'

interface IPatientsFiltersProps {
    updateFilters: (filters: any) => void
    resetFilters: () => void
    filters: any
}

const PatientsFilters: FC<IPatientsFiltersProps> = ({ updateFilters, filters, resetFilters }) => {

    const { userCan } = usePrivilege();

    const [filterMenu, setFilterMenu] = useState(false);
    const [minAgeValue, setMinAgeValue] = useState(0);
    const [maxAgeValue, setMaxAgeValue] = useState(100);
    const [labelSelected, setLabelSelected] = useState<Array<string>>([]);
    const [userSelected, setUserSelected] = useState<string>('');
    const [allergensSelected, setAllergensSelected] = useState<any>([]);

    const [labels] = useFetch(useCallback(async () => {
        const patientLabelsService = new PatientService();
        const response = await patientLabelsService.getAllPatientTags();
        return response.getResponseData();
    }, []));

    const [users] = useFetch(useCallback(async () => {
        if (userCan("access_to_all_patients", 'clients')) {
            const userService = new UserService();
            const response = await userService.getUsers();
            return response.getResponseData() as UsersApiResponse;
        }
    }, []));

    const [allergens] = useFetch(useCallback(async () => {
        const response = await (new AlimentService).getAllergensList();
        return response.getResponseData();
    }, []));

    const _handleTextChange = (search: string) => {
        updateFilters({ search_array: search });
    };

    const _handleMinAgeChange = (e: ChangeEvent<HTMLInputElement>) => {
        setMinAgeValue(parseInt(e.target.value));
        setTimeout(() => {
            updateFilters({ minAge: parseInt(e.target.value) });
        }, 800);
    };

    const _handleMaxAgeChange = (e: ChangeEvent<HTMLInputElement>) => {
        setMaxAgeValue(parseInt(e.target.value));
        setTimeout(() => {
            updateFilters({ maxAge: parseInt(e.target.value) });
        }, 800);
    };

    const _handleLabelChange = (e: any) => {
        setLabelSelected(e);
        const labels = e.map((label: any) => label.value);
        updateFilters({ tags: labels });
    };

    const _handleUserChange = (e: any) => {
        setUserSelected(e);
        updateFilters({ user: e });
    };

    // obtener la lista de etiquetas
    const getTagsOptions = () => {
        if (!labels) return [];
        const options = labels?.labels.map((label: Label) => {
            return { value: label.id, label: label.name }
        });
        return options;
    };

    // obtener la lista de usuarios
    const getUsersList = () => {
        if (users as Users) {
            return users.users.map((option: { id: string; name: string; lastName: string }) => {
                return {
                    value: option.id,
                    label: option.name + (option.lastName ? ' ' + option.lastName : '')
                }
            })
        }
        return [];
    };

    // obtener la lista de alérgenos
    const getAllergensOptions = () => {
        if (allergens) {
            return allergens.map((option: { id: any; name: any; nombre: string }) => {
                return {
                    value: option.name,
                    label: option.nombre
                }
            })
        }
        return [];
    }

    return (
        <Fragment>
            <label className='border-0 bg-transparent cursor-pointer' htmlFor='searchInput'>
                <Icon icon='Search' size='2x' color='secondary' />
            </label>
            <CustomSearchInput onSearch={_handleTextChange} placeholder='Buscar...' />

            <Button color='secondary' isLight icon='Filter' onClick={() => {
                setFilterMenu(true)
            }}>
                Filtros
            </Button>

            <OffCanvas setOpen={setFilterMenu} isOpen={filterMenu} titleId='patientFilters' isBodyScroll placement='end'>
                <OffCanvasHeader setOpen={setFilterMenu}>
                    <OffCanvasTitle id='patientFilters'> Filtros de Paciente </OffCanvasTitle>
                    <Button
                        color="storybook"
                        isLight
                        onClick={() => {
                            resetFilters()
                            setLabelSelected([])
                            setUserSelected('')
                        }}
                    >
                        Resetear
                    </Button>
                </OffCanvasHeader>

                <OffCanvasBody>
                    <div className='row g-4'>
                        <div className='col-12'>
                            <FormGroup id='filter1' label='Estado de cuenta'>
                                <Select
                                    id='active-select'
                                    onChange={(e: React.ChangeEvent<HTMLInputElement>) => updateFilters({ active: parseInt(e.target.value) })}
                                    value={filters.filter_filters.active.toString()}
                                    list={[
                                        { value: 3, label: 'Todos', text: 'Todos' },
                                        { value: 1, label: 'Activo', text: 'Activo' },
                                        { value: 0, label: 'Desactivado', text: 'Desactivado' }
                                    ]}
                                    placeholder='Estado...'
                                    ariaLabel='Default select example'
                                />
                            </FormGroup>

                            <FormGroup id='filter2' label='Edad' className='mt-3'>
                                <div className='row'>
                                    <div className='col-6'>
                                        <div className='row'>
                                            <div className='col-4 d-flex flex-column align-content-center'><p className='m-0 d-flex align-content-center' style={{ height: '100%' }}>Desde: </p></div>
                                            <div className='col-6'>
                                                <input
                                                    onChange={(e: React.ChangeEvent<HTMLInputElement>) => _handleMinAgeChange(e)}
                                                    value={minAgeValue.toString()}
                                                    type='number' className='form-control' placeholder='Edad' min={0} max={maxAgeValue}
                                                />
                                            </div>
                                        </div>
                                    </div>
                                    <div className='col-6'>
                                        <div className='row'>
                                            <div className='col-4 d-flex flex-column align-content-center'><p className='m-0 d-flex align-content-center' style={{ height: '100%' }}>Hasta: </p></div>
                                            <div className='col-6'>
                                                <input
                                                    onChange={(e: React.ChangeEvent<HTMLInputElement>) => _handleMaxAgeChange(e)}
                                                    value={maxAgeValue.toString()}
                                                    type='number' className='form-control col-2' placeholder='Edad' min={minAgeValue} max={100}
                                                />
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </FormGroup>

                            <FormGroup id='filter3' label='Etiquetas' className='mt-3'>
                                {
                                    labels ? (
                                        <ReactSelect
                                            isMulti
                                            onChange={(e: any) => _handleLabelChange(e)}
                                            value={labelSelected}
                                            id='tag-select' placeholder='Etiquetas...'
                                            options={getTagsOptions()}
                                            styles={{
                                                control: (base, state) => ({
                                                    ...base,
                                                    borderRadius: '10px',
                                                    boxShadow: state.isFocused ? '0 0 0 3px rgba(93, 236, 227, 0.3)' : '0 -1.5px 0 1px rgba(0,0,0, 0.07) !important',
                                                    border: '0px !important',
                                                    backgroundColor: '#f8f9fa',
                                                }),
                                                option: (provided, state) => ({
                                                    ...provided,
                                                    backgroundColor: state.isFocused ? '#30b7af' : 'white',
                                                    color: state.isFocused ? 'black' : 'grey',
                                                    '&:hover': {
                                                        backgroundColor: '#30b7af',
                                                        color: 'black',
                                                        borderColor: '#30b7af !important'
                                                    }
                                                })
                                            }}
                                            noOptionsMessage={() => 'No hay etiquetas con ese nombre'}
                                        />
                                    ) : (
                                        <Spinner isSmall className='ms-3' />
                                    )
                                }
                            </FormGroup>

                            <FormGroup id='filter4' label='Profesional' className='mt-3'>
                                <Select
                                    onChange={(e: React.ChangeEvent<HTMLInputElement>) => _handleUserChange(e.target.value)}
                                    value={userSelected}
                                    id='user-select' ariaLabel='Listado de profesionales' placeholder='Profesional...'
                                    list={getUsersList()}
                                />
                            </FormGroup>

                            <FormGroup id='filter5' label='Alérgenos' className='mt-3'>
                                {
                                    allergens ? (
                                        <SearchableSelect
                                            isMulti
                                            onChange={(e: any) => {
                                                setAllergensSelected(e);
                                                updateFilters({ allergens: e.map((allergen: any) => allergen.value) });
                                            }}
                                            value={allergensSelected}
                                            name='allergen_filter'
                                            placeholder='alérgenos'
                                            options={getAllergensOptions()}
                                        />
                                    ) : (
                                        <Spinner isSmall className='ms-3' />
                                    )
                                }
                            </FormGroup>
                        </div>
                    </div>
                </OffCanvasBody>
            </OffCanvas>
        </Fragment>
    )
}

export default PatientsFilters;