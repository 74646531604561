import { FC, useCallback } from "react";
import { useParams } from "react-router-dom";
import { RecipeService } from "../../../services/recipes/recipeService";
import { Recipe } from "../../../type/recipes-type";
import useFetch from "../../../hooks/useFetch";
import Spinner from "../../../components/bootstrap/Spinner";

const RecipeView: FC = () => {
    const { id = '' } = useParams<{ id: string }>();

    const fetchRecipe = useCallback(async () => {
        const recipeService = new RecipeService();
        const response = (await recipeService.getRecipeById(id as string, null));
        return response.getResponseData() as Recipe;
    }, [id]);

    const [recipeData, loading, error] = useFetch(fetchRecipe);

    if (loading) return <Spinner />;
    if (error) return <div>Error</div>;

    return (
        recipeData && (
            <></>
        )
    );
};

export default RecipeView;
