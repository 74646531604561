import { useFormik } from "formik";
import SubHeader, { SubHeaderLeft, SubHeaderRight, SubheaderSeparator } from "../../layout/SubHeader/SubHeader";
import Button from "../../components/bootstrap/Button";
import Card, { CardBody, CardTitle } from "../../components/bootstrap/Card";
import Spinner from "../../components/bootstrap/Spinner";
import Page from "../../layout/Page/Page";
import { useNavigate, useParams } from "react-router-dom";
import FormGroup from "../../components/bootstrap/forms/FormGroup";
import Input from "../../components/bootstrap/forms/Input";
import * as Yup from 'yup';
import { useSelector } from "react-redux";
import { RootState } from "../../redux/store";
import { useCallback, useState } from "react";
import CreateTip from "./create-tip/ModalCreateTip";
import { TipsService } from "../../services/tips/tipsService";
import useFetch from "../../hooks/useFetch";
import useFilters from "../../hooks/useFilters";
import ErrorMessage from "../../components/ErrorMessage";
import Icon from "../../components/icon/Icon";
import EasyEdit from 'react-easy-edit';

interface TipFormProps {
    submit: (values: any) => void;
    isLoading: boolean;
    tipData?: any;
}

interface TipForm {
    user: string | undefined;
    name: string;
    description: string;
}

const tipInitialValues: TipForm = {
    user: '',
    name: '',
    description: '',
}

const TipSchema = Yup.object().shape({
    name: Yup.string().required('El nombre es obligatorio'),
    description: Yup.string(),
});

interface ITipsFilters {
    tipGroup?: string;
    client?: string;
}

const TipForm: React.FC<TipFormProps> = ({ submit, isLoading, tipData }) => {
    const navigate = useNavigate();
    const token = useSelector((state: RootState) => state.auth);
    const { id = '' } = useParams<{ id: string }>();
    const mode = tipData ? 'Editar' : 'Crear';
    const tipsService = new TipsService();

    const [displayModal, setDisplayModal] = useState<boolean>(false);

    const formsFilters: ITipsFilters = {
        tipGroup: id,
    };

    const { filters, updateFilters, resetFilters, updateFilterOrder, updatePage, updatePageSize } = useFilters(formsFilters);

    const fetchFills = useCallback(async () => {
        const response = (await tipsService.listTips(filters));
        return response.getResponseData();
    }, [filters]);

    const [tips, loadingTips, tipsError, refetchTips] = useFetch(fetchFills);

    const formik = useFormik<TipForm>({
        initialValues: tipData || tipInitialValues,
        validationSchema: TipSchema,
        onSubmit: values => {
            values.user = token.user?.id;
            submit(values);
        }
    });

    const verifyClass = (inputFieldID: keyof TipForm) => {
        return (formik.touched[inputFieldID] && formik.errors[inputFieldID]) ? 'is-invalid' : '';
    }

    const showErrors = (inputFieldID: keyof TipForm) => {
        return (formik.touched[inputFieldID] && formik.errors[inputFieldID]) ? <div className="invalid-feedback">{formik.errors[inputFieldID]}</div> : <></>;
    }

    const getTipsList = () => {
        if (tipsError) return <ErrorMessage message={tipsError} />;

        return (
            <>
                {
                    tips?.tips.length > 0
                        ? (
                            <div className="row mt-5">
                                {
                                    tips.tips.map((tip: any) => (
                                        <div className="row mt-2" key={tip.id}>
                                            <div className="col-md-3">
                                                <FormGroup label="Nombre">
                                                    <EasyEdit
                                                        type="text"
                                                        value={tip.name}
                                                        onSave={() => { }}
                                                        onCancel={() => { }}
                                                        saveButtonLabel={<Icon icon="Save" />}
                                                        cancelButtonLabel={<Icon icon="Close" />}
                                                        attributes={{ name: "name" }}
                                                    />
                                                </FormGroup>
                                            </div>
                                            <div className="col-md-9">
                                                <FormGroup label="Descripción">

                                                </FormGroup>
                                            </div>
                                        </div>

                                        /* <div className="col-md-3" key={tip.id}>
                                            <Card>
                                                <CardBody>
                                                    <CardTitle>{tip.name}</CardTitle>
                                                    <div className="row">
                                                        <div className="col-md-12">
                                                            <Button
                                                                color="primary"
                                                                className="w-100"
                                                                onClick={() => navigate(`/tips/${tip.id}`)}
                                                            >
                                                                <Icon icon="Edit" />
                                                            </Button>
                                                        </div>
                                                        <div className="col-md-12 mt-2">
                                                            <Button
                                                                color="danger"
                                                                className="w-100"
                                                                onClick={() => { }}
                                                            >
                                                                <Icon icon="Delete" />
                                                            </Button>
                                                        </div>
                                                    </div>
                                                </CardBody>
                                            </Card>
                                        </div> */
                                    ))
                                }
                            </div>
                        )
                        : <div className="text-center">No tiene consejos</div>
                }
            </>
        )
    }

    const getTips = () => {
        return (
            <div className="row mt-5">
                <div className="col-md-12 d-flex justify-content-center">
                    <Button
                        color="primary"
                        className="w-auto"
                        onClick={() => setDisplayModal(true)}
                    >
                        Añadir consejo
                    </Button>
                </div>

                <div className="col-md-12 mt-3">
                    {
                        loadingTips ? <div className="text-center"><Spinner /></div> : getTipsList()
                    }
                </div>

                <div className="col-md-12 mt-3">
                    {
                        displayModal && <CreateTip isOpen={displayModal} setIsOpen={setDisplayModal} groupId={id} refetch={refetchTips} />
                    }
                </div>
            </div>

        )
    }

    const getContent = () => {
        return (
            <form onSubmit={formik.handleSubmit} autoComplete="off">
                <Card>
                    <CardBody>
                        <CardTitle>Datos del grupo de consejos</CardTitle>

                        <div className="row mt-4 d-flex justify-content-between">
                            <div className="col-md-4">
                                <FormGroup label="Nombre" requiredInputLabel>
                                    <Input
                                        type="text"
                                        className={verifyClass('name')}
                                        id="name"
                                        name="name"
                                        onChange={formik.handleChange}
                                        value={formik.values.name}
                                    />
                                    {showErrors('name')}
                                </FormGroup>
                            </div>

                            {/* <div className="col-md-3">
                                <FormGroup label="Documento">
                                </FormGroup>
                            </div> */}
                        </div>

                        <div className="row d-flex justify-content-between mt-3">
                            <div className="col-md-12">
                                <FormGroup label="Descripción">
                                    <textarea
                                        className={`form-control ${verifyClass('description')}`}
                                        id="description"
                                        name="description"
                                        onChange={formik.handleChange}
                                        value={formik.values.description}
                                    />
                                    {showErrors('description')}
                                </FormGroup>
                            </div>
                        </div>

                        {/*TODO: {
                            mode === 'Editar' && getTips()
                        } */}
                    </CardBody>
                </Card>
            </form>
        )
    }

    return (
        <>
            <SubHeader>
                <SubHeaderLeft>
                    <Button color='secondary' isLink icon='ArrowBack' onClick={() => navigate(-1)} />
                    <SubheaderSeparator />
                    <CardTitle>{mode} Grupo</CardTitle>
                </SubHeaderLeft>
                <SubHeaderRight>
                    <Button type="submit" color='primary' onClick={formik.handleSubmit}>
                        {isLoading ? <Spinner /> : `${mode} Grupo`}
                    </Button>
                </SubHeaderRight>
            </SubHeader>
            <Page container='sm' className="w-50">
                {(mode === "Editar" && !tipData) && <Spinner />}
                {(mode === "Editar" && tipData) && getContent()}
                {mode === "Crear" && getContent()}
            </Page>
        </>
    )
}

export default TipForm;