import { FC, Fragment } from "react";
import Card, { CardBody, CardLabel, CardTitle } from "../../../components/bootstrap/Card";
import Icon from "../../../components/icon/Icon";
import AsyncImg from "../../../components/AsyncImg";
import Allergens from "../../../components/Allergens";

interface FoodInfoCard {
    name_en: string | null;
    food_img: string | null;
    foodData?: any;
}

const FoodInfoCard: FC<FoodInfoCard> = ({ name_en, food_img, foodData }) => {
    const getNutritionalValues = () => {
        for (const value in foodData) {
            return <></>
        }
    }

    return (
        <Fragment>
            <Card className='shadow-3d-primary'>
                <CardBody>
                    <div className='row g-5 py-3'>
                        <div className='col-12 d-flex justify-content-center'>
                            <AsyncImg isBackground height="120px" width="120px" styles="rounded-circle" id={food_img} food />
                        </div>
                        <div className='col-12'>
                            <div className='d-flex align-items-center'>
                                <div className='flex-grow-1 fs-5'>
                                    <div className='fw-bold mb-0'>
                                        Alérgenos
                                    </div>
                                    <div className='text-muted'>
                                        <Allergens recipe={foodData} />
                                    </div>
                                </div>
                            </div>

                            <div className='d-flex align-items-center mt-3'>
                                <div className='flex-grow-1 fs-5'>
                                    <div className='fw-bold mb-0'>
                                        Temporada
                                    </div>
                                    <div className='text-muted'>
                                        {foodData.season ? foodData.season + ' a ' : '-'}
                                        {foodData.seasonend ? foodData.seasonend : '-'}
                                    </div>
                                </div>
                            </div>

                            <div className='d-flex align-items-center mt-3'>
                                <div className='flex-grow-1 fs-5'>
                                    <div className='fw-bold mb-0'>
                                        Parte comestible
                                    </div>
                                    <div className='text-muted'>
                                        {foodData.ediblePart ? foodData.ediblePart : '-'}
                                    </div>
                                </div>
                            </div>

                            <div className='d-flex align-items-center mt-3'>
                                <div className='flex-grow-1 fs-5'>
                                    <div className='fw-bold mb-0'>
                                        Valores nutricionales
                                    </div>
                                    <div className='text-muted'>
                                        {
                                            foodData ? getNutritionalValues() : '-'
                                        }
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </CardBody>
            </Card>
        </Fragment>
    )
}

export default FoodInfoCard;