import moment from "moment";

function capitalizeFirstLetter(inputString: string) {
    if (inputString.length === 0) return inputString; // Maneja el caso de una cadena vacía

    return inputString.charAt(0).toUpperCase() + inputString.slice(1);
}

export const getDuration = (start: Date, end: Date): string => {
    const startDateMoment = moment(start);
    const endDateMoment = moment(end);
    const duration = moment.duration(startDateMoment.diff(endDateMoment));

    return capitalizeFirstLetter(duration.locale('es').humanize());
}