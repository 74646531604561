import { useFormik } from "formik";
import FormGroup from "../../../../components/bootstrap/forms/FormGroup";
import Button from "../../../../components/bootstrap/Button";
import SearchableSelect from "../../../../components/select/SearchableSelect";
import useFetch from "../../../../hooks/useFetch";
import { useCallback, useState } from "react";
import { TipsGroupsService } from "../../../../services/tips/groupsService";
import OffCanvas, { OffCanvasBody, OffCanvasHeader, OffCanvasTitle } from "../../../../components/bootstrap/OffCanvas";

interface ITipsFiltersProps {
    updateFilters: (filters: any) => void
    resetFilters: () => void
    filters: any
}

export const TipsFilters: React.FC<ITipsFiltersProps> = ({ updateFilters, filters, resetFilters }) => {

    const [filterMenu, setFilterMenu] = useState(false);
    const [groupsSelected, setGroupsSelected] = useState<any>([]);

    const [groups] = useFetch(useCallback(async () => {
        const response = await (new TipsGroupsService).listGroups();
        return response.getResponseData();
    }, []));

    const getGroupsOptions = () => {
        if (groups) {
            return groups.tip_groups.map((option: { id: string; name: string }) => {
                return {
                    value: option.id,
                    label: option.name
                }
            })
        }
        return [];
    }

    return (
        <>
            <Button color='secondary' isLight icon='Filter' onClick={() => {
                setFilterMenu(true)
            }}>
                Filtros
            </Button>

            <OffCanvas setOpen={setFilterMenu} isOpen={filterMenu} titleId='foodsFilters' isBodyScroll placement='end'>
                <OffCanvasHeader setOpen={setFilterMenu}>
                    <OffCanvasTitle id='foodsFilters'> Filtros de Recetas </OffCanvasTitle>
                    <Button
                        color="storybook"
                        isLight
                        onClick={() => {
                            setGroupsSelected([]);
                            resetFilters();
                        }}
                    >
                        Resetear
                    </Button>
                </OffCanvasHeader>

                <OffCanvasBody>
                    <div className="flex-grow">
                        <FormGroup label="Grupo de consejos" className="mt-2">
                            <SearchableSelect
                                isMulti
                                name="tipGroup-select"
                                options={getGroupsOptions()}
                                onChange={(e: any) => {
                                    setGroupsSelected(e);
                                    updateFilters({ tipGroup: e.map((group: any) => group.value) });
                                }}
                                value={groupsSelected}
                                placeholder="grupo"
                            />
                        </FormGroup>
                    </div>
                </OffCanvasBody>
            </OffCanvas>
        </>
    );
}