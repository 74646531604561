import { FC, useCallback } from "react";
import { useNavigate, useParams } from "react-router-dom";
import Spinner from "../../../components/bootstrap/Spinner";
import useFetch from "../../../hooks/useFetch";
import { AlimentService } from "../../../services/aliments/alimentService";
import { Aliment } from "../../../type/aliments-type";
import { toast } from "react-toastify";
import AlimentForm from "../AlimentForm";
import ErrorMessage from "../../../components/ErrorMessage";

const AlimentEdit: FC = () => {

  const navigate = useNavigate();
  const { id = '' } = useParams<{ id: string }>();

  const [data, loading, error] = useFetch(useCallback(async () => {
    const foodService = new AlimentService();
    const response = await foodService.getAlimentById(id as string);
    return response.getResponseData() as Aliment;
  }, [id]));

  const handleUpdate = async (values: any) => {
    // Capitalize first letter of name
    values.name = values.name.charAt(0).toUpperCase() + values.name.slice(1);
    values.name_en = values.name_en.charAt(0).toUpperCase() + values.name_en.slice(1);

    try {
      const response = await (await (new AlimentService()).editAliment(values)).getResponseData();
      if (response.success) {
        navigate(-1);
        toast.success('Alimento editado correctamente');
      } else {
        toast.error(response.message || "Error al editar el alimento");
      }
    } catch (error: any) {
      toast.error(error.message || "Error al editar el alimento");
    }
  };

  const getContent = () => {
    if (loading) return <div className="text-center"><Spinner /></div>;

    if (error) return <ErrorMessage message={error} />;

    if (data !== null) {
      const alimentData = {
        id: data.id !== null ? data.id : "",
        name: data.name !== null ? data.name : "",
        name_en: data.nameEn !== null ? data.nameEn : "",
        group_food: data.foodGroup.name !== null ? data.foodGroup.name : "",
        edible_part: data.ediblePart !== null ? data.ediblePart : 0,
        nutrients: data.food_nutrients !== null ? data.food_nutrients : "",
        season: data.season !== null ? data.season : "",
        seasonend: data.seasonend !== null ? data.seasonend : "",
        supplement: data.supplement !== null ? data.supplement : false,
      };

      return <AlimentForm isLoading={false} submit={handleUpdate} alimentData={alimentData} foodImage={data.foodImage} />;
    }
  };

  return <>{getContent()}</>;
};

export default AlimentEdit;